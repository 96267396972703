import { fromJS } from 'immutable';
import { SAVE_JOURNAL } from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalsDucks';
import {
  SEARCH_USER,
  CREATE_USER,
  EDIT_USER,
  MANAGE_TITLES_UPDATE_VENDOR,
} from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalUsersDucks';
import {
  USER_PE_TYPE,
  USER_CV_TYPE,
  USER_VBA_TYPE,
  usersTypeMatching,
} from 'app/pages/Admin/ManageTitles/common/constants';
import { createSelectorCreator } from 'reselect';
import memoize from 'memoize-immutable';
import { SUCCESS } from 'app/redux/asyncMiddleware';

export const initialState = fromJS({});

export const keyBy = (type, list) => list.reduce((acc, item) => {
  acc[type + item.id] = item;
  return acc;
}, {});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_JOURNAL: {
      const { journal } = payload;
      return state.merge(fromJS({
        ...keyBy(USER_PE_TYPE, journal[usersTypeMatching[USER_PE_TYPE]]),
        ...keyBy(USER_CV_TYPE, journal[usersTypeMatching[USER_CV_TYPE]]),
        ...keyBy(USER_VBA_TYPE, journal[usersTypeMatching[USER_VBA_TYPE]]),
      }));
    }
    case CREATE_USER + SUCCESS:
    case SEARCH_USER + SUCCESS: {
      const { userType } = action;
      return state.merge(fromJS(keyBy(userType, payload)));
    }
    case EDIT_USER + SUCCESS:
    case MANAGE_TITLES_UPDATE_VENDOR + SUCCESS: {
      const { userType, id } = action;
      return state.mergeDeep(fromJS({
        [`${userType}${id}`]: payload,
      }));
    }
    default:
      return state;
  }
};


const createSelector = createSelectorCreator(memoize);
export const selectUsers = createSelector(state => state, state => state.admin.manageTitles.users.toJS());

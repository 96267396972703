import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useFeatures from 'app/utils/hooks/useFeatures';
import { connect } from 'react-redux';
import useIntervalAction from 'app/utils/hooks/useIntervalAction';
import useArticleHistoryState from 'app/utils/hooks/useArticleHistoryState';
import { loadData, selectData, selectError, selectHasLoading, update } from 'app/redux/detailed/DetailedDucks';
import { clearArticleHistory } from 'app/pages/ArticleHistory/redux/articleHistoryDucks';
import { detailedEndpoints } from 'app/redux/api/common';
import { LA_INTERVAL, LA_TASKS_PATHS, LA_CACHE_PATHS } from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';
import { IN_PROGRESS } from 'app/constants/contentCacheStatuses';
import DetailedArticlesView from './DetailedArticlesView';
import { loadLightArticle } from './redux/DetailedArticlesViewDucks';


const getLightParts = ({ isContentCaching, isScheduleUpdating }) => {
  const paths = [];
  if (isContentCaching) paths.push(...LA_CACHE_PATHS);
  if (isScheduleUpdating) paths.push(...LA_TASKS_PATHS);
  return paths;
};

const DetailedArticlesViewWrap = ({
  isLoading: isArticleLoading,
  clearArticleHistory,
  isDetailedPath,
  id,
  data = {},
  ...props
}) => {
  const { isLoading: isFeaturesLoading, features: { articleMetaDataManage, historyProductionManage } } = useFeatures();
  const { isUpdated: isHistoryUpdated } = useArticleHistoryState();
  const isLoading = isArticleLoading || isFeaturesLoading;

  const { contentCacheStatus } = data;
  const isContentCaching = !isLoading && !isEmpty(data) && (contentCacheStatus === IN_PROGRESS || !contentCacheStatus);
  const isScheduleUpdating = !!(isHistoryUpdated && historyProductionManage);

  useEffect(() => () => clearArticleHistory(), []);

  const parts = getLightParts({ isContentCaching, isScheduleUpdating });
  useIntervalAction({
    intervalMs: LA_INTERVAL,
    condition: isDetailedPath && (isContentCaching || isScheduleUpdating),
    action: loadLightArticle,
    params: { storeType: detailedEndpoints.articleDetailed, id, parts },
  });

  return (
    <DetailedArticlesView
      {...props}
      id={id}
      hasLoading={isLoading}
      isEditable={!!articleMetaDataManage}
      isContentCaching={isContentCaching}
      data={data}
    />
  );
};

DetailedArticlesViewWrap.propTypes = {
  clearArticleHistory: PropTypes.func.isRequired,
  data: PropTypes.shape({
    contentCacheStatus: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  isDetailedPath: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

export default connect(
  (state, { match: { params, isExact } }) => {
    const id = params.article_id;
    const data = selectData(state, detailedEndpoints.articleDetailed, id);
    return {
      isLoading: selectHasLoading(state, detailedEndpoints.articleDetailed, id),
      hasError: selectError(state, detailedEndpoints.articleDetailed, id),
      data,
      id,
      aid: data?.aid,
      isDetailedPath: isExact,
    };
  },
  {
    loadData,
    update,
    clearArticleHistory,
  },
)(DetailedArticlesViewWrap);

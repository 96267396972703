import { apiCall } from 'app/services/api';
import { LOAD_ERROR_MSG } from 'app/constants';
import { endpoints as commonEndpoints } from 'app/redux/api/common';

export const PARTICIPANT_REMOVE = 'remove';
export const PARTICIPANT_EDIT = 'edit';
export const PARTICIPANT_ADD = 'add';
export const PE_PROPERTY = 'productionEditor';
export const VBA_PROPERTY = 'bookinAssociate';

export const endpoints = {
  manageTitles: '/v2/journals',
  participants: '/v2/participants',
  participant: id => `/v2/participants/${id}`,
  changeUserInJournal: (journalId, action) => `/v2/journals/${journalId}/configuration/${action}`,
  changeUsersGroupInJournal: (journalId, action) => `/v2/journals/${journalId}/configuration/${action}`,
  updateInCMHSinceDate: (journalId) => `/v2/journals/${journalId}/configuration/add`,
  removeInCMHSinceDate: (journalId) => `/v2/journals/${journalId}/configuration/remove`,
  deleteUserRole: ({ userId, role }) => `/v2/users/${userId}/role/${role}`,
  updateVendor: ({ userId, vendorId }) => `/v2/participants/${userId}/vendor/${vendorId}`,
};

export const changeUserInJournal = ({ value, property, journalId, action }) => apiCall(
  endpoints.changeUserInJournal(journalId, action),
  {
    property,
    value,
  },
  { method: 'PATCH' },
);

export const changeUsersGroupInJournal = ({ journalId, property, value, action }) => apiCall(
  endpoints.changeUsersGroupInJournal(journalId, action),
  {
    property,
    value,
  },
  {
    method: 'PATCH',
  },
);

export const createParticipant = ({ firstName, lastName, email }) =>
  apiCall(endpoints.participants, { firstName, lastName, email }, { method: 'POST' });

export const editParticipant = ({ id, firstName, lastName }) =>
  apiCall(endpoints.participant(id), { firstName, lastName }, { method: 'PATCH' });

export const searchTitles = acronym =>
  apiCall(commonEndpoints.journals, { exactJournalAcronym: acronym }, { errorMessage5XX: LOAD_ERROR_MSG('journals') }).then((res = {}) => {
    const size = res?.length;

    if (!size) {
      throw new Error('no results');
    }

    return res;
  });

export const searchUsers = (params) =>
  apiCall(endpoints.participants, {
    ...params,
  });

export const assignParticipantVendor = params => apiCall(endpoints.updateVendor(params), {}, { method: 'POST' });
export const deleteParticipantVendor = params => apiCall(endpoints.updateVendor(params), {}, { method: 'DELETE' });
export const updateJournalInCMHSinceDate = ({ journalId, inCMHSinceDate }) =>
  apiCall(inCMHSinceDate
    ? endpoints.updateInCMHSinceDate(journalId)
    : endpoints.removeInCMHSinceDate(journalId),
  { property: 'vendorExchangeEnabled', value: inCMHSinceDate },
  { method: 'PATCH' });

import React, { memo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useFeatures from 'app/utils/hooks/useFeatures';
import useIntervalAction from 'app/utils/hooks/useIntervalAction';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import PaperHeader from 'app/components/PaperHeader';
import { AuthContext } from 'app/modules/Auth';
import { LA_INTERVAL } from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';
import userRoles from 'app/enums/roles';
import useArticleHistoryState from 'app/utils/hooks/useArticleHistoryState';

import { getIsLoading, getError, loadArticleHistory } from './redux/articleHistoryDucks';
import ProductionHistory from './components/ProductionHistory';
import PublicationHistory from './components/PublicationHistory';
import { ERROR_MSG } from './common/constants';


const ArticleHistory = ({ id }) => {
  const { isLoading: isFeaturesLoading } = useFeatures();
  const { roles } = useContext(AuthContext);
  const dispatch = useDispatch();

  const { isUpdated } = useArticleHistoryState();
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getError);

  useEffect(() => {
    dispatch(loadArticleHistory({ id }));
  }, []);

  useIntervalAction({
    intervalMs: LA_INTERVAL,
    condition: isUpdated,
    action: loadArticleHistory,
    params: { id, silent: true },
  });

  if (error) return <PaperHeader title={ERROR_MSG} />;

  const hasLoading = isLoading || isFeaturesLoading;
  const isAdmin = (roles || []).includes(userRoles.systemAdministrator);

  return (
    <Loader hasLoading={hasLoading}>
      <div className="article-history">
        <div className="article-history__left">
          <ProductionHistory
            id={id}
            isLoading={hasLoading}
            isAdmin={isAdmin}
          />
        </div>
        <div className="article-history__right">
          <PublicationHistory
            id={id}
            isLoading={hasLoading}
          />
        </div>
      </div>
    </Loader>
  );
};

ArticleHistory.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(ArticleHistory);

import { getUrgencyOrDefault } from 'app/enums/urgency';
import { IconRender } from 'app/containers/Table/containers/CellRender';
import { LinkRender } from '@wiley/cpp-ui-commons/lib/components/Table/containers/CellRender';
import path from 'app/router/path';
import { prepareDataForIconRender, stringDateToDateModifier } from 'app/utils';

export const descriptor = [
  {
    id: 'urgency',
    caption: 'Urgency',
    sortable: true,
    Render: IconRender,
    colWidth: 18,
    dataModifier: (val, id, row) => prepareDataForIconRender(getUrgencyOrDefault(val), row.alertsCount),
  },
  {
    id: 'issueId',
    caption: 'ID',
    sortable: true,
    colWidth: 18,
    Render: LinkRender,
    dataModifier: (val, id) => ({ title: val, href: path.getInProgressIssuesDetail(id), hrefId: id }),
  },
  {
    id: 'currentTask',
    caption: 'Current task',
    sortable: true,
    colWidth: 20,
  },
  {
    id: 'currentTaskActor',
    caption: 'Actor',
    sortable: true,
    colWidth: 22,
  },
  {
    id: 'currentTaskDueDate',
    caption: 'Task due date',
    sortable: true,
    defaultSortField: true,
    dataModifier: stringDateToDateModifier,
    colWidth: 22,
  },
];

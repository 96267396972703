import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import { unsetSwitchView, selectUser } from 'app/pages/SwitchView/redux/SwitchViewDucks';

export const SwitchViewContext = React.createContext();

export const withSwitchView = Component => {
  const ModifiedComponent = class extends PureComponent {
    static propTypes = {
      onRestoreView: PropTypes.func,
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    };

    render() {
      const { user = {}, onRestoreView } = this.props;
      const data = {
        user,
        hasChangedView: !!user,
        userName: user ? `${user.firstName} ${user.lastName}` : null,
        onRestoreView,
      };
      return (
        <SwitchViewContext.Provider value={data}>
          <Component {...this.props} {...data} />
        </SwitchViewContext.Provider>
      );
    }
  };
  const ConnectedComponent = connect(
    state => ({
      user: selectUser(state),
    }),
    dispatch => ({
      onRestoreView() {
        dispatch(unsetSwitchView());
      },
    }),
  )(ModifiedComponent);

  ConnectedComponent.displayName = `withSwitchView(${Component.displayName || Component.name})`;
  return hoistNonReactStatics(ConnectedComponent, Component);
};

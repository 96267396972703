import { connect } from 'react-redux';
import { stringify } from 'query-string';
import history, { getQueryParams } from '@wiley/cpp-ui-commons/lib/router/history';
import path from 'app/router/path';
import { selectHistory, addSearchHistory } from 'app/pages/Search/redux/SearchDucks';
import SearchByCategory from 'app/components/SearchByCategory';

const mapStateToProps = (state, props) => {
  const { q, type } = getQueryParams();
  const typeIndex = type ? props.selectOptions.findIndex(val => type === val.value) : props.defaultValue;

  return ({
    searchHistory: selectHistory(state),
    defaultInputValue: q,
    defaultValue: typeIndex >= 0 ? typeIndex : undefined,
  });
};

const mapDispatchToProps = dispatch => ({
  addSearchHistory(searchRequest) {
    dispatch(addSearchHistory(searchRequest));
  },

  onSearch({ value: type }, q) {
    history.push(`${path.search}?${stringify({ type, q })}`);
  },
});

const SearchByCategoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchByCategory);

export default SearchByCategoryContainer;

import { fromJS } from 'immutable';
import { createSelectorCreator } from 'reselect';
import memoize from 'memoize-immutable';
import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { getArticlesCount, getIssuesCount, getSearchHistory } from '../api/search';

const initialState = fromJS({
  counts: {},
  history: [],
  countsIsLoading: false,
});

export const FETCH_ENTITIES_COUNT = 'search/entities-count/fetch';
export const ADD_SEARCH_HISTORY = 'search/history/add';
export const FETCH_SEARCH_HISTORY = 'search/history/fetch';

export default function searchReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ENTITIES_COUNT: {
      const [type] = Object.keys(payload);
      const requestValue = payload[type];
      const correctedTypeName = type === 'wildcardJournalAcronym' ? 'jid' : type;
      return state
        .set('counts', fromJS({}))
        .set('countsIsLoading', true)
        .updateIn(['history'], arr =>
          fromJS([
            { type: correctedTypeName, requestValue },
            ...arr.filter(item => correctedTypeName === item.get('type') && item.get('requestValue') !== requestValue).slice(0, 4),
            ...arr.filter(item => correctedTypeName !== item.get('type')),
          ]),
        );
    }
    case FETCH_ENTITIES_COUNT + SUCCESS:
      return state
        .set('counts', fromJS(payload))
        .set('countsIsLoading', false);

    case FETCH_ENTITIES_COUNT + FAILURE:
      return state.set('countsIsLoading', false);

    case FETCH_SEARCH_HISTORY + SUCCESS: {
      const { history } = payload;

      return state.set('history', fromJS(history));
    }
    default:
      return state;
  }
}

export const addSearchHistory = payload => ({
  type: ADD_SEARCH_HISTORY,
  payload,
});

export const fetchEntitiesCount = (search, { extraFilterParamsArticles, extraFilterParamsIssues, extraFilterParamsPublishedArticles }) => ({
  type: FETCH_ENTITIES_COUNT,
  payload: search,
  transformResult: ([{ count: articlesCount }, { count: issuesCount }, { count: publishedArticlesCount }]) => ({ articlesCount, issuesCount, publishedArticlesCount }),
  asyncCall: () => Promise.all([
    getArticlesCount({
      ...search,
      ignoreAssignedJournals: true,
      ...(extraFilterParamsArticles || {}),
    }),
    getIssuesCount({
      ...search,
      ignoreAssignedJournals: true,
      ...(extraFilterParamsIssues || {}),
    }),
    getArticlesCount({
      ...search,
      ignoreAssignedJournals: true,
      ...(extraFilterParamsPublishedArticles || {}),
    }),
  ]),
});

export const setEntitiesCount = (articlesCount, issuesCount, publishedArticlesCount) => ({
  type: FETCH_ENTITIES_COUNT + SUCCESS,
  payload: { issuesCount, articlesCount, publishedArticlesCount },
});

export const fetchSearchHistory = () => ({
  type: FETCH_SEARCH_HISTORY,
  asyncCall: () => getSearchHistory(),
  transformResult: ({ history }) => ({
    history: history
      .map(el => (el.type === 'wildcardJournalAcronym' ? { ...el, type: 'jid' } : el)),
  }),
});

const createSelector = createSelectorCreator(memoize);

const selectState = state => state.search;

export const selectHistory = createSelector(selectState, state => state?.get('history')?.toJS?.() || []);

export const selectEntitiesCount = createSelector(selectState, state => state?.get('counts')?.toJS?.() || {});

export const selectCountsIsLoading = createSelector(selectState, state => state.get('countsIsLoading'));

import { generatePath } from 'react-router-dom';

export const SEARCH_DASHBOARD_ARTICLE_DETAILED = 'dashboard-articles';
export const SEARCH_DASHBOARD_ISSUE_DETAILED = 'dashboard-issues';
export const SEARCH_PUBLISHED_ARTICLE_DETAILED = 'published-articles';

export default {
  default: '/',
  admin: '/admin',
  adminManageTitles: '/admin/manageTitles',
  adminManageTitlesDetail: '/admin/manageTitles/:acronym',
  adminManageUsers: '/admin/manageUsers',
  articlesInIssue: `/search/${SEARCH_DASHBOARD_ARTICLE_DETAILED}`,
  dashboardArticlesSearchDetail: `/search/${SEARCH_DASHBOARD_ARTICLE_DETAILED}/:id`,
  publishedArticlesSearchDetail: `/search/${SEARCH_PUBLISHED_ARTICLE_DETAILED}/:id`,
  dashboard: '/dashboard',
  dashboardJournal: '/dashboard/:acronym',
  help: 'https://wiley.service-now.com/service_gateway?id=sc_cat_item&sys_id=80d57244dbd12300670b30cf9d9619d6',
  inProgress: '/in-progress',
  inProgressArticles: '/in-progress/articles',
  inProgressArticlesDetail: '/in-progress/articles/:article_id/',
  inProgressIssues: '/in-progress/issues',
  inProgressIssuesDetail: '/in-progress/issues/:issue_id/',
  inProgressReadyForIssue: '/in-progress/articlesInIssue',
  inProgressReadyForIssueDetail: '/in-progress/articlesInIssue/:article_id/',
  dashboardIssuesSearchDetail: `/search/${SEARCH_DASHBOARD_ISSUE_DETAILED}/:id`,
  login: '/login',
  logout: '/logout',
  report: '/report',
  requiresAction: '/requires-action',
  requiresActionArticles: '/requires-action/articles',
  requiresActionArticlesDetail: '/requires-action/articles/:article_id/',
  requiresActionIssues: '/requires-action/issues',
  requiresActionIssuesDetail: '/requires-action/issues/:issue_id/',
  search: '/search',
  settings: '/settings',
  switchView: '/switch-view',
  version: '/versionPage',
  preview: 'preview/:fileName/:previewType/:previewContentId/:isVersion',
  previewJobsheet: 'preview/:fileName/:previewType/:jobsheetId/',
  history: 'history',
  privacyPolicy: 'https://www.wiley.com/en-ru/privacy',

  getInProgressArticlesDetail(articleId) {
    return `${this.inProgressArticles}/${articleId}/`;
  },
  getSearchDashboardArticlesDetail(articleId, { type, q }) {
    return `${generatePath(this.dashboardArticlesSearchDetail, { id: articleId })}/?type=${type}&q=${q}`;
  },
  getSearchPublishedArticlesDetail(articleId, { type, q }) {
    return `${generatePath(this.publishedArticlesSearchDetail, { id: articleId })}/?type=${type}&q=${q}`;
  },
  getInProgressReadyForIssueDetail(articleId) {
    return `${this.inProgressReadyForIssue}/${articleId}/`;
  },
  getInProgressIssuesDetail(articleId) {
    return `${this.inProgressIssues}/${articleId}/`;
  },
  getSearchDashboardIssuesDetail(articleId, { type, q }) {
    return `${generatePath(this.dashboardIssuesSearchDetail, { id: articleId })}/?type=${type}&q=${q}`;
  },
  getRequiresArticlesDetail(articleId) {
    return `${this.requiresActionArticles}/${articleId}/`;
  },
  getRequiresIssuesDetail(articleId) {
    return `${this.requiresActionIssues}/${articleId}/`;
  },
  getManageTitles(journalId) {
    return `${this.adminManageTitles}/${journalId}/`;
  },
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SwitchViewContext } from 'app/hoc/withSwitchView';

class SwitchViewRestore extends PureComponent {
  static propTypes = {
    hasMobile: PropTypes.bool,
  };

  static contextType = SwitchViewContext;

  renderMobile() {
    const { userName, onRestoreView } = this.context;
    return (
      <div data-seleniumid="sv-resore-container" className="sv-restore--mobile">
        <div className="sv-restore__container">
          <div className="sv-restore__content">
            View as:{' '}
            <strong>
              {userName}
            </strong>
          </div>
          <input
            type="button"
            data-seleniumid="switch-view-restore"
            className="sv-restore__button"
            value="Restore view"
            onClick={onRestoreView}
          />
        </div>
      </div>
    );
  }

  renderFull() {
    const { userName, onRestoreView } = this.context;
    return (
      <div data-seleniumid="sv-resore-container" className="sv-restore">
        <div className="container sv-restore__container">
          <div className="sv-restore__content">
            You are viewing as{' '}
            <strong>
              {userName}
            </strong>
            . Do you want to go back to your view?
          </div>
          <input
            type="button"
            data-seleniumid="switch-view-restore"
            className="sv-restore__button"
            value="Go back to my view"
            onClick={onRestoreView}
          />
        </div>
      </div>
    );
  }

  render() {
    const { hasMobile } = this.props;
    const { hasChangedView } = this.context;

    if (!hasChangedView) {
      return null;
    }
    return hasMobile ? this.renderMobile() : this.renderFull();
  }
}

export default SwitchViewRestore;

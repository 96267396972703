import React from 'react';
import PropTypes from 'prop-types';
import SelectSearch from '@wiley/cpp-ui-commons/lib/components/SelectSearch';
import ReactTooltip from 'react-tooltip';
import Checkbox from 'app/components/Checkbox';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import cn from 'classnames';
import rolesEnum from 'app/enums/roles';
import { NONE_VENDOR } from 'app/constants';
import { WORKS_FOR_WILEY, CV_SUPER_MSG } from 'app/pages/Admin/common/constants';

import './ManageUserCard.scss';

const ManageUserCard = ({ data, seleniumId, vendors = [], onSelectVendor, onSelectRole, onResetError }) => {
  const { id, firstName, lastName, email, isLoading, assignedJournals, roles, worksFor, error } = data;
  const isSuperuser = roles?.includes(rolesEnum.systemAdministrator);
  const isProductionEditor = roles?.includes(rolesEnum.productionEditor);
  const contentVendor = (worksFor || '').toLowerCase() === WORKS_FOR_WILEY ? null : worksFor;
  const vendorsList = contentVendor ? [{ label: NONE_VENDOR, value: null }, ...vendors] : vendors;

  const onHideError = () => {
    if (!error) return;
    onResetError(id);
  };

  function renderError() {
    const { message } = error || {};
    return (
      <div className={cn('manage-user-card__error-wrap', { active: message })}>
        <span className="manage-user-card__error-close">&times;</span>
        <span className="manage-user-card__error-msg" data-seleniumid={`${seleniumId}-${id}-error-message`}>{message}</span>
      </div>
    );
  }

  const onChangeRole = role => (isAssign) => {
    onSelectRole({ role, isAssign });
  };

  function renderSearch() {
    return (
      <SelectSearch
        disabled={isSuperuser}
        value={contentVendor}
        placeholder="None"
        listMaxHeight={200}
        searchable
        onChange={onSelectVendor}
        seleniumId={seleniumId}
        className="search-select manage-user-card__select"
        options={vendorsList}
      />
    );
  }
  return (
    <div className="manage-user-card" data-id={id} data-seleniumid={`${seleniumId}-${id}-card`} onClick={onHideError}>
      <div className="manage-user-card__container">
        <SectionLoader hasLoading={isLoading} center>
          <div className="manage-user-card__wrap">
            <div className="manage-user-card__info">
              <div className="manage-user-card__user-info">
                <span className="manage-user-card__first-name" data-seleniumid={`${seleniumId}-${id}-firstName`}>
                  {firstName}
                </span>{' '}
                <span className="manage-user-card__last-name" data-seleniumid={`${seleniumId}-${id}-lastName`}>
                  {lastName}
                </span>
              </div>
              <div className="manage-user-card__email" title={email} data-seleniumid={`${seleniumId}-${id}-email`}>{email}</div>
            </div>
            <div className="manage-user-card__assigned">
              <span className="manage-user-card__assigned-title" data-seleniumid={`${seleniumId}-${id}-assignedJournals`}>{assignedJournals}</span>
              <span className="manage-user-card__assigned-text">Assigned</span>
            </div>
            <div className="manage-user-card__vendor">
              <span className="manage-user-card__vendor-title">Content Vendor:</span>
              <div data-tip={isSuperuser ? CV_SUPER_MSG : null}>
                {renderSearch()}
              </div>
            </div>
            <div className="manage-user-card__roles">
              <Checkbox
                checked={isProductionEditor}
                disabled={true}
                seleniumId="role-pe-checkbox"
                label="Production Editor"
              />
              <div data-tip={contentVendor ? CV_SUPER_MSG : null}>
                <Checkbox
                  checked={isSuperuser}
                  disabled={(worksFor || '').toLowerCase() !== WORKS_FOR_WILEY}
                  onClick={onChangeRole(rolesEnum.systemAdministrator)}
                  seleniumId="role-sa-checkbox"
                  label="Superuser"
                />
              </div>
            </div>
            <ReactTooltip
              key={`tooltip-${isSuperuser}`}
              effect="solid"
              type="error"
              place="right"
              html
              className="manage-users__tooltip"
            />
          </div>
        </SectionLoader>
      </div>
      {renderError()}
    </div>
  );
};

ManageUserCard.propTypes = {
  data: PropTypes.shape({
    assignedJournals: PropTypes.number,
    email: PropTypes.string,
    error: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    isLoading: PropTypes.bool,
    lastName: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    worksFor: PropTypes.string,
  }),
  onResetError: PropTypes.func,
  onSelectRole: PropTypes.func,
  onSelectVendor: PropTypes.func,
  seleniumId: PropTypes.string,
  vendors: PropTypes.array,
};

export default ManageUserCard;

import React from 'react';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';

const InfoView = ({ message, icon = 'content' }) => (
  <div className="col-md-12 article-content__info" data-seleniumid="article-info">
    <Svg name={icon} />
    <div>{message}</div>
  </div>
);

InfoView.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default InfoView;

import React from 'react';
import InputWithDropdown from 'app/components/InputWithDropdown';
import Search from './Search';

const SearchWithDropdown = props => (
  <Search
    {...props}
    renderHeader={() => 'Recent history'}
    input={InputWithDropdown}
  />
);

export default SearchWithDropdown;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import path from 'app/router/path';
import AdminDashboard from 'app/pages/Admin/AdminDashboard';
import ManageTitles from 'app/pages/Admin/ManageTitles';
import ManageUsers from 'app/pages/Admin/ManageUsers';

const Admin = () => (
  <>
    <Helmet>
      <title>CMH | Admin dashboard</title>
    </Helmet>
    <Switch>
      <Route path={path.admin} exact component={AdminDashboard} />
      <Route path={path.adminManageTitles} exact component={ManageTitles} />
      <Route path={path.adminManageTitlesDetail} component={ManageTitles} />
      <Route path={path.adminManageUsers} exact component={ManageUsers} />
    </Switch>
  </>
);

Admin.layoutProps = {
  header: {
    tabs: [
      {
        id: 'manage-titles',
        title: 'Manage Titles',
        href: path.adminManageTitles,
      },
      {
        id: 'manage-users',
        title: 'Manage Users',
        href: path.adminManageUsers,
      },
    ],
  },
  tab: {
    id: 'admin',
    title: 'Admin Dashboard',
    to: path.admin,
  },
};

export default Admin;

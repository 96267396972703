import { fromJS, Map } from 'immutable';
import memoize from 'memoize-immutable';
import { createSelectorCreator } from 'reselect';
import { USER_PE_TYPE, USER_CV_TYPE, USER_VBA_TYPE } from 'app/pages/Admin/ManageTitles/common/constants';
import JournalUserDucks from './JournalUsersDucks';
import JournalsDucks from './JournalsDucks';


const initialState = fromJS({});
const emptyAction = { type: '' };
const usersTypes = [USER_PE_TYPE, USER_CV_TYPE, USER_VBA_TYPE];


const journalsReducer = (state = initialState, action = {}) => {
  const { journalId, userType } = action;

  if (!journalId) return state;

  return state.updateIn(
    [journalId],
    journalsState => Map({
      journal: JournalsDucks(journalsState?.get?.('journal'), action),
      ...usersTypes.reduce((acc, type) => {
        acc[type] = JournalUserDucks(journalsState?.get?.(type), type === userType ? action : emptyAction);
        return acc;
      }, {}),
    }),
  );
};

export default journalsReducer;


// Selectors
const createSelector = createSelectorCreator(memoize);
const selectJournalsInfo = createSelector(state => state, state => state.admin.manageTitles?.journals);

export const selectJournals = currentJournalAcronym => createSelector(selectJournalsInfo, (journalsInfo = fromJS({})) => {
  if (!currentJournalAcronym) return [];
  const journals = Object.values(journalsInfo.toJS())
    .map(({ journal }) => journal)
    .filter(({ acronym }) => acronym === currentJournalAcronym.toUpperCase());
  return journals || [];
});
export const selectJournalTabInfo = (journalId, tabType) => createSelector(selectJournalsInfo, (journalsInfo = fromJS({})) => journalsInfo.getIn([journalId, tabType], fromJS({})).toJS());

import { connect } from 'react-redux';
import {
  fetchUsers,
  searchUser,
  updateRole,
  updateVendor,
  resetUserError,
  selectUsers,
  selectIsLoading,
  selectError,
  selectTotalAmount,
} from 'app/pages/Admin/ManageUsers/redux/ManageUsersDucks';
import { setUserRole, deleteUserRole } from 'app/pages/Admin/ManageUsers/api/manageUsers';
import { deleteParticipantVendor, assignParticipantVendor } from 'app/pages/Admin/api/admin';
import { NO_RESULTS_MESSAGE } from 'app/enums/commonErrors';
import { fetchGroups, selectGroups } from 'app/redux/reducers/groups';
import ManageUsers from '../ManageUsers';

export const mapStateToProps = state => {
  const users = selectUsers(state);
  const isLoading = selectIsLoading(state);
  const totalAmount = selectTotalAmount(state);
  const isEmptyResponse = !isLoading && !users.length;
  const error = selectError(state) || isEmptyResponse ? { message: NO_RESULTS_MESSAGE } : null;

  return {
    users,
    isLoading,
    error,
    totalAmount,
    vendors: selectGroups(state),
  };
};

export const mapDispatchToProps = dispatch => ({
  fetchUsers(query) {
    dispatch(fetchUsers(query));
  },
  fetchGroups() {
    dispatch(fetchGroups());
  },
  searchUser(search) {
    dispatch(searchUser({ email: search }));
  },
  updateRoles({ user: { id, userAccountId }, role, isAssign }) {
    const payload = isAssign ? { userId: id, id, userAccountId, role } : { userId: id, role };
    const params = isAssign ? { id, userAccountId, role } : { userId: id, role };
    dispatch(updateRole(payload, params, isAssign ? setUserRole : deleteUserRole));
  },
  updateVendor({ user, vendorId, prevVendorId }) {
    if (vendorId) {
      dispatch(updateVendor({ user, vendorId }, assignParticipantVendor));
    }
    else {
      dispatch(updateVendor({ user, vendorId: prevVendorId }, deleteParticipantVendor));
    }
  },
  resetUserError(userId) {
    dispatch(resetUserError(userId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageUsers);

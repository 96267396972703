import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  disableHistoryProcessingWarning,
} from 'app/pages/ArticleHistory/common/utils';
import { HISTORY_PROCESSING_WARNING_MESSAGE, HISTORY_PROCESSING_WARNING_DISMISS, PRODUCTION_DELAY_MS } from 'app/pages/ArticleHistory/common/constants';
import './HistoryProcessingWarning.scss';


export const toastOptions = {
  autoClose: PRODUCTION_DELAY_MS,
  toastId: 'history_processing_warning',
  type: toast.TYPE.INFO,
  theme: 'light',
};

const HistoryProcessingWarning = ({ closeToast }) => {
  const onClick = useCallback(() => {
    disableHistoryProcessingWarning();
    closeToast();
  }, []);

  return (
    <div data-seleniumid="history-processing-warning">
      <div data-seleniumid="history-processing-warning-message">{HISTORY_PROCESSING_WARNING_MESSAGE}</div>
      <div className="history-processing-warning__button-wrap">
        <button
          className="history-processing-warning__button"
          data-seleniumid="history-processing-warning-button"
          type="button"
          onClick={onClick}
        >
          {HISTORY_PROCESSING_WARNING_DISMISS}
        </button>
      </div>
    </div>
  );
};

HistoryProcessingWarning.propTypes = {
  closeToast: PropTypes.func,
};

export default HistoryProcessingWarning;

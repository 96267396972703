import { takeLatest, call, put, select } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import ls from 'app/services/localStorage';
import path from 'app/router/path';
import history from '@wiley/cpp-ui-commons/lib/router/history';
import { getToken, getPtpId, removeToken } from 'app/services/auth';
import { authenticate, logout } from 'app/modules/Auth/api/auth';
import { getRoles } from 'app/redux/api/common';
import { INIT_APP_ACTION } from 'app/redux/commonActions';
import { cancelRequests } from 'app/requestSpy';
import { AUTH_USER_KEY, AUTH_ROLES_KEY } from 'app/modules/Auth/constants';
import { setAnalyticsUser, clearAnalyticsUser } from 'app/modules/Analytics';
import {
  LOGOUT_REQUEST,
  LOGIN_REQUEST,
  ROLES_REQUEST,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  fetchRolesSuccess,
  fetchRolesFailure,
  selectIsAuth,
  selectPtpId,
  fetchRoles,
} from './AuthDucks';

export function* rolesWorker() {
  try {
    const userId = yield select(selectPtpId);
    const res = yield call(getRoles, userId);
    const { roles } = res;

    ls.setJSON(AUTH_ROLES_KEY, roles);
    yield put(fetchRolesSuccess(roles));
  }
  catch (e) {
    yield put(fetchRolesFailure());
  }
}

export function* loginWorker({ payload }) {
  try {
    const userInfo = yield call(authenticate, payload);
    const token = getToken();
    const ptpid = getPtpId(token);
    const user = { ...userInfo, ptpid };

    yield call(ls.setJSON, AUTH_USER_KEY, user);
    yield call(setAnalyticsUser, user);
    yield put(
      loginSuccess({
        user,
        token,
      }),
    );
    yield put(fetchRoles());

    const { pathname, state } = history.location;
    const isGuestRoute = !matchPath(pathname, path.login);
    const { search, lastPathName } = state || {};

    if (!isGuestRoute) {
      const navigatePath = lastPathName || path.default;
      yield call(history.push, `${navigatePath || path.default}${search || ''}`, {});
    }
  }
  catch (e) {
    yield call(ls.remove, AUTH_USER_KEY);
    yield put(loginFailure(e.response && e.response.data));
  }
}

export function* logoutWorker({ payload }) {
  try {
    const res = yield call(logout);
    yield put(logoutSuccess(res));
  }
  catch (e) {
    yield put(logoutFailure(e));
  }
  finally {
    removeToken();
    cancelRequests();
    // clear all data after logout including last used url if user pressed Logout from the Menu (not expired session)
    if (payload?.byClick) {
      yield call(history.push, path.login, {});
    }
    ls.clear();
    clearAnalyticsUser();
  }
}

export function* watchAuth() {
  yield takeLatest(LOGIN_REQUEST, loginWorker);
  yield takeLatest(LOGOUT_REQUEST, logoutWorker);
  yield takeLatest(ROLES_REQUEST, rolesWorker);

  const isAuthorized = yield select(selectIsAuth);
  if (isAuthorized) {
    yield takeLatest(INIT_APP_ACTION, rolesWorker);
  }
}

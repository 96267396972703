import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import DetailedSection from 'app/components/DetailedSection';
import ExpandButton from 'app/components/ExpandButton';
import Comment from 'app/pages/DetailedViews/DetailedArticlesView/components/Comment';
import EditComment from 'app/pages/DetailedViews/DetailedArticlesView/components/EditComment';
import commentType, { PRODUCTION_COMMENT_TYPE, GENERAL_COMMENT_TYPE, EDITORIAL_COMMENT_TYPE } from 'app/enums/articleCommentType';
import './Comments.scss';

const CommentsList = ({ generalComment, productionComments, editorialComments, id, isEditable, updateMetaData }) => {
  const generalCommentEntity = (isEditable || generalComment) ? { type: GENERAL_COMMENT_TYPE, text: generalComment, edit: isEditable } : null;

  const comments = useMemo(() => [
    ...(generalCommentEntity ? [generalCommentEntity] : []),
    ...(productionComments || []).filter(comment => !!comment).map(comment => ({ type: PRODUCTION_COMMENT_TYPE, text: comment })),
    ...(editorialComments || []).filter(comment => !!comment).map(comment => ({ type: EDITORIAL_COMMENT_TYPE, text: comment })),
  ], [generalComment, productionComments, editorialComments]);
  const [showAllStatus, setStatus] = useState(false);

  return (
    <div>
      {
        comments.length > 0
          ? (
            <DetailedSection title="Article comments">
              <div className="row">
                { comments.map((comment, i) => {
                  if (!showAllStatus && i > 1) return;
                  const Component = comment.edit ? EditComment : Comment;
                  return (
                    <div className="col-md-6 py-2" key={i}>
                      <Component
                        id={id}
                        title={commentType[comment.type]}
                        text={comment.text}
                        updateMetaData={updateMetaData}
                      />
                    </div>
                  );
                }) }
              </div>
              { comments.length > 2
              && (
                <div className="d-flex justify-content-left ml-1 mt-3">
                  <ExpandButton
                    seleniumId="comments-show-more-toggle"
                    isExpanded={showAllStatus}
                    onClick={() => setStatus(!showAllStatus)}
                  />
                </div>
              )
              }
            </DetailedSection>
          )
          : (
            <div className="col-md-12 article-comments__empty">
              <Svg name="comments" />
              Article has no comments
            </div>
          )
      }
    </div>
  );
};

CommentsList.propTypes = {
  editorialComments: PropTypes.arrayOf(PropTypes.string),
  generalComment: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  productionComments: PropTypes.arrayOf(PropTypes.string),
  updateMetaData: PropTypes.func.isRequired,
};

export default CommentsList;

export default {
  AUTHSVC_UNAUTHORIZED: 'Authentication failed. Please try again',
  AUTHSVC_LOCKED: 'User account is locked. Please try again later',
  AUTHSVC_GOT_LOCKED:
    'Limit of failed login attempts has been exceeded and now account is locked. Please try again later',
  AUTHSVC_UNVERIFIED: 'Your account is not active. Please activate your account',
  AUTHSVC_UNREGISTERED: 'User not found',
  AUTHSVC_INVALID_DATA: 'Invalid data provided',
  AUTHSVC_INT_ERR: 'Request cannot be completed',
  AUTHSVC_UKNOWN_APP: 'Invalid or unknown application key',
  AUTHSVC_TOKEN_VALID: 'Token successfully validated',
  AUTHSVC_TOKEN_INVALID: 'Invalid token. Token is probably expired.',
};

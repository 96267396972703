import React from 'react';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';

export default function ActionIconsGroup({ iconsGroup }) {
  return (
    <div className="action-view__icon-cover">
      <Svg name={iconsGroup.cover} className={`icon-${iconsGroup.cover}`} />
      { iconsGroup.status && <Svg name={iconsGroup.status} className="icon-status" /> }
    </div>
  );
}

ActionIconsGroup.propTypes = {
  iconsGroup: PropTypes.shape({
    cover: PropTypes.string,
    status: PropTypes.string,
  }),
};

import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';

const StickyContainerIE = ({ children }) => (
  <div className="sticky-container">
    <Sticky
      hideOnBoundaryHit={false}
      positionRecheckInterval={100}
      boundaryElement=".container"
      stickyClassName="sticky-ie"
      scrollElement=".scrollable"
      className="sticky-el"
      style={{ transform: 'none' }}
      stickyStyle={{ transform: 'none' }}
    >
      <div id="stickable-container" />
    </Sticky>
    <>{children}</>
  </div>
);

StickyContainerIE.propTypes = {
  children: PropTypes.any,
};

export default StickyContainerIE;

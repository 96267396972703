import { combineReducers } from 'redux';

import journals from './Journals';
import state from './JournalStateDucks';
import users from './UsersDucks';

export default combineReducers({
  users,
  state,
  journals,
});

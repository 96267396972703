import { startCase, compact } from 'lodash';
import ls from 'app/services/localStorage';
import { differenceInDays, isAfter, isBefore, parseISO } from 'date-fns';
import {
  between,
  parseDatePickerDate,
  parsedDateFromString,
  stringDateToDateModifierCustomFormat,
} from 'app/utils/utils';
import {
  INCORRECT_DATE_FORMAT,
  INCORRECT_MONTH,
  INCORRECT_DAY,
  WARNING_FUTURE_MSG,
  HISTORY_PROCESSING_WARNING_LS_KEY,
} from 'app/pages/ArticleHistory/common/constants';
import { LONG_MONTH_DATE } from 'app/constants';
import {
  ACTUAL_DATE,
  MNEMONIC_EVENT,
  REVISED_DATE,
} from 'app/constants/articleWorkFlowEvents';

export const ACTUAL_REVISED_DATES_RANGE = 90;

export const getProductionHistoryItems = data => (data || []).map(item => {
  const {
    id,
    [MNEMONIC_EVENT]: title,
    [ACTUAL_DATE]: actualDate = '',
    [REVISED_DATE]: revisedDate = '',
    ...rest
  } = item;
  const { [`raw_${REVISED_DATE}`]: rawRevisedDate } = rest || {};
  return {
    id,
    title,
    actualDate: stringDateToDateModifierCustomFormat(actualDate, true, LONG_MONTH_DATE),
    revisedDate: stringDateToDateModifierCustomFormat(revisedDate, true, LONG_MONTH_DATE),
    ...(rawRevisedDate ? { rawRevisedDate: stringDateToDateModifierCustomFormat(rawRevisedDate, true, LONG_MONTH_DATE) } : {}),
  };
}, []);

export const getPublicationHistoryItems = data => (data || []).map(({ id, date, rawDate }) => ({
  id,
  title: startCase(id),
  date: stringDateToDateModifierCustomFormat(date, true, LONG_MONTH_DATE),
  ...(rawDate ? { rawDate: stringDateToDateModifierCustomFormat(rawDate, true, LONG_MONTH_DATE) } : {}),
}));

export const getValidateError = (rawDate) => {
  const date = (rawDate || '').replace(/_/g, '');
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) return INCORRECT_DATE_FORMAT;

  const [mm, dd] = date.split('/');
  if (!between(mm, 1, 12)) return INCORRECT_MONTH;
  if (!between(dd, 1, 31)) return INCORRECT_DAY;

  return null;
};

export const parseISOStringDate = stringDate => (stringDate ? parseISO(stringDate) : null);
export const parseStringDate = stringDate => parsedDateFromString(stringDate, LONG_MONTH_DATE);
export const parseTimeToString = (date, format = LONG_MONTH_DATE) => stringDateToDateModifierCustomFormat(date, true, format);

/**
 * function parses to Date with an offset equal timezone
 *
 * @param date (e.g. Wed Jun 30 2021 15:29:33 GMT+0300)
 * @param stringDate (e.g. 19 February 2021)
 * @param range offset between dates
 *
 * parsedDate:  Wed Jun 30 2021 03:00:00 GMT+0300
 * parsedStringDate: Fri Feb 19 2021 03:00:00 GMT+0300
 */
export const isDateMoreStringDate = (date, stringDate, range = 0) => {
  const parsedDate = parseDatePickerDate(date);
  const parsedStringDate = parseDatePickerDate(parseStringDate(stringDate));
  return Math.abs(differenceInDays(parsedDate, parsedStringDate)) > range;
};

const getComparablePublicationWarningMessage = ({ currentDate, items, msg, fields, compareFunc }) => {
  if (!msg) return null;

  if (fields.some(el => {
    const { date, rawDate } = items.find(item => item.id === el);
    if (!date && !rawDate) return false;
    return compareFunc(currentDate, parseStringDate(rawDate || date));
  })) return msg;

  return null;
};
/**
 * Function checks conditions for warning message:
 * 1.1) If currentDate is after one of other dates (fields)
 * 1.2) If currentDate is before one of other dates (fields)
 * 2) If currentDate is in future
 *
 * @param currentDate
 * @param items items from store (including date and rawDate)
 * @param msg warning message for first condition
 * @param fields dates ids (values from PUBLICATIONS_EDIT_FIELDS)
 * @returns {string|null|*}
 */
export const getPublicationWarningMessage = ({
  date: currentDate,
  items = [],
  before = {},
  after = {},
}) => {
  const compareMessage = compact([
    getComparablePublicationWarningMessage({ currentDate, items, compareFunc: isAfter, ...after }),
    getComparablePublicationWarningMessage({ currentDate, items, compareFunc: isBefore, ...before }),
  ]);
  if (compareMessage.length) return compareMessage;

  if (isAfter(currentDate, new Date())) return WARNING_FUTURE_MSG;
  return null;
};

export const parseProductionApiPayload = ({ id, date, type }) => ({ scheduleId: id, [type]: date });
export const parsePublicationId = id => id.split('manuscript').reverse()[0].toLowerCase();
export const parsePublicationApiPayload = ({ id, date }) => ({ manuscriptDate: parsePublicationId(id), date });


export const isHistoryProcessingWarningDisabled = () => !!ls.getJSON(HISTORY_PROCESSING_WARNING_LS_KEY);
export const disableHistoryProcessingWarning = () => {
  ls.setJSON(HISTORY_PROCESSING_WARNING_LS_KEY, true);
};

export const getErrorTooltip = content => ({
  className: '',
  content,
  spyClassName: 'date-cell-datepicker',
  type: 'error',
  effect: 'solid',
  place: 'left',
});

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import Table from '@wiley/cpp-ui-commons/lib/components/Table';
import useFeatures from 'app/utils/hooks/useFeatures';


import { NO_RESULTS_MESSAGE } from 'app/enums/commonErrors';
import { PUBLICATION_TITLE } from 'app/pages/ArticleHistory/common/constants';
import { getPublicationHistoryItems } from 'app/pages/ArticleHistory/common/utils';
import tableDescriptor from 'app/pages/ArticleHistory/common/publicationTableDescriptor';
import { getHistory, updateHistory } from '../redux/publicationArticleHistoryDucks';
import ScheduleRow from './table/ScheduleRow';
import './PublicationHistory.scss';

export const PublicationHistory = ({ id, isLoading, updateHistory, history = [] }) => {
  const { features: { historyPublicationManage } } = useFeatures();
  const items = useMemo(() => getPublicationHistoryItems(history), [history]);
  const descriptor = useMemo(() => tableDescriptor(id, updateHistory, historyPublicationManage, items),
    [id, updateHistory, historyPublicationManage, items],
  );

  return (
    <div>
      <h4 data-seleniumid="publication-history">{PUBLICATION_TITLE}</h4>
      <Table
        className="publication-history__table"
        renderRow={props => <ScheduleRow {...props} />}
        descriptor={descriptor}
        items={items}
        showHeader={false}
      />
      {(!isLoading && !history?.length) && <InfoView title={NO_RESULTS_MESSAGE} seleniumId="empty-production-history" />}
    </div>
  );
};

PublicationHistory.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      actualDate: PropTypes.string,
      id: PropTypes.string,
      revisedDate: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  updateHistory: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    history: getHistory(state),
  }),
  {
    updateHistory,
  },
)(PublicationHistory);

import http from 'app/services/http';

export default (url, httpParams, { raw = false, method = 'GET', errorMessage5XX = null } = {}) => {
  const initRequest = {
    method,
    url,
    data: httpParams,
    errorMessage5XX, // for errors with status >=500, see requestErrorSpy.js for more details
  };

  if (raw) {
    initRequest.baseURL = '';
  }

  if (method === 'GET') {
    initRequest.params = httpParams;
  }

  return http
    .request(initRequest)
    .then(res => {
      if (res && res.status) {
        if (res.status >= 200 && res.status <= 299) {
          return res.data;
        }
        return Promise.reject(res.data);
      }
      return Promise.reject({ message: 'Empty response' });
    })
    .catch(err => Promise.reject(err));
};

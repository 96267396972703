import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { generateDoiURL } from 'app/utils/utils';

import './FunderDetails.scss';

class FunderDetails extends PureComponent {
  static propTypes = {
    config: PropTypes.shape({
      funders: PropTypes.arrayOf(PropTypes.shape({
        doi: PropTypes.string,
        grantIds: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
      })),
      message: PropTypes.string,
    }),
  };

  renderFunder = ({ name, doi, grantIds }, key) => {
    const grantIdsValue = grantIds && grantIds.length ? grantIds.join(', ') : 'N/A';
    const nameValue = name || 'N/A';
    const seleniumPart = `funder-details__funder-${key}`;
    const doiValue = doi ? (
      <a href={generateDoiURL(doi)} target="_blank" data-seleniumid={`${seleniumPart}-doi`}>
        {doi}
      </a>
    ) : <span data-seleniumid={`${seleniumPart}-doi`}>No match found</span>;

    return (
      <div key={key} className="funder-details__funder" data-seleniumid={`${seleniumPart}`}>
        <div className="funder-details__funder-header">
          <span className="funder-details__funder-name" data-seleniumid={`${seleniumPart}-name`}>
            { nameValue }
          </span>
          {' '}
          <span className="funder-details__funder-doi">
            { doiValue }
          </span>
        </div>
        <div>
          <span className="funder-details__title--small">Grant ID(s): </span>
          <span className="funder-details__funder-grand-ids" data-seleniumid={`funder-details__funder-${key}-grand-ids`}>
            {grantIdsValue}
          </span>
        </div>
      </div>
    );
  };

  renderFunders(funders) {
    if (!(funders && funders.length)) {
      return (
        <div className="funder-details__na" data-seleniumid="funder-details__funders">N/A</div>
      );
    }

    return (
      <div className="funder-details__funders" data-seleniumid="funder-details__funders">
        {funders.map(this.renderFunder)}
      </div>
    );
  }

  render() {
    const { config } = this.props;
    if (!config) {
      return (<div className="funder-details__na" data-seleniumid="funder-details">N/A</div>);
    }

    const { message, funders } = config;

    return (
      <div className="funder-details" data-seleniumid="funder-details">
        {message && <div className="funder-details__message">{message}</div>}
        <div className="funder-details__title">Funders:</div>
        {this.renderFunders(funders)}
      </div>
    );
  }
}

export default FunderDetails;

import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@wiley/cpp-ui-commons/lib/components/Select';

const getOptions = (arr, value) => {
  if (!value) return arr;
  return [value, ...arr.filter(el => el !== value)];
};


const SelectInput = ({ options: initOptions = [], searchable = false, value: prevValue, onChange }) => {
  const [options, setOptions] = useState(getOptions(initOptions, prevValue));
  const onSearch = (rawValue) => {
    const newValue = rawValue.trim();
    const newOptions = initOptions.filter(el => el.toLowerCase().includes(newValue.toLowerCase()));
    setOptions(prevValue ? [prevValue, ...newOptions] : newOptions);
  };

  useLayoutEffect(() => {
    document.querySelector('[data-seleniumid="edit-field-select-input"] .dd__search').focus();
  }, []);

  return (
    <Select
      options={options}
      onSearch={onSearch}
      className="edit-field-select-input"
      searchable={searchable}
      placeholder="Select value"
      maxLengthSelectedValue={Infinity}
      seleniumId="edit-field-select-input"
      onChange={({ value }) => onChange(prevValue, value)}
      value={prevValue}
    />
  );
};

SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  searchable: PropTypes.bool,
  value: PropTypes.string,
};

export default SelectInput;

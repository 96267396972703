import { applyMiddleware, createStore, compose } from 'redux';
import rootSaga from 'app/redux/sagas';
import rootReducer from 'app/redux/reducers';

import middleware, { sagaMiddleware } from './middleware';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configStore = (initialState = {}) => {
  const store = createStore(rootReducer, initialState, composeEnhancer(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('app/redux/reducers', () => {
      store.replaceReducer(require('app/redux/reducers').default);
    });
  }

  return store;
};

const store = configStore();
global.store = store;

export default store;

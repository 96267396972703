const MAX_TIMEOUT_FOR_SPINNER = 5000;
const INTERVAL_FOR_SPINNER = 100;

let intervalForSpinner;

export const getSpinner = () => document.querySelector('.section-loader.disabled');

export const waitForSpinners = () => new Promise((resolve, reject) => {
  intervalForSpinner = setInterval(() => {
    if (getSpinner() === null) {
      resolve();
    }
  }, INTERVAL_FOR_SPINNER);

  setTimeout(() => {
    clearInterval(intervalForSpinner);
    reject();
  }, MAX_TIMEOUT_FOR_SPINNER);
});

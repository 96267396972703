import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { noop } from 'lodash';
import withTableFilter from 'app/modules/Table/hoc/withTableFilter';
import FilterTableView from 'app/modules/Table/components/FilterTableView';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Pager from '@wiley/cpp-ui-commons/lib/components/Pager';
import urgencyEnum, { ignoreListForRAPage } from 'app/enums/urgency';
import UrgencyFilter from './UrgencyFilter';

const applyIgnoreListForRAPage = item => !ignoreListForRAPage.includes(item.optionId);
export const filterTempFacets = facets => {
  if (!facets) return facets;
  const { options, ...rest } = facets;
  const filteredOptions = options ? { options: options.filter(applyIgnoreListForRAPage) } : {};

  return { ...rest, ...filteredOptions };
};

class RequiresActionContainer extends PureComponent {
  static propTypes = {
    descriptor: PropTypes.array.isRequired,
    entitiesError: PropTypes.any,
    entitiesHasLoading: PropTypes.bool,
    facetsCategories: PropTypes.arrayOf(PropTypes.string),
    facetsError: PropTypes.any,
    facetsHasLoading: PropTypes.bool,
    facetsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.string,
        count: PropTypes.number,
        optionId: PropTypes.string,
        parentId: PropTypes.string,
      }),
    ),
    hideFilter: PropTypes.bool,
    items: PropTypes.array,
    itemsCount: PropTypes.number,
    onApplySelectedFilters: PropTypes.func.isRequired,
    onChangeCount: PropTypes.func.isRequired,
    onChangePage: PropTypes.func,
    onChangeTable: PropTypes.func.isRequired,
    onUpdateFilters: PropTypes.func.isRequired,
    onUpdateSelectedFilters: PropTypes.func,
    pageCount: PropTypes.number,
    pageTitle: PropTypes.string,
    renderError: PropTypes.func,
    renderNoResults: PropTypes.func,
    selectedFilters: PropTypes.array,
    stats: PropTypes.shape({
      currentPage: PropTypes.number,
      pageSize: PropTypes.number,
      pageSizeOptions: PropTypes.array,
      sortDir: PropTypes.string,
      sortField: PropTypes.string,
    }),
    tempFacets: PropTypes.object,
    title: PropTypes.string,
  };

  get title() {
    const { selectedFilters, pageTitle, hideFilter } = this.props;

    if (hideFilter) {
      return pageTitle;
    }

    return selectedFilters
      .filter(item => item.categoryId === 'urgency')
      .map(item => item.valueText || urgencyEnum[item.optionId])
      .join(', ') || 'All';
  }

  render() {
    const {
      pageCount,
      title,
      onChangePage,
      entitiesHasLoading,
      items,
      itemsCount,
      onUpdateSelectedFilters,
      selectedFilters,
      stats,
      facetsOptions,
      tempFacets,
      hideFilter,
    } = this.props;

    return (
      <>
        <Helmet>
          <title>{`CMH | Requires Action | ${title}`}</title>
        </Helmet>
        {
          hideFilter
            ? null
            : (
              <UrgencyFilter
                type="article"
                options={facetsOptions.filter(applyIgnoreListForRAPage)}
                selectedFilters={selectedFilters}
                onChangeFilter={entitiesHasLoading ? noop : onUpdateSelectedFilters}
              />
            )
        }
        <Loader hasLoading={entitiesHasLoading}>
          <FilterTableView
            {...this.props}
            tempFacets={filterTempFacets(tempFacets)}
            facetsOptions={facetsOptions.filter(applyIgnoreListForRAPage)}
            items={items}
            allPagesSizes={stats.pageSizeOptions}
            currentPage={stats.currentPage}
            pageSize={stats.pageSize}
            sortDir={stats.sortDir}
            sortField={stats.sortField}
            totalRecords={itemsCount}
          />
        </Loader>
        <div className="mt-4">
          <Pager
            currentPage={stats.currentPage}
            onPage={onChangePage}
            totalPages={pageCount}
          />
        </div>
      </>
    );
  }
}
//
export default withTableFilter(RequiresActionContainer);

import React from 'react';
import PropTypes from 'prop-types';
import Select from '@wiley/cpp-ui-commons/lib/components/Select';
import Tooltip from 'app/components/Tooltip';

const SelectWithTooltip = ({ className, tooltipProps, ...props }) => (
  <>
    <Select className={className} {...props} />
    <Tooltip {...tooltipProps} />
  </>
);

SelectWithTooltip.propTypes = {
  className: PropTypes.string,
  s: PropTypes.object,
  tooltipProps: PropTypes.object.isRequired,
};

export default SelectWithTooltip;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandButton from 'app/components/ExpandButton';
import { getAuthorName, isCorresponding, getAffiliations } from 'app/pages/DetailedViews/DetailedArticlesView/common/utils';
import AuthorField from './AuthorField';


const SPLITTER = ', ';
export const SHOW_LIMIT = 10;

const ArticleAuthors = ({ authors, affiliations }) => {
  const [showMore, setShowMore] = useState(false);

  const isShowEnabled = authors.length > SHOW_LIMIT;
  const showedAuthors = authors.slice(0, (isShowEnabled && !showMore) ? SHOW_LIMIT : authors.length)
    .map(({ personName, ...rest }) => ({
      name: getAuthorName(personName),
      ...rest,
    })).filter(el => !!el?.name);

  if (!showedAuthors.length) return null;

  return (
    <div>
      {showedAuthors.map(({ 'xml:id': xmlId, name, corresponding, contactDetails, idGroup, affiliationRef }) => (
        <AuthorField
          key={xmlId}
          name={name}
          isCorresponding={isCorresponding(corresponding)}
          email={contactDetails?.email}
          orcid={idGroup?.id?.value}
          affiliations={getAffiliations(affiliationRef, affiliations)}
          seleniumId={`author-${xmlId}`}
        />
      )).reduce((prev, curr) => [prev, SPLITTER, curr])}
      { isShowEnabled ? (
        <span className="ml-2">
          <ExpandButton
            seleniumId="expand-authors-button"
            isExpanded={showMore}
            onClick={() => setShowMore(!showMore)}
          />
        </span>
      ) : null }
    </div>
  );
};

ArticleAuthors.propTypes = {
  affiliations: PropTypes.arrayOf(PropTypes.shape({
    affiliationDetails: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })),
    countryCode: PropTypes.string,
    type: PropTypes.string,
    'xml:id': PropTypes.string.isRequired,
  })),

  authors: PropTypes.arrayOf(PropTypes.shape({
    affiliationRef: PropTypes.arrayOf(PropTypes.string),
    contactDetails: PropTypes.shape({
      email: PropTypes.string,
    }),
    corresponding: PropTypes.string,
    creatorRole: PropTypes.string,
    idGroup: PropTypes.shape({
      id: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
    personName: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })),
    'xml:id': PropTypes.string.isRequired,
  })),
};

export default ArticleAuthors;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DetailedError } from 'app/pages/DetailedViews/components';
import { FieldsList } from 'app/components/PageField';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import DetailedSection from 'app/components/DetailedSection';
import ActionView from 'app/components/ActionView';
import { detailedEndpoints } from 'app/redux/api/common';
import appPaths from 'app/router/path';
import ArticleFiles from 'app/components/ArticleContent/Files';
import ArticleJobsheets from 'app/components/ArticleContent/Jobsheets';
import ArticleHistory from 'app/pages/ArticleHistory';
import {
  Header,
  ArticleAuthors,
  ExpandedFields,
  CommentsList,
  FunderDetails,
  LicenseDetails,
  PaymentDetails,
} from 'app/pages/DetailedViews/DetailedArticlesView/components';
import {
  getTitle,
  getCurrentTaskColumnConfig,
  getFunderDetailConfig,
  getLeftInfoColumnConfig,
  getLicenseDetailConfig,
  getLicensePreStatus,
  getNextTaskColumnConfig,
  getPaymentDetailConfig,
  getPreviousTaskColumnConfig,
  getRightInfoColumnConfig,
  parseComment,
} from 'app/pages/DetailedViews/DetailedArticlesView/common/detailedArticlesDataHelper';
import { getIconsGroups } from 'app/pages/DetailedViews/DetailedArticlesView/common/utils';
import { articleMetaDataPaths } from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';
import '../Detailed.scss';

const SECTION_OFFSET = '4';

class DetailedArticlesView extends PureComponent {
  static propTypes = {
    aid: PropTypes.string, // id of the type as AORN13080
    data: PropTypes.object,
    hasError: PropTypes.object,
    hasLoading: PropTypes.bool,
    history: PropTypes.object,
    id: PropTypes.string.isRequired, // {uuid} or productOf-{uuid} on CMH API
    isContentCaching: PropTypes.bool,
    isEditable: PropTypes.bool,
    loadData: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        article_id: PropTypes.string,
      }),
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    update: PropTypes.func,
  };

  componentDidMount() {
    const { loadData, id } = this.props;
    loadData(detailedEndpoints.articleDetailed, { id });
  }

  componentDidUpdate(prevProps) {
    this.checkArticleReload(prevProps);
  }

  checkArticleReload(prevProps) {
    const { match: { params: { article_id: newArticleId } }, loadData } = this.props;
    const { match: { params: { article_id: oldArticleId } } } = prevProps;

    if (newArticleId !== oldArticleId) {
      loadData(detailedEndpoints.articleDetailed, { id: newArticleId });
    }
  }

  renderPageHeader() {
    const { data = {}, hasLoading } = this.props;

    const { articleCategory, classification } = data?.jpcmsProperties || {};
    const { access } = data?.paymentDetails || {};
    const { licenseType } = data.licenseDetails || {};
    const { status, urgency } = data;

    const title = getTitle(data) || data.title || '';
    return (
      <Header
        isLoading={hasLoading}
        title={title}
        articleCategory={articleCategory}
        classification={classification}
        status={status}
        urgency={urgency}
        access={access}
        licenseType={licenseType}
      />
    );
  }

  renderCommentsCols() {
    const { data: { generalComments, productionComments, jpcmsProperties }, id, isEditable } = this.props;
    const generalComment = parseComment((generalComments || [])[0] || '');
    const productionComment = (productionComments || []).map(el => parseComment(el));
    return (
      <div className={`article-comments__wrap py-${SECTION_OFFSET}`}>
        <CommentsList
          id={id}
          isEditable={isEditable}
          updateMetaData={this.updateMetaData}
          generalComment={generalComment}
          productionComments={productionComment}
          editorialComments={jpcmsProperties?.eeoComments?.length ? [jpcmsProperties?.eeoComments] : []}
        />
      </div>
    );
  }

  renderInfoCols() {
    const { data, id, isEditable } = this.props;
    const issueLink = data.issueNavId ? appPaths.getSearchDashboardIssuesDetail(data.issueNavId, { type: 'iid', q: data.issueId }) : '';

    const leftPropsCol = getLeftInfoColumnConfig(data, id, this.updateMetaData, isEditable);
    const rightPropsCol = getRightInfoColumnConfig(data, issueLink);

    return (
      <div className="row mb-3">
        <div className="col-md-6 detailed-info-left">
          <FieldsList fields={leftPropsCol} />
        </div>
        <div className="col-md-6 detailed-info-right">
          <FieldsList fields={rightPropsCol} />
        </div>
      </div>
    );
  }

  renderAuthors() {
    const { data } = this.props;
    if (!data?.creators?.length) {
      return '';
    }

    const { creators: authors, affiliations } = data;
    if (!authors?.length) return null;

    return (
      <div className="row mt-1 authors-view-wrap">
        <div className="col-md-12">
          <ArticleAuthors
            authors={authors}
            affiliations={affiliations}
          />
        </div>
      </div>
    );
  }

  updateMetaData = ({ type, ...rest }) => {
    const { update } = this.props;
    update({
      store: detailedEndpoints.articleDetailed,
      ...rest,
      path: articleMetaDataPaths[type],
    });
  }

  renderExpandViewFields() {
    const { data, id, isEditable } = this.props;
    if (!data) return '';

    const {
      authorServicesEnabled,
      participateInWals,
    } = data;

    return (
      <div className={`mb-${SECTION_OFFSET}`}>
        <ExpandedFields
          {...data.jpcmsProperties}
          authorServicesEnabled={authorServicesEnabled}
          participateInWals={participateInWals}
          id={id}
          updateMetaData={this.updateMetaData}
          isEditable={isEditable}
        />
      </div>
    );
  }

  renderVersionsCols() {
    // eslint-disable-next-line react/prop-types
    const { store, match, id, aid, data, isContentCaching } = this.props;
    const { contentCacheStatus, contentId } = data || {};
    return (
      <div className={`article-content__wrap py-${SECTION_OFFSET}`} id="article-content">
        <ArticleFiles
          // for unit-tests
          store={store}
          match={match}
          id={id}
          aid={aid}
          cid={contentId}
          cacheStatus={contentCacheStatus}
          isCaching={isContentCaching}
        />
      </div>
    );
  }

  renderJobsheetCols() {
    // eslint-disable-next-line react/prop-types
    const { store, match, id, aid, data } = this.props;
    const { productionId } = data;
    return (
      <div className={`article-content__wrap py-${SECTION_OFFSET}`} id="article-jobsheets">
        <ArticleJobsheets
          // for unit-tests
          store={store}
          match={match}
          id={id}
          aid={aid}
          productionId={productionId}
        />
      </div>
    );
  }

  showHistory = () => {
    const { history, match: { url }, location: { search } } = this.props;
    const pathWithQuery = `${url}${appPaths.history}${search}`;

    history.push(pathWithQuery, { avoidScrollToTop: true });
  };

  renderTasksCols() {
    const { data, id, isEditable } = this.props;
    const { taskPrevious, taskCurrent, taskNext, jpcmsProperties } = data || {};
    const { delayReason } = jpcmsProperties || {};

    const prevTaskCol = getPreviousTaskColumnConfig(taskPrevious);
    const curTaskCol = getCurrentTaskColumnConfig({ taskCurrent, delayReason, entityId: id }, this.updateMetaData, isEditable);
    const nextTaskCol = getNextTaskColumnConfig(taskNext);

    return (
      <div className={`tasks-view__wrap py-${SECTION_OFFSET}`}>
        <div className="row">
          <div className="col-md-4 col-with-line">
            <DetailedSection title="Previous Task" className="detailed-section_wide">
              <FieldsList fields={prevTaskCol} />
            </DetailedSection>
          </div>
          <div className="col-md-4 col-with-line">
            <DetailedSection title="Current Task" className="detailed-section_wide">
              <FieldsList fields={curTaskCol} />
            </DetailedSection>
          </div>
          <div className="col-md-4 col-with-line">
            <DetailedSection title="Next Task" className="detailed-section_wide">
              <FieldsList fields={nextTaskCol} />
            </DetailedSection>
          </div>
        </div>
        <div className="tasks-view__button-wrap mt-3">
          <button
            data-seleniumid="history-button"
            className="history-button"
            type="button"
            onClick={this.showHistory}
          >
            View schedule & history
          </button>
        </div>
      </div>
    );
  }

  renderActionsCols() {
    const { data } = this.props;

    const iconsGroup = getIconsGroups(data);
    const funderConfig = getFunderDetailConfig(data);
    const licenseConfig = getLicenseDetailConfig(data);
    const paymentConfig = getPaymentDetailConfig(data);
    const licensePreStatus = getLicensePreStatus(data);

    return (
      <div className={`row py-${SECTION_OFFSET} actions-view-wrap`}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h4>To be actioned</h4>
            </div>
            <div className="col-md-4">
              <ActionView
                title="License"
                status={data?.licenseDetails?.status || ''}
                iconsGroup={iconsGroup.licenseDetails}
                preStatus={licensePreStatus}
                seleniumId="license"
              >
                <LicenseDetails config={licenseConfig} />
              </ActionView>
            </div>
            <div className="col-md-4">
              <ActionView
                title="Payment"
                iconsGroup={iconsGroup.paymentDetails}
                status={data.paymentDetails ? data.paymentDetails.status : ''}
                access={data.paymentDetails ? data.paymentDetails.access : null}
                seleniumId="payment"
              >
                <PaymentDetails config={paymentConfig} />
              </ActionView>
            </div>
            <div className="col-md-4">
              <ActionView
                title="Funder"
                iconsGroup={iconsGroup.funderDetails}
                status={data.funderDetails ? data.funderDetails.status : ''}
                seleniumId="funder"
              >
                <FunderDetails config={funderConfig} />
              </ActionView>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderArticleHistory() {
    const { match: { path, url, params: { article_id } }, location: { search } } = this.props;
    return (
      <Route
        exact path={`${path}${appPaths.history}`} render={({ history }) => <ArticleHistory routeHistory={history} url={url + search} id={article_id} />}
      />
    );
  }

  renderErrorMessage(error) {
    const { id = '' } = this.props;

    return (
      <DetailedError error={error} entityType="article" entityId={id} />
    );
  }

  render() {
    const { hasError, hasLoading, data } = this.props;
    return (
      <>
        <Helmet>
          <title>{`CMH | Detailed Article | ${data.id}`}</title>
        </Helmet>
        {hasError ? this.renderErrorMessage(hasError) : (
          <Loader hasLoading={hasLoading}>
            {this.renderPageHeader()}
            {this.renderAuthors()}
            {this.renderInfoCols()}
            {this.renderExpandViewFields()}
            {this.renderCommentsCols()}
            {this.renderTasksCols()}
            {this.renderActionsCols()}
            {this.renderArticleHistory()}
            {this.renderVersionsCols()}
            {this.renderJobsheetCols()}
          </Loader>
        )}
      </>
    );
  }
}

export default DetailedArticlesView;

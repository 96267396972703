import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import path from 'app/router/path';
import RoutePrivate from 'app/router/RoutePrivate';
import RoutePublic from 'app/router/RoutePublic';

import GuestLayout from 'app/layouts/Guest';

import { Login } from 'app/pages/Login';
import { Dashboard } from 'app/pages/Dashboard';
import { RequiresAction } from 'app/pages/RequiresAction';
import { WorkInProgress } from 'app/pages/WorkInProgress';
import { Search } from 'app/pages/Search';
import { Settings } from 'app/pages/Settings';
import { Report } from 'app/pages/Report';
import { NotFound } from 'app/pages/NotFound';
import { Version } from 'app/pages/Version';
import { Admin } from 'app/pages/Admin';
import { SwitchView } from 'app/pages/SwitchView';
import roles from 'app/enums/roles';

export default (
  <Switch>
    <RoutePublic layout={GuestLayout} path={path.login} component={Login} />
    <Route path={path.default} exact render={() => <Redirect to={path.dashboard} />} />
    <RoutePrivate path={path.dashboard} exact component={Dashboard} />
    <RoutePrivate path={path.dashboardJournal} exact component={Dashboard} />
    <RoutePrivate path={path.requiresAction} component={RequiresAction} />
    <RoutePrivate path={path.inProgress} component={WorkInProgress} />
    <RoutePrivate path={path.search} component={Search} />
    <RoutePrivate path={path.settings} component={Settings} />
    <RoutePrivate path={path.report} component={Report} />
    <RoutePublic path={path.version} component={Version} />
    <RoutePrivate path={path.switchView} component={SwitchView} />
    <RoutePrivate path={path.admin} component={Admin} requiredRoles={roles.systemAdministrator} />
    <RoutePublic component={NotFound} />
  </Switch>
);

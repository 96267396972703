import React from 'react';
import path from 'app/router/path';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import './AppFooter.scss';

export default function AppFooter() {
  return (
    <footer>
      <div className="container">
        <div className="footer-wrap">
          <div className="footer__menu">
            <ul>
              <li>All rights reserved</li>
              <li>
                <a target="_blank" href={path.help}>
                  Help
                </a>
              </li>
              <li>
                <a target="_blank" href={path.privacyPolicy}>
                  privacy policy
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__logo">
            <Svg name="logo-white" className="footer__logo__icon" />
          </div>
        </div>
      </div>
    </footer>
  );
}

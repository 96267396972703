import React from 'react';
import { LinkRender, IconLinkRender, FileSizeRender } from '@wiley/cpp-ui-commons/lib/components/Table/containers/CellRender';
import { stringDateToDateModifier } from 'app/utils';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import { endpoints } from 'app/components/ArticleContent/api';
import { generatePath } from 'react-router-dom';
import appPath from 'app/router/path';
import { PREVIEW_PLACE } from './fileFormats';
import { fileIconDateModifier, getFileFormatByMnemonicOrExtention } from './utils';

export const getArticleFilesDescriptor = ({ toggleFileVersions, history, url }) => [
  {
    id: 'icon',
    caption: 'Icon',
    sortable: false,
    colWidth: 10,
    Render: IconLinkRender,
    dataModifier: fileIconDateModifier,
  },
  {
    id: 'fileName',
    caption: 'File Name',
    sortable: false,
    colWidth: 25,
    className: 'truncated-link',
    Render: LinkRender,
    dataModifier: ({ name, isVersion }, id, { formatMnemonic, fileName, contentId }) => {
      const preview = getFileFormatByMnemonicOrExtention(formatMnemonic, fileName)?.preview;
      const previewType = getFileFormatByMnemonicOrExtention(formatMnemonic, fileName)?.previewType;
      let fileNameProps = {};
      let classNameForFilename = isVersion ? 'article-content__modal-file-name-version' : '';

      const seleniumProps = { 'data-seleniumid': `preview-link${contentId ? '-version' : ''}` };

      if (preview === PREVIEW_PLACE.modal) {
        fileNameProps = {
          ...seleniumProps,
          onClick: () => {
            history.push(generatePath(url + appPath.preview, {
              fileName: fileName.name,
              previewType,
              previewContentId: id,
              isVersion: !!contentId,
            }), { avoidScrollToTop: true });
          },
        };
        classNameForFilename += ' preview-active';
      }

      if (preview === PREVIEW_PLACE.newTab) {
        const newTabURL = contentId
          ? `/api/${endpoints.getContentVersion(id)}`
          : `/api/${endpoints.getContent(id)}`;

        fileNameProps = {
          ...seleniumProps,
          onClick: () => {
            const pdfWindow = window.open(newTabURL);
            const title     = fileName?.name || 'test';
            const html      = `
              <html>
                <head>
                  <title>${title}</title>
                </head>
                <body style="margin:0">
                  <embed width="100%" height="100%" src=${newTabURL} type="application/pdf">
                </body>
              </html>
            `;

            if (pdfWindow) pdfWindow.document.write(html);
          },
        };
        classNameForFilename += ' preview-active';
      }

      return ({
        title: <span title={name} {...fileNameProps} className={classNameForFilename}>{name}</span>,
        openInNewWindow: true,
      });
    },
  },
  {
    id: 'fileSize',
    caption: 'Size',
    sortable: false,
    colWidth: 12,
    Render: FileSizeRender,
  },
  {
    id: 'modifiedDate',
    caption: 'Modified Date',
    sortable: false,
    colWidth: 15,
    dataModifier: (val, id, { fileModified, modifiedDate }) => stringDateToDateModifier(fileModified || modifiedDate),
  },
  {
    id: 'versionLabel',
    caption: 'Version Label',
    sortable: false,
    colWidth: 15,
  },
  {
    id: 'id',
    caption: 'Download',
    sortable: false,
    colWidth: 12,
    dataModifier: (_, id, { fileName, contentId }) => {
      if ((!id && !contentId) || !fileName?.name) {
        return '';
      }
      const donwloadURL = contentId
        ? `/api/${endpoints.getContentVersion(id)}`
        : `/api/${endpoints.getContent(id)}`;
      return (
        <a
          href={donwloadURL}
          className="article-content__download-button"
          download={fileName.name}
          data-seleniumid={`download-${id}`}
        >
          <Svg style={{ height: '1rem' }} name="download" />
        </a>
      );
    },
  },
  {
    id: 'action',
    sortable: false,
    caption: 'Versions',
    colWidth: 11,
    dataModifier: (val, id, { containsVersions, fileName }) => (
      val && containsVersions ? (
        <button
          className="article-content__file-type-corner-btn"
          type="button"
          onClick={() => toggleFileVersions(val.id, fileName?.name)}
          data-seleniumid={`expand-versions-${val.id}`}
        >
          <Svg style={{ height: '0.4rem' }} name={val.isExpanded ? 'carrot-up' : 'carrot-down'} />
        </button>
      ) : null
    ),
  },

];

import React from 'react';
import ReactDOM from 'react-dom';
import './polyfills';
import cn from 'classnames';
import FontFaceObserver from 'fontfaceobserver';
import { Provider } from 'react-redux';
import App from 'app/containers/App';
import store from 'app/redux/store';
import { INIT_APP_ACTION } from 'app/redux/commonActions';

import 'app/styles/main.scss';
import './requestSpy';
import './requestErrorSpy';
import './svg';
import dtTags from 'app/config/dynatraceTags';
import { isIE } from './utils';

// Add Dynatrace environment tag
window.DT_ENV = dtTags[window.location.hostname] ? `env: ${dtTags[window.location.hostname]}` : 'env: other';

const root = document.getElementById('react');

const font = new FontFaceObserver('Open Sans');
font.load().then(() => {
  document.body.setAttribute('font-loaded', true);
});

document.body.className = cn({
  ie: isIE(),
});

store.dispatch({
  type: INIT_APP_ACTION,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  root,
);

import { getCorrectSeleniumId } from '@wiley/cpp-ui-commons/lib/utils/seleniumIdUtil';
import EmailRender from './Render/EmailRender';
import FieldListRender from './Render/FieldListRender';


export function getDefaultLabelledField(label, value, emptyText, seleniumId) {
  return {
    title: { text: label || '' },
    value: { text: value?.toString() || '', emptyText },
    seleniumId: seleniumId || getCorrectSeleniumId(label)?.toLowerCase?.(),
  };
}

export function getDefaultLabelledUserField(label, user, seleniumId) {
  const hasValue = !!user;
  return {
    title: { text: label || '' },
    value: hasValue ? { name: user.name || '', email: user.email || '' } : { name: '', email: '' },
    ValueRender: EmailRender,
    seleniumId,
  };
}

export function getDefaultMultiLabelledField(items, seleniumId = '') {
  return {
    ValueRender: FieldListRender,
    value: {
      items,
    },
    seleniumId,
  };
}

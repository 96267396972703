import { all } from 'redux-saga/effects';
import { watchTableEntities } from 'app/modules/Table/redux/TableSagas';
import { watchFacets } from 'app/modules/Table/redux/TableFiltersSagas';
import { watchDetailedNew as watchDetailed } from 'app/redux/detailed/DetailedSagas';
import { watchDashboard } from 'app/pages/Dashboard/redux/DashboardSagas';
import { watchVersion } from 'app/pages/Version/redux/VersionSagas';
import { watchAuth } from 'app/modules/Auth/redux/AuthSagas';
import { watchAdmin } from 'app/pages/Admin/redux/AdminSagas';
import { watchSwitchView } from 'app/pages/SwitchView/redux/SwitchViewSagas';
import { watchSearch } from 'app/pages/Search/redux/SearchSagas';
import { watchJournals } from 'app/redux/sagas/journals';
import { watchSystemMessage } from 'app/redux/sagas/systemMessage';

export default function* root() {
  yield all([
    watchJournals(),
    watchSwitchView(),
    watchAuth(),
    watchTableEntities(),
    watchFacets(),
    watchDetailed(),
    watchDashboard(),
    watchVersion(),
    watchAdmin(),
    watchSearch(),
    watchSystemMessage(),
  ]);
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Field from 'app/components/PageField';
import TextInput from 'app/components/PageField/Edit/TextInput';
import SelectInput from 'app/components/PageField/Edit/SelectInput';
import ExpandButton from 'app/components/ExpandButton';
import { getWithEmptyValue, getYNValue } from 'app/pages/DetailedViews/DetailedArticlesView/common/detailedArticlesDataHelper';
import {
  preProcessPrintPages,

  onlinePagesProcessing,
  printPagesProcessing,
  manuscriptPagesProcessing,

  getOnlinePagesHint,
  getColorUnpaginatedHint,
  parseEoSource,
} from 'app/pages/DetailedViews/DetailedArticlesView/common/utils';
import {
  getTooltipProps,
} from 'app/pages/DetailedViews/common/utils';
import {
  articleMetaDataTypes,
  getOptions,
  ONLINE_PAGES_HINT,
} from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';

const ExpandedFields = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    id,
    color,
    colorPageNo,
    colorPageNoUnpaginated,
    copyeditingLevel,
    eoSource,
    noOfMsPages,
    onlinePages,
    printPages,
    supplMater,
    tocCategory,
    authorServicesEnabled,
    participateInWals,

    isEditable,
    updateMetaData,
  } = props;

  const commonEditProps = {
    entityId: id,
    onUpdate: updateMetaData,
  };

  return (
    <>
      <div className="d-flex justify-content-left ml-1">
        <ExpandButton
          seleniumId="expand-fields-button"
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(v => !v)}
        />
      </div>

      {isExpanded ? (
        <div data-seleniumid="expand-fields-container">
          <div className="row mt-3">
            <div className="col-md-6 mb-3 detailed-info-left">
              <Field
                title={{ text: 'Pagination Details' }}
                className="details-field--header"
                seleniumId="expand-fields-pagination-details"
              />
              <Field
                title={{ text: 'TOC Category:' }}
                value={{ text: getWithEmptyValue(tocCategory), emptyText: '' }}
                seleniumId="expand-fields-toc-category"
                edit={isEditable && {
                  type: articleMetaDataTypes.TOC_CATEGORY,
                  component: SelectInput,
                  ...commonEditProps,
                  inputProps: { options: getOptions(articleMetaDataTypes.TOC_CATEGORY), searchable: true, isOpened: true },
                }}
              />
              <Field
                title={{ text: 'Print Pages:' }}
                value={{ text: preProcessPrintPages(printPages), emptyText: '' }}
                seleniumId="expand-fields-print-pages"
                edit={isEditable && {
                  type: articleMetaDataTypes.PRINT_PAGES,
                  component: TextInput,
                  inputProcess: printPagesProcessing,
                  ...commonEditProps,
                }}
              />
              <Field
                title={{ text: 'Online Pages:' }}
                value={{ text: onlinePages, emptyText: '' }}
                seleniumId="expand-fields-online-pages"
                tooltip={ONLINE_PAGES_HINT}
                tooltipProps={{
                  id: 'expand-fields-online-pages',
                  ...getTooltipProps({ dataHtml: true }),
                }}
                edit={isEditable && {
                  type: articleMetaDataTypes.ONLINE_PAGES,
                  component: TextInput,
                  inputProcess: onlinePagesProcessing,
                  ...commonEditProps,
                  tooltipProps: {
                    id: 'expand-fields-online-pages-edit',
                    dataTip: getOnlinePagesHint,
                    ...getTooltipProps({ dataHtml: true }),
                  },
                }}
              />
              <Field
                title={{ text: 'Manuscript Pages:' }}
                value={{ text: noOfMsPages, emptyText: '' }}
                seleniumId="expand-fields-ms-pages"
                edit={isEditable && {
                  type: articleMetaDataTypes.NO_OF_MS_PAGES,
                  component: TextInput,
                  inputProcess: manuscriptPagesProcessing,
                  ...commonEditProps,
                }}
              />
            </div>
            <div className="col-md-6 mb-3 detailed-info-right">
              <Field
                title={{ text: 'System Details' }}
                seleniumId="expand-fields-system-details"
                className="details-field--header"
              />
              <Field
                title={{ text: 'EEO Source:' }}
                value={{ text: parseEoSource(eoSource), emptyText: '' }}
                seleniumId="expand-fields-eeo-source"
              />
              <Field
                title={{ text: 'Participates in Author Services:' }}
                value={{ text: getYNValue(authorServicesEnabled), emptyText: '' }}
                seleniumId="expand-fields-participate-as"
              />
              <Field
                title={{ text: 'Participates in WALS:' }}
                value={{ text: getYNValue(participateInWals), emptyText: '' }}
                seleniumId="expand-fields-participate-wals"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 mb-3 detailed-info-left">
              <Field
                title={{ text: 'Color Details' }}
                seleniumId="expand-fields-color-details"
                className="details-field--header"
              />
              <Field
                title={{ text: 'Color:' }}
                value={{ text: getWithEmptyValue(getYNValue(color)), emptyText: '' }}
                seleniumId="expand-fields-color"
              />
              <Field
                title={{ text: 'Unpaginated Color page no.:' }}
                value={{ text: colorPageNoUnpaginated, emptyText: '' }}
                seleniumId="expand-fields-color-unpaginated"
                edit={isEditable && {
                  type: articleMetaDataTypes.COLOR_PAGE_NO_UNPAGINATED,
                  component: TextInput,
                  value: colorPageNoUnpaginated,
                  ...commonEditProps,
                  tooltipProps: {
                    id: 'expand-fields-color-unpaginated-edit',
                    dataTip: getColorUnpaginatedHint,
                    ...getTooltipProps({ dataHtml: true }),
                  },
                }}
              />
              <Field
                title={{ text: 'Paginated Color page no.:' }}
                value={{ text: colorPageNo, emptyText: '' }}
                seleniumId="expand-fields-color-paginated"
              />
            </div>
            <div className="col-md-6 mb-3 detailed-info-right">
              <Field
                title={{ text: 'Production Details' }}
                className="details-field--header"
                seleniumId="expand-fields-production-details"
              />
              <Field
                title={{ text: 'Copyediting Level:' }}
                value={{ text: getWithEmptyValue(copyeditingLevel), emptyText: '' }}
                seleniumId="expand-fields-copyediting-level"
                edit={isEditable && {
                  type: articleMetaDataTypes.COPY_EDITING_LEVEL,
                  component: SelectInput,
                  ...commonEditProps,
                  inputProps: { options: getOptions(articleMetaDataTypes.COPY_EDITING_LEVEL), searchable: true },
                }}
              />
              <Field
                title={{ text: 'Supporting Information:' }}
                value={{ text: supplMater, emptyText: '' }}
                seleniumId="expand-fields-supporting-info"
              />
            </div>
          </div>
        </div>
      ) : ''}
    </>
  );
};

ExpandedFields.propTypes = {
  authorServicesEnabled: PropTypes.string,
  color: PropTypes.string,
  colorPageNo: PropTypes.string,
  colorPageNoUnpaginated: PropTypes.string,
  copyeditingLevel: PropTypes.string,
  eoSource: PropTypes.string,
  id: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  noOfMsPages: PropTypes.string,
  onlinePages: PropTypes.string,
  participateInWals: PropTypes.bool,
  printPages: PropTypes.string,
  supplMater: PropTypes.string,
  tocCategory: PropTypes.string,

  updateMetaData: PropTypes.func,
};

export default ExpandedFields;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import { statusIconTypes } from './StatusIconTypes';
import './StatusIcon.scss';


export default function StatusIcon({ value, count, seleniumId = '' }) {
  const type = value?.toLowerCase?.();
  const iconData = statusIconTypes[type];

  if (!iconData) {
    return null;
  }

  return (
    <div data-seleniumid={`${seleniumId}-status-with-icon`} className={cn('status-with-icon', value)}>
      <Svg name={iconData.icon} className="status-icon" />
      <div data-seleniumid={`${seleniumId}-status-with-icon__text`} className="status-with-icon__text">{iconData.text}</div>
      {count > 1 && <div data-seleniumid={`${seleniumId}-status-with-icon__count`} className="status-with-icon__count">{count}</div>}
    </div>
  );
}

StatusIcon.propTypes = {
  count: PropTypes.number,
  seleniumId: PropTypes.string,
  value: PropTypes.string,
};

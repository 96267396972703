import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import emitter from './emitter';

class ScrollableConsumer extends PureComponent {
  state = {
    scrollTop: 0,
    isSticky: false,
  };

  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.scroll = emitter.addListener('scroll', ({ scrollTop }) =>
      this.setState({
        scrollTop,
        isSticky: scrollTop > 2,
      }),
    );
  }

  componentWillUnmount() {
    this.scroll.remove();
  }

  render() {
    const { scrollTop, isSticky } = this.state;
    const { children } = this.props;

    return children(isSticky, scrollTop);
  }
}

export default ScrollableConsumer;

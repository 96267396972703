import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import SearchWithDropdown from 'app/components/Search/SearchWithDropdown';
import SelectWithTooltip from 'app/components/SelectWithTooltip';
import { cutLongValue } from 'app/utils';
import './SearchByCategory.scss';

export const ERROR_MESSAGE = 'Please select type';
const MAX_HISTORY_ITEM_LENGTH = 30;

export class SearchByCategory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectError: false,
      selectedCategory: this.getDefaultSearchCategory(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { defaultValue } = this.props;
    const { selectedCategory } = this.state;

    if (defaultValue !== prevProps.defaultValue && selectedCategory === prevState.selectedCategory) {
      this.setState({ selectedCategory: this.getDefaultSearchCategory() });
    }
  }

  getDefaultSearchCategory = () => {
    const { defaultValue, selectOptions } = this.props;
    return defaultValue == null ? undefined : selectOptions[defaultValue];
  };

  get preparedHistory() {
    const { searchHistory } = this.props;

    return searchHistory.reduce(
      (acc, { type, requestValue: request }) => ({
        ...acc,
        [type]: [...(acc[type] || []), { label: type, value: request, payload: { type, value: request } }],
      }),
      {},
    );
  }

  handleSearch = value => {
    const { selectedCategory } = this.state;
    const { onSearch } = this.props;

    this.setState({ selectError: !selectedCategory });

    if (!selectedCategory || !value) {
      return;
    }

    onSearch(selectedCategory, value);
  };

  handleSelectChange = option => {
    this.setState({ selectedCategory: option, selectError: false });
  };

  handleClickOutside = () => {
    this.setState({ selectError: false });
  };

  handleHistoryItemClick = (value, { payload }) => {
    this.setState(
      {
        selectError: false,
        selectedCategory: {
          label: payload.type.toUpperCase(),
          value: payload.type,
        },
      },
      () => this.handleSearch(value),
    );
  };

  renderHistoryItem = item => (
    <div className="recent-history">
      <div className="recent-history__icon">
        <Svg name="search" className="profile-popup__nav-menu-icon" />
      </div>
      <div title={item?.value?.length > MAX_HISTORY_ITEM_LENGTH ? item.value : undefined}>
        {cutLongValue(item.value, MAX_HISTORY_ITEM_LENGTH)}
      </div>
    </div>
  );

  render() {
    const { selectOptions, inputPlaceholder, seleniumId, focusable, defaultInputValue } = this.props;
    const { selectedCategory, selectError } = this.state;

    const tooltipProps = {
      className: 'search-tooltip',
      content: selectError ? () => ERROR_MESSAGE : null,
      spyClassName: 'search-select',
      place: 'left',
      type: 'error',
      effect: 'solid',
    };

    const historyItems = this.preparedHistory[(selectedCategory?.value)];

    return (
      <div className="search-by-category__wrap">
        <div className="search-by-category">
          <div className="search-by-category__select-wrap">
            <SelectWithTooltip
              placeholder="Select"
              value={selectedCategory}
              className="search-select"
              options={selectOptions}
              seleniumId={`${seleniumId}-search-select`}
              onChange={this.handleSelectChange}
              tooltipProps={tooltipProps}
            />
          </div>
          <SearchWithDropdown
            placeholder={inputPlaceholder}
            seleniumId={`${seleniumId}-input`}
            defaultValue={defaultInputValue}
            hasCollapse={false}
            focusable={focusable}
            onSearch={this.handleSearch}
            items={historyItems}
            onSelectItem={this.handleHistoryItemClick}
            renderItem={this.renderHistoryItem}
          />
        </div>
      </div>
    );
  }
}

SearchByCategory.defaultProps = {
  defaultValue: null,
  selectOptions: [],
};

SearchByCategory.propTypes = {
  defaultInputValue: PropTypes.string,
  defaultValue: PropTypes.number,
  focusable: PropTypes.bool,
  inputPlaceholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func,
  searchHistory: PropTypes.array,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  seleniumId: PropTypes.string.isRequired,
};

export default onClickOutside(SearchByCategory);

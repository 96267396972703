import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import EditableDateCell from './EditableDateCell';

const getStyle = (colWidth) => {
  const width = `${colWidth}%`;
  return {
    width,
    minWidth: width,
  };
};

const getDOMProps = ({ id, colWidth, seleniumId, className = '' }) => ({
  style: getStyle(colWidth),
  seleniumId: seleniumId || id,
  className: cn('table-cell', className),
});

// eslint-disable-next-line react/prop-types
const BaseDateCell = ({ value, ...rest }) => (
  <div {...rest}>
    {value}
  </div>
);

const DateCell = ({
  value,
  row,
  additionalProps,
  ...rest }) => {
  const { isEditable, className, ...restAdditionalProps } = additionalProps;
  const { seleniumId, ...domProps } = getDOMProps({ ...rest, className });
  const { id, title } = row;
  if (!isEditable() || !id) return <BaseDateCell {...domProps} value={value} data-seleniumid={seleniumId} />;

  return (
    <EditableDateCell
      {...domProps}
      data-seleniumid={`${seleniumId}-editable`}
      date={value}
      rowId={id}
      rowTitle={title}
      additionalProps={restAdditionalProps}
    />
  );
};

DateCell.propTypes = {
  additionalProps: PropTypes.shape({
    className: PropTypes.string,
    entityId: PropTypes.string.isRequired,
    getApiPayload: PropTypes.func.isRequired,
    getWarningMessage: PropTypes.func,
    ignoreEditLoading: PropTypes.bool,
    isEditable: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    removeAsyncCall: PropTypes.func,
    type: PropTypes.string,
    updateAsyncCall: PropTypes.func,
  }),
  colWidth: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  seleniumId: PropTypes.string,
  value: PropTypes.string,
};

export default DateCell;

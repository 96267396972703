import React from 'react';

export const NONE_VENDOR = 'None';

export const SHORT_MONTH_DATE = 'd MMM yyyy';
export const LONG_MONTH_DATE = 'd MMMM yyyy';

export const LOAD_ERROR_MSG = entity => `Sorry, we couldn't load ${entity}, please try again later`;
export const UPDATE_ERROR_MSG = title => <div><div>{title} is not updated</div><div>Please try again later</div></div>;
export const DELETE_ERROR_MSG = title => <div><div>{title} is not deleted</div><div>Please try again later</div></div>;

export const LOAD_USERS_ERROR_MSG = LOAD_ERROR_MSG('User(s) information');

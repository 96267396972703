import React from 'react';
import PropTypes from 'prop-types';
import Field from './Field';

export default function FieldsList({ fields }) {
  return fields.map(({ title, value, TitleRender, ValueRender, icon, seleniumId, className = '', options, edit }, index) => (
    <Field
      seleniumId={seleniumId}
      key={index}
      title={title}
      value={value}
      className={className}
      TitleRender={TitleRender}
      ValueRender={ValueRender}
      icon={icon}
      edit={edit}
      options={options}
    />
  ));
}

function FieldsArray({ fields }) {
  return (<div className="fields-array"><FieldsList fields={fields} /></div>);
}

const itemProp = {
  icon: PropTypes.string,
  title: PropTypes.object.isRequired,
  TitleRender: PropTypes.func,
  value: PropTypes.object,
  ValueRender: PropTypes.func,
};

FieldsList.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType(
      [
        PropTypes.shape(itemProp),
        PropTypes.shape({
          items: PropTypes.arrayOf(PropTypes.shape(itemProp)),
          type: PropTypes.string,
        }),
      ],
    ),
  ).isRequired,
};

FieldsArray.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape(itemProp)),
};

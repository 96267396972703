import { createSelector } from 'reselect';
import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';

const initialState = {
  items: [],
  error: null,
  isLoading: false,
};

export const FETCH_JOURNALS = 'journals/fetch';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_JOURNALS:
      return {
        ...state,
        items: [],
        isLoading: true,
      };
    case FETCH_JOURNALS + SUCCESS: {
      return {
        ...state,
        items: payload,
        isLoading: false,
      };
    }
    case FETCH_JOURNALS + FAILURE:
      return { ...initialState, isLoading: false };
    default:
      return state;
  }
};

// Actions
export const fetchJournals = (acronym) => ({
  type: FETCH_JOURNALS,
  payload: { wildcardJournalAcronym: acronym },
});

// Selectors
const selectJournalsStore = state => state.journals;

export const selectJournals = createSelector(
  selectJournalsStore,
  groups => groups.items,
);
export const selectJournalsIsLoading = createSelector(
  selectJournalsStore,
  groups => groups.isLoading,
);
export const selectJournalsError = createSelector(
  selectJournalsStore,
  groups => groups.error,
);

import urgency, { EMPTY_URGENCY } from 'app/enums/urgency';

export const statusIconTypes = {
  onlineopen: {
    icon: 'unlock-progress-view',
    text: 'OO',
  },
  openaccess: {
    icon: 'unlock-progress-view',
    text: 'OA',
  },
  subscription: {
    icon: 'subs',
    text: 'Subs',
  },
  critical: {
    icon: 'critical',
    text: urgency.critical,
  },
  at_risk: {
    icon: 'at-risk',
    text: urgency.at_risk,
  },
  due_today: {
    icon: 'due-today',
    text: urgency.due_today,
  },
  resolved: {
    icon: 'resolved',
    text: urgency.resolved,
  },
  [EMPTY_URGENCY.type]: {
    icon: EMPTY_URGENCY.type.replace('_', '-'),
    text: EMPTY_URGENCY.title,
  },
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { Helmet } from 'react-helmet';
import PaperHeader from 'app/components/PaperHeader';
import Select from '@wiley/cpp-ui-commons/lib/components/SelectSearch';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import roles from 'app/enums/roles';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import Permission from 'app/modules/Auth/components/Permission';
import TileWidgetsGrid from 'app/components/TileWidget';
import { NO_RESULTS_MESSAGE, NO_JOURNALS } from 'app/enums/commonErrors';
import { createFilterWithValue } from 'app/utils/filters';
import { getIssuesConfig, getArticlesConfig } from '../common/journalTilesConfig';
import { getHorizontalDiagramConfig } from '../common/horizontalDiagramConfig';
import HorizontalDiagramWidget from '../widget/HorizontalDiagramWidget';
import EmptyWidget from '../widget/EmptyWidget';

import './DashboardComponent.scss';

const NO_RESULTS_FOR_JOURNAL_ACRONYM = 'Sorry no matches found';
const LAYOUT_TITLE = 'Dashboard';
export const DEFAULT_TITLE = 'All journals';
export const CLEAR_TEXT = 'clear filter';
export const UNKNOWN_JOURNAL = 'Unknown journal';


export class DashboardComponent extends PureComponent {
  state = {
    searchJournals: null,
  };

  static propTypes = {
    acronym: PropTypes.string,
    articleUserStatistic: PropTypes.array,
    auth: PropTypes.object,
    hasLoading: PropTypes.bool,
    initLoad: PropTypes.func.isRequired,
    issueUserStatistic: PropTypes.array,
    journals: PropTypes.array,
    journalsHasLoading: PropTypes.bool,
    loadData: PropTypes.func.isRequired,
    loadJournals: PropTypes.func.isRequired,
    productionStagesStatistic: PropTypes.array,
    switchView: PropTypes.object,
  };

  componentDidMount() {
    const { initLoad, loadJournals } = this.props;
    initLoad();
    loadJournals();
  }

  static layoutProps = {
    header: {
      title: LAYOUT_TITLE,
    },
  };

  onSearch = searchString => {
    const { journals } = this.props;
    const lowerString = searchString.toLowerCase();
    this.setState({ searchJournals: searchString ? journals.filter(({ acronym }) => acronym.toLowerCase().indexOf(lowerString) > -1) : journals });
  };

  onChange = (select) => {
    const { loadData, acronym } = this.props;
    const { value } = select || {};

    if (acronym !== value) {
      loadData({ acronym: select.value });
    }
  };

  clearFilter = () => {
    const { loadData } = this.props;
    loadData();
  };

  getOptions = source => source?.map(({ acronym, journalName }) => ({ value: acronym, label: `${journalName} (${acronym})` })) || [];

  renderSelectedJournalTitle({ journalName, acronym }) {
    return (
      <>
        <span
          data-seleniumid="dashboard-selected-journal-title"
        >
          Journal: {journalName} ({acronym})
        </span>
        <span className="dashboard__reset" data-seleniumid="dashboard-selected-journal-reset" onClick={this.clearFilter}>
          {CLEAR_TEXT}
        </span>
      </>
    );
  }

  getPlaceHolder() {
    return (
      <span>
        <Svg
          className="dashboard__search-img"
          name="search"
        />
        Filter by Journal ID
      </span>
    );
  }

  getTitle = () => {
    const { acronym, journals, journalsHasLoading } = this.props;
    const { switchView, auth } = this.props;

    let title = DEFAULT_TITLE;
    if (acronym && !journalsHasLoading) {
      const journal = find(journals, { acronym });
      title = this.renderSelectedJournalTitle(journal || { journalName: UNKNOWN_JOURNAL, acronym });
    }
    else if (switchView.user) {
      title = `${title} in ${switchView.user.firstName} ${switchView.user.lastName}'s view`;
    }
    else {
      title = `${title} in ${auth.user.firstName} ${auth.user.lastName}'s view`;
    }

    return title;
  };

  renderSelect() {
    const { searchJournals } = this.state;
    const { journals, acronym } = this.props;
    const seleniumId = 'dashboard-select';
    const options = this.getOptions(searchJournals || journals);

    return (
      <Select
        className="dashboard__select"
        searchable={true}
        isSearchByValue
        onSearch={this.onSearch}
        onChange={this.onChange}
        noItemsFound={true}
        searchClearOnClose={true}
        placeholder={this.getPlaceHolder()}
        options={options}
        value={acronym || undefined}
        seleniumId={seleniumId}
      />
    );
  }

  isDisplayEmptyResultsForJournalAcronym() {
    const { hasLoading, articleUserStatistic, issueUserStatistic, acronym } = this.props;
    return !hasLoading && !articleUserStatistic?.length && !issueUserStatistic?.length && acronym;
  }

  getAdditionalFilters = () => {
    const { acronym } = this.props;
    return acronym ? [createFilterWithValue('journalAcronym', acronym)] : [];
  };

  render() {
    const { hasLoading, articleUserStatistic, issueUserStatistic, productionStagesStatistic } = this.props;
    const { switchView } = this.props;
    const additionalFilters = this.getAdditionalFilters();

    return (
      <>
        <Helmet>
          <title>CMH | Dashboard</title>
        </Helmet>
        <Permission
          requiredRoles={switchView.user ? null : [roles.productionEditor, roles.productionManager]}
          fallback={() => <InfoView seleniumId="no-results" title={NO_RESULTS_MESSAGE} text={NO_JOURNALS} />}
        >
          <SectionLoader hasLoading={hasLoading}>
            <PaperHeader title={this.getTitle()} className="dashboard__pagetitle">
              {this.renderSelect()}
            </PaperHeader>
            {
              this.isDisplayEmptyResultsForJournalAcronym() ? <InfoView seleniumId="no-results" title={NO_RESULTS_FOR_JOURNAL_ACRONYM} />
                : (
                  <>
                    <div className="mb-3">
                      <TileWidgetsGrid seleniumPart="articles" data={articleUserStatistic} listConfig={getArticlesConfig(additionalFilters)} />
                    </div>
                    <div>
                      <TileWidgetsGrid seleniumPart="issues" data={issueUserStatistic} listConfig={getIssuesConfig(additionalFilters)} />
                    </div>
                    <div className="dashboard-separator" />
                    <div className="row">
                      <div className="col-md-8">
                        <HorizontalDiagramWidget
                          seleniumPart="articlesByStage"
                          config={getHorizontalDiagramConfig(additionalFilters)}
                          items={productionStagesStatistic}
                        />
                      </div>
                      <div className="col-md-4 dashboard__vertical-cols">
                        <EmptyWidget
                          seleniumPart="coming-soon"
                          text="New features are coming soon"
                        />
                      </div>
                    </div>
                  </>
                )}
          </SectionLoader>
        </Permission>
      </>
    );
  }
}

export default DashboardComponent;

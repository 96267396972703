export const selectOptions = [
  { label: 'AID', value: 'aid' },
  { label: 'DOI', value: 'doi' },
  { label: 'Ed Ref Code', value: 'edRefCode' },
  { label: 'IID', value: 'iid' },
  { label: 'JID', value: 'jid' },
];

export const articlesCategories = [
  'jid',
  'doi',
  'aid',
  'edrefcode',
];

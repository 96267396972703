import { connect } from 'react-redux';
import { map } from 'lodash';
import CVsTab from 'app/pages/Admin/ManageTitles/components/Tabs/CVsTab';
import { selectUsers } from 'app/pages/Admin/ManageTitles/reducers/UsersDucks';
import { unassignUser, assignUser, searchingUser, searchUser, resetUsersStats } from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalUsersDucks';
import { selectJournalTabInfo } from 'app/pages/Admin/ManageTitles/reducers/Journals';
import { USER_CV_TYPE } from 'app/pages/Admin/ManageTitles/common/constants';
import { changeUsersGroupInJournal } from 'app/pages/Admin/api/admin';
import { getGroups } from 'app/redux/api/common';
import { mergeProps, isNotValidName } from './utils';

export const mapStateToProps = (state, { journal }) => {
  const users = selectUsers(state);
  const assignedUsers = journal[USER_CV_TYPE].map(id => users[USER_CV_TYPE + id]);
  const { search, create, ...usersStats } = selectJournalTabInfo(journal.id, USER_CV_TYPE)(state);

  return {
    users: assignedUsers,
    search,
    usersStats,
  };
};

export const mapDispatchToProps = (dispatch, { journal }) => {
  const { id: journalId } = journal;

  const userProps = {
    journalId,
    userType: USER_CV_TYPE,
  };

  return {
    onRemove: (id) => {
      dispatch(unassignUser({
        id,
        effect: changeUsersGroupInJournal,
        asyncProps: {
          property: 'contentVendor',
          value: id,
        },
        ...userProps,
      }));
    },
    onAdd: (id) => {
      dispatch(assignUser({
        id,
        effect: changeUsersGroupInJournal,
        asyncProps: {
          property: 'contentVendor',
          value: id,
        },
        ...userProps,
      }));
    },
    onChangeSearch: (value) => {
      dispatch(searchingUser({
        searchText: value,
        ...userProps,
      }));
    },
    onSearch: (users, searchText) => {
      const isNotValid =  isNotValidName(searchText, users, USER_CV_TYPE);
      if (isNotValid) {
        dispatch(searchingUser({
          searchText,
          error: isNotValid,
          ...userProps,
        }));
      }
      else {
        dispatch(searchUser({
          ...userProps,
          assignedUsers: map(users, 'id'),
          params: {
            name: searchText,
          },
          effect: getGroups,
        }));
      }
    },
    onResetUsers: () => {
      dispatch(resetUsersStats(userProps));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CVsTab);

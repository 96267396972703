import React from 'react';
import { LinkRender, IconLinkRender, FileSizeRender } from '@wiley/cpp-ui-commons/lib/components/Table/containers/CellRender';
import { stringDateToDateModifier } from 'app/utils';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import { endpoints } from 'app/components/ArticleContent/api';
import { generatePath } from 'react-router-dom';
import appPath from 'app/router/path';
import _ from 'lodash';
import { PREVIEW_TYPE } from './fileFormats';
import { fileIconDateModifier } from './utils';


export const getArticleJobsheetsDescriptor = ({ history, url }) => [
  {
    id: 'icon',
    caption: 'Icon',
    sortable: false,
    colWidth: 10,
    Render: IconLinkRender,
    dataModifier: fileIconDateModifier,
  },
  {
    id: 'fileName',
    caption: 'File Name',
    sortable: false,
    colWidth: 23,
    className: 'truncated-link',
    Render: LinkRender,
    dataModifier: ({ name }, id, { fileName, jobsheetId }) => {
      let fileNameProps = {};
      let classNameForFilename = '';

      const seleniumProps = { 'data-seleniumid': 'preview-link-jobsheet' };

      fileNameProps = {
        ...seleniumProps,
        onClick: () => {
          history.push(generatePath(url + appPath.previewJobsheet, {
            previewType: PREVIEW_TYPE.xml,
            fileName: fileName.name,
            jobsheetId,
          }), { avoidScrollToTop: true });
        },
      };
      classNameForFilename += ' preview-active';

      return ({
        title: <span title={name} {...fileNameProps} className={classNameForFilename}>{name}</span>,
        openInNewWindow: true,
      });
    },
  },
  {
    id: 'fileSize',
    caption: 'Size',
    sortable: false,
    colWidth: 12,
    Render: FileSizeRender,
  },
  {
    id: 'modifiedDate',
    caption: 'Modified Date',
    sortable: false,
    colWidth: 15,
    dataModifier: stringDateToDateModifier,
  },
  {
    id: 'packageStage',
    caption: 'stage',
    sortable: false,
    colWidth: 15,
  },
  {
    id: 'exchange',
    caption: 'Exchange',
    sortable: false,
    colWidth: 12,
    dataModifier: (exchange) => _.capitalize(exchange),
  },
  {
    id: 'jobsheetId',
    caption: 'Download',
    sortable: false,
    colWidth: 13,
    dataModifier: (jobsheetId, id, { fileName }) => {
      if (!jobsheetId) {
        return '';
      }
      return (
        <a
          href={endpoints.getJobhseetContent(jobsheetId)}
          className="article-content__download-button"
          download={fileName.name}
          data-seleniumid={`download-${jobsheetId}`}
        >
          <Svg style={{ height: '1rem' }} name="download" />
        </a>
      );
    },
  },
];

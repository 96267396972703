import React, { memo } from 'react';
import { compact } from 'lodash';
import PropTypes from 'prop-types';
import useFeatures from 'app/utils/hooks/useFeatures';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import Field from 'app/components/PageField';
import { getTooltipProps, getBubbleConfig } from 'app/pages/DetailedViews/common/utils';
import { accessConfig, licenseConfig } from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';
import StatusBubble from 'app/components/PaperHeader/StatusBubble';
import PaperHeader from 'app/components/PaperHeader';
import HTMLView from 'app/components/HTMLView';
import './Header.scss';


const Splitter = () => (<span className="header-splitter" />);

export const Category = ({ articleCategory, classification }) => {
  const categoryId = 'header-category';
  return (
    <span className={`${categoryId}-field`}>
      <Field
        value={{ text: articleCategory }}
        seleniumId={categoryId}
        tooltip={classification}
        tooltipProps={{
          id: categoryId,
          ...getTooltipProps(),
        }}
      />
    </span>

  );
};

Category.propTypes = {
  articleCategory: PropTypes.string,
  classification: PropTypes.string,
};

export const Access = ({ icon, label }) => (
  <Field
    className="header-access"
    value={{ text: label }}
    icon={icon}
    seleniumId="header-access"
  />
);

Access.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
};

export const License = ({ icons, url }) => (
  <a
    className="header-license"
    href={url}
    target="_blank"
    data-seleniumid="header-license"
  >
    {icons.map(icon => (
      <Svg
        key={icon}
        name={`license_${icon}`}
      />
    ))}
  </a>
);

License.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string,
};


export const Header = ({
  title,
  articleCategory,
  classification,
  status,
  urgency,
  access,
  licenseType,
  isLoading,
}) => {
  const { features } = useFeatures();
  const { articleAccessView } = features || {};

  if (isLoading) return <div className="detailed-header-loading" />;

  const accessMeta = accessConfig[access];
  const licenseMeta = licenseConfig[licenseType];

  const components = compact([
    articleCategory && <Category key="category" articleCategory={articleCategory} classification={classification} />,
    articleAccessView && accessMeta && <Access key="access" {...accessMeta} />,
    licenseMeta && <License key="license" {...licenseMeta} />,
  ]).reduce((acc, component, i) => [...acc, ...(i === 0 ? [component] : [<Splitter key={i} />, component])], []);

  return (
    <PaperHeader
      title={<HTMLView text={title} />} subHeader={(
        <div className="detailed-header">
          {components}
        </div>
      )}
    >
      <StatusBubble seleniumId="urgency" status={status} config={getBubbleConfig(urgency)} />
    </PaperHeader>
  );
};

Header.propTypes = {
  access: PropTypes.string,
  articleCategory: PropTypes.string,
  classification: PropTypes.string,
  isLoading: PropTypes.bool,
  licenseType: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,
  urgency: PropTypes.string,
};

export default memo(Header);

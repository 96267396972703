import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import CommentExpand, { getShortText } from './CommentExpand';
import './Comments.scss';

const Comment = props => {
  const { title, text } = props;
  const shortText = getShortText(text);

  const [showFullText, setStatus] = useState(false);

  return text && text.length ? (
    <div className="article-comments__comment">
      <div>
        <Svg name="comments" className="article-comments__icon" />
      </div>
      <div className="article-comments__comment-section">
        <div className="article-comments__comment-header">
          {title}
        </div>
        <div className="article-comments__comment-body">
          { shortText && !showFullText ? shortText : text }
        </div>
        <CommentExpand
          shortText={shortText}
          onClick={setStatus}
          isOpen={showFullText}
        />
      </div>
    </div>
  ) : '';
};

Comment.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
};

export default Comment;

import { compose } from 'redux';

import createUIStateContext, { UIStateContext } from '@wiley/cpp-ui-commons/lib/hoc/createUIStateContext';

import { withAuthProvider, AuthContext } from 'app/modules/Auth';
import { withSidebar, SidebarContext } from './withSidebar';
import { withSwitchView, SwitchViewContext } from './withSwitchView';


export const applyHOCs = compose(
  withAuthProvider,
  withSidebar,
  withSwitchView,
);

export {
  UIStateContext,
};

export const SIDEBAR = 'sidebar';
export const SWITCH_VIEW = 'switchView';
export const AUTH = 'auth';

export default createUIStateContext({
  [SIDEBAR]: SidebarContext,
  [SWITCH_VIEW]: SwitchViewContext,
  [AUTH]: AuthContext,
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './HorizontalDiagram.scss';

export default class HorizontalDiagram extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.shape({
      filterId: PropTypes.string,
      filterValue: PropTypes.number,
    })),
    seleniumPart: PropTypes.string,
  };

  render() {
    const { items, config, seleniumPart } = this.props;
    let prevItemColor = null;
    return (
      <div className="h-diagram">
        <div className="h-diagram__gradient-overlay" />
        <div className="h-diagram__items-wrap">
          {Object.keys(config).map((configKey, i) => {
            const conf = config[configKey];
            const item = items ? items.find(val => val.filterId === configKey) : null;

            const color = conf?.color || '#ffffff';
            const hasValue = !(!item || item.filterValue === null || item.filterValue === undefined);
            const flex = (!hasValue || item.filterValue === 0) ? 1 : item.filterValue;

            const elementStyle = {
              backgroundColor: color,
              flex: `${flex} 0 2rem`,
            };

            const arrowColorStyle = { borderColor: `transparent transparent transparent ${prevItemColor}` };
            const hasPrev = prevItemColor !== null;
            prevItemColor = color;

            const showLink = item?.filterValue;
            const Wrapper = showLink ? Link : 'div';

            return (
              <Wrapper
                className={cn('h-diagram__item', { disabled: !showLink })}
                to={(conf?.link) ? conf?.link : ''}
                key={item?.filterId || i}
                style={elementStyle}
                data-seleniumid={`${seleniumPart}-h-diagram-${configKey}-item`}
              >
                {hasPrev && <div className="h-diagram__before-arrow" style={arrowColorStyle} />}
                <div className="h-diagram__item-fill">
                  <div className="h-diagram__item-text" data-seleniumid={`${seleniumPart}-h-diagram-${configKey}-value`}>
                    {!hasValue ? 'N/A' : item?.filterValue}
                  </div>
                </div>
              </Wrapper>
            );
          })}
        </div>
      </div>
    );
  }
}

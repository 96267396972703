export const SUCCESS = '/success';
export const FAILURE = '/failure';

const createActionCreator = actionType => type => ({ payload, ...params }) => ({
  type: `${type}${actionType}`,
  payload,
  ...params,
});

export const successActionCreator = createActionCreator(SUCCESS);
export const failureActionCreator = createActionCreator(FAILURE);

const asyncMiddleware = ({ defaultTransformError }) => () => next => async action => {
  const { type, payload, asyncCall, selfCall = true, transformResult, transformError = defaultTransformError, ...params } = action;

  if (!asyncCall) {
    return next(action);
  }

  if (selfCall) {
    next({ type, payload, ...payload });
  }

  const successAction = successActionCreator(type);
  const failAction = failureActionCreator(type);

  try {
    const res = await asyncCall();
    return next(
      successAction({
        ...payload,
        ...params,
        payload: transformResult ? transformResult(res) : res,
      }),
    );
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.log('e', e);
    return next(
      failAction({
        ...payload,
        ...params,
        payload: transformError ? transformError(e) : e,
      }),
    );
  }
};

export default asyncMiddleware;

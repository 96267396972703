export default {
  urgency: 'Urgency',
  tsVendors: 'TS Vendors',
  paymentStatuses: 'Payment Statuses',
  productionStages: 'Production Stages',
  licenseStatuses: 'License Statuses',
  accesses: 'Access',
  acronym: 'JID',
  journalAcronym: 'JID',
  volumeYears: 'Volume Year',
  volumeNumbers: 'Volume Number',
  issueNumbers: 'Issue Number',
  pubDate: 'Pub Date (Issue)',
};

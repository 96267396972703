import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MainLayout from 'app/layouts/Main';
import ErrorBoundary from 'app/components/ErrorBoundary/index';

const RoutePublic = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout {...Component.layoutProps}>
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      </Layout>
    )}
  />
);

RoutePublic.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  layout: PropTypes.func,
  location: PropTypes.object,
  to: PropTypes.string,
};

RoutePublic.defaultProps = {
  to: '/',
  layout: MainLayout,
};

export default RoutePublic;

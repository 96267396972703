import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withForm, Field } from '@wiley/cpp-ui-commons/lib/hoc/withForm';
import { validateEmail, validatePassword } from 'app/services/validators';
import LoginErrors from './LoginErrors';
import './LoginForm.scss';

class LoginForm extends PureComponent {
  static propTypes = {
    authError: PropTypes.shape({
      errorCode: PropTypes.string,
      refId: PropTypes.string,
    }),
    focusable: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { onSubmit, authError, focusable } = this.props;
    return (
      <>
        <LoginErrors authError={authError} />
        <form onSubmit={onSubmit}>
          <div className="login-form__row">
            <Field
              data-seleniumid="login-email-input"
              name="email"
              placeholder="Email"
              className="login-form__field"
              focusable={focusable}
            />
          </div>
          <div className="login-form__row">
            <Field
              data-seleniumid="login-password-input"
              name="password"
              placeholder="Password"
              type="password"
              className="login-form__field"
            />
          </div>
          <div className="login-form__row">
            <input
              data-seleniumid="login-submit-button"
              className="login-form__submit-btn"
              value="Login"
              type="submit"
            />
          </div>
        </form>
      </>
    );
  }
}

export default withForm({
  validators: {
    email: validateEmail,
    password: validatePassword,
  },
  onSubmit: (fields, props) => props.onSubmit(fields),
})(LoginForm);

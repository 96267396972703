import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import './TableHeader.scss';

const TableHeader = ({ headers, onRemove, seleniumId }) => (
  <div className="mt-header__wrap">
    {headers.map(id => <div key={id} data-seleniumid={`${seleniumId}-header-${id}`} className="mt-header__item">{startCase(id)}</div>)}
    {onRemove && <div className="mt-header__actions" />}
  </div>
);

TableHeader.propTypes = {
  headers: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  seleniumId: PropTypes.string.isRequired,
};

export default TableHeader;

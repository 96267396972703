import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import { WorkInProgressContainer } from 'app/pages/WorkInProgress/containers';
import { INCORRECT_REQUEST, NO_RESULTS_SEARCH_MESSAGE } from 'app/enums/commonErrors';
import { ASC_SORT } from 'app/services/api/sort';
import { getArticles, getArticlesFacets } from 'app/redux/api/common';
import { getValidParamsForPage, sortSecondaryFields } from 'app/utils';
import { getRelevantSearchType, getExtraFilterParams } from 'app/pages/Search/common/utils';
import path from 'app/router/path';
import {
  DASHBOARD_ARTICLES_STORE_KEY,
  PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ARTICLES,
} from 'app/pages/Search/constants';
import { descriptor } from './SearchDashboardArticlesDescriptor';

const DashboardArticleSearchResults = ({ type, q, countsIsLoading, issueNavId = '', articlesInIssue, location = {} }) => {
  const defaultSortFieldId = descriptor({ type, q }).find(col => col.defaultSortField).id;
  const { sortField: sortFieldFromURL } = getValidParamsForPage(location, ['sortField']);
  const isArticlesInIssue = articlesInIssue === 'true';
  return (
    <WorkInProgressContainer
      entitiesEffect={p => (
        (type && q)
          ? getArticles()({
            ...p,
            [getRelevantSearchType(type)]: q,
            ignoreAssignedJournals: true,
            progressStatuses: isArticlesInIssue ? [] : PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ARTICLES,
          })
          : Promise.reject({ error: 'Incorrect request' })
      )}
      facetsEffect={getArticlesFacets({
        [getRelevantSearchType(type)]: q,
        ignoreAssignedJournals: true,
        progressStatuses: isArticlesInIssue ? [] : PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ARTICLES,
      })}
      initialState={{
        searchQuery: type && q ? { [getRelevantSearchType(type)]: q } : null,
        stats: {
          sortField: defaultSortFieldId,
          sortDir: ASC_SORT,
          secondarySortFields: [...sortSecondaryFields(descriptor({ type, q })).map(col => col.id)
            .filter(id => id !== (sortFieldFromURL || defaultSortFieldId))],
        },
        ...getExtraFilterParams(articlesInIssue),
      }}
      descriptor={descriptor({ type, q })}
      hideTitle={!articlesInIssue || !issueNavId}
      pageTitle={articlesInIssue && issueNavId
        ? (
          <>
            Articles In Issue&nbsp;
            <Link
              data-seleniumid="page-title-link"
              to={path.getInProgressIssuesDetail(issueNavId)}
            >
              {q}
            </Link>
          </>
        ) : undefined}
      fullTitle="CMH | Search | Dashboard Article results"
      name={DASHBOARD_ARTICLES_STORE_KEY}
      renderError={() => <InfoView seleniumId="no-results" title={NO_RESULTS_SEARCH_MESSAGE} text={INCORRECT_REQUEST} />}
      renderNoResults={() => <InfoView seleniumId="no-results" title={NO_RESULTS_SEARCH_MESSAGE} text="" />}
      pipeProps={({ defaultMerge, ...otherProps }) => {
        const mergedProps = defaultMerge(otherProps);
        const { entitiesHasLoading } = mergedProps;

        return {
          ...mergedProps,
          entitiesHasLoading: entitiesHasLoading || countsIsLoading,
        };
      }}
    />
  );
};

DashboardArticleSearchResults.propTypes = {
  articlesInIssue: PropTypes.string,
  countsIsLoading: PropTypes.bool,
  issueNavId: PropTypes.string,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  q: PropTypes.string,
  type: PropTypes.string,
};

export default DashboardArticleSearchResults;

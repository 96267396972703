import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import hoistStatics from 'hoist-non-react-statics';
import connectTableView from 'app/modules/Table/hoc/withTableFilter/connectTableView';

const withTableFilter = Component => {
  const C = class extends PureComponent {
    componentDidMount() {
      const { fetchEntities, setInitialState, initialState } = this.props;

      setInitialState(initialState);
      fetchEntities();
    }

    handleTableChange = stats => {
      const { updateStats } = this.props;
      return updateStats(stats);
    };

    handleChangeCount = pageSize => {
      const { updateStats } = this.props;
      return updateStats({ pageSize });
    };

    handleChangePage = currentPage => {
      const { updateStats } = this.props;
      return updateStats({ currentPage });
    };

    handleUpdateFilters = payload => {
      const { updateFacets } = this.props;
      return updateFacets(payload);
    };

    handleRemoveSelectedFilters = selectedFilters => {
      const { removeSelectedFilters } = this.props;
      removeSelectedFilters(selectedFilters);
    };

    handleApplyFiltersChange = selectedFilters => {
      const { applySelectedFilters } = this.props;
      applySelectedFilters(selectedFilters, true);
    };

    handleUpdateSelectedFilters = selectedFilters => {
      const { updateSelectedFilters } = this.props;
      updateSelectedFilters(selectedFilters, true);
    };

    render() {
      const { name } = this.props;
      return (
        <Component
          key={name}
          {...this.props}
          onUpdateSelectedFilters={this.handleUpdateSelectedFilters}
          onChangeTable={this.handleTableChange}
          onChangeCount={this.handleChangeCount}
          onRemoveSelectedFilters={this.handleRemoveSelectedFilters}
          onChangePage={this.handleChangePage}
          onApplySelectedFilters={this.handleApplyFiltersChange}
          onUpdateFilters={this.handleUpdateFilters}
        />
      );
    }
  };

  C.propTypes = {
    applySelectedFilters: PropTypes.func,
    fetchEntities: PropTypes.func,
    initialState: PropTypes.object,
    name: PropTypes.string,
    removeSelectedFilters: PropTypes.func,
    setInitialState: PropTypes.func,
    updateFacets: PropTypes.func,
    updateSelectedFilters: PropTypes.func,
    updateStats: PropTypes.func,
  };

  const CContainer = connectTableView(hoistStatics(C, Component));

  CContainer.wrappedComponent = Component;
  CContainer.displayName = `withTableFilter(${Component.displayName || Component.name})`;

  return withRouter(CContainer);
};

export default withTableFilter;

import React from 'react';
import { Helmet } from 'react-helmet';

const NotFound = () => (
  <div className="app__not-found app__route">
    <Helmet>
      <title>CMH | Not found</title>
    </Helmet>
    <h1>404</h1>
    <p>Page not found.</p>
  </div>
);

NotFound.layoutProps = {
  header: {
    title: 'Page Not Found',
  },
};

export default NotFound;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BaseRender from '@wiley/cpp-ui-commons/lib/components/Table/containers/CellRender/BaseRender';
import './ScheduleRow.scss';


const ScheduleRow = ({ row, wrapProps, descriptor }) => {
  const { className, ...wrap } = wrapProps;

  return (
    <div {...wrap} className={cn(className)}>
      {descriptor.map(
        ({ id, Render = BaseRender, additionalProps, colWidth }) => {
          const props = additionalProps ? additionalProps(row[id], row) : {};
          return (
            <Render
              id={id}
              value={row[id]}
              colWidth={colWidth}
              row={row}
              key={id}
              additionalProps={props}
            />
          );
        })}
    </div>
  );
};

ScheduleRow.propTypes = {
  descriptor: PropTypes.array.isRequired,
  row: PropTypes.shape({
    actualDate: PropTypes.string,
    id: PropTypes.string,
    revisedDate: PropTypes.string,
    title: PropTypes.string,
  }),
  wrapProps: PropTypes.shape({
    className: PropTypes.string,
    'data-seleniumid': PropTypes.string,
    key: PropTypes.string,
  }),
};


export default ScheduleRow;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import path from 'app/router/path';
import MainLayout from 'app/layouts/Main';
import PageLoadingLayout from 'app/layouts/PageLoading';
import { Guard, Permission, PermissionWaiter } from 'app/modules/Auth';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import ErrorBoundary from 'app/components/ErrorBoundary/index';

class RoutePrivate extends PureComponent {
  static propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    layout: PropTypes.func,
    location: PropTypes.object,
    requiredRoles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    to: PropTypes.string,
  };

  static defaultProps = {
    to: '/',
    layout: MainLayout,
    requiredRoles: '',
  };

  renderGuardNotAuth = () => {
    const { location } = this.props;
    return <Redirect to={{ pathname: path.login, state: { search: location.search, lastPathName: location.pathname } }} />;
  };

  renderPermissionLoader = () => {
    const { component: Component } = this.props;
    return <PageLoadingLayout {...Component.layoutProps} />;
  };

  renderPermissionDenied = () => {
    const { component: Component, layout: Layout } = this.props;

    return (
      <Layout {...Component.layoutProps}>
        <InfoView seleniumId="forbidden" title="403" text="Forbidden." className="forbidden" />
      </Layout>
    );
  };

  renderRoute = props => {
    const { component: Component, layout: Layout, requiredRoles } = this.props;

    return (
      <Guard fallback={this.renderGuardNotAuth}>
        <PermissionWaiter fallback={this.renderPermissionLoader}>
          <Permission requiredRoles={requiredRoles} fallback={this.renderPermissionDenied}>
            <Layout {...Component.layoutProps}>
              <ErrorBoundary>
                <Component {...props} />
              </ErrorBoundary>
            </Layout>
          </Permission>
        </PermissionWaiter>
      </Guard>
    );
  };

  render() {
    const { component, ...rest } = this.props;

    return <Route {...rest} render={this.renderRoute} />;
  }
}

export default RoutePrivate;

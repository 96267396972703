import {
  updatePublicationArticleHistory,
  removePublicationArticleHistory,
} from 'app/redux/api/common';
import {
  WARNING_BEFORE_ACCEPTED_MSG,
  WARNING_BEFORE_REVISED_MSG,
  WARNING_AFTER_REVISED_MSG,
  WARNING_AFTER_RECEIVED_MSG,

  PUBLICATIONS_EDIT_FIELDS,
} from './constants';
import DateCell from '../components/table/DateCell';
import { parsePublicationApiPayload, getPublicationWarningMessage } from './utils';


const editableFields = Object.values(PUBLICATIONS_EDIT_FIELDS);

const getWarning = ({ id }, items) => date => {
  if (!date || !editableFields.includes(id)) return null;

  switch (id) {
    case PUBLICATIONS_EDIT_FIELDS.accepted:
      return getPublicationWarningMessage({
        date,
        items,
        before: {
          msg: WARNING_BEFORE_ACCEPTED_MSG,
          fields: [PUBLICATIONS_EDIT_FIELDS.revised, PUBLICATIONS_EDIT_FIELDS.received],
        },
      });
    case PUBLICATIONS_EDIT_FIELDS.revised:
      return getPublicationWarningMessage({
        date,
        items,
        before: {
          msg: WARNING_BEFORE_REVISED_MSG,
          fields: [PUBLICATIONS_EDIT_FIELDS.received],
        },
        after: {
          msg: WARNING_AFTER_REVISED_MSG,
          fields: [PUBLICATIONS_EDIT_FIELDS.accepted],
        },
      });
    case PUBLICATIONS_EDIT_FIELDS.received:
      return getPublicationWarningMessage({
        date,
        items,
        after: {
          msg: WARNING_AFTER_RECEIVED_MSG,
          fields: [PUBLICATIONS_EDIT_FIELDS.accepted, PUBLICATIONS_EDIT_FIELDS.revised],
        },
      });
    default: return null;
  }
};

export default (entityId, onUpdate, isEditable, items) => [
  {
    id: 'title',
    caption: 'Title',
    sortable: false,
    colWidth: 50,
  },
  {
    id: 'date',
    caption: 'date',
    sortable: false,
    colWidth: 50,
    Render: DateCell,
    additionalProps: (val, row) => ({
      isEditable: () => isEditable && editableFields.includes(row?.id),
      getWarningMessage: getWarning(row, items),
      entityId,
      onUpdate,
      getApiPayload: parsePublicationApiPayload,
      updateAsyncCall: updatePublicationArticleHistory,
      removeAsyncCall: val ? removePublicationArticleHistory : null,
      className: 'publication-cell',
    }),
  },
];

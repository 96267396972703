import { createSelector } from 'reselect';
import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { getArticleJobsheets } from 'app/components/ArticleContent/api';

const initialState = {
  jobsheets: {},
  error: null,
  isLoading: false,
};

const LOAD_ARTICLE_JOBSHEETS = 'article-jobsheets/load';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ARTICLE_JOBSHEETS: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case LOAD_ARTICLE_JOBSHEETS + SUCCESS: {
      const { id } = action;
      return {
        ...state,
        isLoading: false,
        jobsheets: { [id]: payload },
      };
    }
    case LOAD_ARTICLE_JOBSHEETS + FAILURE: {
      const { id } = action;
      const { message } = payload;
      return {
        ...state,
        isLoading: false,
        jobsheets: { [id]: [] },
        error: message,
      };
    }
    default:
      return state;
  }
};

// Actions
export const loadArticleJobsheets = ({ id, productionId, aid }) => ({
  type: LOAD_ARTICLE_JOBSHEETS,
  payload: { id },
  asyncCall: () => getArticleJobsheets(productionId, aid),
});

// Selectors
const selectArticleJobsheetsStore = (state = initialState) => state.articleJobsheets;

export const selectJobsheets = (state, id) => selectArticleJobsheetsStore(state)?.jobsheets[id] || [];

export const selectJobsheetsIsLoading = createSelector(
  selectArticleJobsheetsStore,
  state => state?.isLoading,
);
export const selectJobsheetsError = createSelector(
  selectArticleJobsheetsStore,
  state => state?.error,
);

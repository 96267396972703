import React from 'react';
import PropTypes from 'prop-types';
import AppHeaderBaseLayout from '@wiley/cpp-ui-commons/lib/components/AppHeader/layouts/AppHeaderBaseLayout';
import Footer from 'app/components/AppFooter';
import AppHeaderLogo from 'app/components/AppHeader/AppHeaderLogo';

const GuestLayout = ({ children }) => (
  <>
    <AppHeaderBaseLayout leftCol={<AppHeaderLogo logoIcon="logo-black" projectName="Production Dashboard" />} />
    <div className="scrollable">
      <div className="app__main">{children}</div>
      <Footer />
    </div>
  </>
);

GuestLayout.propTypes = {
  children: PropTypes.any,
};

export default GuestLayout;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { WorkInProgressContainer } from 'app/pages/WorkInProgress/containers';
import { ASC_SORT } from 'app/services/api/sort';
import { defaultTableNoResult } from 'app/utils/defaultTableNoResult';
import { getIssues, getIssuesFacets } from 'app/redux/api/common';
import { getValidParamsForPage } from 'app/utils';
import { descriptor } from './common/issuesInProgressDescriptor';
import { WIP_ISSUE_PROGRESS_STATUSES } from './constants';

const defaultSortFieldId = descriptor.find(col => col.defaultSortField).id;

class IssuesInProgress extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
      key: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  };

  getInitialStats() {
    const { location = {} } = this.props;
    const { sortField: sortFieldFromURL } = getValidParamsForPage(location, ['sortField']);
    return {
      stats: {
        sortField: defaultSortFieldId,
        sortDir: ASC_SORT,
        secondarySortFields: [...descriptor.map(col => col.id)
          .filter(id => id !== (sortFieldFromURL || defaultSortFieldId))],
      },
    };
  }

  render() {
    const { location } = this.props;

    return (
      <WorkInProgressContainer
        title="Issues"
        descriptor={descriptor}
        name="issues"
        entitiesEffect={getIssues(WIP_ISSUE_PROGRESS_STATUSES)}
        facetsEffect={getIssuesFacets(WIP_ISSUE_PROGRESS_STATUSES)}
        initialState={this.getInitialStats()}
        renderNoResults={defaultTableNoResult}
        location={location}
        hideTitle={true}
      />
    );
  }
}

export default IssuesInProgress;

import he from 'he';
import DOMPurify from 'dompurify';

export const sanitizeOptions = { USE_PROFILES: { mathMl: true, html: true } };

export const parse = rawTitle => {
  if (!rawTitle) return '';
  const title = rawTitle.replace(/<annotation.*?>.*?<\/annotation>/ig, '');
  return DOMPurify.sanitize(he.decode(title || ''), sanitizeOptions);
};
export const isTitleMathML = title => (title || '').includes('<math') && (title || '').includes('</math>');

import React, { PureComponent } from 'react';
import { Switch, Route, Redirect, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import PageHeaderPortal from '@wiley/cpp-ui-commons/lib/components/PageHeader/PageHeaderPortal';
import { getQueryParams } from '@wiley/cpp-ui-commons/lib/router/history';
import DotLoader from '@wiley/cpp-ui-commons/lib/components/DotLoader';
import path, { SEARCH_DASHBOARD_ARTICLE_DETAILED, SEARCH_DASHBOARD_ISSUE_DETAILED, SEARCH_PUBLISHED_ARTICLE_DETAILED } from 'app/router/path';
import { addImitateToParams } from 'app/pages/SwitchView/utils';
import { articlesCategories } from 'app/enums/mainSearchCategories';
import DetailedIssues from 'app/pages/DetailedViews/DetailedIssuesView';
import DetailedArticles from 'app/pages/DetailedViews/DetailedArticlesView';
import { getRelevantSearchType, getExtraFilterParams } from 'app/pages/Search/common/utils';
import DashboardArticleSearchResults from './tabs/DashboardArticles/DashboardArticleSearchResults';
import DashboardIssueSearchResults from './tabs/DashboardIssues/DashboardIssueSearchResults';
import PublishedArticleSearchResults from './tabs/PublishedArticles/PublishedArticleSearchResults';

export class Search extends PureComponent {
  static propTypes = {
    counts: PropTypes.object,
    countsIsLoading: PropTypes.bool,
    fetchEntitiesCount: PropTypes.func,
    location: PropTypes.object,
    match: PropTypes.object,
  };

  componentDidMount() {
    const { location } = this.props;
    if (this.isDetailSearchPage(location)) {
      this.loadDetailCounts(location);
    }
  }

  componentDidUpdate(prevProps) {
    const { location: oldLocation } = prevProps;
    const { location } = this.props;

    if (this.isChangeId(oldLocation, location) && this.isDetailSearchPage(location)) {
      this.loadDetailCounts(location);
    }
  }

  loadDetailCounts(location) {
    const { fetchEntitiesCount } = this.props;
    const { type, q, articlesInIssue } = getQueryParams({ location });
    if (type && q) {
      fetchEntitiesCount(addImitateToParams({ [getRelevantSearchType(type)]: q, ignoreAssignedJournals: true }), getExtraFilterParams(articlesInIssue));
    }
  }

  isChangeId(oldLocation, newLocation) {
    if (!oldLocation) return false;
    const { q: oldQ } = getQueryParams({ location: oldLocation });
    const { q } = getQueryParams({ location: newLocation });
    return oldQ !== q;
  }

  isDetailSearchPage(location) {
    const { pathname } = location;
    return matchPath(pathname, { path: path.dashboardArticlesSearchDetail }) || matchPath(pathname, { path: path.publishedArticlesSearchDetail }) || matchPath(pathname, { path: path.dashboardIssuesSearchDetail });
  }

  renderDashboardArticles = () => {
    const { location } = this.props;
    return <DashboardArticleSearchResults {...this.props} {...getQueryParams({ location })} />;
  };

  renderPublishedArticles = () => {
    const { location } = this.props;
    return <PublishedArticleSearchResults {...this.props} {...getQueryParams({ location })} />;
  };

  renderDashboardIssues = () => {
    const { location } = this.props;
    return <DashboardIssueSearchResults {...this.props} {...getQueryParams({ location })} />;
  };

  renderDetailedArticles = ({ match }) => <DetailedArticles {...this.props} match={match} />;

  renderDetailedIssues = ({ match }) => <DetailedIssues {...this.props} match={match} />;

  getPaths = (id, isArticle) => {
    const { match, location } = this.props;
    const { type, pageSize, q, articlesInIssue } = getQueryParams({ location });

    const path = `${match.url}/${id}`;
    const pathWithQuery = `${path}?${stringify({ articlesInIssue, pageSize, q, type })}`;
    const detailedPath = isArticle ? `${match.url}/${id}/:article_id/` : `${match.url}/${id}/:issue_id/`;

    return ({
      path,
      pathWithQuery,
      detailedPath,
    });
  };

  getTabs = (id, title, countsIsLoading, counts, href) => ({
    id,
    title: <>{title} ({ countsIsLoading ? <DotLoader /> : counts })</>,
    href,
    isActive: (_, { pathname }) => pathname.includes(id),
  });

  render() {
    const { location, counts, countsIsLoading } = this.props;
    const { type } = getQueryParams({ location });

    const dashboardArticles = this.getPaths(SEARCH_DASHBOARD_ARTICLE_DETAILED, true);
    const dashboardIssues = this.getPaths(SEARCH_DASHBOARD_ISSUE_DETAILED, false);
    const publishedArticles = this.getPaths(SEARCH_PUBLISHED_ARTICLE_DETAILED, true);

    let defaultPath;
    if (type) {
      defaultPath = articlesCategories.includes(type.toLowerCase()) ? dashboardArticles.pathWithQuery : dashboardIssues.pathWithQuery;
    }
    else {
      defaultPath = dashboardArticles.pathWithQuery;
    }
    const { articlesCount = 0, issuesCount = 0, publishedArticlesCount = 0 } = counts ?? {};

    return (
      <>
        <PageHeaderPortal
          inside="top-header"
          tabs={[
            this.getTabs(SEARCH_DASHBOARD_ARTICLE_DETAILED, 'Dashboard Articles', countsIsLoading, articlesCount, dashboardArticles.pathWithQuery),
            this.getTabs(SEARCH_DASHBOARD_ISSUE_DETAILED, 'Dashboard Issues', countsIsLoading, issuesCount, dashboardIssues.pathWithQuery),
            this.getTabs(SEARCH_PUBLISHED_ARTICLE_DETAILED, 'Published Articles', countsIsLoading, publishedArticlesCount, publishedArticles.pathWithQuery),
          ]}
        />
        <Switch>
          <Route path={dashboardArticles.path} exact render={this.renderDashboardArticles} />
          <Route path={dashboardArticles.detailedPath} render={this.renderDetailedArticles} />

          <Route path={dashboardIssues.path} exact render={this.renderDashboardIssues} />
          <Route path={dashboardIssues.detailedPath} render={this.renderDetailedIssues} />

          <Route path={publishedArticles.path} exact render={this.renderPublishedArticles} />
          <Route path={publishedArticles.detailedPath} render={this.renderDetailedArticles} />

          <Redirect to={defaultPath} />
        </Switch>
      </>
    );
  }
}

Search.propTypes = {
  counts: PropTypes.shape({
    articlesCount: PropTypes.number,
    issuesCount: PropTypes.number,
  }),
  countsIsLoading: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
};

Search.layoutProps = {
  tab: {
    id: 'search',
    title: 'Search results',
    to: path.search,
  },
};

export default Search;

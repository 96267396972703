import { call, put, putResolve, takeLatest, select } from 'redux-saga/effects';
import { isEmpty, mergeWith } from 'lodash';
import ls from 'app/services/localStorage';
import { packFilters, unpackSelectedFilters } from 'app/services/filters';
import { successActionCreator } from 'app/redux/asyncMiddleware';
import history, { getQueryParams, setQueryParams } from '@wiley/cpp-ui-commons/lib/router/history';
import { getImitatePEidInfo } from 'app/pages/SwitchView/utils';
import {
  FETCH_ENTITIES,
  FETCH_ENTITIES_INIT,
  APPLY_SELECTED_FILTERS,
  REMOVE_SELECTED_FILTERS,
  UPDATE_STATS,
  UPDATE_SELECTED_FILTERS,
  selectStats,
} from './TableDucks';
import { getFiltersParams, getRelevantFilters } from './common';
import { fetchFacets } from './TableFiltersDucks';

function* fetchTableEntitiesWorker(action) {
  const {
    name,
    payload: { selectedFilters } = {},
    entitiesEffect,
    stats = yield select(state => selectStats(state, name)),
    filters = yield getFiltersParams({ selectedFilters, name }),
  } = action;

  const { sortField, sortDir, currentPage, pageSize, secondarySortFields } = stats;

  return yield putResolve({
    ...action,
    type: FETCH_ENTITIES,
    selfCall: false,
    asyncCall: () =>
      entitiesEffect({
        secondarySortFields,
        sortField,
        sortDir,
        offset: (currentPage - 1) * pageSize,
        size: pageSize,
        ...filters,
        ...getImitatePEidInfo(),
      }),
  });
}

function* addSearchQuery({ name, stats, selectedFilters }) {
  const filters = yield getRelevantFilters({ name, selectedFilters });
  const query = getQueryParams();
  const newQueryObject = {
    ...query,
    ...stats,
    secondarySortFields: undefined,
    criteria: isEmpty(filters)
      ? undefined
      : packFilters({ filters }),
  };

  ls.setJSON(name, newQueryObject);
  setQueryParams(newQueryObject);
}

function* selectedFiltersWorker(action) {
  const { name, payload } = action;
  const { selectedFilters } = payload;
  const { pageSizeOptions, ...stats } = yield select(state => selectStats(state, name));

  yield call(addSearchQuery, { name, stats, selectedFilters });

  yield put({
    ...action,
    type: FETCH_ENTITIES,
  });

  const successAction = successActionCreator(action.type);
  yield put(successAction({ name, payload }));
}

function* removeSelectedFiltersWorker(action) {
  yield put(fetchFacets(action));
  yield call(selectedFiltersWorker, action);
}

function* applySelectedFiltersWorker(action) {
  yield call(selectedFiltersWorker, action);
}

function* updateStatsWorker(action) {
  const { name, payload: payloadStats } = action;

  const isChangeSize = Boolean(payloadStats?.pageSize);
  const stats = isChangeSize
    ? ({
      ...payloadStats,
      currentPage: 1,
    })
    : payloadStats;
  yield call(addSearchQuery, { name, stats });

  yield put({
    ...action,
    payload: {
      stats,
    },
    type: FETCH_ENTITIES,
  });
}

function* initTable(action) {
  const { name, initialState, payload } = action;

  const { state = {} } = history.location;
  const { resetFilters } = state;

  const queryObj = getQueryParams();
  const savedFilters = resetFilters
    ? {}
    : ls.getJSON(name) || {};

  const {
    pageSize,
    currentPage,
    sortDir,
    sortField,
    criteria,
  } = !resetFilters && isEmpty(queryObj)
    ? savedFilters
    : queryObj;

  const { pageSizeOptions, ...storeStats } = yield select(state => selectStats(state, name));

  const stats = mergeWith({
    pageSize: parseInt(pageSize, 10),
    currentPage: parseInt(currentPage, 10),
    sortField,
    sortDir,
  },
  {
    ...storeStats,
    ...initialState.stats,
    ...payload?.stats,
  },
  (toVal, srcVal) => toVal || srcVal);

  const selectedFilters = unpackSelectedFilters(criteria) || [];

  yield call(addSearchQuery, { name, selectedFilters, stats });

  yield put({
    ...action,
    type: FETCH_ENTITIES,
    initialState: {
      ...initialState,
      stats,
      selectedFilters,
    },
  });
}

export function* watchTableEntities() {
  yield takeLatest(FETCH_ENTITIES, fetchTableEntitiesWorker);
  yield takeLatest(REMOVE_SELECTED_FILTERS, removeSelectedFiltersWorker);
  yield takeLatest(APPLY_SELECTED_FILTERS, applySelectedFiltersWorker);
  yield takeLatest(UPDATE_SELECTED_FILTERS, selectedFiltersWorker);
  yield takeLatest(UPDATE_STATS, updateStatsWorker);
  yield takeLatest(FETCH_ENTITIES_INIT, initTable);
}

import React from 'react';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import MainLayout from 'app/layouts/Main';

const PageLoading = ({ ...props }) => (
  <MainLayout {...props}>
    <SectionLoader hasLoading />
  </MainLayout>
);

export default PageLoading;

import { connect } from 'react-redux';
import {
  logout,
  logoutByClick,
  login,
  fetchRoles,
  selectUser,
  selectIsAuth,
  selectError,
  selectIsAuthorizing,
  selectRoles,
  selectRolesIsLoading,
  selectRolesIsLoaded,
} from '../../redux/AuthDucks';

export const mapStateToProps = state => ({
  isAuthorized: selectIsAuth(state),
  isAuthorizing: selectIsAuthorizing(state),
  user: selectUser(state),
  authError: selectError(state),
  roles: selectRoles(state),
  rolesIsLoading: selectRolesIsLoading(state),
  rolesIsLoaded: selectRolesIsLoaded(state),
});

export const mapDispatchToProps = dispatch => ({
  login(creds) {
    dispatch(login(creds));
  },

  logout() {
    dispatch(logout());
  },

  logoutByClick() {
    dispatch(logoutByClick());
  },

  fetchRoles() {
    dispatch(fetchRoles());
  },
});

export default C =>
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(C);

import path from 'app/router/path';
import roles from 'app/enums/roles';

export const SECTION_HOME = 'homeSection';
export const SECTION_FEATURES = 'featuresSection';
export const PAGE_HOME = 'home';
export const SECTION_WORK_IN_PROGRESS = 'workInProgress';
export const PAGE_ARTICLES_IN_PROGRESS = 'articleInProgress';
export const PAGE_ARTICLES_IN_ISSUE = 'articlesInIssue';
export const PAGE_ISSUES_IN_PROGRESS = 'issueInProgress';
export const SECTION_REQUIRES_ACTION = 'requiresAction';
export const PAGE_ARTICLES_REQUIRING_ACTION = 'articlesRequiringAction';
export const PAGE_ISSUES_REQUIRING_ACTION = 'issuesRequiringAction';
export const PAGE_SWITCH_VIEW = 'switchView';
export const SECTION_ADMIN_DASHBOARD = 'adminSection';
export const PAGE_ADMIN_DASHBOARD = 'adminDashboard';
export const PAGE_MANAGE_TITLES = 'manageTitles';
export const PAGE_MANAGE_USERS = 'manageUsers';

export const menusConfig = [
  {
    id: SECTION_HOME,
    links: [
      {
        id: PAGE_HOME,
        title: 'Home',
        href: path.dashboard,
      },
    ],
  },
  {
    id: SECTION_WORK_IN_PROGRESS,
    title: 'Work In Progress',
    href: path.inProgress,
    links: [
      {
        id: PAGE_ARTICLES_IN_PROGRESS,
        title: 'Articles In Progress',
        href: path.inProgressArticles,
      },
      {
        id: PAGE_ISSUES_IN_PROGRESS,
        title: 'Issues In Progress',
        href: path.inProgressIssues,
      },
      {
        id: PAGE_ARTICLES_IN_ISSUE,
        title: 'Ready For Issue',
        href: path.inProgressReadyForIssue,
      },
    ],
  },
  {
    id: SECTION_REQUIRES_ACTION,
    title: 'Requires Action',
    href: path.requiresAction,
    links: [
      {
        id: PAGE_ARTICLES_REQUIRING_ACTION,
        title: 'Articles Requiring Action',
        href: path.requiresActionArticles,
      },
      {
        id: PAGE_ISSUES_REQUIRING_ACTION,
        title: 'Issues Requiring Action',
        href: path.requiresActionIssues,
      },
    ],
  },
  {
    id: SECTION_FEATURES,
    title: 'Features',
    links: [
      {
        id: PAGE_SWITCH_VIEW,
        title: 'Switch View',
        href: path.switchView,
        icon: 'switch-view',
      },
    ],
  },
  {
    id: SECTION_ADMIN_DASHBOARD,
    title: 'Admin Features',
    role: roles.systemAdministrator,
    links: [
      {
        id: PAGE_ADMIN_DASHBOARD,
        title: 'Admin Dashboard',
        href: path.admin,
        exact: true,
        icon: 'admin-dashboard',
      },
      {
        id: PAGE_MANAGE_TITLES,
        title: 'Manage Titles',
        href: path.adminManageTitles,
        icon: 'manage-titles',
      },
      {
        id: PAGE_MANAGE_USERS,
        title: 'Manage Users',
        href: path.adminManageUsers,
        icon: 'manage-users',
      },
    ],
  },
];

const itemsById = menusConfig.reduce((acc, category) => {
  acc[category.id] = category;
  category.links.forEach(item => {
    acc[item.id] = item;
  });
  return acc;
}, {});

export function generateMenuByIds(items) {
  return items.map(item => (typeof item === 'object' ? item : itemsById[item]));
}

export function getLinksFromGroupById(id) {
  const group = itemsById[id];

  return group?.links;
}

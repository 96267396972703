import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateInCMHSinceDate as updateInCMHSinceDateAction } from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalsDucks';

import CMHFlowSelect from 'app/pages/Admin/ManageTitles/components/JournalCard/components/CMHFlowSelect';

export const mapDispatchToProps = dispatch => bindActionCreators({
  updateInCMHSinceDate: ({ journalId, inCMHSinceDate }) =>
    updateInCMHSinceDateAction({ journalId, inCMHSinceDate }),
},
dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(CMHFlowSelect);

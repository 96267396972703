import { connect } from 'react-redux';
import VBAsTab from 'app/pages/Admin/ManageTitles/components/Tabs/VBAsTab';
import { selectUsers } from 'app/pages/Admin/ManageTitles/reducers/UsersDucks';
import {
  unassignUser,
  assignUser,
  searchingUser,
  searchUser,
  createUser,
  resetCreating,
  resetUsersStats,
  editUser,
} from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalUsersDucks';
import { selectJournalTabInfo } from 'app/pages/Admin/ManageTitles/reducers/Journals';
import { USER_VBA_TYPE } from 'app/pages/Admin/ManageTitles/common/constants';
import { changeUserInJournal, createParticipant, searchUsers, editParticipant, VBA_PROPERTY } from 'app/pages/Admin/api/admin';
import { mergeProps, isNotValidEmail } from './utils';

export const mapStateToProps = (state, { journal }) => {
  const users = selectUsers(state);
  const assignedUsers = journal[USER_VBA_TYPE].map(id => users[USER_VBA_TYPE + id]);
  const { search, create, ...usersStats } = selectJournalTabInfo(journal.id, USER_VBA_TYPE)(state);

  return {
    users: assignedUsers,
    search,
    usersStats,
    create,
  };
};

export const mapDispatchToProps = (dispatch, { journal }) => {
  const { id: journalId } = journal;

  const userProps = {
    journalId,
    userType: USER_VBA_TYPE,
  };

  return {
    onRemove: (id) => {
      dispatch(unassignUser({
        id,
        ...userProps,
        effect: changeUserInJournal,
        asyncProps: {
          value: id,
          property: VBA_PROPERTY,
        },
      }));
    },
    onAdd: (id) => {
      dispatch(assignUser({
        id,
        ...userProps,
        effect: changeUserInJournal,
        asyncProps: {
          value: id,
          property: VBA_PROPERTY,
        },
      }));
    },
    onEdit: (id, { firstName, lastName }) => {
      dispatch(editUser({
        ...userProps,
        id,
        effect: editParticipant,
        asyncProps: {
          id,
          firstName,
          lastName,
          value: id,
          property: VBA_PROPERTY,
        },
      }));
    },
    onCreate: (fields) => {
      dispatch(createUser({
        fields,
        effect: createParticipant,
        ...userProps,
      }));
    },
    onResetCreate: () => {
      dispatch(resetCreating(userProps));
    },
    onChangeSearch: (value) => {
      dispatch(searchingUser({
        searchText: value,
        ...userProps,
      }));
    },
    onSearch: (users, searchText) => {
      const isNotValid =  isNotValidEmail(searchText, users, USER_VBA_TYPE);
      if (isNotValid) {
        dispatch(searchingUser({
          searchText,
          error: isNotValid,
          ...userProps,
        }));
      }
      else {
        dispatch(searchUser({
          ...userProps,
          params: {
            email: searchText,
            hasUserId: false,
            allowAlmUserId: true,
          },
          effect: searchUsers,
        }));
      }
    },
    onResetUsers: (userId) => {
      dispatch(resetUsersStats(userProps, userId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(VBAsTab);

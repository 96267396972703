/* eslint-disable react/no-unused-state */
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import './ErrorBoundary.scss';
import PropTypes from 'prop-types';
import { errorReport } from 'app/redux/api/common';
import { withAuthProvider } from 'app/modules/Auth';
import { selectUser } from 'app/pages/SwitchView/redux/SwitchViewDucks';


const title = 'Something went wrong.';
const errorMessage = 'Unfortunately current block cannot be displayed. We know about the problem and are already fixing it. Please, reload the page later.';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    const { userEmail = null, switchUserEmail = null } = props;
    this.state = {
      userEmail,
      switchUserEmail,
      errorStack: '',
      errorPath: null,
    };
    this.devMode = process.env.NODE_ENV !== 'production';
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    switchUserEmail: PropTypes.string,
    userEmail: PropTypes.string,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { location: { pathname }, userEmail, switchUserEmail } = nextProps;
    const { errorPath } = prevState;

    if (errorPath && errorPath !== pathname) {
      return { errorStack: '', errorPath: null, userEmail, switchUserEmail };
    }
    return null;
  }

  async componentDidCatch(error) {
    const { location: { pathname }, userEmail, switchUserEmail } = this.props;

    const state = {
      errorPath: pathname,
      errorStack: error?.stack || '',
      userEmail,
      switchUserEmail,
    };

    this.setState(state);

    if (!this.devMode) {
      const report = btoa(JSON.stringify(state));
      await errorReport({ report });
    }
  }

  render() {
    const {
      errorPath,
      errorStack,
    } = this.state;

    if (errorPath) {
      return (
        <div className="ml-3 mt-3">
          <div className="mb-3 error-boundary__title">
            {title}
          </div>
          <div className="error-boundary__description">
            { this.devMode ? <pre>{errorStack}</pre> : <h4>{errorMessage}</h4> }
          </div>
        </div>
      );
    }

    const { children } = this.props;
    return children;
  }
}


export const mapStateToProps = (state = {}) => ({
  switchUserEmail: (selectUser(state) || {}).email,
});


export default compose(
  withRouter,
  withAuthProvider,
)(connect(mapStateToProps, null)(ErrorBoundary));

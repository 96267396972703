import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { FAILURE, SUCCESS } from 'app/redux/asyncMiddleware';
import { getSortedCategories, getSortedOptions } from 'app/utils/filters';
import { APPLY_SELECTED_FILTERS } from './TableDucks';
import { createAction } from './actionUtils';

export const FETCH_FACETS = 'table-filter-view/fetchFacets';
export const UPDATE_FACETS = 'table-filter-view/updateFacets';

export const initialState = fromJS({
  hasLoading: false,
  error: null,
  facets: [],
  categories: false,
  tempFacets: {
    options: [],
    categories: false,
  },
});

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_FACETS:
      return state.merge({ hasLoading: true, error: null, tempFacets: null });

    case FETCH_FACETS + SUCCESS: {
      const { options, categories } = payload;
      return state.merge({ hasLoading: false, facets: fromJS(options), categories: fromJS(categories) });
    }

    case FETCH_FACETS + FAILURE:
      return state.merge({
        hasLoading: false,
        error: fromJS(payload),
        categories: fromJS([]),
        facets: fromJS([]),
      });

    case UPDATE_FACETS:
      return state
        .set('error', null)
        .updateIn(
          ['tempFacets'],
          tempFacets => (tempFacets ? tempFacets.set('isLoading', true) : fromJS({ isLoading: true })),
        );

    case UPDATE_FACETS + SUCCESS: {
      const { options, categories } = payload;
      return state
        .set('tempFacets', fromJS({ options, isLoading: false, categories }))
        .updateIn(['categories'], currentCategories => (currentCategories.size ? currentCategories : fromJS(categories)))
        .updateIn(['facets'], facets => (facets.size ? facets : fromJS(options)));
    }

    case UPDATE_FACETS + FAILURE: {
      return state
        .set('error', fromJS(payload))
        .set('tempFacets', null);
    }

    case APPLY_SELECTED_FILTERS + SUCCESS:
      return state.update(state => {
        const tempFacets = state.get('tempFacets');
        return state
          .set('facets', tempFacets.get('options'))
          .set('categories', tempFacets.get('categories'))
          .set('tempFacets', null);
      });

    default:
      return state;
  }
};

export const fetchFacets = createAction(({ payload }) => ({
  type: FETCH_FACETS,
  payload,
}));

export const updateFacets = createAction(({ payload }) => ({
  type: UPDATE_FACETS,
  payload,
}));

export const selectFilters = (state, name) => state.tableFilterView?.get(name)?.get?.('filters');

export const selectTempFacets = createSelector(selectFilters, filters => filters?.get?.('tempFacets')?.toJS?.());

export const selectFacetsCategories = createSelector(selectFilters, filters => {
  const categories = filters?.get('categories')?.toJS?.();

  return getSortedCategories(categories || []);
});

export const selectFacetsOptions = createSelector(selectFilters, selectFacetsCategories, (filters, categories) => {
  const facets = filters?.get('facets')?.toJS?.() || [];

  return getSortedOptions(categories, facets);
});
export const selectFacetsHasLoading = createSelector(selectFilters, filters => filters?.get('hasLoading'));
export const selectFacetsError = createSelector(selectFilters, filters => filters?.get('error')?.toJS?.());

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Pager from '@wiley/cpp-ui-commons/lib/components/Pager';
import TableStatsLayout from '@wiley/cpp-ui-commons/lib/components/Table/TableStatsLayout';
import Select from '@wiley/cpp-ui-commons/lib/components/Select';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import Search from 'app/components/Search';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Stickable from 'app/components/Stickable';
import StickyContainer from 'app/components/Stickable/StickyContainer';
import FilterTabs from 'app/components/FilterTabs';
import Tooltip from 'app/components/Tooltip';
import { validateEmail } from 'app/services/validators';
import {
  ROLE_TABS,
  ALPHABET_OPTIONS,
  SIZES,
  DEFAULT_SIZE,
  SEARCH_PLACEHOLDER,
  SELECT_LETTER_PLACEHOLDER,
  DEFAULT_USERS_SORT_FIELD,
  DEFAULT_USERS_SORT_DIR,
} from 'app/pages/Admin/common/constants';
import ManageUsersList from './components/ManageUsersList';

import './ManageUsers.scss';

const inputRef = React.createRef();

class ManageUsers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      filter: ROLE_TABS[0],
      letter: null,
      size: DEFAULT_SIZE,
      emailValidationError: false,
    };
  }

  componentDidMount() {
    const {
      fetchGroups,
    } = this.props;

    fetchGroups();
    this.fetch();
  }

  fetch() {
    const {
      fetchUsers,
    } = this.props;

    const {
      page,
      filter,
      letter,
      size,
    } = this.state;

    fetchUsers({
      letter: letter?.value || null,
      size,
      offset: (page - 1) * size,
      sortField: DEFAULT_USERS_SORT_FIELD,
      sortDir: DEFAULT_USERS_SORT_DIR,
      dashboardUserRoles: filter.roles,
    });
    if (inputRef?.current) inputRef.current.clear();
  }

  renderList() {
    const {
      resetUserError,
      updateVendor,
      users,
      vendors,
      seleniumId = 'manage-users',
      updateRoles,
    } = this.props;


    return (
      <div className="manage-users__list">
        <ManageUsersList
          seleniumId={seleniumId}
          onSelectVendor={res => updateVendor({
            user: res.user,
            vendorId: res.vendor,
            prevVendorId: res.user.worksFor ? vendors?.find(vendor => vendor.name === res.user.worksFor)?.id : null,
          })}
          onSelectRole={updateRoles}
          onResetError={resetUserError}
          users={users}
          vendors={vendors}
        />
      </div>
    );
  }

  renderError() {
    const {
      error,
      seleniumId = 'manage-users',
    } = this.props;

    return (
      <InfoView title={error.message} seleniumId={seleniumId} />
    );
  }

  onSearch = search => {
    const {
      searchUser,
    } = this.props;

    if (search.length === 0) {
      this.setState({ emailValidationError: false, page: 1 }, this.fetch);
    }
    else {
      const emailValidationError = validateEmail(search);
      if (emailValidationError) {
        this.setState({ emailValidationError });
      }
      else {
        this.setState({ letter: null, emailValidationError: false, page: 1, filter: ROLE_TABS[0] }, () => searchUser(search));
      }
    }
  };

  onSearchChange = text => {
    const {
      emailValidationError,
    } = this.state;

    if (emailValidationError && (text.length === 0 || !validateEmail(text))) {
      this.setState({ emailValidationError: false });
    }
  };

  onBlur = () => {
    const {
      emailValidationError,
    } = this.state;

    if (emailValidationError) {
      this.setState({ emailValidationError: false });
    }
  };

  changeTab = tab => {
    this.setState({ page: 1, filter: tab }, this.fetch);
  };

  changeCount = size => {
    this.setState({ size, page: 1 }, this.fetch);
  };

  changeLetter = letter => {
    this.setState({ letter, page: 1 }, this.fetch);
  };

  changePage = page => {
    this.setState({ page }, this.fetch);
  };

  render() {
    const {
      users,
      error,
      totalAmount,
      isLoading,
      seleniumId = 'manage-users',
    } = this.props;

    const {
      page,
      letter,
      filter,
      size,
      emailValidationError,
    } = this.state;

    const tooltipProps = {
      id: 'manage-users__search-tooltip',
      className: 'manage-users__search-tooltip',
      content: emailValidationError ? () => emailValidationError : null,
      spyClassName: 'manage-users-search-input',
      place: 'left',
      type: 'error',
      effect: 'solid',
    };

    return (
      <div className="manage-users">
        <div className="manage-users__header">
          <FilterTabs
            className="manage-users__role-filter"
            onClick={this.changeTab}
            tabList={ROLE_TABS.map(tab => (filter?.id === tab.id ? { ...tab, active: true } : tab))}
          />
          <div className="manage-users__search-wrapper">
            <Search
              ref={inputRef}
              focusable
              seleniumId={seleniumId}
              placeholder={SEARCH_PLACEHOLDER}
              onSearch={this.onSearch}
              onSearchChange={this.onSearchChange}
              onBlur={this.onBlur}
              className="manage-users-search-input"
            />
          </div>
          <Tooltip {...tooltipProps} />
        </div>
        <SectionLoader hasLoading={isLoading}>
          <StickyContainer>
            <Stickable>
              <div className="manage-users__sticky-header">
                <div className="manage-users__stats">
                  <TableStatsLayout
                    allPageSizes={SIZES}
                    currentPage={page}
                    currentPageSize={size}
                    onCountChoose={this.changeCount}
                    recordsCount={users.length}
                    totalRecords={totalAmount}
                  />
                </div>
                <div className="letter-select__wrap">
                  <span className="letter-select__title">
                  Browse by surname:
                  </span>
                  <Select
                    className="letter-select"
                    options={ALPHABET_OPTIONS}
                    value={letter}
                    listPosition="bottom"
                    placeholder={SELECT_LETTER_PLACEHOLDER}
                    seleniumId="select-letter"
                    onChange={this.changeLetter}
                  />
                </div>
              </div>
            </Stickable>
            {!error ? this.renderList() : this.renderError()}
            <Pager
              totalPages={Math.ceil(totalAmount / size)}
              currentPage={page}
              maxCountAroundCurrent={1}
              onPage={this.changePage}
            />
          </StickyContainer>
        </SectionLoader>
      </div>
    );
  }
}

ManageUsers.propTypes = {
  error: PropTypes.object,
  fetchGroups: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  resetUserError: PropTypes.func,
  searchUser: PropTypes.func,
  seleniumId: PropTypes.string,
  totalAmount: PropTypes.number,
  updateRoles: PropTypes.func,
  updateVendor: PropTypes.func,
  users: PropTypes.array,
  vendors: PropTypes.array,
};

export default ManageUsers;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Table from 'app/containers/Table';
import { getArticleJobsheetsDescriptor } from 'app/components/ArticleContent/common/jobsheetsDescriptor';
import history from '@wiley/cpp-ui-commons/lib/router/history';
import appPath from 'app/router/path';
import { LOAD_ERROR_MSG } from 'app/constants';
import Preview from 'app/components/ArticleContent/components/Preview';
import ExpandButton from 'app/components/ExpandButton';
import DetailedSection from 'app/components/DetailedSection';
import { endpoints } from 'app/components/ArticleContent/api';
import TableWrap from '../components/TableWrap';
import InfoView from '../components/InfoView';
import '../common/ArticlesFiles.scss';

export const SHOW_LIMIT = 10;
const STICKY_ID = 'jobsheets-stickable-container';

const PreviewJobsheet = (props) => {
  // eslint-disable-next-line react/prop-types
  const { match: { params: { jobsheetId } } } = props;
  return <Preview {...props} contentURLPredefined={endpoints.getJobhseetContent(jobsheetId)} />;
};

const ArticleJobsheets = ({
  id,
  aid,
  productionId,
  loadArticleJobsheets,
  files,
  isLoading,
  error,
  match: { url, path },
}) => {
  const [showAll, setShowAll] = useState(false);
  const [fileList, setFileList] = useState([]);
  const showExpandToggle = files?.length > SHOW_LIMIT;
  useEffect(() => {
    if (id && productionId) loadArticleJobsheets();
  }, [id, productionId]);

  useEffect(() => {
    const resultFilesList = [];
    for (const file of files.slice(0, showAll ? files.length : SHOW_LIMIT)) {
      resultFilesList.push({
        ...file,
        fileName: { isVersion: false, name: file.fileName },
        icon: { isVersion: false, formatMnemonic: file.formatMnemonic },
        action: { isExpanded: file.isExpanded, contentId: file.contentId },
      });
    }

    setFileList(_.sortBy(resultFilesList, jobsheet => jobsheet.modifiedDate));
  }, [files, showAll]);

  return (
    <SectionLoader
      hasLoading={isLoading}
      className="article-content__loader"
    >
      <div data-seleniumid="article-jobsheets" className="article-content">
        {error
          ? <InfoView message={LOAD_ERROR_MSG(`${aid} jobsheets`)} />
          : (
            <div className="article-versions__body">
              {files?.length > 0 ? (
                <DetailedSection title="Article Jobsheets">
                  <TableWrap stickyId={STICKY_ID}>
                    <Table
                      className="limit-list"
                      items={fileList}
                      descriptor={getArticleJobsheetsDescriptor({ history, url })}
                      headerProps={{ stickyId: STICKY_ID }}
                    />
                  </TableWrap>
                  {showExpandToggle ? (
                    <div className="d-flex justify-content-left ml-1 mt-3">
                      <ExpandButton
                        seleniumId="article-jobsheet-show-more-toggle"
                        isExpanded={showAll}
                        onClick={() => setShowAll(!showAll)}
                      />
                    </div>
                  ) : null}
                </DetailedSection>
              ) : (
                <InfoView message="Article has no jobsheets in CMS yet" />
              )}
            </div>
          )
        }
      </div>
      <Route exact path={`${path}${appPath.previewJobsheet}`} component={PreviewJobsheet} />
    </SectionLoader>
  );
};

ArticleJobsheets.propTypes = {
  aid: PropTypes.string,
  error: PropTypes.string,
  files: PropTypes.array.isRequired,
  id: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  loadArticleJobsheets: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      article_id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  productionId: PropTypes.string,
};

export default ArticleJobsheets;

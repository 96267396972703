import qs from 'query-string';
import { isEmpty } from 'lodash';

export const packFilters = ({ filters }) => {
  const encoded = btoa(unescape(encodeURIComponent(JSON.stringify(filters))));
  return qs.stringify({
    filters: encoded,
  });
};

export const unpackFilters = payload => {
  const { filters } = qs.parse(payload);
  const decode = JSON.parse(decodeURIComponent(escape(atob(filters))));
  return {
    filters: decode,
  };
};

export const unpackSelectedFilters = criteria => {
  try {
    const unpacked = unpackFilters(criteria);
    const { filters } = unpacked;
    return filters;
  }
  catch (e) {
    return null;
  }
};

export const generateLink = ({ pathname, filters }) => ({
  pathname,
  search: isEmpty(filters)
    ? ''
    : qs.stringify({
      criteria: packFilters({
        filters,
      }),
    }),
  state: { resetFilters: true },
});

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TabSelector from 'app/components/TabSelector';
import BorderMarker from '@wiley/cpp-ui-commons/lib/components/BorderMarker';
import CVsContainer from 'app/pages/Admin/ManageTitles/containers/CVsContainer';
import PEsContainer from 'app/pages/Admin/ManageTitles/containers/PEsContainer';
import VBAsContainer from 'app/pages/Admin/ManageTitles/containers/VBAsContainer';
import { USER_PE_TYPE, USER_CV_TYPE, USER_VBA_TYPE } from 'app/pages/Admin/ManageTitles/common/constants';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import parseISO from 'date-fns/parseISO';
import CMHFlowSelectContainer from './containers/CMHFlowSelectContainer';

import './JournalCard.scss';

const userTimezoneOffset = (new Date()).getTimezoneOffset() * 60000;

const ASSIGNED_TABS = [
  { text: 'Production Editors', id: USER_PE_TYPE },
  { text: 'Content Vendors', id: USER_CV_TYPE },
  { text: 'Vendor Bookin Associate', id: USER_VBA_TYPE },
];

class JournalCard extends PureComponent {
  state = {
    tabId: USER_PE_TYPE,
  };

  static propTypes = {
    journal: PropTypes.shape({
      acronym: PropTypes.string,
      id: PropTypes.string,
      inCMHSinceDate: PropTypes.string,
      isDateLoading: PropTypes.bool,
      isLoading: PropTypes.bool,
      journalName: PropTypes.string,
    }),
  };

  onTabChange = (id) => {
    this.setState({ tabId: id });
  };

  renderTab(Container) {
    const { journal } = this.props;
    return (
      <div className="mt-journal__assigned-wrap">
        <Container
          journal={journal}
        />
      </div>
    );
  }

  prepareDate = dateString => (dateString ? new Date(parseISO(dateString).getTime() + userTimezoneOffset) : null);

  render() {
    const { journal: { id, acronym, journalName, inCMHSinceDate, isDateLoading } } = this.props;
    const { tabId } = this.state;
    return (
      <div className="mt-journal mb-4" data-seleniumid={`search-journal-${id}`}>
        <div className="mt-journal__info-wrap">
          <div className="mt-journal__data-item">
            <div className="mt-journal__info-field" data-seleniumid="journal-name-value">
              {journalName}
            </div>
            <div className="mt-journal__info-subfield">
              Journal ID: <b data-seleniumid="jid-value">{acronym}</b>
            </div>
          </div>
          <div className="mt-journal__included-in-flow-container">
            <SectionLoader hasLoading={isDateLoading}>
              <CMHFlowSelectContainer
                journalId={id}
                inCMHSinceDate={this.prepareDate(inCMHSinceDate)}
              />
            </SectionLoader>
          </div>
        </div>
        <BorderMarker spyClassName="active" markerClassName="mt-journal__tabs-marker">
          <TabSelector
            className="mt-journal__tabs" header="List of Assigned" items={ASSIGNED_TABS} onTabChange={this.onTabChange}
            activeTabId={tabId}
          />
        </BorderMarker>
        {tabId === USER_PE_TYPE && this.renderTab(PEsContainer)}
        {tabId === USER_CV_TYPE && this.renderTab(CVsContainer)}
        {tabId === USER_VBA_TYPE && this.renderTab(VBAsContainer)}
      </div>
    );
  }
}

export default JournalCard;

import {
  generateMenuByIds,
  PAGE_HOME,
  SECTION_REQUIRES_ACTION,
  SECTION_WORK_IN_PROGRESS,
} from 'app/config/menusConfig';

export const menuListConfig = generateMenuByIds([
  PAGE_HOME,
  SECTION_REQUIRES_ACTION,
  SECTION_WORK_IN_PROGRESS,
]);

import { getLightArticle } from 'app/redux/api/common';
import { updateByPaths } from 'app/redux/detailed/utils';

export const LOAD_LIGHT_ARTICLE = 'detailed/load/light/article';
export const LOAD_LIGHT_ARTICLE_SUCCESS = `${LOAD_LIGHT_ARTICLE}/success`;

export default (state, action, updateFunc, itemState) => {
  const { type, payload } = action || {};

  switch (type) {
    case LOAD_LIGHT_ARTICLE_SUCCESS: {
      const { context, result } = payload || {};
      const { parts } = context || {};

      let data = result;
      if (parts?.length) data = updateByPaths(parts, result, itemState);
      return updateFunc([], { hasLoading: false, data });
    }
    default:
      return state;
  }
};


export const loadLightArticle = ({ storeType, id, parts }) => ({
  type: LOAD_LIGHT_ARTICLE,
  payload: { context: { storeType, params: { id } } },
  asyncCall: () => getLightArticle(id),
  transformResult: result => ({ context: { storeType, params: { id }, parts }, result }),
});

import { END, eventChannel } from 'redux-saga';
import history from '@wiley/cpp-ui-commons/lib/router/history';
import qs from 'query-string';
import { call, take } from 'redux-saga/effects';

export function historyChannel(pathName) {
  return eventChannel(emitter => history.listen((location, action) => {
    if (location.pathname !== pathName) return emitter(END);

    if (action === 'POP') {
      emitter({ search: qs.parse(location.search), isBack: true });
    }
    else if (!location.search) {
      emitter({});
    }
  },
  ));
}

export function* historySpy({ pathname, onBack, onReset }) {
  const chan = yield call(historyChannel, pathname);
  try {
    while (true) {
      const { search, isBack } = yield take(chan);

      if (isBack) {
        yield call(onBack, search);
      }
      else if (onReset) {
        yield call(onReset, search);
      }
    }
  }
  catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
}

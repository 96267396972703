import { connect } from 'react-redux';
import PEsTab from 'app/pages/Admin/ManageTitles/components/Tabs/PEsTab';
import { selectUsers } from 'app/pages/Admin/ManageTitles/reducers/UsersDucks';
import { unassignUser, assignUser, searchingUser, searchUser, resetUsersStats, updateVendor } from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalUsersDucks';
import { selectJournalTabInfo } from 'app/pages/Admin/ManageTitles/reducers/Journals';
import { USER_PE_TYPE } from 'app/pages/Admin/ManageTitles/common/constants';
import { changeUserInJournal, PE_PROPERTY, deleteParticipantVendor, assignParticipantVendor } from 'app/pages/Admin/api/admin';
import { searchUsers } from 'app/pages/Admin/ManageUsers/api/manageUsers';
import { fetchGroups, selectGroups } from 'app/redux/reducers/groups';
import { mergeProps, isNotValidEmail } from './utils';

export const mapStateToProps = (state, { journal }) => {
  const users = selectUsers(state);
  const assignedUsers = journal[USER_PE_TYPE].map(id => users[USER_PE_TYPE + id]);

  const { search, create, ...usersStats } = selectJournalTabInfo(journal.id, USER_PE_TYPE)(state);
  const vendors = selectGroups(state);

  return {
    users: assignedUsers,
    search,
    usersStats,
    vendors,
  };
};

export const mapDispatchToProps = (dispatch, { journal }) => {
  const { id: journalId } = journal;

  const userProps = {
    journalId,
    userType: USER_PE_TYPE,
  };

  return {
    fetchGroups() {
      dispatch(fetchGroups());
    },
    onRemove: (id) => {
      dispatch(unassignUser({
        id,
        ...userProps,
        effect: changeUserInJournal,
        asyncProps: {
          value: id,
          property: PE_PROPERTY,
        },
      }));
    },
    onAdd: (id) => {
      dispatch(assignUser({
        id,
        ...userProps,
        effect: changeUserInJournal,
        asyncProps: {
          value: id,
          property: PE_PROPERTY,
        },
      }));
    },
    onChangeSearch: (value) => {
      dispatch(searchingUser({
        searchText: value,
        ...userProps,
      }));
    },
    onSearch: (users, searchText) => {
      const isNotValid =  isNotValidEmail(searchText, users, USER_PE_TYPE);
      if (isNotValid) {
        dispatch(searchingUser({
          searchText,
          error: isNotValid,
          ...userProps,
        }));
      }
      else {
        dispatch(searchUser({
          ...userProps,
          params: {
            email: searchText,
            accountType: 'sso',
          },
          effect: searchUsers,
        }));
      }
    },
    onResetUsers: () => {
      dispatch(resetUsersStats(userProps));
    },
    updateVendor({ user, vendorId, prevVendorId }) {
      if (vendorId) {
        dispatch(updateVendor({ ...userProps, user, vendorId }, assignParticipantVendor));
      }
      else {
        dispatch(updateVendor({ ...userProps, user, vendorId: prevVendorId }, deleteParticipantVendor));
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PEsTab);

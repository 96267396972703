import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { getCorrectSeleniumId } from '@wiley/cpp-ui-commons/lib/utils/seleniumIdUtil';

export default function DetailedSection({ title, children, className = '' }) {
  return (
    <div className={cn('detailed-section', className)}>
      <h4 className="detailed-section__title" data-seleniumid={`${getCorrectSeleniumId(title)}-section`}>{title}</h4>
      { children }
    </div>
  );
}

DetailedSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

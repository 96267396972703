import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactEventListener from 'react-event-listener';
import ReactTooltip from 'react-tooltip';

// @TODO: It will be refactored. Need to research new tooltip
class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.id = props.id || Date.now().toString();
  }

  componentDidMount() {
    const { spyClassName } = this.props;
    const element = document.querySelector(`.${spyClassName}`);
    if (!element) {
      return;
    }

    this.element = element;
    element.setAttribute('data-for', this.id);
    element.setAttribute('data-tip', '');
  }

  componentDidUpdate() {
    const { content } = this.props;

    if (content) {
      ReactTooltip.show(this.element);
    }
    else {
      ReactTooltip.hide(this.element);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  tid = 0;

  handleResize = () => {
    if (this.tid) {
      return;
    }

    this.tid = setTimeout(() => {
      requestAnimationFrame(() => {
        if (this.unmounted) {
          return;
        }
        this.setState({}, () => {
          this.tid = 0;
        });
      });
    }, 50);
  };

  handleLoad = () => {
    this.handleResize();
  };

  render() {
    const { className, content, ...tooltipProps } = this.props;

    if (!content) {
      return null;
    }

    return (
      <>
        <ReactTooltip
          {...tooltipProps}
          id={this.id}
          className={cn(className, 'show')}
        >
          {React.createElement(content)}
        </ReactTooltip>
        <ReactEventListener
          target="window"
          onResize={this.handleResize}
          onLoad={this.handleLoad}
        />
      </>
    );
  }
}

Tooltip.propTypes = {
  className: PropTypes.string,
  content: PropTypes.any,
  id: PropTypes.string,
  spyClassName: PropTypes.string.isRequired,
};

export default Tooltip;

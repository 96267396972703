import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Router } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { ToastContainer, Slide } from 'react-toastify';
import { ModalContainer } from '@wiley/cpp-ui-commons/lib/components/Modal';
import history from '@wiley/cpp-ui-commons/lib/router/history';
import { FeatureToggle } from '@wiley/cpp-ui-commons/lib/components/FeatureToggle';
import { applyHOCs } from 'app/hoc';
import routes from 'app/router/routes';
import { features } from 'app/constants/features';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = ({ isAuthorized }) => (
  <FeatureToggle
    isAuthorized={isAuthorized}
    features={features}
  >
    <ModalContainer>
      <ToastContainer
        autoClose={10000}
        transition={Slide}
        theme="colored"
      />
      <Router history={history}>{routes}</Router>
    </ModalContainer>
  </FeatureToggle>
);

App.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

export default compose(
  hot(module),
  applyHOCs,
)(App);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './EmptyWidget.scss';

export default class EmptyWidget extends PureComponent {
  static propTypes = {
    seleniumPart: PropTypes.string,
    text: PropTypes.string,
  };

  render() {
    const { seleniumPart, text } = this.props;
    return (
      <div className="empty-widget__card" data-seleniumid={`${seleniumPart}-card`}>
        <div>{text}</div>
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ExpandableList from 'app/components/ExpandableList';
import NA from './EmptyRender';
import TextRender from './TextRender';
import './ListColumnRender.scss';

class ListColumnRender extends React.PureComponent {
  static propTypes = {
    options: PropTypes.shape({
      fullWidth: PropTypes.bool,
      ItemRender: PropTypes.func,
      staticHeight: PropTypes.bool,
    }),
    seleniumId: PropTypes.string.isRequired,
    value: PropTypes.shape({
      emptyText: PropTypes.string,
      expand: PropTypes.bool,
      items: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.object),
      ]),
      limit: PropTypes.number,
    }).isRequired,
  };

  static defaultProps = {
    options: {},
  };

  renderItems(items) {
    const {
      value: { expand = false, limit },
      seleniumId,
      options: { staticHeight = false, ItemRender = TextRender },
    } = this.props;

    const rows = items.map((item, i) => (
      <ItemRender
        key={i}
        seleniumId={`${seleniumId}-string-list-${i}`}
        {...(typeof item === 'string' ? { value: { text: item } } : item)}
      />
    ));

    return expand
      ? (
        <ExpandableList
          seleniumId={seleniumId}
          limit={limit}
          staticHeight={staticHeight}
        >
          {rows}
        </ExpandableList>
      )
      : rows;
  }

  render() {
    const {
      value: { items, emptyText },
      seleniumId,
      options: { fullWidth },
    } = this.props;

    return (
      <div
        data-seleniumid={`${seleniumId}-string-list`}
        className={cn('list-column-render', fullWidth ? 'full-width' : '')}
      >
        {
          items?.length
            ? this.renderItems(items)
            : <NA seleniumId={seleniumId} value={emptyText} />
        }
      </div>
    );
  }
}

export default ListColumnRender;

import React from 'react';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import { formatByExtention } from 'app/components/ArticleContent/common/fileFormats';

const FileIconCell = ({ fileType, isVersion = false }) => (
  <div style={{ display: 'flex', alignItems: 'flex-start' }} className="article-content__modal-file-wrapper">
    {
      isVersion ? (
        <svg
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ height: '20px', width: '15px' }}
          className="article-content__modal-file-type-corner"
        >
          <path d="M5 0H0V200H200V195H5V0Z" fill="#005274" />
        </svg>
      ) : null
    }
    <Svg className="article-content__modal-file-type-icon" name={`file-${fileType}`} />
  </div>
);

FileIconCell.propTypes = {
  fileType: PropTypes.oneOf(['unknown', 'image', ...Object.keys(formatByExtention)]).isRequired,
  isVersion: PropTypes.bool,
};

export default FileIconCell;

import React, { useState } from 'react';
import ClickAwayListener from 'app/components/ClickAwayListener';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { parseOrcid } from 'app/pages/DetailedViews/DetailedArticlesView/common/utils';
import './AuthorField.scss';


const C_AUTHOR = 'Corresponding author';

const AuthorField = ({ seleniumId, name, isCorresponding, email, orcid, affiliations }) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!name) return null;

  const isDetail = email || orcid || affiliations.length;

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <span className="author">
        <span className={cn('author__name', { isDetail, isOpen })}>
          <span
            onClick={() => isDetail && setIsOpen(!isOpen)}
            data-seleniumid={`${seleniumId}-name`}
          >
            {name}
          </span>
          {(isCorresponding && email) ? (
            <a
              href={`mailto:${email}`}
              className="author__mail"
              data-seleniumid={`${seleniumId}-mail-icon`}
            >
              <Svg name="mail" className="icon" />
            </a>
          )
            : null}
        </span>
        {(isOpen && isDetail) ? (
          <div className="author__detail">
            {isCorresponding ? (
              <div
                className="author__detail-title"
                data-seleniumid={`${seleniumId}-detail-title`}
              >
                {C_AUTHOR}
              </div>
            ) : null}
            {email ? (
              <div className="author__detail-link">
                <Svg name="mail" className="icon" />
                <a
                  target="_blank"
                  href={`mailto:${email}`}
                  className="author__detail-link"
                  data-seleniumid={`${seleniumId}-detail-mail-link`}
                >
                  {email}
                </a>
              </div>
            ) : null}
            {orcid ? (
              <div className="author__detail-link">
                <a
                  target="_blank"
                  href={orcid}
                  className="author__detail-link"
                  data-seleniumid={`${seleniumId}-detail-orcid-link`}
                >
                  <Svg name="orcid" className="icon" />
                  {parseOrcid(orcid)}
                </a>
              </div>
            ) : null}
            { affiliations?.length ? affiliations.map(({ id, text }) => (
              <div
                key={id}
                className="author__detail-affiliation"
                data-seleniumid={`${seleniumId}-detail-affiliation`}
              >
                {text}
              </div>
            )) : null}
          </div>
        ) : null}
      </span>
    </ClickAwayListener>
  );
};

AuthorField.propTypes = {
  affiliations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  })),
  email: PropTypes.string,
  isCorresponding: PropTypes.bool,
  name: PropTypes.string,
  orcid: PropTypes.string,
  seleniumId: PropTypes.string.isRequired,
};


export default AuthorField;

import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { updateByPaths, preProcess } from 'app/redux/detailed/utils';
import extensions from './extensions';

const initialState = fromJS({});

const defaultPageState = {
  hasLoading: false,
  data: {},
};

export const FETCH = 'detailed/fetch';
export const FETCH_SUCCESS = 'detailed/fetch/success';
export const FETCH_FAILURE = 'detailed/fetch/failure';
export const UPDATE_ENTITY = 'detailed/update';
export const LOAD = 'detailed/load';

function getUpdatePageFieldFunction(state, storeType, id) {
  return (path, value) =>
    state
      .updateIn([storeType, id].concat(path || []), page => page || fromJS(defaultPageState))
      .mergeIn([storeType, id].concat(path || []), value);
}

function getItemState(state, storeType, id) {
  return state && state.get(storeType) && state.get(storeType).get(id)
    ? state
      .get(storeType)
      .get(id)
    : null;
}

// Actions
export const fetchStart = payload => ({ type: FETCH, payload });
export const fetchSuccess = payload => ({ type: FETCH_SUCCESS, payload });
export const fetchFailure = payload => ({ type: FETCH_FAILURE, payload });
export const loadData = (storeType, params) => ({ type: LOAD, payload: { storeType, params } });
export const update = ({ id, path, result, store }) => {
  if (!id || !path || !store) return {};
  return { type: UPDATE_ENTITY,
    payload: {
      context: { storeType: store, params: { id } },
      path,
      result,
    } };
};

// Selectors
export const selectStoreByType = (state, storeType) => state.detailed.get(storeType) || fromJS({});

export const selectDetailed = (state, storeType, id) =>
  selectStoreByType(state, storeType).get(id) || fromJS(defaultPageState);

export const selectData = createSelector(selectDetailed, detailed => detailed.get('data').toJS());
export const selectHasLoading = createSelector(selectDetailed, detailed => detailed.get('hasLoading'));
export const selectError = createSelector(selectDetailed, detailed => {
  const error = detailed.get('error');

  return error ? error.toJS() : null;
});
export const selectHasError = createSelector(selectDetailed, detailed => !!detailed.get('error'));


export default (state = initialState, action) => {
  const { type, payload } = action;
  if (!payload || !payload.context) {
    return state;
  }

  const { storeType, params } = payload.context;

  const updateFunc = getUpdatePageFieldFunction(state, storeType, params.id);

  switch (type) {
    case FETCH:
      return updateFunc([], { hasLoading: true, error: null });

    case FETCH_SUCCESS: {
      return updateFunc([], { hasLoading: false, data: preProcess(storeType, payload.result) });
    }
    case UPDATE_ENTITY: {
      const { path, result } = payload;
      const detailed = getItemState(state, storeType, params.id);
      return updateFunc([], { hasLoading: false, data: updateByPaths(Array.isArray(path) ? path : [path], preProcess(storeType, result), detailed) });
    }
    case FETCH_FAILURE:
      return updateFunc([], { hasLoading: false, error: fromJS(payload) });
    default:
      return extensions.reduce(
        (acc, val) => val(acc, action, updateFunc, getItemState(acc, storeType, params.id)),
        state,
      );
  }
};

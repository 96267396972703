import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import { getCompareArrayByFieldsFunction } from 'app/utils';
import { validateEmail, validateNotEmpty } from 'app/services/validators';
import EditableTableRow from 'app/pages/Admin/ManageTitles/components/Tabs/components/EditableTableRow';
import TableHeader from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableHeader';
import TableCreate from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableCreate';
import TableSearch from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableSearch';
import TableError from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableError';
import TableRowButton from 'app/pages/Admin/ManageTitles/components/Tabs/components/buttons/TableRowButton';
import TableRowTooltipButton from 'app/pages/Admin/ManageTitles/components/Tabs/components/buttons/TableRowTooltipButton';
import EditableTableAdd from './components/EditableTableAdd';
import './Tab.scss';

const descriptor = {
  headers: [
    {
      id: 'lastName',
      validator: value => validateNotEmpty('Last Name', value),
    },
    {
      id: 'firstName',
      validator: value => validateNotEmpty('First Name', value),
    },
    {
      id: 'email',
      readOnly: true,
      validator: validateEmail,
    },
  ],
  sort: getCompareArrayByFieldsFunction(['lastName', 'firstName', 'email']),
  searchPlaceholder: 'Assign a user by email',
  noDataPlaceholder: 'No assigned users',
  seleniumId: 'vba',
  suggestionHeader: ['firstName', 'lastName'],
  suggestionText: 'email',
  removeMessage: '<div><b>Not permitted.</b></div><div>At least one Vendor BA must be assigned</div>',
};

class VBAsTab extends PureComponent {
  state = {
    createMode: false,
  };

  static propTypes = {
    create: PropTypes.shape({
      error: PropTypes.any,
      isCreating: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
    onAdd: PropTypes.func,
    onChangeSearch: PropTypes.func,
    onCreate: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    onResetCreate: PropTypes.func,
    onResetUsers: PropTypes.func,
    onSearch: PropTypes.func,
    search: PropTypes.object,
    users: PropTypes.array,
    usersStats: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    const { create: { isLoading: oldIsCreating } } = prevProps;
    const { create: { isCreating, error } } = this.props;
    const { createMode } = this.state;

    if (oldIsCreating && !isCreating && !error && createMode) {
      this.setState({ createMode: false });
    }
  }

  componentWillUnmount() {
    const { onResetUsers } = this.props;
    onResetUsers();
  }

  onStartCreate = () => {
    this.setState({ createMode: true });
  };

  onStartEdit = (userId) => {
    const { onResetUsers } = this.props;
    onResetUsers(userId);
  };

  onCancelCreate = () => {
    const { onResetCreate } = this.props;
    onResetCreate();
    this.setState({ createMode: false });
  };

  onCreate = (entity) => {
    const { onCreate } = this.props;
    onCreate(entity);
  };

  onCreateInputsChange = () => {
    const { create: { error }, onResetCreate } = this.props;
    if (error) {
      onResetCreate();
    }
  };

  renderHeader = () => {
    const { headers, seleniumId } = descriptor;
    const { onRemove } = this.props;
    return (
      <TableHeader
        headers={map(headers, 'id')}
        onRemove={onRemove}
        seleniumId={seleniumId}
      />
    );
  };

  renderRows = () => {
    const { users, onRemove, usersStats, onEdit } = this.props;
    const { headers, seleniumId, sort, removeMessage } = descriptor;

    const removeRender = users.length === 1 ? TableRowTooltipButton({ message: removeMessage, disabled: true }) : TableRowButton;
    return sort(users).map(user => (
      <EditableTableRow
        key={user.id}
        headers={headers}
        value={user}
        stats={usersStats[user.id]}
        seleniumId={seleniumId}
        removeHandler={onRemove(user.id)}
        RemoveRender={removeRender}
        editHandler={onEdit(user.id)}
        startEditHandler={this.onStartEdit}
        EditRender={TableRowButton}
        initialFields={user}
      />
    ),
    );
  };

  renderTable = () => {
    const { users } = this.props;
    const { noDataPlaceholder } = descriptor;

    return (
      <div className="mt-table__list-scrollable">
        <div className="mt-table__list">
          {this.renderHeader()}
          {this.renderRows()}
        </div>
        {!users.length && <div className="mt-table__no-result">{noDataPlaceholder}</div>}
      </div>
    );
  };

  renderSearch() {
    const { search, usersStats, onSearch, onChangeSearch, onAdd, onEdit } = this.props;
    const { searchPlaceholder, seleniumId, suggestionHeader, suggestionText, headers } = descriptor;

    const TableAddRender = EditableTableAdd({ editHandler: onEdit, headers });

    return (
      <TableSearch
        {...search}
        onSearch={onSearch}
        onAdd={onAdd}
        onCreate={this.onStartCreate}
        onChangeSearch={onChangeSearch}
        seleniumId={seleniumId}
        searchPlaceholder={searchPlaceholder}
        suggestionHeader={suggestionHeader}
        suggestionText={suggestionText}
        stats={usersStats}
        TableAddRender={TableAddRender}
      />
    );
  }

  renderError() {
    const { create: { error } } = this.props;
    const { seleniumId } = descriptor;

    return (
      <TableError
        error={error}
        seleniumId={`create-${seleniumId}`}
      />
    );
  }

  renderCreate() {
    const { create } = this.props;
    const { seleniumId, headers } = descriptor;

    return (
      <SectionLoader hasLoading={Boolean(create?.isLoading)}>
        <TableCreate
          seleniumId={seleniumId}
          headers={headers}
          onCancel={this.onCancelCreate}
          onSubmit={this.onCreate}
          onClick={this.onCreateInputsChange}
        />
        {create?.error && this.renderError()}
      </SectionLoader>
    );
  }

  render() {
    const { createMode } = this.state;
    return (
      <div className="mt-table__list-wrap">
        {this.renderTable()}
        {createMode ? this.renderCreate() : this.renderSearch()}
      </div>
    );
  }
}

export default VBAsTab;

import React, { PureComponent } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import SearchByCategory from 'app/containers/SearchByCategoryContainer';
import { selectOptions } from 'app/enums/mainSearchCategories';
import withUIState, { UIStateContext, AUTH, SIDEBAR } from 'app/hoc';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Login from 'app/pages/Login/components/LoginForm';
import { menusConfig } from 'app/config/menusConfig';
import Permission from 'app/modules/Auth/components/Permission';
import onClickOutside from 'react-onclickoutside';
import './MobileMenu.scss';

class MobileMenu extends PureComponent {
  static contextType = UIStateContext;

  handleClickOutside = () => {
    const { sidebar: { handleChange } } = this.context;
    handleChange(false);
  };

  renderLogged(logout, user) {
    return (
      <div data-seleniumid="profile-block-mobile" className="mobile-menu-profile-popup">
        <div data-dtrum-mask className="mobile-menu-profile-popup__name">
          {user.firstName} {user.lastName}
        </div>
        <div data-dtrum-mask className="mobile-menu-profile-popup__email">{user.email}</div>
        <a
          className="mobile-menu-profile-popup__logout"
          onClick={e => {
            e.preventDefault();
            logout();
          }}
          data-seleniumid="profile-menu-button-logout-mobile"
        >
          Logout
        </a>
      </div>
    );
  }

  renderGuest(isAuthorizing, authError, login) {
    return (
      <div>
        <div className="profile-popup login-form">
          <SectionLoader hasLoading={isAuthorizing}>
            <Login onSubmit={login} authError={authError} />
          </SectionLoader>
        </div>
      </div>
    );
  }

  renderLink(link, handleMenuChange) {
    const { id, role, href, icon, title, hasTargetBlank, exact } = link;
    const seleniumId = `${title.toLowerCase().split(' ').join('')}-link-button`;

    return (
      <Permission requiredRoles={role} key={id}>
        <div className="mobile-menu-list-category__item-wrap">
          {
            hasTargetBlank
              ? (
                <a
                  href={href}
                  data-seleniumid={seleniumId}
                  target="_blank"
                  onClick={() => handleMenuChange(false)}
                >
                  {icon && <Svg name={icon} className="mobile-menu-icon" />}{title}
                </a>
              )
              : (
                <NavLink
                  data-seleniumid={seleniumId}
                  to={href}
                  exact={exact}
                  onClick={() => handleMenuChange(false)}
                >
                  {icon && <Svg name={icon} className="mobile-menu-icon" />}{title}
                </NavLink>
              )
          }
        </div>
      </Permission>
    );
  }

  renderGroup(group, handleMenuChange) {
    const { id, role, title, links } = group;
    return (
      <Permission requiredRoles={role} key={id}>
        <div className="mobile-menu-list-category">
          {title && (<div className="mobile-menu-list-category__header">{title}</div>)}
          {links && links.map(link => this.renderLink(link, handleMenuChange))}
        </div>
      </Permission>
    );
  }

  render() {
    const { sidebar, auth } = this.context;
    const { handleChange } = sidebar;
    const { isAuthorized, isAuthorizing, authError, login, logout, user } = auth;

    return (
      <div className="mobile-menu">
        <div
          className="mobile-menu-collapse-hamburger"
          onClick={() => handleChange(false)}
          data-seleniumid="mobile-menu-hide-button"
        >
          <Svg name="hamburger" />
          <div className="mobile-menu-collapse-hamburger__text">
            Hide Menu
          </div>
        </div>
        <div className="mobile-menu-scrollable">
          {isAuthorized && (
            <div className="mobile-menu-menu-wrap">
              {menusConfig.map(group => this.renderGroup(group, handleChange))}
            </div>
          )}
          <div className="mobile-menu-bottom-wrap">
            {isAuthorized && (
              <div className="mobile-menu-search-wrap">
                <SearchByCategory
                  inputPlaceholder="Search articles or issues"
                  hasCollapse
                  seleniumId="front-page-mobile"
                  selectPlaceholder="Select"
                  selectOptions={selectOptions}
                  defaultValue={0}
                />
              </div>
            )}
            <div className="mobile-menu-profile-wrap">
              {isAuthorized ? this.renderLogged(logout, user) : this.renderGuest(isAuthorizing, authError, login)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUIState(AUTH, SIDEBAR)(withRouter(onClickOutside(MobileMenu)));

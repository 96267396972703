import { withRouter } from 'react-router-dom';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PaperHeader from 'app/components/PaperHeader';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Search from 'app/components/Search';
import path from 'app/router/path';
import { INCORRECT_REQUEST } from 'app/enums/commonErrors';
import { searchJournals, selectIsLoading, selectError } from 'app/pages/Admin/ManageTitles/reducers/JournalStateDucks';
import { selectJournals } from 'app/pages/Admin/ManageTitles/reducers/Journals';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import { getSortNaturalCompareByFieldFunction } from 'app/utils';
import JournalCard from './components/JournalCard';

export class ManageTitles extends PureComponent {
  static propTypes = {
    acronym: PropTypes.string,
    error: PropTypes.any,
    history: PropTypes.object,
    isLoading: PropTypes.bool,
    journals: PropTypes.array,
    location: PropTypes.object,
    searchJournal: PropTypes.func,
  };

  componentDidMount() {
    const { searchJournal } = this.props;
    searchJournal();
  }

  componentDidUpdate(prevProps) {
    const { acronym: prevJournalAcronym, location: prevLocation } = prevProps;
    const { searchJournal, acronym, location } = this.props;

    const { uid } = location.state || {};
    const { uid: prevUid } = prevLocation.state || {};

    if (acronym && (acronym !== prevJournalAcronym || prevUid !== uid)) {
      searchJournal();
    }
  }

  handleSearch = newJournalAcronym => {
    const { history } = this.props;
    const { acronym } = this.props;

    const url = newJournalAcronym.length === 0 ? path.adminManageTitles : path.getManageTitles(newJournalAcronym);
    const state = { uid: Date.now() };

    if (acronym !== newJournalAcronym) {
      history.push(url, state);
    }
    else {
      history.replace(url, state);
    }
  };

  renderDetails = () => {
    const { isLoading, error, acronym } = this.props;
    const { journals } = this.props;
    return (
      <Loader hasLoading={isLoading}>
        {error ? (
          <InfoView
            seleniumId="no-results"
            title={acronym ? 'no results found' : 'Title not found'}
            text={error.response?.status === 500 ? INCORRECT_REQUEST : ''}
          />
        ) : journals
          .sort(getSortNaturalCompareByFieldFunction('journalName'))
          .map(journal => (
            <JournalCard key={journal.id} journal={journal} />
          ))
        }
      </Loader>
    );
  };

  render() {
    const { acronym } = this.props;
    const hasDetailed = Boolean(acronym);

    return (
      <>
        <PaperHeader title="Manage Titles">
          <Search
            focusable
            placeholder="Search by Acronym"
            onSearch={this.handleSearch}
            hasCollapse={false}
            defaultValue={acronym}
            seleniumId="app-header"
          />
        </PaperHeader>
        {hasDetailed ? this.renderDetails() : <p>Search Journal by Acronym</p>}
      </>
    );
  }
}
export default compose(
  withRouter,
  connect(
    (state, { match }) => ({
      journals: selectJournals(match.params.acronym)(state),
      error: selectError(state),
      isLoading: selectIsLoading(state),
      acronym: match.params.acronym || '',
    }),
    (dispatch, { match }) => ({
      searchJournal() {
        const { acronym } = match.params || {};

        if (acronym) {
          dispatch(searchJournals(acronym));
        }
      },
    }),
  ),
)(ManageTitles);

import React from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../hoc/withAuthProvider';

const PermissionWaiter = ({ fallback: Fallback, children }) => (
  <AuthContext.Consumer>{({ rolesIsLoading }) => (rolesIsLoading ? <Fallback /> : children)}</AuthContext.Consumer>
);

PermissionWaiter.propTypes = {
  children: PropTypes.object,
  fallback: PropTypes.func,
};

export default PermissionWaiter;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import './ExpandButton.scss';

export const SHOW_MORE = 'Show more';
export const SHOW_LESS = 'Show less';

const ExpandButton = ({
  className = '',
  seleniumId = '',
  isExpanded = false,
  onClick = () => {},
  more = 'Show more',
  less = 'Show less',
}) => (
  <button
    data-seleniumid={seleniumId}
    type="button"
    onClick={onClick}
    className={cn('expand-button', className)}
  >
    <Svg className="expand-button__icon" name={isExpanded ? 'carrot-up' : 'carrot-down'} />
    {isExpanded ? less : more}
  </button>
);

ExpandButton.propTypes = {
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  less: PropTypes.string,
  more: PropTypes.string,
  onClick: PropTypes.func,
  seleniumId: PropTypes.string.isRequired,
};
export default ExpandButton;

import { getUrgencyOrDefault } from 'app/enums/urgency';
import { IconRender } from 'app/containers/Table/containers/CellRender';
import { LinkRender } from '@wiley/cpp-ui-commons/lib/components/Table/containers/CellRender';
import path from 'app/router/path';
import { DESC_SORT } from 'app/services/api/sort';
import { daysDateModifier, prepareDataForIconRender, stringDateToDateModifier } from 'app/utils';

export const AID_ID = 'aid';

export const descriptor = (search) => [
  {
    id: 'urgency',
    caption: 'Urgency',
    sortable: true,
    Render: IconRender,
    colWidth: 15,
    dataModifier: (val, id, row) => prepareDataForIconRender(getUrgencyOrDefault(val), row.alertsCount),
    secondarySortOrder: 1,
  },
  {
    id: 'access',
    caption: 'Access',
    sortable: true,
    Render: IconRender,
    colWidth: 12,
    dataModifier: val => prepareDataForIconRender(val),
    secondarySortOrder: 6,
  },
  {
    id: AID_ID,
    caption: 'ID',
    sortable: true,
    colWidth: 13,
    Render: LinkRender,
    dataModifier: (val, id) => ({
      title: val,
      href: path.getSearchDashboardArticlesDetail(id, search),
      hrefId: id,
    }),
    secondarySortOrder: 4,
  },
  {
    id: 'currentTask',
    caption: 'Current task',
    sortable: true,
    colWidth: 18,
    secondarySortOrder: 3,
  },
  {
    id: 'currentTaskActor',
    caption: 'Actor',
    sortable: true,
    colWidth: 12,
    secondarySortOrder: 5,
  },
  {
    id: 'currentTaskDueDate',
    caption: 'Task due date',
    sortable: true,
    defaultSortField: true,
    dataModifier: stringDateToDateModifier,
    colWidth: 18,
  },
  {
    id: 'age',
    caption: 'Age',
    sortable: true,
    defaultSort: DESC_SORT,
    dataModifier: daysDateModifier,
    colWidth: 12,
    secondarySortOrder: 2,
  },
];

import { takeEvery, put, call } from 'redux-saga/effects';
import { successActionCreator, failureActionCreator } from 'app/redux/asyncMiddleware';
import ls from 'app/services/localStorage';
import axiosInstance from 'app/services/axiosInstance';
import { LOGOUT_SUCCESS } from 'app/modules/Auth/redux/AuthDucks';
import history from '@wiley/cpp-ui-commons/lib/router/history';
import path from 'app/router/path';
import { INIT_APP_ACTION } from 'app/redux/commonActions';
import { SET_SWITCH_VIEW, UNSET_SWITCH_VIEW, SWITCH_VIEW_LS } from './SwitchViewDucks';

function* initSwitchView(res) {
  if (res.type === INIT_APP_ACTION) {
    // eslint-disable-next-line
    res = ls.getJSON(SWITCH_VIEW_LS);
  }

  if (res) {
    const successAction = successActionCreator(SET_SWITCH_VIEW);
    yield put(successAction({ payload: res }));
  }
}

function* applySwitchView(action) {
  const { payload: user } = action;
  const failureAction = failureActionCreator(action.type);
  try {
    const data = { user };
    const { location } = history;
    yield call(ls.setJSON, SWITCH_VIEW_LS, data);
    yield call(initSwitchView, data);
    yield call(history.push, path.default, location.state);
  }
  catch (e) {
    yield put(failureAction(typeof e === 'string' ? { message: e } : e));
  }
}

function* unsetSwitchView(action) {
  const successAction = successActionCreator(UNSET_SWITCH_VIEW);
  yield call(ls.remove, SWITCH_VIEW_LS);
  const { location } = history;
  delete axiosInstance.defaults.headers.common.switchViewToken;
  if (action.type !== LOGOUT_SUCCESS) {
    yield call(history.push, path.default, location.state);
  }
  yield put(successAction({}));
}

export function* watchSwitchView() {
  yield takeEvery(INIT_APP_ACTION, initSwitchView);
  yield takeEvery(SET_SWITCH_VIEW, applySwitchView);
  yield takeEvery(UNSET_SWITCH_VIEW, unsetSwitchView);
  yield takeEvery(LOGOUT_SUCCESS, unsetSwitchView);
}

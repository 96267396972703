import React from 'react';
import PropTypes from 'prop-types';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import { WorkInProgressContainer } from 'app/pages/WorkInProgress/containers';
import { INCORRECT_REQUEST, NO_RESULTS_SEARCH_MESSAGE } from 'app/enums/commonErrors';
import { ASC_SORT } from 'app/services/api/sort';
import { getValidParamsForPage } from 'app/utils';
import { getIssues, getIssuesFacets } from 'app/redux/api/common';
import { getRelevantSearchType, getExtraFilterParams } from 'app/pages/Search/common/utils';
import { descriptor } from './SearchDashboardIssuesDescriptor';
import {
  DASHBOARD_ISSUES_STORE_KEY,
  PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ISSUES,
} from '../../constants';

const DashboardIssueSearchResults = ({ type, q, countsIsLoading, articlesInIssue, location = {} }) => {
  const defaultSortFieldId = descriptor({ type, q }).find(col => col.defaultSortField).id;
  const { sortField: sortFieldFromURL } = getValidParamsForPage(location, ['sortField']);

  return (
    <WorkInProgressContainer
      entitiesEffect={p => ((type && q)
        ? getIssues()({ ...p, [getRelevantSearchType(type)]: q, ignoreAssignedJournals: true, progressStatuses: PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ISSUES })
        : Promise.reject({ error: 'Incorrect request' })
      )}
      facetsEffect={getIssuesFacets({ [getRelevantSearchType(type)]: q, ignoreAssignedJournals: true, progressStatuses: PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ISSUES })}
      initialState={{
        searchQuery: type && q ? { [getRelevantSearchType(type)]: q } : null,
        stats: {
          sortField: defaultSortFieldId,
          sortDir: ASC_SORT,
          secondarySortFields: [...descriptor({ type, q }).map(col => col.id)
            .filter(id => id !== (sortFieldFromURL || defaultSortFieldId))],
        },
        ...getExtraFilterParams(articlesInIssue),
      }}
      name={DASHBOARD_ISSUES_STORE_KEY}
      pageTitle="Issue Results"
      hideTitle
      descriptor={descriptor({ type, q })}
      fullTitle="CMH | Search | Dashboard Issue Results"
      renderError={() => <InfoView seleniumId="no-results" title={NO_RESULTS_SEARCH_MESSAGE} text={INCORRECT_REQUEST} />}
      renderNoResults={() => <InfoView seleniumId="no-results" title={NO_RESULTS_SEARCH_MESSAGE} text="" />}
      pipeProps={({ defaultMerge, ...otherProps }) => {
        const mergedProps = defaultMerge(otherProps);
        const { entitiesHasLoading } = mergedProps;

        return {
          ...mergedProps,
          entitiesHasLoading: entitiesHasLoading || countsIsLoading,
        };
      }}
    />
  );
};

DashboardIssueSearchResults.propTypes = {
  articlesInIssue: PropTypes.string,
  countsIsLoading: PropTypes.bool,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  q: PropTypes.string,
  type: PropTypes.string,
};

export default DashboardIssueSearchResults;

/* eslint react/prop-types: 0 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { reduce, startCase } from 'lodash';
import withForm, { Field } from '@wiley/cpp-ui-commons/lib/hoc/withForm';
import './TableCreate.scss';

export const TableCreate = ({ onSubmit, onCancel, headers, seleniumId, onClick }) => (
  <div className="mt-create__wrap">
    <div className="mt-create__fields">
      {headers.map(col => (
        <Field
          key={col.id}
          name={col.id}
          placeholder={startCase(col.id)}
          data-seleniumid={`${seleniumId}-create-${col.id}-input`}
          onClick={onClick}
        />
      ))}
    </div>
    <div className="mt-create__buttons-wrap">
      <div
        data-seleniumid={`assigned-${seleniumId}-cancel-create-button`}
        className="mt-create__cancel"
        onClick={onCancel}
      >
        Cancel
      </div>
      <div
        data-seleniumid={`assigned-${seleniumId}-create-create-button`}
        className="mt-create__save"
        onClick={onSubmit}
      >
        Save
      </div>
    </div>
  </div>
);

TableCreate.propTypes = {
  headers: PropTypes.array.isRequired,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
  seleniumId: PropTypes.string.isRequired,
};

export default memo(props => {
  const { headers } = props;
  const validators = headers.reduce((acc, col) => ({
    ...acc,
    [col.id]: col.validator,
  }), {});

  const C = withForm({
    validators,
    onSubmit: (fields, props) => props.onSubmit(reduce(fields, (acc, v, k) => {
      acc[k] = v && v.trim();
      return acc;
    }, {})),
  })(TableCreate);

  return <C {...props} />;
});

const ls = window.localStorage;

const get = key => {
  try {
    return ls.getItem(key);
  }
  catch (e) {
    return null;
  }
};

const set = (key, value) => {
  try {
    return ls.setItem(key, value);
  }
  catch (e) {
    return null;
  }
};

const setJSON = (key, obj) => set(key, JSON.stringify(obj));

const getJSON = key => {
  try {
    const res = JSON.parse(get(key) || 0) || null;
    return res;
  }
  catch (e) {
    return null;
  }
};

const remove = key => {
  try {
    return ls.removeItem(key);
  }
  catch (e) {
    return false;
  }
};

const clear = () => {
  try {
    return ls.clear();
  }
  catch (e) {
    return false;
  }
};

export default {
  get,
  set,
  setJSON,
  getJSON,
  remove,
  clear,
};

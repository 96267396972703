import { call, put, takeEvery } from 'redux-saga/effects';
import { getVersion } from 'app/pages/Version/api/version';
import { LOAD, fetchStart, fetchFailure, fetchSuccess } from './VersionDucks';

export function* fetchData() {
  yield put(fetchStart());

  try {
    const res = yield call(getVersion);
    yield put(fetchSuccess(res));
  }
  catch (e) {
    yield put(fetchFailure(e));
  }
}

export function* watchVersion() {
  yield takeEvery(LOAD, fetchData);
}

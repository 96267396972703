import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './TableError.scss';

const TableError = ({ error, seleniumId, className = '' }) => (
  <div
    className={cn('mt-error__text', className)}
    data-seleniumid={`${seleniumId}-error`}
  >
    {error}
  </div>
);

TableError.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string.isRequired,
  seleniumId: PropTypes.string.isRequired,
};

export default TableError;

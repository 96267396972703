import { apiCall } from 'app/services/api';
import { endpoints as switchViewEndpoints } from 'app/pages/SwitchView/api/switchView';
import { endpoints as commonEndpoints } from 'app/redux/api/common';
import { endpoints as adminEndpoints } from 'app/pages/Admin/api/admin';
import { LOAD_USERS_ERROR_MSG } from 'app/constants';

const endpoints = {
  setUserRole: 'v2/users/role',
};

export const getUsers = params => apiCall(
  switchViewEndpoints.users,
  params,
  {
    errorMessage5XX: LOAD_USERS_ERROR_MSG,
  },
);
export const searchUsers = (params, errorMsg) => apiCall(commonEndpoints.searchUsers, params, { errorMessage5XX: errorMsg });
export const setUserRole = params => apiCall(endpoints.setUserRole, params, { method: 'POST' });
export const deleteUserRole = params => apiCall(adminEndpoints.deleteUserRole(params), {}, { method: 'DELETE' });

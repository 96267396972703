import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'app/services/axiosInstance';
import { UNAUTHORIZED } from 'app/services/api/httpCodes';
import connectAuth from '../../hoc/withAuthProvider/connectAuth';

export class Guard extends PureComponent {
  constructor(props) {
    super(props);
    this.interceptorResponse = axiosInstance.interceptors.response.use(null, this.handleResponseError);
  }

  static propTypes = {
    children: PropTypes.any,
    fallback: PropTypes.func,
    isAuthorized: PropTypes.bool,
    logout: PropTypes.func,
    user: PropTypes.shape({}),
  };

  componentDidMount() {
    const { user, logout } = this.props;

    if (!user && logout) {
      logout();
    }
  }

  componentWillUnmount() {
    axiosInstance.interceptors.response.eject(this.interceptorResponse);
  }

  handleResponseError = error => {
    if (error && error.response) {
      const { status } = error.response;
      const { logout } = this.props;

      if (status === UNAUTHORIZED) {
        return logout(error);
      }
      return Promise.reject(error);
    }
    return Promise.reject({ message: 'Empty response' });
  };

  hasAccess() {
    const { isAuthorized } = this.props;

    return isAuthorized;
  }

  render() {
    const { children, fallback: Fallback } = this.props;

    return this.hasAccess() ? children : <Fallback />;
  }
}

export default connectAuth(Guard);

import { put, all, takeLatest } from 'redux-saga/effects';
import { searchTitles } from 'app/pages/Admin/api/admin';
import { successActionCreator, failureActionCreator } from 'app/redux/asyncMiddleware';
import { SEARCH_JOURNALS } from 'app/pages/Admin/ManageTitles/reducers/JournalStateDucks';
import { SAVE_JOURNAL } from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalsDucks';
import { SEARCH_USER } from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalUsersDucks';
import { NO_ACTION_MESSAGE } from 'app/enums/commonErrors';

function* fetchJournals({ type, payload }) {
  const { acronym } = payload;
  try {
    const res = yield searchTitles(acronym.toUpperCase());
    yield all((res || []).map(journal => put({
      type: SAVE_JOURNAL,
      journalId: journal.id,
      payload: { journal },
    })));

    yield put(successActionCreator(type)({ payload: {} }));
  }
  catch (e) {
    yield put(failureActionCreator(type)({ payload: { error: e } }));
  }
}

function* searchUsers(action) {
  const { type, journalId, userType, assignedUsers, payload: { effect, params } } = action;
  const actionParams = { journalId, userType, assignedUsers };
  try {
    const res = yield effect(params);
    const searchedUsers = res || [];

    yield put(successActionCreator(type)({ payload: searchedUsers, ...actionParams }));
  }
  catch (e) {
    yield put(failureActionCreator(type)({ payload: { error: e?.response?.data?.message || NO_ACTION_MESSAGE('search') }, ...actionParams }));
  }
}

export function* watchManageTitles() {
  yield takeLatest(SEARCH_JOURNALS, fetchJournals);
  yield takeLatest(SEARCH_USER, searchUsers);
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Categorize from 'app/components/Categorize';
import UserCard from '../UserCard';

class UserList extends PureComponent {
  static propTypes = {
    onSelect: PropTypes.func,
    selectedUserId: PropTypes.string,
    users: PropTypes.array,
  };

  renderUserCard = user => {
    const { onSelect, selectedUserId } = this.props;
    return (
      <div key={user.id} className="col-md-3 mb-4">
        <UserCard
          seleniumid={`select-user-${user.id}`}
          isActive={user.id === selectedUserId}
          onSelect={onSelect}
          data={user}
        />
      </div>
    );
  };

  renderCategory = category => (
    <div data-seleniumid={`category__name-${category}`} className="user-list__category" key={category}>
      {category}
    </div>
  );

  renderUserList = (users, category) => (
    <div data-seleniumid={`category__list-${category}`} className="user-list row">
      {users.map(this.renderUserCard)}
    </div>
  );

  render() {
    const { users } = this.props;

    return (
      <div className="user-list">
        <Categorize
          items={users}
          sortCategories={items => items.sort()}
          renderCategory={this.renderCategory}
          renderList={this.renderUserList}
          categoryCreator={item => item.lastName[0].toUpperCase()}
        />
      </div>
    );
  }
}

export default UserList;

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withSwitchView } from 'app/hoc/withSwitchView';

import {
  fetchEntities,
  updateStats,
  setInitialState,
  updateSelectedFilters,
  removeSelectedFilters,
  applySelectedFilters,
  selectItemsCount,
  selectPageCount,
  selectEntitiesHasLoading,
  selectEntitiesError,
  selectItems,
  selectStats,
  selectSelectedFilters,
} from 'app/modules/Table/redux/TableDucks';

import {
  updateFacets,
  selectFacetsCategories,
  selectFacetsOptions,
  selectFacetsHasLoading,
  selectFacetsError,
  selectTempFacets,
} from 'app/modules/Table/redux/TableFiltersDucks';

function getTableName({ name, user }) {
  const nameWithSwitchViewUser = [name];

  if (user) {
    nameWithSwitchViewUser.push(user.id);
  }

  return nameWithSwitchViewUser.join('-');
}

export const mapStateToProps = (state, { name: initialName, user }) => {
  const name = getTableName({ name: initialName, user });

  return {
    entitiesHasLoading: selectEntitiesHasLoading(state, name),
    items: selectItems(state, name),
    stats: selectStats(state, name),
    pageCount: selectPageCount(state, name),
    itemsCount: selectItemsCount(state, name),
    selectedFilters: selectSelectedFilters(state, name),
    entitiesError: selectEntitiesError(state, name),
    facetsCategories: selectFacetsCategories(state, name),
    facetsOptions: selectFacetsOptions(state, name),
    facetsHasLoading: selectFacetsHasLoading(state, name),
    facetsError: selectFacetsError(state, name),
    tempFacets: selectTempFacets(state, name),
  };
};

export const mapDispatchToProps = (dispatchRedux, {
  initialState,
  entitiesEffect,
  facetsEffect,
  name,
  user,
}) => {
  const dispatch = params => dispatchRedux(
    { ...params, entitiesEffect, facetsEffect, name: getTableName({ name, user }) });

  return {
    setInitialState(initialState) {
      return dispatch(setInitialState({
        initialState,
      }));
    },
    updateSelectedFilters(selectedFilters) {
      return dispatch(updateSelectedFilters({
        payload: {
          selectedFilters,
        },
      }));
    },
    removeSelectedFilters(selectedFilters) {
      return dispatch(removeSelectedFilters({
        payload: {
          selectedFilters,
        },
      }));
    },
    applySelectedFilters(selectedFilters) {
      return dispatch(applySelectedFilters({
        payload: {
          selectedFilters,
        },
      }));
    },
    updateStats(payload) {
      return dispatch(updateStats({
        payload,
      }));
    },
    fetchEntities(payload) {
      return dispatch(fetchEntities({
        payload,
        initialState,
      }));
    },
    updateFacets(payload) {
      return dispatch(updateFacets({
        payload,
      }));
    },
  };
};

const defaultMerge = ({ mapState, mapDispatch, ownProps }) => ({
  ...mapState,
  ...mapDispatch,
  ...ownProps,
});

const mergeProps = (mapState, mapDispatch, ownProps) => {
  const { pipeProps = defaultMerge } = ownProps;

  return pipeProps({
    mapState,
    mapDispatch,
    ownProps,
    defaultMerge,
  });
};

export default compose(
  withSwitchView,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
);

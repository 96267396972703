import { isAfter, differenceInDays } from 'date-fns';
import { parseDatePickerDate } from 'app/utils/utils';
import {
  updateArticleHistory,
  removeProductionArticleHistory,
} from 'app/redux/api/common';
import DateCell from '../components/table/DateCell';
import { ACTUAL_REVISED_DATES_RANGE, isDateMoreStringDate, parseProductionApiPayload } from './utils';
import {
  WARNING_FUTURE_MSG,
  WARNING_DIFF_REVISED_MSG,
  WARNING_DIFF_CURRENT_MSG,

  ACTUAL_WARNING,
} from './constants';

const ACTUAL_WARNINT_TIP = {
  'wevent:PublishedOnlineEarlyUnpaginated': ACTUAL_WARNING('Literatum'),
  'wevent:AcceptedArticlePublishedOnline': ACTUAL_WARNING('Literatum'),
  'wevent:EarlyViewFileReceived': ACTUAL_WARNING('JQA'),
  'wevent:EarlyViewFileApproved': ACTUAL_WARNING('JQA'),
};

const getActualDateWarning = (row, items) => date => {
  if (!date) return null;

  const { revisedDate } = row || {};
  const { rawRevisedDate } = items.find(el => el.id === row.id) || {};
  const relevantRevisedDate = rawRevisedDate || revisedDate;

  if (relevantRevisedDate
    && isDateMoreStringDate(date, relevantRevisedDate, ACTUAL_REVISED_DATES_RANGE)) return WARNING_DIFF_REVISED_MSG;
  if (isAfter(date, new Date())) return WARNING_FUTURE_MSG;

  return null;
};

const getRevisedDateWarning = date => {
  if (!date) return null;
  return Math.abs(differenceInDays(parseDatePickerDate(date), parseDatePickerDate(new Date()))) > 90 ? WARNING_DIFF_CURRENT_MSG : null;
};

export default (isEditable, items, { id: entityId, onUpdate, isAdmin }) => [
  {
    id: 'title',
    caption: 'Event',
    sortable: false,
    colWidth: 30,
  },
  {
    id: 'revisedDate',
    caption: 'Revised',
    sortable: false,
    colWidth: 35,
    Render: DateCell,
    additionalProps: (val, row) => ({
      isEditable: () => isEditable && !row?.actualDate,
      type: 'revisedDate',
      entityId,
      onUpdate,
      getWarningMessage: getRevisedDateWarning,
      getApiPayload: parseProductionApiPayload,
      updateAsyncCall: updateArticleHistory,
      ignoreEditLoading: true,
    }),
  },
  {
    id: 'actualDate',
    caption: 'Actual',
    sortable: false,
    colWidth: 35,
    Render: DateCell,
    additionalProps: (val, row) => ({
      isEditable: () => isEditable && (!val || isAdmin),
      type: 'actualDate',
      entityId,
      onUpdate,
      getWarningMessage: getActualDateWarning(row, items),
      getApiPayload: parseProductionApiPayload,
      updateAsyncCall: updateArticleHistory,
      removeAsyncCall: (isAdmin && val) ? removeProductionArticleHistory : null,
      getViewTooltipMessage: id => ACTUAL_WARNINT_TIP[id] || null,
      ignoreEditLoading: true,
    }),
  },
];

/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import XMLViewer from 'react-xml-viewer';
import PropTypes from 'prop-types';
import { PREVIEW_TYPE, isTextFormatContent } from '../common/fileFormats';

const getTextContent = (textContent, type) => {
  if (!textContent) return null;

  switch (type) {
    case PREVIEW_TYPE.xml:
      return <XMLViewer xml={textContent} invalidXml={textContent} collapsible />;
    case PREVIEW_TYPE.html:
      return (
        <iframe
          frameBorder="0"
          id={PREVIEW_TYPE.html}
          name={PREVIEW_TYPE.html}
          sandbox="allow-same-origin"
          srcDoc={textContent}
        />
      );
    default:
      return textContent;
  }
};

export const Content = ({
  contentURL,
  fileName,
  previewType,
  isLoading,
  error,
  textContent,
}) => {
  if (isTextFormatContent(previewType)) {
    return (
      <SectionLoader
        hasLoading={isLoading}
        className="preview-modal__content-text-container-loader"
      >
        <div
          className="preview-modal__content-text-container"
        >
          <div className="preview-modal__content-text-error">{error}</div>
          <div
            className="preview-modal__content-text-content"
            data-seleniumid="preview-content"
          >
            {getTextContent(textContent, previewType)}
          </div>
        </div>
      </SectionLoader>
    );
  }

  switch (previewType) {
    case PREVIEW_TYPE.image:
      return (
        <div
          className="preview-modal__content-image-container"
        >
          <img className="preview-modal__content-image-content" src={contentURL} alt={fileName} />
        </div>
      );
    default:
      return '';
  }
};

Content.propTypes = {
  contentURL: PropTypes.string,
  error: PropTypes.string,
  fileName: PropTypes.string,
  isLoading: PropTypes.bool,
  previewType: PropTypes.string,
  textContent: PropTypes.string,
};

export default Content;

import { put, takeLatest } from 'redux-saga/effects';
import { successActionCreator, failureActionCreator } from 'app/redux/asyncMiddleware';
import { MANAGE_USERS_SEARCH } from 'app/pages/Admin/ManageUsers/redux/ManageUsersDucks';
import { searchUsers } from 'app/pages/Admin/ManageUsers/api/manageUsers';
import accountTypes from 'app/enums/accountTypes';
import { NO_RESULTS_MESSAGE } from 'app/enums/commonErrors';
import { LOAD_USERS_ERROR_MSG } from 'app/constants';

function* searchSSOUser(payload) {
  try {
    return yield searchUsers({ ...payload, accountType: accountTypes.sso }, LOAD_USERS_ERROR_MSG);
  }
  catch (e) {
    return [];
  }
}

function* searchUser({ type, payload }) {
  try {
    const items = yield searchSSOUser(payload);
    yield put(successActionCreator(type)({ payload: { items, totalAmount: items.length } }));
  }
  catch (e) {
    const { response } = e || {};
    yield put(failureActionCreator(type)({ payload: { message: response?.data?.message || NO_RESULTS_MESSAGE } }));
  }
}

export function* watchManageUsers() {
  yield takeLatest(MANAGE_USERS_SEARCH, searchUser);
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Stickable from 'app/components/Stickable';
import { sortSecondaryFields } from 'app/utils';
import { ASC_SORT, DESC_SORT } from 'app/services/api/sort';
import BaseTable from '@wiley/cpp-ui-commons/lib/components/Table';

import './Table.scss';

const switchSortMap = {
  [ASC_SORT]: DESC_SORT,
  [DESC_SORT]: ASC_SORT,
  default: ASC_SORT,
};

export default class Table extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    descriptor: PropTypes.array.isRequired,
    headerProps: PropTypes.shape({
      stickyId: PropTypes.string,
    }),
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    sectionsDescriptor: PropTypes.shape(),
    showHeader: PropTypes.bool,
    sortDir: PropTypes.string,
    sortField: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    sortDir: 'asc',
    sortField: '',
  };

  onSort = (sortField, sortDir) => {
    const { descriptor, onChange } = this.props;
    onChange({ sortField, sortDir, secondarySortFields: [...sortSecondaryFields(descriptor).map(col => col.id).filter(id => id !== sortField)] });
  };

  headerWrap = ({ children, stickyId }) => <Stickable stickyId={stickyId}>{children}</Stickable>;

  render() {
    const { sortField, sortDir, items, descriptor, sectionsDescriptor, showHeader = true, className = '', headerProps = {} } = this.props;

    return (
      <BaseTable
        className={cn('base-table', className)}
        descriptor={descriptor}
        sortField={sortField}
        sortDir={sortDir}
        switchSortMap={switchSortMap}
        items={items}
        onSort={this.onSort}
        HeaderWrap={this.headerWrap}
        sectionsDescriptor={sectionsDescriptor}
        showHeader={showHeader}
        headerProps={headerProps}
      />
    );
  }
}

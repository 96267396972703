import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getValidParamsForPage, sortSecondaryFields } from 'app/utils';
import { ARTICLES_IN_PROGRESS } from 'app/pages/WorkInProgress/constants';
import { WorkInProgressContainer } from 'app/pages/WorkInProgress/containers';
import { ASC_SORT } from 'app/services/api/sort';
import { detailedEndpoints, getArticles, getArticlesFacets } from 'app/redux/api/common';
import { defaultTableNoResult } from 'app/utils/defaultTableNoResult';
import { descriptor } from 'app/pages/WorkInProgress/common/articlesInProgressDescriptor';
import { selectData, loadData } from 'app/redux/detailed/DetailedDucks';

const availableParams = ['iid', 'naviid', 'ignoreAssignedJournals'];

const defaultSortFieldId = descriptor.find(col => col.defaultSortField).id;

class ArticlesInProgress extends PureComponent {
  static propTypes = {
    loadData: PropTypes.func,
    location: PropTypes.shape({
      hash: PropTypes.string,
      key: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  };

  componentDidMount() {
    const { location, loadData } = this.props;
    const params = getValidParamsForPage(location, availableParams);
    if (params?.naviid) {
      loadData(detailedEndpoints.issueDetailed, { id: params.naviid });
    }
  }

  getInitialStats() {
    const { location = {} } = this.props;
    const { sortField: sortFieldFromURL } = getValidParamsForPage(location, ['sortField']);
    return {
      stats: {
        sortField: defaultSortFieldId,
        sortDir: ASC_SORT,
        secondarySortFields: [...sortSecondaryFields(descriptor).map(col => col.id)
          .filter(id => id !== (sortFieldFromURL || defaultSortFieldId))],
      },
    };
  }

  render() {
    const { location } = this.props;

    const params = {
      ...getValidParamsForPage(
        location,
        availableParams,
        (param, paramName) => ((paramName === 'ignoreAssignedJournals') ? Boolean(param) : param),
      ),
      progressStatuses: ARTICLES_IN_PROGRESS,
    };

    const name = 'articles';

    return (
      <WorkInProgressContainer
        title="Articles"
        descriptor={descriptor}
        name={name}
        entitiesEffect={getArticles(params)}
        facetsEffect={getArticlesFacets(params)}
        initialState={this.getInitialStats()}
        renderNoResults={defaultTableNoResult}
        hideTitle={!params.iid}
      />
    );
  }
}
export default connect(
  (state, ownProps) => {
    const params = getValidParamsForPage(ownProps.location, availableParams);

    return {
      issueData: selectData(state, detailedEndpoints.issueDetailed, params?.naviid),
    };
  },
  {
    loadData,
  },
)(ArticlesInProgress);

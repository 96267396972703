import { apiCall } from 'app/services/api';
import { LOAD_ERROR_MSG } from 'app/constants';

export const endpoints = {
  getDashboardURL: (userId) => `/v2/users/${userId}/statistic`,
  getJournals: (userId) => `/v2/users/${userId}/journals`,
};

export const getDashboard = userId => params => apiCall(endpoints.getDashboardURL(userId), params, { errorMessage5XX: LOAD_ERROR_MSG('dashboard statistics') });
export const getJournals = userId => params => apiCall(endpoints.getJournals(userId), params, { errorMessage5XX: LOAD_ERROR_MSG('journals') });

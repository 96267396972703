import { apiCall } from 'app/services/api';
import { LOAD_USERS_ERROR_MSG } from 'app/constants';

export const endpoints = {
  users: '/v2/users',
  getSwitchViewToken: '/getSwitchViewToken',
};

export const getUsers = ({ size, offset, letter, email, sortDir, sortField, dashboardUserRoles }) =>
  apiCall(endpoints.users, {
    size,
    offset,
    letter,
    email,
    sortDir,
    sortField,
    dashboardUserRoles,
  },
  {
    errorMessage5XX: LOAD_USERS_ERROR_MSG,
  });

import React from 'react';
import PropTypes from 'prop-types';
import filtersNames from 'app/enums/filtersNames';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import DotLoader from '@wiley/cpp-ui-commons/lib/components/DotLoader';

const FilterItem = ({ item, selected, onSelect, isChild, isLoading }) => (
  <div
    data-seleniumid={`filter-item-${item.categoryId}--${item.optionId}`}
    className={cn('filter-item', { selected })}
    onClick={() => onSelect(item)}
  >
    <div className="filter-item__content">
      <div className="filter-item__content-name-wrap">
        <div className="filter-item__content-checked">
          <Svg name="ok-arrow" />
        </div>
        <div className={cn('filter-item__content-name', { child: isChild })}>{filtersNames?.[item.categoryId]?.[item.optionId] || item.optionId}</div>
      </div>
      <div className="filter-item__content-count">({isLoading ? <DotLoader /> : item.count})</div>
    </div>
  </div>
);

FilterItem.propTypes = {
  isChild: PropTypes.bool,
  isLoading: PropTypes.bool,
  item: PropTypes.shape({
    categoryId: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    optionId: PropTypes.string.isRequired,
    parentId: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default FilterItem;

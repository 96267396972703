import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import path from 'app/router/path';
import DetailedArticlesView from 'app/pages/DetailedViews/DetailedArticlesView';
import DetailedIssuesView from 'app/pages/DetailedViews/DetailedIssuesView';
import RequiresActionArticles from './RequiresActionArticles';
import RequiresActionIssues from './RequiresActionIssues';

import { tabsConfig } from './common/tabsConfig';

const RequiresAction = () => (
  <>
    <Helmet>
      <title>CMH | Requires Action</title>
    </Helmet>
    <Switch>
      <Route path={path.requiresActionArticles} exact component={RequiresActionArticles} />
      <Route path={path.requiresActionArticlesDetail} component={DetailedArticlesView} />
      <Route path={path.requiresActionIssues} exact component={RequiresActionIssues} />
      <Route path={path.requiresActionIssuesDetail} component={DetailedIssuesView} />
      <Redirect to={path.requiresActionArticles} />
    </Switch>
  </>
);

RequiresAction.layoutProps = {
  header: {
    tabs: tabsConfig,
  },
};

export default RequiresAction;

const dtTags = {
  'cmh-production-dashboard-ui.perf.cmh.wiley.host': 'perf',
  'cmh-production-dashboard-ui.sit.cmh.wiley.host': 'sit',
  'cmh-production-dashboard-ui.dev.cmh.wiley.host': 'dev',
  'cmh-production-dashboard-ui.qa.cmh.wiley.host': 'qa',
  'cmh-production-dashboard-ui.uat.cmh.wiley.host': 'uat',
  'dash.wiley.com': 'prod',
};

export default dtTags;

import { apiCall } from 'app/services/api';
import { LOAD_ERROR_MSG } from 'app/constants';
import { parsePublicationId } from 'app/pages/ArticleHistory/common/utils';

export const endpoints = {
  articles: '/v2/articles/search',
  articlesFacets: '/v2/articles/facets',
  lightArticle: id => `/v2/articles/${id}/light`,
  issues: '/v2/issues/search',
  issuesFacets: '/v2/issues/facets',
  searchUsers: '/v2/search/users',
  groups: '/v2/groups',
  roles: '/v2/users/:userId/roles',
  affiliations: '/v2/users/:userId/affiliations',
  journals: '/v2/journals',
  articleHistory: aid => `v2/articles/${aid}/history`,
  publicationArticleHistory: aid => `v2/articles/${aid}/history/publication`,
  deleteProductionArticleHistory: (aid, type, scheduleId) => `v2/articles/${aid}/history/${type}/${scheduleId}`,
  deletePublicationArticleHistory: (aid, scheduleId) => `v2/articles/${aid}/history/publication/${scheduleId}`,
  synchronizationDelay: 'v2/synchronization/delay',
  features: '/v2/features',
  updateArticleMetadata: aid => `v2/articles/${aid}/metadata`,

  errorReport: '/v2/errorReport',
};

export const detailedEndpoints = {
  articleDetailed: '/v2/articles',
  issueDetailed: '/v2/issues',
};

export const getArticles = (additionalParams = {}) => params => apiCall(endpoints.articles, { ...additionalParams, ...params }, { method: 'POST', errorMessage5XX: LOAD_ERROR_MSG('articles') });
export const getArticlesFacets = (additionalParams = {}) => params => apiCall(endpoints.articlesFacets, { ...additionalParams, ...params }, { method: 'POST' });
export const getLightArticle = id => apiCall(endpoints.lightArticle(id));
export const getIssues = (additionalParams = {}) => params => apiCall(endpoints.issues, { ...additionalParams, ...params }, { method: 'POST', errorMessage5XX: LOAD_ERROR_MSG('issues') });
export const getIssuesFacets = (additionalParams = {}) => params => apiCall(endpoints.issuesFacets, { ...additionalParams, ...params }, { method: 'POST' });
export const getGroups = params => apiCall(endpoints.groups, params);
export const getJournals = params => apiCall(endpoints.journals, params);
export const getRoles = (userId) => apiCall(endpoints.roles.replace(':userId', userId));
export const getAffiliations = (userId) => apiCall(endpoints.affiliations.replace(':userId', userId));
export const getArticleHistory = aid => apiCall(endpoints.articleHistory(aid));
export const removePublicationArticleHistory = (aid, { id }) => apiCall(endpoints.deletePublicationArticleHistory(aid, parsePublicationId(id)), {}, { method: 'DELETE' });
export const removeProductionArticleHistory = (aid, { type, id }) => apiCall(endpoints.deleteProductionArticleHistory(aid, type, id), {}, { method: 'DELETE' });
export const updateArticleHistory = (aid, params) => apiCall(endpoints.articleHistory(aid), params, { method: 'PATCH' });
export const updatePublicationArticleHistory = (aid, params) => apiCall(endpoints.publicationArticleHistory(aid), params, { method: 'PATCH' });
export const getSynchronizationDelayStatus = () => apiCall(endpoints.synchronizationDelay);
export const getFeatures = () => apiCall(endpoints.features);
export const updateArticleMetadata = (aid, params) => apiCall(endpoints.updateArticleMetadata(aid), params, { method: 'PATCH' });

export const errorReport = params => apiCall(endpoints.errorReport, params);

import path from 'app/router/path';
import { configGenerator } from 'app/pages/Dashboard/common/utils';
import { createFilter } from 'app/utils/filters';

const config = {
  bookingIn: {
    color: '#f76926',
    title: 'Booking In',
    link: {
      pathname: path.inProgressArticles,
      storeName: 'articles',
      filters: [createFilter('productionStages', 'bookingIn')],
    },
  },
  proofPrep: {
    color: '#32afe9',
    title: 'Proof Prep',
    link: {
      pathname: path.inProgressArticles,
      storeName: 'articles',
      filters: [createFilter('productionStages', 'proofPrep')],
    },
  },
  proofing: {
    color: '#1b275f',
    title: 'Proofing',
    link: {
      pathname: path.inProgressArticles,
      storeName: 'articles',
      filters: [createFilter('productionStages', 'proofing')],
    },
  },
  articleRevises: {
    color: '#4269b4',
    title: 'Article Revision',
    link: {
      pathname: path.inProgressArticles,
      storeName: 'articles',
      filters: [createFilter('productionStages', 'articleRevises')],
    },
  },
  revisesCheck: {
    color: '#11a064',
    title: 'Revises Check',
    link: {
      pathname: path.inProgressArticles,
      storeName: 'articles',
      filters: [createFilter('productionStages', 'revisesCheck')],
    },
  },
  evQA: {
    color: '#fbab17',
    title: 'EV QA',
    link: {
      pathname: path.inProgressArticles,
      storeName: 'articles',
      filters: [createFilter('productionStages', 'evQA')],
    },
  },
  evPending: {
    color: '#b00003',
    title: 'EV Pending',
    link: {
      pathname: path.inProgressArticles,
      storeName: 'articles',
      filters: [createFilter('productionStages', 'evPending')],
    },
  },
  prodComplete: {
    color: '#61165e',
    title: 'Ready for Issue',
    link: {
      pathname: path.inProgressReadyForIssue,
      storeName: 'articles',
      filters: [],
    },
  },
};

export const getHorizontalDiagramConfig = (additionalFilters) => configGenerator(config, 'link', additionalFilters);

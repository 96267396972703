import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import './StatusBubble.scss';

export default function StatusBubble({ config, status, seleniumId }) {
  if (!config) {
    return null;
  }

  return (
    <div className="page-title__bubble-wrap">
      <div className={cn('alert', config.class)}>
        <span className="alert__status" data-seleniumid={`${seleniumId}-status`}>{status}</span>
        <div className="alert__title">
          <Svg name={config.icon} className="bubble-icon" />
          <span data-seleniumid={`${seleniumId}-title`}>{config.title}</span>
        </div>
        {config.text && <div className="alert__description" data-seleniumid={`${seleniumId}-description`}>{config.text}</div>}
      </div>
    </div>
  );
}

StatusBubble.propTypes = {
  config: PropTypes.object,
  seleniumId: PropTypes.string,
  status: PropTypes.string,
};

StatusBubble.defaultProps = {
  status: '',
  seleniumId: '',
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableAdd from './TableAdd';
import TableError from './TableError';
import './TableSearch.scss';

class TableSearch extends PureComponent {
  static propTypes = {
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    onAdd: PropTypes.func,
    onChangeSearch: PropTypes.func.isRequired,
    onCreate: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    searchText: PropTypes.string,
    seleniumId: PropTypes.string.isRequired,
    stats: PropTypes.object,
    suggestionHeader: PropTypes.array,
    suggestionText: PropTypes.string,
    TableAddRender: PropTypes.func,
  };

  onChange = e => {
    const { value = '' } = e.target;
    const { onChangeSearch } = this.props;
    onChangeSearch(value);
  };

  onSearch = () => {
    const { onSearch } = this.props;
    onSearch();
  };

  renderControls() {
    const { seleniumId, searchPlaceholder, searchText = '', isLoading, onCreate } = this.props;

    return (
      <div className="mt-search__wrap">
        <input
          type="text"
          className="mt-search__input"
          placeholder={searchPlaceholder}
          data-seleniumid={`assigned-${seleniumId}-input`}
          value={searchText}
          disabled={isLoading}
          onChange={this.onChange}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              this.onSearch();
            }
          }}
        />
        <div
          data-seleniumid={`assigned-${seleniumId}-search-button`}
          className="mt-search__submit"
          onClick={this.onSearch}
        >
          Search
        </div>
        {onCreate && (
          <div
            data-seleniumid={`assigned-${seleniumId}-create-button`}
            className="mt-search__create"
            onClick={onCreate}
          >
            Create New
          </div>
        )}
      </div>
    );
  }

  renderError() {
    const { error, seleniumId } = this.props;

    return (
      <TableError
        error={error}
        seleniumId={`assigned-${seleniumId}`}
      />
    );
  }

  renderResults() {
    const { items, seleniumId, suggestionHeader, suggestionText, stats, onAdd, TableAddRender = TableAdd } = this.props;

    if (!items?.length) return null;
    return items.map(item => (
      <TableAddRender
        key={item.id}
        isLoading={Boolean(stats[item.id]?.isLoading)}
        error={stats[item.id]?.error}
        seleniumId={seleniumId}
        entity={item}
        initialFields={item}
        suggestionHeader={suggestionHeader}
        suggestionText={suggestionText}
        onAdd={onAdd}
      />
    ));
  }

  render() {
    const { error } = this.props;
    return (
      <>
        {this.renderControls()}
        {this.renderResults()}
        {error && this.renderError()}
      </>
    );
  }
}

export default TableSearch;

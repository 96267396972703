import React from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import connectAuth from './connectAuth';

export const AuthContext = React.createContext({});

const withAuthProvider = Component => {
  const Modified = ({
    user,
    isAuthorized,
    isAuthorizing,
    login,
    logout,
    logoutByClick,
    authError,
    fetchRoles,
    roles,
    rolesIsLoaded,
    rolesIsLoading,
    ...props
  }) => {
    const authProps = {
      user: user || {},
      userId: user?.ptpid || null,
      userEmail: user?.email || null,
      isAuthorized,
      isAuthorizing,
      login,
      logout,
      logoutByClick,
      authError,
      fetchRoles,
      roles,
      rolesIsLoaded,
      rolesIsLoading,
    };

    return (
      <AuthContext.Provider value={authProps}>
        <Component {...props} {...authProps} />
      </AuthContext.Provider>
    );
  };

  Modified.propTypes = {
    authError: PropTypes.object,
    fetchRoles: PropTypes.func,
    isAuthorized: PropTypes.bool,
    isAuthorizing: PropTypes.bool,
    login: PropTypes.func,
    logout: PropTypes.func,
    logoutByClick: PropTypes.func,
    roles: PropTypes.array,
    rolesIsLoaded: PropTypes.bool,
    rolesIsLoading: PropTypes.bool,
    user: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      ptpid: PropTypes.string,
      userid: PropTypes.string,
    }),
  };

  Modified.displayName = `withAuthProvider(${Component.displayName || Component.name})`;

  return connectAuth(hoistStatics(Modified, Component));
};

export default withAuthProvider;

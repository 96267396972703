import axios from 'axios';
import qs from 'query-string';
import config from 'app/config';

const instance = axios.create({
  baseURL: config.rootUrl,
  responseType: 'json',
  withCredentials: true,
  timeout: 1000 * 300,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export default instance;

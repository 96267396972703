import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './PaperHeader.scss';

export default function PaperHeader({ title, subHeader, children, bordered, className }) {
  if (children || bordered) {
    return (
      <div className={cn('page-title--with-right-panel', className, { bordered })}>
        <div className="page-title__header-container">
          {subHeader}
          <div data-dtrum-mask data-seleniumid="page-title" className={cn('page-title', { 'mb-3': !bordered })}>
            {title}
          </div>
        </div>
        {children}
      </div>
    );
  }
  return (
    <h2 data-dtrum-mask data-seleniumid="page-title" className="mb-3">
      {title}
    </h2>
  );
}

PaperHeader.propTypes = {
  bordered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

PaperHeader.defaultProps = {
  className: '',
  subHeader: '',
};

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from 'app/modules/Auth/redux/AuthDucks';
import { UNSET_SWITCH_VIEW_SUCCESS } from 'app/pages/SwitchView/redux/SwitchViewDucks';
import { LOAD, FETCH, FETCH_SUCCESS, FETCH_FAILURE } from 'app/pages/Version/redux/VersionDucks';
import { INIT_APP_ACTION } from 'app/redux/commonActions';

const ignoreTypes = [
  INIT_APP_ACTION,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UNSET_SWITCH_VIEW_SUCCESS,
  LOAD,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  /toastr/g,
];

const extraType = 'extra_type';

const middleware = ({ getState }) => next => async action => {
  const token = getState().auth.get('token');
  const { type } = action;

  if (!token && !ignoreTypes.some(t => !!type.match(t))) {
    return next({ type: extraType });
  }

  return next(action);
};

export default middleware;

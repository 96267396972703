/* eslint react/prop-types: 0 */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import TableRowButton from './TableRowButton';

const tooltipProps = {
  type: 'error',
  place: 'left',
  effect: 'solid',
  multiLine: true,
  html: true,
};

const TableRowTooltipButton = ({ message, disabled = false }) => ({ className = '', ...props }) => {
  const { onClick } = props;

  return (
    <>
      <ReactTooltip {...tooltipProps} />
      <span
        data-tip={message}
      >
        <TableRowButton
          {...props}
          onClick={disabled ? () => {} : onClick}
          className={cn(className, { disabled })}
        />
      </span>
    </>
  );
};

export default TableRowTooltipButton;

export const FILES_ICON = {
  doc: 'doc',
  docx: 'docx',
  image: 'image',
  pdf: 'pdf',
  txt: 'txt',
  xml: 'xml',
  '3d': '3d',
  ppt: 'ppt',
  pptx: 'pptx',
  html: 'html',
  eps: 'eps',
  xhtml: 'xhtml',
  tex: 'tex',
  bib: 'bib',
  idml: 'idml',
  mp4: 'mp4',
};

export const PREVIEW_PLACE = {
  modal: 'modal',
  newTab: 'newTab',
};

export const PREVIEW_TYPE = {
  image: 'image',
  text: 'text',
  xml: 'xml',
  html: 'html',
};

export const isTextFormatContent = previewType => [
  PREVIEW_TYPE.text,
  PREVIEW_TYPE.xml,
  PREVIEW_TYPE.html,
].includes(previewType);

const formats = [
  {
    extention: 'html',
    mnemonic: 'text/html',
    icon: FILES_ICON.html,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.html,
  },
  {
    extention: 'xml',
    mnemonic: 'text/xml',
    icon: FILES_ICON.xml,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.xml,
  },
  {
    extention: 'xml',
    mnemonic: 'application/xml',
    icon: FILES_ICON.xml,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.xml,
  },
  {
    extention: 'txt',
    mnemonic: 'text/plain',
    icon: FILES_ICON.txt,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.text,
  },
  {
    extention: 'doc',
    mnemonic: 'application/msword',
    icon: FILES_ICON.doc,
  },
  {
    extention: 'pdf',
    mnemonic: 'application/pdf',
    icon: FILES_ICON.pdf,
    preview: PREVIEW_PLACE.newTab,
  },
  {
    extention: 'docx',
    mnemonic: 'application/vnd.openxmlformats-officedocument.wordprocessing',
    icon: FILES_ICON.docx,
  },
  {
    extention: 'docx',
    mnemonic: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: FILES_ICON.docx,
  },
  {
    extention: 'apng',
    mnemonic: 'image/apng',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'bmp',
    mnemonic: 'image/bmp',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'gif',
    mnemonic: 'image/gif',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'ico',
    mnemonic: 'image/x-icon',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'cur',
    mnemonic: 'image/x-icon',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'jpg',
    mnemonic: 'image/jpeg',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'jpeg',
    mnemonic: 'image/jpeg',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'jfif',
    mnemonic: 'image/jpeg',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'pjpeg',
    mnemonic: 'image/jpeg',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'pjp',
    mnemonic: 'image/jpeg',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'png',
    mnemonic: 'image/png',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'svg',
    mnemonic: 'image/svg',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'tif',
    mnemonic: 'image/tiff',
    icon: FILES_ICON.image,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'tiff',
    mnemonic: 'image/tiff',
    icon: FILES_ICON.image,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'webp',
    mnemonic: 'image/webp',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: 'gif',
    mnemonic: 'image/gif',
    icon: FILES_ICON.image,
    preview: PREVIEW_PLACE.modal,
    previewType: PREVIEW_TYPE.image,
  },
  {
    extention: '3d',
    mnemonic: '-',
    icon: FILES_ICON['3d'],
  },
  {
    extention: 'ppt',
    mnemonic: '-',
    icon: FILES_ICON.ppt,
  },
  {
    extention: 'pptx',
    mnemonic: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    icon: FILES_ICON.pptx,
  },
  {
    extention: 'eps',
    mnemonic: 'application/eps',
    icon: FILES_ICON.eps,
  },
  {
    extention: 'xhtml',
    mnemonic: '-',
    icon: FILES_ICON.xhtml,
  },
  {
    extention: 'tex',
    mnemonic: '-',
    icon: FILES_ICON.tex,
  },
  {
    extention: 'bib',
    mnemonic: '-',
    icon: FILES_ICON.bib,
  },
  {
    extention: 'idml',
    mnemonic: '-',
    icon: FILES_ICON.idml,
  },
  {
    extention: 'mp4',
    mnemonic: '-',
    icon: FILES_ICON.mp4,
  },
];


export const formatByMnemonic = formats.reduce((acc, format) => {
  acc[format.mnemonic] = format;
  return acc;
}, {});

export const formatByExtention = formats.reduce((acc, format) => {
  acc[format.extention] = format;
  return acc;
}, {});

export default formats;

export const ERROR_MSG = 'Sorry, we couldn\'t load article history, please try again later';

export const PUBLICATIONS_EDIT_FIELDS = {
  accepted: 'manuscriptAccepted',
  revised: 'manuscriptRevised',
  received: 'manuscriptReceived',
};

export const PUBLICATION_FIELDS = [
  'issueOnline',
  'versionOfRecordOnline',
  'acceptedManuscriptOnline',
  PUBLICATIONS_EDIT_FIELDS.accepted,
  PUBLICATIONS_EDIT_FIELDS.revised,
  PUBLICATIONS_EDIT_FIELDS.received,
];

export const PUBLICATION_TITLE = 'PUBLICATION HISTORY';
export const PRODUCTION_TITLE = 'PRODUCTION HISTORY';
export const PRODUCTION_DELAY_MS = 10000;

export const INCORRECT_DATE_FORMAT = 'Incorrect date format, use mm/dd/yyyy';
export const INCORRECT_MONTH = 'Month must be in the range 1 - 12';
export const INCORRECT_DAY = 'Day must be in the range 1 - 31';

export const WARNING_FUTURE_MSG = 'The entered date is in the future';
export const WARNING_DIFF_REVISED_MSG = 'The entered date is not within 90 days of the revised date (before or after)';
export const WARNING_DIFF_CURRENT_MSG = 'The entered date is more than 90 days from the current date';
export const WARNING_BEFORE_ACCEPTED_MSG = 'The entered Manuscript Accepted date is before Manuscript Revised and/or Manuscript Received dates';
export const WARNING_BEFORE_REVISED_MSG = 'The entered Manuscript Revised date is before Manuscript Received date';
export const WARNING_AFTER_REVISED_MSG = 'The entered Manuscript Revised date is after Manuscript Accepted date';
export const WARNING_AFTER_RECEIVED_MSG = 'The entered Manuscript Received date is after Manuscript Revised and/or Manuscript Accepted dates';

export const HISTORY_PROCESSING_WARNING_LS_KEY = 'HISTORY_PROCESSING_WARNING_LS_KEY';
export const HISTORY_PROCESSING_WARNING_MESSAGE = 'Please wait until updated date is synchronized with JPCMS to avoid potential data processing issues';
export const HISTORY_PROCESSING_WARNING_DISMISS = 'Don\'t show again';

export const ACTUAL_WARNING = source => `<strong>Note</strong>: Actual Date in this field is<br>expected to be received from ${source}.`;

import React, { PureComponent } from 'react';
import PaperHeader from 'app/components/PaperHeader';
import TileWidgetsGrid from 'app/components/TileWidget';
import { adminTiles } from 'app/pages/Dashboard/common/journalTilesConfig';

export default class AdminDashboard extends PureComponent {
  render() {
    return (
      <>
        <PaperHeader title="Admin Dashboard" />
        <div className="mb-3">
          <TileWidgetsGrid
            seleniumPart="adminJournals"
            title="Journals"
            data={[
              {
                filterId: 'manageTitles',
              },
              {
                filterId: 'manageUsers',
              },
            ]}
            listConfig={adminTiles}
          />
        </div>
      </>
    );
  }
}

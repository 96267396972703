import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AppHeaderBaseLayout from '@wiley/cpp-ui-commons/lib/components/AppHeader/layouts/AppHeaderBaseLayout';
import AppHeaderMenu from '@wiley/cpp-ui-commons/lib/components/AppHeader/components/AppHeaderMenu';
import { UIStateContext } from '@wiley/cpp-ui-commons/lib/hoc/createUIStateContext';
import withUIState, { AUTH, SIDEBAR } from 'app/hoc';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import HeaderProfile from 'app/containers/Profile';
import SearchByCategory from 'app/containers/SearchByCategoryContainer';
import { selectOptions } from 'app/enums/mainSearchCategories';
import SwitchViewRestore from 'app/components/SwitchViewRestore';
import { STATE_HAMBURGER, STATE_HIDDEN } from 'app/hoc/withSidebar';
import { menuListConfig } from './common/menuListConfig';
import AppHeaderLogo from './AppHeaderLogo';
import AppHeaderLeftAdaptiveLayout from './AppHeaderLeftAdaptiveLayout';
import './AppHeaderContainer.scss';

class AppHeaderContainer extends PureComponent {
  static propTypes = {
    externalTab: PropTypes.object,
    history: PropTypes.object,
  };

  static contextType = UIStateContext;

  renderHamburger(onHamburgerClick) {
    return (
      <div className="header-menu__hamburger-button" onClick={() => onHamburgerClick(true)}>
        <Svg name="hamburger" />
      </div>
    );
  }

  renderLeftCol() {
    const { history, externalTab } =  this.props;
    const { auth, sidebar } = this.context;

    const { handleChange, menuModeState } = sidebar;
    const { isAuthorized } = auth;

    return (
      <>
        <AppHeaderLeftAdaptiveLayout
          hasMobile={menuModeState === STATE_HAMBURGER}
          hamburger={this.renderHamburger(handleChange)}
          logo={
            <AppHeaderLogo logoIcon="logo-black" projectName="Production Dashboard" />
          }
          menu={isAuthorized && <AppHeaderMenu menuConfig={menuListConfig} externalTab={externalTab} />}
          onLogoClick={() => {
            history.push('/');
          }}
        />
      </>
    );
  }

  renderRightCol() {
    const { auth, sidebar } = this.context;

    const { menuModeState } = sidebar;
    const { isAuthorized, user, login, logoutByClick } = auth;

    return (
      <div className="header-menu-right-layout">
        {menuModeState === STATE_HAMBURGER && <SwitchViewRestore hasMobile={true} />}
        {menuModeState === STATE_HIDDEN && (
          <>
            {isAuthorized && Boolean(user) && (
              <>
                <div className="header-menu__search-resizable" />
                <SearchByCategory
                  inputPlaceholder="Search articles or issues"
                  selectPlaceholder="Select"
                  selectOptions={selectOptions}
                  hasCollapse
                  seleniumId="front-page"
                />
              </>
            )}
            <HeaderProfile login={login} user={user} logout={logoutByClick} />
          </>
        )}
      </div>
    );
  }

  render() {
    const { sidebar } = this.context;
    const { menuModeState } = sidebar;

    return (
      <>
        <AppHeaderBaseLayout
          leftCol={this.renderLeftCol()}
          rightCol={this.renderRightCol()}
          styleModifier={menuModeState === STATE_HAMBURGER ? 'mobile-container' : ''}
        />
      </>
    );
  }
}

export default withUIState(AUTH, SIDEBAR)(AppHeaderContainer);

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Table from '@wiley/cpp-ui-commons/lib/components/Table';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import useFeatures from 'app/utils/hooks/useFeatures';

import { PRODUCTION_TITLE, PRODUCTION_DELAY_MS } from 'app/pages/ArticleHistory/common/constants';
import tableDescriptor from 'app/pages/ArticleHistory/common/productionTableDescriptor';
import { NO_RESULTS_MESSAGE } from 'app/enums/commonErrors';
import {
  getProductionHistoryItems,
  isHistoryProcessingWarningDisabled,
} from 'app/pages/ArticleHistory/common/utils';
import ScheduleRow from 'app/pages/ArticleHistory/components/table/ScheduleRow';
import HistoryProcessingWarning, { toastOptions } from 'app/pages/ArticleHistory/components/HistoryProcessingWarning';
import { getHistory, updateHistory } from 'app/pages/ArticleHistory/redux/productionArticleHistoryDucks';
import './ProductionHistory.scss';

export const ProductionHistory = ({ history = [], isLoading, updateHistory, ...descriptorProps }) => {
  const [isProductionLoading, setProductionLoading] = useState(false);
  const { features: { historyProductionManage } } = useFeatures();
  const timerRef = React.useRef();

  useEffect(
    () => () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    }, [],
  );

  useEffect(() => () => {
    toast.dismiss(toastOptions.toastId);
  }, []);

  const onUpdate = useCallback((el) => {
    const { isUpdating, isUpdated, isFailed, date } = el;
    if (isFailed) {
      setProductionLoading(false);
      return;
    }

    updateHistory(el);
    if (!date) return;
    if (isUpdating) setProductionLoading(true);
    if (isUpdated) {
      timerRef.current = setTimeout(() => {
        setProductionLoading(false);
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }, PRODUCTION_DELAY_MS);
      if (!isHistoryProcessingWarningDisabled()) toast(<HistoryProcessingWarning />, toastOptions);
    }
  }, [updateHistory, timerRef]);

  const items = useMemo(() => getProductionHistoryItems(history), [history]);
  const descriptor = useMemo(() => tableDescriptor(historyProductionManage, items, { ...descriptorProps, onUpdate }), [historyProductionManage, items, onUpdate, descriptorProps]);

  const isEmpty = !isLoading && !history?.length;

  return (
    <>
      <h4
        data-seleniumid="production-history"
        className="production-history__title"
      >
        {PRODUCTION_TITLE}
      </h4>
      <Loader hasLoading={isProductionLoading} center>
        <Table
          className="production-history__table"
          renderRow={props => <ScheduleRow {...props} />}
          descriptor={descriptor}
          items={items}
        />
        {isEmpty && <InfoView title={NO_RESULTS_MESSAGE} seleniumId="empty-production-history" />}
      </Loader>
    </>
  );
};

ProductionHistory.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      actualDate: PropTypes.string,
      id: PropTypes.string,
      revisedDate: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  id: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  isLoading: PropTypes.bool,
  updateHistory: PropTypes.func,
};

export default connect(
  state => ({
    history: getHistory(state),
  }),
  {
    updateHistory,
  },
)(ProductionHistory);

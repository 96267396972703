import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import ViewControls from 'app/components/PageField/Edit/ViewControls';
import EditControls from 'app/components/PageField/Edit/EditControls';
import { postProcessMetadata } from 'app/pages/DetailedViews/DetailedArticlesView/common/utils';
import { updateArticleMetadata } from 'app/redux/api/common';
import ReactTooltip from 'react-tooltip';
import { UPDATE_ERROR_MSG } from 'app/constants';
import { capitalizeFirstLetter, isSelection } from 'app/utils';
import './Edit.scss';

const initialState = value => ({
  value,
  isEditing: false,
  isLoading: false,
});

function reducer(state, action) {
  const { payload = {} } = action || {};
  switch (action.type) {
    case 'change': return { ...state, ...payload };
    case 'edit': return { ...state, isEditing: payload };
    case 'loading': return { ...state, isEditing: false, isLoading: payload };
    case 'reset': return initialState(payload.value);
    default: return state;
  }
}

const EditField = ({
  children,
  component: EditComponent,
  value: initValue,
  type,
  title,
  entityId,
  onUpdate,
  onEditing = () => {},
  onLoading = () => {},
  inputProcess,
  inputProps = {},
  forceEditing = false,
  ignoreClickOutside = false,
  ignoreEmptyView = false,
  ignoreKeySave = false,
  tooltipProps = {},
}) => {
  const [{ value, isEditing, isLoading }, dispatch] = useReducer(reducer, initialState(initValue));
  const seleniumId = `edit-${type}`;
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (initValue && initValue !== value && !isEditing) {
      dispatch({ type: 'change', payload: { value: initValue } });
    }
  }, [initValue]);

  const onEdit = useCallback((e) => {
    if (isSelection()) return;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    dispatch({ type: 'edit', payload: true });
    onEditing(true);
  }, [dispatch]);

  const onStopEdit = useCallback(() => {
    dispatch({ type: 'reset', payload: { value: initValue } });
    onEditing(false);
  }, [dispatch, initValue]);

  const save = async (rawValue) => {
    onLoading(true);
    try {
      const value = postProcessMetadata({ type, value: rawValue });

      dispatch({ type: 'loading', payload: true });
      const result = await updateArticleMetadata(entityId, { metaDataType: type, value: value || null });
      dispatch({ type: 'reset', payload: { value } });

      onUpdate({ type, result, id: entityId });
    }
    catch (e) {
      const errorType = capitalizeFirstLetter(title || type.replace(/([a-z])([A-Z])/g, '$1 $2'));
      toast.error(UPDATE_ERROR_MSG(errorType), { toastId: type });
      dispatch({ type: 'loading', payload: false });
    }
    finally {
      onEditing(false);
      onLoading(false);
    }
  };

  const onSave = useCallback(() => {
    if (value !== initValue) {
      save(value);
    }
    else {
      onStopEdit();
    }
  }, [value, initValue]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      onStopEdit();
    }
    if (!ignoreKeySave && event.key === 'Enter') {
      onSave();
    }
  }, [value, initValue]);

  useEffect(() => {
    isEditing
      ? document.addEventListener('keydown', handleKeyDown)
      : document.removeEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, value]);

  const onChange = useCallback((oldValue, newValue) => {
    let value = newValue;
    if (inputProcess) value = inputProcess(oldValue, newValue);
    dispatch({ type: 'change', payload: { value } });
  }, [dispatch]);

  useEffect(() => {
    if (isLoading) return;

    if (forceEditing && !isEditing) onEdit();
    if (!forceEditing && isEditing) onSave();
  }, [forceEditing]);

  const { id: tooltipId, dataHtml, dataTip, ...restTooltip } = tooltipProps;

  useEffect(() => {
    if (!tooltipId || !value || !tooltipRef.current) return;
    ReactTooltip.show(tooltipRef.current);
  }, [value, tooltipRef.current]);

  if (!isEditing && !isLoading) {
    return (
      <ViewControls
        className={(initValue || ignoreEmptyView) ? '' : 'view-controls-empty'}
        seleniumId={seleniumId}
        onClick={onEdit}
        actions={[{
          id: 'edit_field',
          title: 'Edit',
          onClick: onEdit,
        }]}
      >
        {children}
      </ViewControls>
    );
  }

  return (
    <>
      {tooltipId ? (
        <ReactTooltip
          id={tooltipId}
          getContent={() => dataTip(value)}
          {...restTooltip}
        />
      ) : null}
      <Loader className="edit-field_progress" hasLoading={isLoading} center>
        <span ref={tooltipRef} {...(tooltipId ? { 'data-for': tooltipId, 'data-tip': '', 'data-html': dataHtml } : {})}>
          <EditControls
            seleniumId={seleniumId}
            onClose={onSave}
            ignoreClickOutside={ignoreClickOutside}
            actions={[{
              id: 'save',
              title: 'Save',
              onClick: onSave,
            }, {
              id: 'close',
              title: 'Cancel',
              onClick: onStopEdit,
            }]}
          >
            <EditComponent
              value={value}
              onChange={onChange}
              {...inputProps}
            />
          </EditControls>
        </span>
      </Loader>
    </>
  );
};

EditField.propTypes = {
  children: PropTypes.node,
  component: PropTypes.any.isRequired,
  entityId: PropTypes.string.isRequired,
  forceEditing: PropTypes.bool,
  ignoreClickOutside: PropTypes.bool,
  ignoreEmptyView: PropTypes.bool,
  ignoreKeySave: PropTypes.bool,
  inputProcess: PropTypes.func,
  inputProps: PropTypes.shape({
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    searchable: PropTypes.bool,
  }),
  onEditing: PropTypes.func,
  onLoading: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  title: PropTypes.string,
  tooltipProps: PropTypes.shape({
    dataHtml: PropTypes.bool,
    dataTip: PropTypes.func,
    id: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default EditField;

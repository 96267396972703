import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import NA from './EmptyRender';

export default function TextRender({ value: { text, emptyText, className }, seleniumId = '' }) {
  if (!text) {
    return <NA seleniumId={seleniumId} value={emptyText} />;
  }
  return (
    <span data-seleniumid={seleniumId} className={cn('text-render-field', className || '')}>
      {text}
    </span>
  );
}

TextRender.propTypes = {
  seleniumId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    className: PropTypes.string,
    emptyText: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from 'app/components/StatusIcon';
import { ActionIconsGroup } from 'app/components/ActionView/components';
import './ActionView.scss';

export default function ActionView({ title, iconsGroup, preStatus, status, access, children, seleniumId = '' }) {
  const selenium = `${seleniumId}-action`;
  return (
    <>
      <div className="action-view">
        <div className="action-view__status-wrap">
          <div className="action-view__status-icon-wrap">
            {iconsGroup && <ActionIconsGroup iconsGroup={iconsGroup} />}
          </div>
          <div>
            <div className="action-view__title" data-seleniumid={`${selenium}-title`}>{title}</div>
            <div className="action-view__pre-status" data-seleniumid={`${selenium}-pre-status`}>{preStatus}</div>
            <div className="action-view__status">
              <div className="action-view__status-title" data-seleniumid={`${selenium}-status`}>{status || 'N/A'}</div>
            </div>
            {access && <StatusIcon value={access} />}
          </div>
        </div>
        <div className="action-view__content_wrap">
          { children }
        </div>
      </div>
    </>
  );
}

ActionView.propTypes = {
  access: PropTypes.string,
  children: PropTypes.any,
  iconsGroup: PropTypes.shape({
    cover: PropTypes.string,
    status: PropTypes.string,
  }),
  preStatus: PropTypes.string,
  seleniumId: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
};

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import './ViewControls.scss';

const ViewControls = ({ className = '', children, onClick, actions, seleniumId }) => (
  <div
    className={cn('view-controls-wrap', className)}
    onClick={onClick}
    data-seleniumid={`${seleniumId}-viewing`}
  >
    <div className="view-controls">
      {children}
      <div className="view-controls__button-wrap">
        {actions.map(({ id, title, onClick }) => (
          <span
            key={id}
            title={title}
            data-seleniumid={`${seleniumId}-${title.toLowerCase()}-button`}
            onClick={onClick}
          >
            <Svg
              name={id}
              className="edit-field-button"
            />
          </span>
        ))}
      </div>
    </div>
  </div>
);

ViewControls.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
  })),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  seleniumId: PropTypes.string.isRequired,
};

export default ViewControls;

import React from 'react';
import PropTypes from 'prop-types';
import categories from 'app/enums/facetsCategories';

import FilterItem from './Items/FilterItem';

const FilterCategory = ({ itemsConfigs, title, onSelect, ...itemProps }) => (
  <div className="filter-category">
    <div className="filter-category__title">{categories[title] || title}</div>
    {itemsConfigs.map(itemConfig => {
      const { item, selected, Render = FilterItem, ...configProps } = itemConfig;
      const { categoryId, optionId, parentId } = item;
      const key = `${categoryId}--${optionId}`;

      return (
        <Render
          {...itemProps}
          {...configProps}
          key={key}
          item={item}
          selected={selected}
          onSelect={onSelect}
          isChild={parentId && categoryId !== parentId}
        />
      );
    })}
  </div>
);

FilterCategory.propTypes = {
  itemsConfigs: PropTypes.arrayOf(PropTypes.shape({
    item: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      count: PropTypes.number,
      optionId: PropTypes.string.isRequired,
      parentId: PropTypes.string,
      value: PropTypes.string,
    }),
    selected: PropTypes.bool,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default FilterCategory;

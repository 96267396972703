import React from 'react';
import PropTypes from 'prop-types';
import AppHeaderLeftBaseLayout from '@wiley/cpp-ui-commons/lib/components/AppHeader/layouts/AppHeaderLeftBaseLayout';

const AppHeaderLeftAdaptiveLayout = ({ hasMobile, hamburger, logo, menu, onLogoClick }) => (
  <AppHeaderLeftBaseLayout
    beforeLogo={hasMobile ? hamburger : null}
    logo={logo}
    menu={hasMobile ? null : menu}
    onLogoClick={onLogoClick}
  />
);

AppHeaderLeftAdaptiveLayout.propTypes = {
  hamburger: PropTypes.node,
  hasMobile: PropTypes.bool,
  logo: PropTypes.node,
  menu: PropTypes.node,
  onLogoClick: PropTypes.func,
};

export default AppHeaderLeftAdaptiveLayout;

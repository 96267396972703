import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Header from 'app/components/AppHeader';
import Footer from 'app/components/AppFooter';
import PageHeader from '@wiley/cpp-ui-commons/lib/components/PageHeader';
import Scrollable, { ScrollableDispatcher } from 'app/components/Scrollable';
import withUIState, { UIStateContext, SIDEBAR } from 'app/hoc';
import { STATE_HAMBURGER, STATE_HIDDEN } from 'app/hoc/withSidebar';
import MobileMenu from 'app/components/MobileMenu';
import SwitchViewRestore from 'app/components/SwitchViewRestore';
import Paper from '@wiley/cpp-ui-commons/lib/components/Paper';

import './Main.scss';

const MainLayout = ({ children, tab, header }) => (
  <UIStateContext.Consumer>
    {({ sidebar: { hasOpened, menuModeState } }) => (
      <div className="main-layout-wrap">
        {menuModeState === STATE_HAMBURGER && (
          <div className={cn('mobile-menu-wrap', { opened: hasOpened })}>
            <MobileMenu />
          </div>
        )}
        <div className="main-content-wrap">
          <ScrollableDispatcher>
            <div className="top-header">
              {menuModeState === STATE_HIDDEN && <SwitchViewRestore />}
              <Header externalTab={tab} />
              {header ? <PageHeader {...header} /> : null}
            </div>
          </ScrollableDispatcher>
          <Scrollable>
            <div className="app__main">
              <Paper>{children}</Paper>
            </div>
            <Footer />
          </Scrollable>
        </div>
      </div>
    )}
  </UIStateContext.Consumer>
);

MainLayout.propTypes = {
  children: PropTypes.any,
  header: PropTypes.object,
  tab: PropTypes.object,
};

export default withUIState(SIDEBAR)(MainLayout);

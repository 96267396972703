import { Map } from 'immutable';
import TableReducer from './TableDucks';
import TableFilterReducer from './TableFiltersDucks';

const initialState = Map({});

const tableReducer = (state = initialState, action) => {
  const { name } = action;

  if (!name) {
    return state;
  }

  return state.updateIn(
    [name],
    tableState => Map({
      entities: TableReducer(tableState?.get?.('entities'), action),
      filters: TableFilterReducer(tableState?.get?.('filters'), action),
    }),
  );
};

export default tableReducer;

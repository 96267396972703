import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import MathJax from 'react-mathjax-preview';
import { parse, sanitizeOptions, isTitleMathML } from './utils';

const HTMLView = ({ text, className = '' }) => {
  if (!text) return null;
  const parsedText = parse(text);
  const isMathML = isTitleMathML(parsedText);

  if (isMathML) {
    return (
      <MathJax
        className={cn(className)}
        math={parsedText}
        sanitizeOptions={sanitizeOptions}
      />
    );
  }
  return (
    <div
      className={cn(className)}
      dangerouslySetInnerHTML={{
        __html: parsedText,
      }}
    />
  );
};

HTMLView.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default HTMLView;

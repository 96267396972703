import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getArticles, getArticlesFacets } from 'app/redux/api/common';
import { ASC_SORT } from 'app/services/api/sort';
import { getValidParamsForPage, sortSecondaryFields } from 'app/utils';
import { defaultTableNoResult } from 'app/utils/defaultTableNoResult';
import { ARTICLES_IN_ISSUES } from 'app/pages/WorkInProgress/constants';
import { descriptor } from './common/articlesInProgressDescriptor';
import WorkInProgressContainer from './containers/WorkInProgressContainer';

const defaultSortFieldId = descriptor.find(col => col.defaultSortField).id;

const getArticlesInIssue = getArticles({
  progressStatuses: ARTICLES_IN_ISSUES,
});

const getFacetsArticlesInIssue = getArticlesFacets({
  progressStatuses: ARTICLES_IN_ISSUES,
});

const ArticlesInIssue = (props) => {
  const { location = {} } = props;
  const { sortField: sortFieldFromURL } = getValidParamsForPage(location, ['sortField']);
  const initialState = useMemo(
    () => ({
      stats: {
        sortField: defaultSortFieldId,
        sortDir: ASC_SORT,
        secondarySortFields: [
          ...sortSecondaryFields(descriptor)
            .map(col => col.id)
            .filter(id => id !== (sortFieldFromURL || defaultSortFieldId)),
        ],
      },
    }),
    [descriptor, sortFieldFromURL],
  );

  return (
    <WorkInProgressContainer
      title="Ready For Issue"
      descriptor={descriptor}
      name="articlesInIssue"
      entitiesEffect={getArticlesInIssue}
      facetsEffect={getFacetsArticlesInIssue}
      initialState={initialState}
      renderNoResults={defaultTableNoResult}
      hideTitle
    />
  );
};

ArticlesInIssue.propTypes = {
  location: PropTypes.object,
};

export default ArticlesInIssue;

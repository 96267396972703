import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactIsolatedScroll from 'react-isolated-scroll';
import Popup from '@wiley/cpp-ui-commons/lib/components/Popup';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import { emitter } from 'app/components/Scrollable';
import { isEqual } from 'lodash';

import {
  getSortedCategories,
  getSortedOptions,
} from 'app/utils/filters';
import FilterCategory from './FilterCategory';
import { checkDisableForApply, isEqualFilter } from './utils';
import renderItemsConfig from './common/renderItemsConfig';
import './Filter.scss';

class Filter extends PureComponent {
  constructor(props) {
    super(props);
    const { initialOpened, initialSelected } = this.props;

    this.state = {
      opened: initialOpened,
      selected: initialSelected,
      touched: false,
      scrollableMaxHeight: 0,
    };
  }

  static propTypes = {
    categories: PropTypes.array.isRequired,
    error: PropTypes.any,
    initialOpened: PropTypes.bool,
    initialSelected: PropTypes.array,
    isLoading: PropTypes.bool,
    onApply: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    tempFacets: PropTypes.object,
  };

  static defaultProps = {
    initialSelected: [],
    initialOpened: false,
    isLoading: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleScroll);
    this.scrollListener = emitter.addListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const { opened, isLoading } = this.state;
    if ((opened && !prevState.opened) || (!isLoading && prevState.isLoading)) {
      this.handleScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleScroll);
    this.scrollListener.remove();
  }

  static getDerivedStateFromProps(props, state) {
    const { initialSelected: selected, tempFacets } = props;
    const { touched } = state;

    const categories = getSortedCategories(
      touched
        ? tempFacets?.categories || props.categories
        : props.categories,
    );

    const options = getSortedOptions(
      categories,
      touched
        ? tempFacets?.options || props.options
        : props.options,
    );

    const isLoading = touched
      ? tempFacets?.isLoading || props.isLoading
      : props.isLoading;

    const newState = {
      options,
      categories,
      isLoading,
    };

    if (!state.opened) {
      newState.selected = selected;
    }

    return newState;
  }

  scrollableRef = React.createRef();

  get needDisableApply() {
    const { selected, options, touched } = this.state;
    return !touched || checkDisableForApply({ selected, options });
  }

  handleScroll = () => {
    if (!this.scrollableRef.current) {
      return;
    }
    const scrollable = this.scrollableRef.current.component;
    const windowHeight = window.innerHeight;
    const scrollableY = scrollable.getBoundingClientRect().y || scrollable.getBoundingClientRect().top;
    const scrollableMaxHeight = windowHeight - scrollableY - 100;
    this.setState({ scrollableMaxHeight });
  };

  showPopup = () => {
    const { initialSelected } = this.props;
    this.setState({
      opened: true,
      touched: false,
      selected: initialSelected,
    });
  };

  hidePopup = () => {
    const { initialSelected } = this.props;
    this.setState({ opened: false, touched: false, selected: initialSelected });
  };

  apply = () => {
    const { onApply } = this.props;
    const { selected } = this.state;

    onApply(selected);
  };

  update = () => {
    const { initialSelected, onUpdate } = this.props;
    const { selected } = this.state;

    this.setState({ touched: !isEqual(initialSelected, selected) }, () => {
      onUpdate({ selectedFilters: selected });
    });
  };

  handleCancelClick = () => {
    this.hidePopup();
  };

  handleApplyClick = () => {
    this.apply();
    this.hidePopup();
  };

  handleSelectFilterItem = item => {
    const { selected } = this.state;
    const selectedIndex = selected.findIndex(isEqualFilter(item));
    const isNewFilterAlreadySelected = selectedIndex > -1;

    if (!isNewFilterAlreadySelected) {
      this.setState({ selected: selected.concat(item) }, this.update);
      return;
    }

    const oldItem = selected[selectedIndex];
    if (!item.value) {
      const filtersWithoutNewItem = selected.filter((_, i) => i !== selectedIndex);
      this.setState({ selected: filtersWithoutNewItem }, this.update);
    }
    else if (oldItem.value !== item.value) {
      const filtersWithUpdatedOldItem = Object.assign([], selected, {
        [selectedIndex]: { ...oldItem, value: item.value },
      });
      this.setState({ selected: filtersWithUpdatedOldItem }, this.update);
    }
  };

  renderFilterCategory = categoryId => {
    const { selected, options, isLoading } = this.state;

    const categoryItems = options.filter(option => option.categoryId === categoryId);
    if (!categoryItems.length) return null;

    const itemsSelectedWrap = categoryItems.map(item => {
      const additionalProps = renderItemsConfig[item.categoryId] || {};
      return {
        selected: selected.findIndex(isEqualFilter(item)) > -1,
        item,
        ...additionalProps,
      };
    });

    return (
      <FilterCategory
        key={categoryId}
        isLoading={isLoading}
        title={categoryId}
        itemsConfigs={itemsSelectedWrap}
        onSelect={this.handleSelectFilterItem}
      />
    );
  };

  renderPopupContent = ({ categories }) => {
    const { options } = this.state;
    if (!options?.length) return null;
    const { scrollableMaxHeight } = this.state;

    return (
      <div className="filter-content">
        <div className="filter-content__header">
          <div className="filter-content__header-title">Filter By</div>
        </div>
        <ReactIsolatedScroll ref={this.scrollableRef} className="filter-content__items" style={{ maxHeight: scrollableMaxHeight || 'inherit' }}>
          {options.length > 0 && categories.map(this.renderFilterCategory)}
        </ReactIsolatedScroll>
        { options.length > 0 && (
          <div className="filter-content__buttons-wrap">
            <button data-seleniumid="filter-cancel-button" className="white" type="button" onClick={this.handleCancelClick}>
            Cancel
            </button>
            <button
              disabled={this.needDisableApply} className="accent" data-seleniumid="filter-apply-button" type="button"
              onClick={this.handleApplyClick}
            >
            Apply
            </button>
          </div>
        ) }
      </div>
    );
  };

  handleClearAll = () => {
    const { onClearAll } = this.props;
    this.setState(() => ({ selected: [] }), onClearAll);
  };

  renderError = () => {
    const { error: { message } } = this.props;
    const { selected } = this.state;

    const isShowClearAll = !!selected?.length;
    return (
      <div className="filter__error-wrap">
        <div data-seleniumid="filter-error-message" className="filter__error-msg">{message}</div>
        <button data-seleniumid="filter-repeat-button" className="filter__error-button" type="button" onClick={this.update}>
          Try again
        </button>
        {isShowClearAll && (
          <button data-seleniumid="filter-remove-all-button" className="filter__error-button" type="button" onClick={this.handleClearAll}>
          Clear All
          </button>
        )}
      </div>
    );
  };

  render() {
    const { opened, categories, isLoading } = this.state;
    const { error } = this.props;

    return (
      <div className="filter">
        <div className="filter__wrap">
          <button
            data-seleniumid="filter-open-button"
            className="filter__button--open-popup"
            type="button"
            onClick={this.showPopup}
          >
            Filter by
          </button>
          <Popup className="filter-popup" opened={opened} onClose={this.hidePopup}>
            <Loader hasLoading={isLoading} center>
              {error ? this.renderError() : this.renderPopupContent({ categories })}
            </Loader>
          </Popup>
        </div>
      </div>
    );
  }
}

export default Filter;

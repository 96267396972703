import React from 'react';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import filtersCategories from 'app/enums/facetsCategories';
import filtersNames, { filtersWithValues } from 'app/enums/filtersNames';
import cn from 'classnames';

const SelectedItem = React.forwardRef(({ data, onRemove, className = '' }, ref) => {
  const { categoryId, optionId, value } = data;
  const templateItemName = filtersNames?.[categoryId]?.[optionId];

  const itemValueName = filtersWithValues[categoryId] ? `${filtersCategories[categoryId]}: ${value}` : optionId;
  const itemName = templateItemName || itemValueName;
  const seleniumPart = filtersWithValues[categoryId] ? value : optionId;

  return (
    <div ref={ref} className={cn('filter-selected__item', className)}>
      <div className="filter-selected__item-name">{itemName}</div>
      <button
        data-seleniumid={`selected-filter-item-${categoryId ? `${categoryId}-` : ''}-${seleniumPart.replace(' ', '-')}`}
        type="button"
        className="filter-selected__item-remove"
        onClick={() => onRemove(data)}
      >
        <Svg name="close" />
      </button>
    </div>
  );
});

SelectedItem.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default SelectedItem;

import React from 'react';
import PropTypes from 'prop-types';
import StickableContainer from 'app/components/Stickable/StickyContainer';

const TableWrap = (props) => (
  <StickableContainer {...props} />
);

TableWrap.propTypes = {
  children: PropTypes.any.isRequired,
  stickyId: PropTypes.string.isRequired,
};

export default TableWrap;

import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from 'app/utils';
import PaperHeader from 'app/components/PaperHeader';

const DetailedError = ({ error, entityType, entityId }) => {
  const { status } = error?.result?.response || {};
  let errorMsg = '';
  if (status === 404) {
    errorMsg = `${capitalizeFirstLetter(entityType)} ${entityId} not found`;
  }
  else {
    errorMsg = `Sorry, we couldn't load ${entityType} ${entityId}, please try again later`;
  }

  return (
    <div data-seleniumid="detailed-page-error" className="mt-3">
      <PaperHeader title={errorMsg} />
    </div>
  );
};

DetailedError.propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  error: PropTypes.object,
};

export default DetailedError;

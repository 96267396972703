import { createSelector } from 'reselect';
import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { getArticleContent, getArticleFileVersions } from 'app/components/ArticleContent/api';

const initialState = {
  files: {},
  error: null,
  isLoading: false,
};

const LOAD_ARTICLE_CONTENT = 'article-content/load';
const LOAD_FILE_VERSIONS = 'file-versions/load';
const TOGGLE_FILE_VERSIONS = 'file-versions/toggle';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ARTICLE_CONTENT: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case LOAD_ARTICLE_CONTENT + SUCCESS: {
      const { id } = action;
      return {
        ...state,
        isLoading: false,
        files: { [id]: payload },
      };
    }
    case LOAD_ARTICLE_CONTENT + FAILURE: {
      const { message } = payload;
      return {
        ...state,
        isLoading: false,
        error: message,
      };
    }
    case LOAD_FILE_VERSIONS: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case LOAD_FILE_VERSIONS + SUCCESS: {
      const validFilesList = payload.length ? payload.filter(({ fileName }) => fileName) : [];
      const { id, cid } = action;
      const files = state.files[id].map((file) => ({
        ...file,
        isExpanded: cid === file.id ? true : file.isExpanded,
        versions: file.id === cid ? validFilesList : (file.versions || []),
      }));
      return {
        ...state,
        isLoading: false,
        files: { [id]: files },
      };
    }
    case LOAD_FILE_VERSIONS + FAILURE: {
      const { message } = payload;
      return {
        ...state,
        isLoading: false,
        error: message,
      };
    }
    case TOGGLE_FILE_VERSIONS: {
      const { id, cid, toggleVal } = payload;
      return {
        ...state,
        files: { [id]: state.files[id].map((file) => ({
          ...file,
          isExpanded: cid === file.id ? toggleVal : file.isExpanded,
        })) },
      };
    }
    default:
      return state;
  }
};

// Actions
export const loadArticleFiles = (id, aid, cid) => ({
  type: LOAD_ARTICLE_CONTENT,
  payload: { id },
  asyncCall: () => getArticleContent(cid, aid),
});

export const loadArticleFileVersions = (id, cid, fileName) => ({
  type: LOAD_FILE_VERSIONS,
  payload: { id, cid },
  asyncCall: () => getArticleFileVersions(cid, fileName),
});

export const toggleFileVersionExpand = (id, cid, toggleVal) => ({
  type: TOGGLE_FILE_VERSIONS,
  payload: { id, cid, toggleVal },
});

// Selectors
const selectArticleFilesStore = (state = initialState) => state.articleContent;
export const selectFiles = (state, id) => selectArticleFilesStore(state)?.files[id] || [];

export const selectFilesIsLoading = createSelector(
  selectArticleFilesStore,
  state => state?.isLoading,
);
export const selectFilesError = createSelector(
  selectArticleFilesStore,
  state => state?.error,
);

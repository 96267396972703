import React, { PureComponent } from 'react';
import TableRow from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableRow';
import TableHeader from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableHeader';
import TableRowButton from 'app/pages/Admin/ManageTitles/components/Tabs/components/buttons/TableRowButton';
import TableRowTooltipButton from 'app/pages/Admin/ManageTitles/components/Tabs/components/buttons/TableRowTooltipButton';
import TableSearch from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableSearch';
import './Tab.scss';
import PropTypes from 'prop-types';

const descriptor = {
  headers: ['name'],
  searchPlaceholder: 'Assign content vendor by name',
  noDataPlaceholder: 'No assigned vendors',
  seleniumId: 'cv',
  suggestionHeader: ['name'],
  removeMessage: '<div><b>Not permitted.</b></div><div>At least one TS Vendor must be assigned</div>',
};

class CVsTab extends PureComponent {
  static propTypes = {
    onAdd: PropTypes.func,
    onChangeSearch: PropTypes.func,
    onRemove: PropTypes.func,
    onResetUsers: PropTypes.func,
    onSearch: PropTypes.func,
    search: PropTypes.object,
    users: PropTypes.array,
    usersStats: PropTypes.object,
  };

  componentWillUnmount() {
    const { onResetUsers } = this.props;
    onResetUsers();
  }

  renderHeader = () => {
    const { headers, seleniumId } = descriptor;
    const { onRemove } = this.props;
    return (
      <TableHeader
        headers={headers}
        onRemove={onRemove}
        seleniumId={seleniumId}
      />
    );
  };

  renderRows = () => {
    const { users, onRemove, usersStats } = this.props;
    const { headers, seleniumId, removeMessage } = descriptor;

    const removeRender = users.length === 1 ? TableRowTooltipButton({ message: removeMessage, disabled: true }) : TableRowButton;
    return users.map(user => (
      <TableRow
        key={user.id}
        headers={headers}
        value={user}
        stats={usersStats[user.id]}
        seleniumId={seleniumId}
        removeHandler={onRemove(user.id)}
        RemoveRender={removeRender}
      />
    ),
    );
  };

  renderTable = () => {
    const { users } = this.props;
    const { noDataPlaceholder } = descriptor;

    return (
      <div className="mt-table__list-scrollable">
        <div className="mt-table__list">
          {this.renderHeader()}
          {this.renderRows()}
        </div>
        {!users.length && <div className="mt-table__no-result">{noDataPlaceholder}</div>}
      </div>
    );
  };

  renderSearch() {
    const { search, usersStats, onSearch, onChangeSearch, onAdd } = this.props;
    const { searchPlaceholder, seleniumId, suggestionHeader, suggestionText } = descriptor;
    return (
      <TableSearch
        {...search}
        onSearch={onSearch}
        onAdd={onAdd}
        onChangeSearch={onChangeSearch}
        seleniumId={seleniumId}
        searchPlaceholder={searchPlaceholder}
        suggestionHeader={suggestionHeader}
        suggestionText={suggestionText}
        stats={usersStats}
      />
    );
  }

  render() {
    return (
      <div className="mt-table__list-wrap">
        {this.renderTable()}
        {this.renderSearch()}
      </div>
    );
  }
}

export default CVsTab;

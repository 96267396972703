import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import path from 'app/router/path';
import ArticlesInIssue from './ArticlesInIssue';
import ArticlesInProgress from './ArticlesInProgress';
import IssuesInProgress from './IssuesInProgress';
import DetailedArticlesView from '../DetailedViews/DetailedArticlesView';
import DetailedIssuesView from '../DetailedViews/DetailedIssuesView';

import { tabsConfig } from './common/tabsConfig';

const WorkInProgress = () => (
  <>
    <Helmet>
      <title>CMH | Work In Progress</title>
    </Helmet>
    <Switch>
      <Route path={path.inProgressReadyForIssue} exact component={ArticlesInIssue} />
      <Route path={path.inProgressReadyForIssueDetail} component={DetailedArticlesView} />
      <Route path={path.inProgressArticles} exact component={ArticlesInProgress} />
      <Route path={path.inProgressArticlesDetail} component={DetailedArticlesView} />
      <Route path={path.inProgressIssues} exact component={IssuesInProgress} />
      <Route path={path.inProgressIssuesDetail} component={DetailedIssuesView} />
      <Redirect to={path.inProgressArticles} />
    </Switch>
  </>
);

WorkInProgress.layoutProps = {
  header: {
    tabs: tabsConfig,
  },
};

export default WorkInProgress;

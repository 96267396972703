import { fromJS } from 'immutable';
import { USER_PE_TYPE, USER_CV_TYPE, USER_VBA_TYPE, usersTypeMatching } from 'app/pages/Admin/ManageTitles/common/constants';
import { map } from 'lodash';
import {
  UNASSIGN_USER,
  ASSIGN_USER,
} from 'app/pages/Admin/ManageTitles/reducers/Journals/JournalUsersDucks';
import { FAILURE, SUCCESS } from 'app/redux/asyncMiddleware';
import { updateJournalInCMHSinceDate } from 'app/pages/Admin/api/admin';

export const SAVE_JOURNAL = 'admin/manage-titles/journal-save';
export const MANAGE_TITLES_UPDATE_IN_CMH_SINCE_DATE = 'admin/manage-titles/journal-update-cmh-since-date';

export const initialState = fromJS({
  id: null,
  acronym: null,
  journalName: null,
  inCMHSinceDate: null,
  [USER_PE_TYPE]: [],
  [USER_CV_TYPE]: [],
  [USER_VBA_TYPE]: [],
  isDateLoading: false,
});

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MANAGE_TITLES_UPDATE_IN_CMH_SINCE_DATE:
      return state.merge({ isDateLoading: true });

    case MANAGE_TITLES_UPDATE_IN_CMH_SINCE_DATE + FAILURE:
      return state.merge({ isDateLoading: false });

    case MANAGE_TITLES_UPDATE_IN_CMH_SINCE_DATE + SUCCESS:
    case SAVE_JOURNAL: {
      const { journal } = payload;
      const { id, acronym, journalName, inCMHSinceDate } = journal;
      return state
        .merge({
          id,
          acronym,
          journalName,
          inCMHSinceDate,
          [USER_PE_TYPE]: fromJS(map(journal[usersTypeMatching[USER_PE_TYPE]], 'id')),
          [USER_CV_TYPE]: fromJS(map(journal[usersTypeMatching[USER_CV_TYPE]], 'id')),
          [USER_VBA_TYPE]: fromJS(map(journal[usersTypeMatching[USER_VBA_TYPE]], 'id')),
          isDateLoading: false,
        });
    }
    case UNASSIGN_USER + SUCCESS: {
      const { userType, id } = action;
      return state.updateIn([userType], users => users.filter(user => user !== id));
    }
    case ASSIGN_USER + SUCCESS: {
      const { userType, id } = action;
      return state.updateIn([userType], users => users.push(id));
    }
    default:
      return state;
  }
};

export const updateInCMHSinceDate = ({ journalId, inCMHSinceDate }) => ({
  type: MANAGE_TITLES_UPDATE_IN_CMH_SINCE_DATE,
  payload: { journalId, inCMHSinceDate },
  asyncCall: () => updateJournalInCMHSinceDate({ journalId, inCMHSinceDate }),
  transformResult: res => ({ journal: res }),
  transformError: e => ({ error: (typeof e === 'string') ? e : e?.response?.data.message }),
});

import { select } from 'redux-saga/effects';
import { filtersWithValues } from 'app/enums/filtersNames';
import { selectSelectedFilters } from './TableDucks';

export function prepareFilters(rawFilters) {
  return rawFilters.reduce((acc, { categoryId, optionId, value }) => {
    const filterWithValueConfig = filtersWithValues[categoryId];
    if (filterWithValueConfig) {
      const valueParam = filterWithValueConfig.getValueParam(value);
      const categoryParam = filterWithValueConfig.categoryParam || categoryId;
      acc[categoryParam] = acc[categoryParam] ? [...acc[categoryParam], valueParam] : [valueParam];
    }
    else {
      acc[categoryId] = acc[categoryId] ? [...acc[categoryId], optionId] : [optionId];
    }
    return acc;
  }, {});
}

export function* getRelevantFilters({ name, selectedFilters }) {
  return selectedFilters === undefined
    ? (yield select(state => selectSelectedFilters(state, name)))
    : selectedFilters || [];
}

export function* getFiltersParams({ name, selectedFilters }) {
  const filters = yield getRelevantFilters({ name, selectedFilters });
  return prepareFilters(filters);
}

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { TextRender, StrongRender } from './Render';
import EditValue from './Edit';

import './Field.scss';

// eslint-disable-next-line react/prop-types
const ValueWrap = ({ children, ...rest }) => (
  <span {...rest}>{children}</span>
);

export default function Field({
  TitleRender,
  ValueRender,
  title,
  value,
  icon,
  seleniumId,
  tooltip,
  tooltipProps,
  className,
  options,
  edit,
}) {
  const { id: tooltipId, dataHtml, ...restTooltipProps } = tooltipProps || {};
  const [interactive, setInteractive] = useState(!!tooltipId);
  const ValueWrapper = edit ? EditValue : ValueWrap;

  const onEditing = useCallback((flag) => {
    setInteractive(tooltipId ? !flag : false);
  }, [tooltipId]);

  const isLabelSection = !!(icon || title);
  const valueWrapperProps = edit ? {
    ...edit,
    title: (title?.text || '').replace(':', ''),
    value: edit.value || value?.text || '',
    onEditing,
  } : {
    className: cn('details-field-value', { withoutPadding: !isLabelSection }),
  };

  return (
    <div>
      <div
        className={cn('details-field', className, { editable: !!edit, tooltiped: !!interactive })}
        {...(interactive ? { 'data-for': tooltipId, 'data-tip': tooltip, 'data-html': dataHtml } : {})}
      >
        <span className="details-field-label">
          {icon && <Svg name={icon} className={`icon-${icon}`} />}
          {title && <TitleRender value={title} seleniumId={`${seleniumId}-title`} />}
        </span>
        {value ? (
          <ValueWrapper
            {...valueWrapperProps}
          >
            <ValueRender value={value} seleniumId={`${seleniumId}-value`} options={options} />
          </ValueWrapper>
        ) : null}
      </div>
      {tooltipId ? (
        <ReactTooltip
          id={tooltipId}
          html={dataHtml}
          {...restTooltipProps}
        />
      ) : null}
    </div>
  );
}

Field.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  icon: PropTypes.string,
  options: PropTypes.object,
  seleniumId: PropTypes.string.isRequired,
  title: PropTypes.object,
  TitleRender: PropTypes.func,
  tooltip: PropTypes.string,
  tooltipProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  ValueRender: PropTypes.func,
};

Field.defaultProps = {
  TitleRender: StrongRender,
  ValueRender: TextRender,
  tooltip: undefined,
};

import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import NA from './EmptyRender';

export default function StrongRender({ value: { className, text, emptyText }, seleniumId = '' }) {
  if (!text || text === '0') {
    return <NA seleniumId={seleniumId} value={emptyText} />;
  }
  return (
    <strong data-seleniumid={seleniumId} className={cn('strong__label', className)}>
      {text}
    </strong>
  );
}

StrongRender.propTypes = {
  seleniumId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    className: PropTypes.string,
    emptyText: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

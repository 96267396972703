import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { PUBLICATION_FIELDS } from 'app/pages/ArticleHistory/common/constants';
import { CLEAR_ARTICLE_HISTORY, LOAD_ARTICLE_HISTORY, UPDATE_PUBLICATION_HISTORY } from './constants';

export default (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ARTICLE_HISTORY:
      return state;
    case LOAD_ARTICLE_HISTORY + SUCCESS: {
      const { publicationHistory = {} } = payload || {};
      return PUBLICATION_FIELDS.map(field => ({
        id: field,
        date: publicationHistory[field],
      }));
    }
    case LOAD_ARTICLE_HISTORY + FAILURE:
      return [];
    case UPDATE_PUBLICATION_HISTORY: {
      const { id, date, isRaw } = payload;
      const data = isRaw ? { rawDate: date } : { date };
      return state.map(el => {
        if (el.id === id) return { ...el, ...data };
        return el;
      });
    }
    case CLEAR_ARTICLE_HISTORY:
      return [];
    default:
      return state;
  }
};

export const updateHistory = (payload) => ({
  type: UPDATE_PUBLICATION_HISTORY,
  payload,
});

export const getHistory = state => state.articleHistory.publication;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isNumber } from 'lodash';
import PaperHeader from 'app/components/PaperHeader';
import StatusBubble from 'app/components/PaperHeader/StatusBubble';
import Field, { FieldsList } from 'app/components/PageField';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import DetailedSection from 'app/components/DetailedSection';
import LinkButton from '@wiley/cpp-ui-commons/lib/components/LinkButton';
import { generateDoiURL } from 'app/utils/utils';
import LinkRender from 'app/components/PageField/Render/LinkRender';
import { getBubbleConfig } from 'app/pages/DetailedViews/common/utils';
import transformIssueDetailedInfo from './common/transformDetailedData';

import '../Detailed.scss';

export const ASSIGNED_ARTICLES_ERR = 'Articles cannot be calculated';

class DetailedIssuesView extends PureComponent {
  static propTypes = {
    articlesUrl: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.any,
    hasLoading: PropTypes.bool,
  };

  renderShowArticlesButtons(articlesAssigned) {
    if (!isNumber(articlesAssigned)) {
      return (
        <span data-seleniumid="assigned-articles-text">{ASSIGNED_ARTICLES_ERR}</span>
      );
    }

    const { articlesUrl } = this.props;
    return (
      <LinkButton
        seleniumId="assigned-articles-link"
        text={`Show all (${articlesAssigned || 0})`}
        url={articlesUrl}
        type="secondary"
        size="small"
        useLink={true}
        disabled={!articlesAssigned}
      />
    );
  }

  render() {
    const { hasLoading, data } = this.props;

    const {
      leftPropsCol,
      rightPropsCol,
      currentTaskCol,
      projectedOnlineDate,
      projectedPrintDate,
    } = transformIssueDetailedInfo(data);

    return (
      <>
        <Helmet>
          <title>{`CMH | Detailed Issue | ${data.id}`}</title>
        </Helmet>
        <Loader hasLoading={hasLoading}>
          <PaperHeader title={data.issueId || ''}>
            <StatusBubble seleniumId="urgency" config={getBubbleConfig(data.urgency)} />
          </PaperHeader>
          <div className="row mb-3">
            <div className="col-md-12 mb-3">
              <Field
                title={{ text: 'Issue DOI:' }}
                ValueRender={LinkRender}
                value={{ link: data.doi ? generateDoiURL(data.doi) : '', blank: true }}
                icon="article"
                seleniumId="issue-doi"
              />
            </div>
            <div className="col-md-6">
              <FieldsList fields={leftPropsCol} />
            </div>
            <div className="col-md-6">
              <FieldsList fields={rightPropsCol} />
            </div>
          </div>
          <div className="tasks-view-wrap tasks-view-wrap--no-border-bottom py-4">
            <div className="row">
              <div className="col-md-6 col-with-line">
                <DetailedSection title="articles">
                  {this.renderShowArticlesButtons(data.articlesAssigned)}
                </DetailedSection>
              </div>
            </div>
          </div>
          <div className="tasks-view-wrap tasks-view-wrap--no-border-bottom py-5 mt-1">
            <div className="row">
              <div className="col-md-4 col-with-line">
                <DetailedSection title="Current Task" className="detailed-section_wide">
                  <FieldsList fields={currentTaskCol} />
                </DetailedSection>
              </div>
              <div className="col-md-4 col-with-line">
                <DetailedSection title="Projected Online Date" className="detailed-section_wide">
                  <FieldsList fields={projectedOnlineDate} />
                </DetailedSection>
              </div>
              <div className="col-md-4 col-with-line">
                <DetailedSection title="Projected Print Date" className="detailed-section_wide">
                  <FieldsList fields={projectedPrintDate} />
                </DetailedSection>
              </div>
            </div>
          </div>
        </Loader>
      </>
    );
  }
}

export default DetailedIssuesView;

import { getSortNaturalCompareByFieldFunction as naturalSort } from 'app/utils';

const categoriesSortOrder = ['journalAcronym', 'volumeYears', 'volumeNumbers', 'issueNumbers', 'urgency', 'accesses', 'tsVendors', 'productionStages', 'paymentStatuses', 'licenseStatuses', 'pubDate'];

const urgencySortOrder = ['critical', 'late_licenses', 'overdue', 'at_risk', 'due_today', 'resolved', 'onschedule'];

const paymentStatusesSortOrder = ['Unpaid', 'Paid'];

const accessesSortOrder = ['openaccess', 'onlineopen', 'subscription'];

const productionStagesSortOrder = [
  'received',
  'bookingIn',
  'proofPrep',
  'proofing',
  'articleRevises',
  'revisesCheck',
  'evQA',
  'evPending',
];

const licenseStatusesSortOrder = ['na', 'missing', 'late', 'signed'];

function sortWith(order, key = 'optionId') {
  return arr =>
    arr.sort((a, b) => {
      const indexA = order.indexOf(key === null ? a : a[key]);
      const indexB = order.indexOf(key === null ? b : b[key]);

      return indexA - indexB;
    });
}

function sortByAlphabet(arr) {
  return arr.sort(naturalSort('optionId'));
}

const categoriesSortParams = {
  urgency: sortWith(urgencySortOrder),
  accesses: sortWith(accessesSortOrder),
  productionStages: sortWith(productionStagesSortOrder),
  tsVendors: sortByAlphabet,
  paymentStatuses: sortWith(paymentStatusesSortOrder),
  licenseStatuses: sortWith(licenseStatusesSortOrder),
};

export const getSortedCategories = sortWith(categoriesSortOrder, null);

export const getSortedOptions = (categories, options) =>
  categories
    .map(categoryId => {
      const categoryOptions = options.filter(option => option.categoryId === categoryId);
      const sort = categoriesSortParams[categoryId];

      return sort ? sort(categoryOptions) : categoryOptions;
    })
    .reduce((acc, options) => acc.concat(options), []);


export const createFilter = (categoryId, optionId) => ({
  categoryId,
  optionId,
});

export const createFilterWithValue = (categoryId, value) => ({
  categoryId,
  optionId: categoryId,
  value,
});

import React from 'react';
import { Helmet } from 'react-helmet';

const Report = () => (
  <>
    <Helmet>
      <title>CMH | Report</title>
    </Helmet>
    <p>
      <b>Report problem title</b>
    </p>
    Report problem content
  </>
);

Report.layoutProps = {
  header: {
    title: 'Report problem',
  },
};

export default Report;

export const USER_PE_TYPE = 'pe';
export const USER_CV_TYPE = 'cv';
export const USER_VBA_TYPE = 'vba';

export const usersTypeMatching = {
  [USER_PE_TYPE]: 'productionEditors',
  [USER_CV_TYPE]: 'contentVendors',
  [USER_VBA_TYPE]: 'bookinAssociate',
};

export const ENTITY_NOT_FOUND_MSG = 'No results found';

export const USER_ALREADY_ADDED_MSG = 'User has already been added';
const VENDOR_ALREADY_ADDED_MSG = 'Content Vendor has already been added';
const ALL_VENDOR_ALREADY_ADDED_MSG = 'All found Content Vendors have been already added.';

export const alreadyAddedMSGs = {
  [USER_PE_TYPE]: USER_ALREADY_ADDED_MSG,
  [USER_CV_TYPE]: VENDOR_ALREADY_ADDED_MSG,
  [USER_VBA_TYPE]: USER_ALREADY_ADDED_MSG,
};

export const alreadyAddedPluralMSGs = {
  ...alreadyAddedMSGs,
  [USER_CV_TYPE]: ALL_VENDOR_ALREADY_ADDED_MSG,
};

import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import useIntervalRequest from 'app/utils/hooks/useIntervalRequest';
import { getSynchronizationDelayStatus } from 'app/redux/api/common';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import './WarningPanel.scss';

const TITLE = 'Article data may be out of sync';
const RELOAD_MSG = 'Please reload the page in a few minutes to check if issue has been resolved.';
const SNOW_MSG = 'If the problem persists a support incident can be created at ';
const SNOW_LABEL = 'ServiceNow';

const WarningPanel = () => {
  const [expanded, setExpanded] = useState(false);
  const status = useIntervalRequest({ endpoint: getSynchronizationDelayStatus });

  useEffect(() => {
    setExpanded(false);
  }, [status]);

  const subTitle = expanded ? (
    <span>. {RELOAD_MSG}<br /> {SNOW_MSG}
      <a
        onClick={(event) => event.stopPropagation()}
        target="_blank"
        data-seleniumid="warning-url"
        href="https://wiley.service-now.com"
      >{SNOW_LABEL}
      </a>
    </span>
  ) : null;

  return (
    <div className={cn('warning-panel', { visible: !!status })} data-seleniumid="warning-panel">
      <Svg name="critical" className="warning-panel__icon" data-seleniumid="warning-icon" />
      <span
        className="warning-panel__title"
        onClick={() => setExpanded(!expanded)}
        data-seleniumid="warning-title"
      >
        {TITLE}{subTitle}<Svg
          name={expanded ? 'carrot-up' : 'carrot-down'}
          className="warning-panel__button"
          onClick={() => setExpanded(!expanded)}
          data-seleniumid="warning-expand"
        />
      </span>
    </div>
  );
};

export default WarningPanel;

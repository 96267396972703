import { put, putResolve, takeLatest } from 'redux-saga/effects';
import { getImitatePEidInfo } from 'app/pages/SwitchView/utils';
import { mergeOptionsAndFiltersWithValue } from './utils';
import { FETCH_ENTITIES_INIT } from './TableDucks';
import { FETCH_FACETS, UPDATE_FACETS } from './TableFiltersDucks';
import { getFiltersParams, getRelevantFilters } from './common';

export const ERROR_MSG = 'Sorry, we couldn\'t load facets';

function* fetchFacetsWorker(action) {
  const { name, facetsEffect, payload } = action;
  const { selectedFilters } = payload ?? {};
  const filters = yield getRelevantFilters({ selectedFilters, name });
  const filtersParams = yield getFiltersParams({ selectedFilters: filters, name });
  const imitated = getImitatePEidInfo();

  yield putResolve({
    ...action,
    selfCall: false,
    transformResult: ({ categories, options }) => ({ categories, options: mergeOptionsAndFiltersWithValue(filters, options) }),
    asyncCall: () =>
      facetsEffect({
        ...filtersParams,
        ...imitated,
      }),
    transformError: () => ({ message: ERROR_MSG }),
  });
}

export function* watchFacets() {
  yield takeLatest(FETCH_FACETS, fetchFacetsWorker);
  yield takeLatest(UPDATE_FACETS, fetchFacetsWorker);
  yield takeLatest(FETCH_ENTITIES_INIT, function* fetch(action) {
    yield put({
      ...action,
      type: FETCH_FACETS,
    });
  });
}

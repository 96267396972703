import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TileWidget from './TileWidget';

class TileWidgetsGrid extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      filterId: PropTypes.string,
      filterValue: PropTypes.number,
    })),
    listConfig: PropTypes.shape({
      categoryName: PropTypes.string,
      itemName: PropTypes.string,
      itemPluralName: PropTypes.string,
      items: PropTypes.objectOf(PropTypes.shape({
        icon: PropTypes.string,
        moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        moreLinkAlwaysEnabled: PropTypes.bool,
        moreText: PropTypes.string,
        title: PropTypes.string,
      })),
    }),
    seleniumPart: PropTypes.string,
  };

  renderItem = data => {
    const { seleniumPart = '', listConfig = {}, listConfig: { itemName = '', itemPluralName = '' } = {} } = this.props;
    const config = listConfig?.items?.[data?.filterId];
    return (
      <div key={data?.filterId} className="col-md-3 col-sm-6 col-12 mb-4 mb-md-2">
        <TileWidget
          name={itemName}
          pluralName={itemPluralName}
          amount={data.filterValue}
          seleniumPart={`${seleniumPart}-${data?.filterId}`}
          config={config}
        />
      </div>
    );
  };

  renderEmpty = () => <div className="col-md-12  mb-4 mb-md-2">Empty</div>;

  render() {
    const { listConfig, data } = this.props;
    const { categoryName } = listConfig || {};

    const isEmpty = !data || data.length === 0;

    return (
      <div className="journals">
        {categoryName && <div data-seleniumid={`tiles-${categoryName.replace(/ /g, '_').toLowerCase()}`} className="journals-title mb-4">{categoryName}</div>}
        <div className="row journal-list">{isEmpty ? this.renderEmpty() : data.map(this.renderItem)}</div>
      </div>
    );
  }
}

export default TileWidgetsGrid;

import { apiCall } from 'app/services/api';
import { LOAD_ERROR_MSG } from 'app/constants';

export const endpoints = {
  getArticleContent: cid => `v2/articles/${cid}/files`,
  getArticleJobsheets: productionId => `v2/articles/${productionId}/jobsheets`,
  getArticleFileVersions: cid => `v2/contents/${cid}/versions`,
  getContent: cid => `v2/contents/${cid}`,
  getContentVersion: versionId => `v2/contents/versions/${versionId}`,
  getJobhseetContent: (jobsheetId) => `/api/v2/jobsheets/${jobsheetId}`,
};

export const getArticleContent = (cid, aid) => apiCall(endpoints.getArticleContent(cid), {}, { errorMessage5XX: LOAD_ERROR_MSG(`${aid || ''} content`) });
export const getArticleJobsheets = (productionId, aid) => apiCall(endpoints.getArticleJobsheets(productionId), {}, { errorMessage5XX: LOAD_ERROR_MSG(`${aid || ''} jobsheets`) });
export const getArticleFileVersions = (cid, fileName) => apiCall(endpoints.getArticleFileVersions(cid), {}, { errorMessage5XX: LOAD_ERROR_MSG(`${fileName || 'file'} versions`) });

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';

import './Search.scss';

export default class Search extends PureComponent {
  componentDidMount() {
    const { focusable } = this.props;

    if (focusable) {
      this.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { current: input } = this.inputRef;

    if (!input) {
      return;
    }

    const { defaultValue: prevDefaultValue } = prevProps;
    const { value } = input;
    const { defaultValue, focusable } = this.props;

    if (prevDefaultValue !== defaultValue && value === prevDefaultValue) {
      input.value = defaultValue?.trim?.() || '';
      if (focusable) {
        input.focus();
      }
    }
  }

  inputRef = React.createRef();

  clear = () => {
    const { current: input } = this.inputRef;
    input.value = '';
  };

  search = () => {
    const { onSearch } = this.props;
    const { current: input } = this.inputRef;

    if (!input) {
      return;
    }

    input.value = input.value.trim();

    if (onSearch) {
      onSearch(input.value);
    }
  };

  focus = () => {
    const { current: input } = this.inputRef;

    if (input) {
      input.focus();
    }
  };

  handleSearchBtnClick = () => {
    this.search();
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  handleChange = () => {
    const { onSearchChange } = this.props;
    const { current: input } = this.inputRef;

    if (onSearchChange) {
      onSearchChange(input.value);
    }
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    const { current: input } = this.inputRef;

    if (onBlur) {
      onBlur(input.value);
    }
  };

  render() {
    const {
      placeholder,
      seleniumId,
      defaultValue,
      hasCollapse = false,
      input: Input = 'input',
      className = '',
      onSearch,
      onSearchChange,
      ...props
    } = this.props;

    return (
      <div className={cn('search', className)}>
        {hasCollapse && (
          <div className="search-button-wrap">
            <button
              onClick={this.handleSearchBtnClick}
              type="button"
              className="search-button"
              data-seleniumid={`${seleniumId}-mobile-show-button`}
            >
              <Svg name="search" />
            </button>
          </div>
        )}
        <div className="search-input-wrap">
          <label htmlFor={seleniumId}>
            <Svg
              name="search"
              data-seleniumid={`${seleniumId}-search-input-button`}
              onClick={this.handleSearchBtnClick}
            />
          </label>
          <Input
            data-seleniumid={`${seleniumId}-search-input`}
            id={seleniumId}
            ref={this.inputRef}
            type="text"
            defaultValue={defaultValue}
            placeholder={placeholder}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            {...props}
          />
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  focusable: PropTypes.bool,
  hasCollapse: PropTypes.bool,
  input: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onBlur: PropTypes.func,
  onSearch: PropTypes.func,
  onSearchChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  seleniumId: PropTypes.string.isRequired,
};

Search.defaultProps = {
  input: 'input',
};

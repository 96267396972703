import { combineReducers } from 'redux';

import fetch from './articleHistoryDucks';
import production from './productionArticleHistoryDucks';
import publication from './publicationArticleHistoryDucks';

export default combineReducers({
  fetch,
  production,
  publication,
});

import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Popup from '@wiley/cpp-ui-commons/lib/components/Popup';
import './InputWithDropdown.scss';

class InputWithDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  state = {
    isActive: false,
  };

  static propTypes = {
    focusable: PropTypes.bool,
    items: PropTypes.array,
    onSearch: PropTypes.func,
    onSelectItem: PropTypes.func,
    renderHeader: PropTypes.func,
    renderItem: PropTypes.func,
  };

  static defaultProps = {
    focusable: true,
  };

  componentDidUpdate() {
    const { focusable } = this.props;
    const { current: input } = this.inputRef;

    if (focusable) {
      input.focus();
    }
  }

  hide = () => this.setState({ isActive: false });

  show = () => this.setState({ isActive: true });

  get value() {
    const { current: input } = this.inputRef;

    if (input) {
      return input.value;
    }

    return '';
  }

  set value(text) {
    this.setText(text);
  }

  setText = text => {
    const { current: input } = this.inputRef;

    if (input) {
      input.value = text;
    }
  };

  focus = () => {
    const { current: input } = this.inputRef;
    if (input) {
      input.focus();
    }
  };

  handleFocus = () => {
    this.show();
  };

  handleClick = (value, item) => {
    const { onSelectItem } = this.props;

    this.setText(value);

    if (onSelectItem) {
      onSelectItem(value, item);
    }

    this.hide();
  };

  renderDropdown = () => {
    const { isActive } = this.state;
    const { items } = this.props;

    if (!items?.length) {
      return null;
    }

    return (
      <Popup className="input-dropdown" onClose={this.hide} opened={isActive}>
        <>
          {this.renderHeader()}
          {items.map(this.renderItem)}
        </>
      </Popup>
    );
  };

  renderHeader = () => {
    const { renderHeader } = this.props;

    return <div className="input-dropdown__header">{renderHeader ? renderHeader() : 'Items'}</div>;
  };

  renderItem = item => {
    const { renderItem } = this.props;
    const { label, value } = item;
    const key = value?.id || `${label}-${value}`;
    return (
      <div
        key={key}
        onClick={() => this.handleClick(value, item)}
        className="input-dropdown__item"
        data-seleniumid={`${key}-option`}
      >
        {renderItem ? renderItem(item) : label}
      </div>
    );
  };

  render() {
    const { isActive } = this.state;
    const { items, renderItem, onSelectItem, renderHeader, onSearch, ...inputProps } = this.props;

    return (
      <div className={cn('input-dropdown-wrap', { 'input-dropdown-wrap--active': isActive && items?.length })}>
        <input ref={this.inputRef} autoComplete="off" onClick={this.handleFocus} {...inputProps} />
        {this.renderDropdown()}
      </div>
    );
  }
}

export default InputWithDropdown;

export const MNEMONIC_EVENT = 'mnemonic';
export const ACTUAL_DATE = 'actualDate';
export const REVISED_DATE = 'revisedDate';

export default {
  ManuscriptReceivedInProduction: 'RECD at JWS',
  BookinComplete: 'Bookin Complete',
  BookinQueryRaised: 'BK In Q RAISED',
  BookinQueryResolved: 'BK In Q RESOLVED',
  SocietyFileCheckComplete: 'SOC ART CHECKED',
  AcceptedArticlePublishedOnline: 'AA PUB Online',
  ManuscriptToCopyEditorAtTypesetter: 'To TS CE',
  ManuscriptFromCopyEditorAtTypesetter: 'From TS CE',
  ManuscriptToCopyEditor: 'To CE',
  ManuscriptFromCopyEditor: 'From CE',
  ManuscriptToTypesetter: 'To TS',
  ProofToAuthor: 'PRF Out',
  ProofedByAuthor: 'PRF RECD',
  ProofCorrectionsToTypesetter: 'PRF CRX to TS',
  RevisedProofReceived: 'REV ART PRF RECD',
  RevisedProofApproved: 'REV ART PRF APPR',
  ProofToEditor: 'PRF to ED',
  ProofedByEditor: 'PRF from ED',
  EditorCorrectionsToTypesetter: 'ED CRX to TS',
  SecondRevisedProofReceived: '2REV PRF RECD',
  SecondRevisedProofApproved: '2REV PRF APPR',
  EarlyViewFileReceived: 'EV Files RECD',
  EarlyViewFileApproved: 'EV Files APPR',
  PublishedOnlineEarlyUnpaginated: 'EV Pub Online',
  IssueCompilation: 'Issue Compilation',
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withTableFilter from 'app/modules/Table/hoc/withTableFilter';
import FilterTableView from 'app/modules/Table/components/FilterTableView';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Pager from '@wiley/cpp-ui-commons/lib/components/Pager';
import { Helmet } from 'react-helmet';
import PaperHeader from 'app/components/PaperHeader';

class WorkInProgressContainer extends PureComponent {
  static propTypes = {
    descriptor: PropTypes.array.isRequired,
    entitiesError: PropTypes.any,
    entitiesHasLoading: PropTypes.bool,
    facetsCategories: PropTypes.arrayOf(PropTypes.string),
    facetsError: PropTypes.any,
    facetsHasLoading: PropTypes.bool,
    facetsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.string,
        count: PropTypes.number,
        optionId: PropTypes.string,
        parentId: PropTypes.string,
      }),
    ),
    fullTitle: PropTypes.string,
    hideFilter: PropTypes.bool,
    hideTitle: PropTypes.bool,
    isSearchPage: PropTypes.bool,
    items: PropTypes.array,
    itemsCount: PropTypes.number,
    onApplySelectedFilters: PropTypes.func.isRequired,
    onChangeCount: PropTypes.func.isRequired,
    onChangePage: PropTypes.func,
    onChangeTable: PropTypes.func.isRequired,
    onUpdateFilters: PropTypes.func.isRequired,
    pageCount: PropTypes.number,
    pageTitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    renderError: PropTypes.func,
    renderNoResults: PropTypes.func,
    selectedFilters: PropTypes.array,
    stats: PropTypes.shape({
      currentPage: PropTypes.number,
      pageSize: PropTypes.number,
      pageSizeOptions: PropTypes.array,
      sortDir: PropTypes.string,
      sortField: PropTypes.string,
    }),
    title: PropTypes.string,
  };

  render() {
    const {
      pageCount,
      stats,
      title,
      hideTitle,
      fullTitle,
      entitiesHasLoading,
      items,
      itemsCount,
      pageTitle = `${title} In Progress`,
      onChangePage,
    } = this.props;

    return (
      <>
        <Helmet>
          <title>{fullTitle || `CMH | Work In Progress | ${title}`}</title>
        </Helmet>
        {!hideTitle && <PaperHeader title={pageTitle} />}
        <Loader hasLoading={entitiesHasLoading}>
          <FilterTableView
            {...this.props}
            items={items}
            currentPage={stats.currentPage}
            totalRecords={itemsCount}
            allPagesSizes={stats.pageSizeOptions}
            pageSize={stats.pageSize}
            sortField={stats.sortField}
            sortDir={stats.sortDir}
          />
        </Loader>
        <div className="mt-4">
          <Pager
            currentPage={stats.currentPage}
            onPage={onChangePage}
            totalPages={pageCount}
          />
        </div>
      </>
    );
  }
}

export default withTableFilter(WorkInProgressContainer);

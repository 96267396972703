import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import SelectSearch from '@wiley/cpp-ui-commons/lib/components/SelectSearch';
import TableError from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableError';
import { WORKS_FOR_WILEY, CV_SUPER_MSG } from 'app/pages/Admin/common/constants';
import ReactTooltip from 'react-tooltip';
import rolesEnum from 'app/enums/roles';
import './TableAdd.scss';
import './PEsSearchResult.scss';


const PEsSearchResult = ({ vendors, updateVendor, entity, onAdd, isLoading, seleniumId, suggestionHeader, suggestionText, error }) => {
  const vendorsOptions = useMemo(() => vendors.map(({ id, name }) => ({ label: name, value: id })), [
    vendors,
  ]);

  const onSelectVendor = ({ value }) => {
    updateVendor({
      user: entity,
      vendorId: value,
      prevVendorId: entity.worksFor ? vendors?.find(vendor => vendor.name === entity.worksFor)?.id : null,
    });
  };

  function renderVendorSelect() {
    const { worksFor, roles } = entity;
    const isSuperuser = roles?.includes(rolesEnum.systemAdministrator);
    const contentVendor = (worksFor || '').toLowerCase() === WORKS_FOR_WILEY ? null : worksFor;
    const vendorsList = contentVendor ? [{ label: 'None', value: null }, ...vendorsOptions] : vendorsOptions;

    return (
      <div data-tip={isSuperuser ? CV_SUPER_MSG : null} className="pe-search__vendor-wrap">
        <span className="pe-search__vendor-title">Content Vendor:</span>
        <SelectSearch
          disabled={isSuperuser}
          value={contentVendor}
          placeholder="None"
          listMaxHeight={200}
          searchable
          onChange={onSelectVendor}
          seleniumId={`${seleniumId}-vendor-select`}
          className="search-select pe-search__select"
          options={vendorsList}
        />
      </div>
    );
  }

  return (
    <SectionLoader hasLoading={isLoading}>
      <div className="mt-add__wrap">
        <div className="mt-add__value-wrap">
          <div className="pe-search__wrap">
            <div>
              { suggestionHeader && (
                <div className="mt-add__header">
                  {suggestionHeader.map(val => (<span key={val} data-seleniumid={`assigned-${seleniumId}-search-result-${val}-${entity.id}`}>{entity[val]}</span>))}
                </div>
              )}
              {suggestionText && (
                <div
                  data-seleniumid={`assigned-${seleniumId}-search-result-${suggestionText}-${entity.id}`}
                  className="mt-add__item"
                >
                  {entity[suggestionText]}
                </div>
              )}
            </div>
            {renderVendorSelect()}
          </div>
          <div
            data-seleniumid={`assigned-${seleniumId}-search-add-button-${entity.id}`}
            className="mt-add__button"
            onClick={onAdd(entity.id)}
          >
            Add
          </div>
        </div>
        { error && (
          <TableError
            className="mt-add__error"
            error={error}
            seleniumId={`add-${entity.id}-${seleniumId}`}
          />
        )}
        <ReactTooltip
          effect="solid"
          type="error"
          place="right"
          html
          className="manage-users__tooltip"
        />
      </div>
    </SectionLoader>
  );
};

PEsSearchResult.propTypes = {
  entity: PropTypes.object.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onAdd: PropTypes.func,
  seleniumId: PropTypes.string.isRequired,
  suggestionHeader: PropTypes.array,
  suggestionText: PropTypes.string,
  updateVendor: PropTypes.func,
  vendors: PropTypes.array,
};

export default PEsSearchResult;

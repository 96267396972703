import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { getArticleHistory } from 'app/redux/api/common';
import { CLEAR_ARTICLE_HISTORY, LOAD_ARTICLE_HISTORY, UPDATE_PRODUCTION_HISTORY } from './constants';

export const initialState = {
  error: null,
  isLoading: true,
  isProductionUpdated: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ARTICLE_HISTORY: {
      const { silent } = payload;
      return {
        ...state,
        isLoading: !silent,
      };
    }
    case LOAD_ARTICLE_HISTORY + SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case LOAD_ARTICLE_HISTORY + FAILURE:
      return {
        ...state,
        isLoading: false,
        history: null,
      };
    case UPDATE_PRODUCTION_HISTORY: {
      const { isUpdated } = payload;
      if (isUpdated) return { ...state, isProductionUpdated: true };
      return state;
    }
    case CLEAR_ARTICLE_HISTORY:
      return initialState;
    default:
      return state;
  }
};

export const loadArticleHistory = ({ id: aid, silent = false }) => ({
  type: LOAD_ARTICLE_HISTORY,
  payload: { aid, silent },
  asyncCall: () => getArticleHistory(aid),
});

export const clearArticleHistory = () => ({
  type: CLEAR_ARTICLE_HISTORY,
});

export const getIsLoading = state => state.articleHistory.fetch.isLoading;
export const getError = state => state.articleHistory.fetch.error;
export const getIsProductionUpdated = state => state.articleHistory.fetch.isProductionUpdated;

import { combineReducers } from 'redux';
import detailed from 'app/redux/detailed/DetailedDucks';
import dashboardView from 'app/pages/Dashboard/redux/DashboardDucks';
import version from 'app/pages/Version/redux/VersionDucks';
import auth, { LOGOUT_SUCCESS } from 'app/modules/Auth/redux/AuthDucks';
import admin from 'app/pages/Admin/redux/reducer';
import switchView from 'app/pages/SwitchView/redux/SwitchViewDucks';
import search from 'app/pages/Search/redux/SearchDucks';
import tableFilterView from 'app/modules/Table/redux';
import articleContent from 'app/components/ArticleContent/Files/redux/ArticleFilesDucks';
import articleJobsheets from 'app/components/ArticleContent/Jobsheets/redux/ArticleJobsheetDucks';
import articleHistory from 'app/pages/ArticleHistory/redux';
import groups from './groups';
import journals from './journals';


const rootReducer = combineReducers({
  tableFilterView,
  dashboardView,
  detailed,
  version,
  auth,
  admin,
  switchView,
  search,
  groups,
  journals,
  articleContent,
  articleJobsheets,
  articleHistory,
});

export default (state, action) => {
  const { type } = action;

  return rootReducer(type === LOGOUT_SUCCESS ? undefined : state, action);
};

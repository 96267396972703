import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import history from '@wiley/cpp-ui-commons/lib/router/history';
import emitter from './emitter';

import './Scrollable.scss';

history.listen(({ state }) => {
  if (!state || !state.avoidScrollToTop) {
    emitter.emit('scrollTo', 0);
  }
});

class Scrollable extends PureComponent {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  static propTypes = {
    children: PropTypes.any,
  };

  componentDidMount() {
    this.unsubscribeScrollTo = emitter.addListener('scrollTo', to => requestAnimationFrame(() => this.scrollTo(to)));
    this.unsubscribeWheel = emitter.addListener('wheel', this.handleWheel);
  }

  componentWillUnmount() {
    this.unsubscribeScrollTo.remove();
    this.unsubscribeWheel.remove();
  }

  get container() {
    const { current: container } = this.containerRef;

    return container;
  }

  scrollTo = to => {
    if (this.container) {
      this.container.scrollTop = to;
    }
  };

  handleScroll = e => {
    const { target } = e;

    if (target !== this.container) {
      return;
    }

    emitter.emit('scroll', { scrollTop: target.scrollTop });
  };

  handleWheel = e => {
    const { deltaY } = e;

    this.container.scrollTop += deltaY;
  };

  render() {
    return <div className="scrollable" onScroll={this.handleScroll} ref={this.containerRef} {...this.props} />;
  }
}

export default Scrollable;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Pager from '@wiley/cpp-ui-commons/lib/components/Pager';
import PaperHeader from 'app/components/PaperHeader';
import TableStatsLayout from '@wiley/cpp-ui-commons/lib/components/Table/TableStatsLayout';
import Select from '@wiley/cpp-ui-commons/lib/components/Select';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';
import { createAlphabet } from 'app/utils';
import Stickable from 'app/components/Stickable';
import StickyContainer from 'app/components/Stickable/StickyContainer';
import emmiter from 'app/components/Scrollable/emitter';
import Search from 'app/components/Search';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import SelectedItem from 'app/components/SelectedFilters/SelectedItem';
import { ASC_SORT } from 'app/services/api/sort';
import { INCORRECT_REQUEST, NO_RESULTS_MESSAGE } from 'app/enums/commonErrors';
import path from 'app/router/path';
import UserList from './components/UserList';
import './SwitchView.scss';

export const alphabetOptions = [
  { value: '', label: '...' },
  ...createAlphabet().map(char => ({ value: char, label: char })),
];

const sizes = [
  25,
  50,
  100,
];

const defaultSize = 25;

const SEARCH_PLACEHOLDER = 'Search by email';
const SELECT_LETTER_PLACEHOLDER = 'Select';
const DEFAULT_USERS_SORT_FIELD = 'lastName';
const DEFAULT_USERS_SORT_DIR = ASC_SORT;

export class SwitchView extends PureComponent {
  state = {
    pageSize: defaultSize,
    page: 1,
    letter: undefined,
    search: null,
    selectedUser: null,
  };

  static propTypes = {
    error: PropTypes.object,
    fetchUsers: PropTypes.func,
    isLoading: PropTypes.bool,
    reject: PropTypes.func,
    switchTo: PropTypes.func,
    totalAmount: PropTypes.number,
    user: PropTypes.object,
    users: PropTypes.array,
  };

  componentDidMount() {
    this.fetchUsers();
  }

  static getDerivedStateFromProps(props, state) {
    const { user } = props;
    const { selectedUser } = state;
    if (user && !selectedUser) {
      return { selectedUser: user };
    }

    return null;
  }

  static layoutProps = {
    header: {
      title: 'Switch View',
    },
  };

  fetchUsers = () => {
    const { fetchUsers } = this.props;
    const { page, letter, pageSize, search } = this.state;

    fetchUsers({
      size: pageSize,
      offset: (page - 1) * pageSize,
      letter: letter?.value,
      sortField: DEFAULT_USERS_SORT_FIELD,
      sortDir: DEFAULT_USERS_SORT_DIR,
      email: search,
    });

    emmiter.emit('scrollTo', 0);
  };

  getUser = (id) => {
    const { users } = this.props;
    return users.find(user => user.id === id);
  };

  getSelectedUserInfo() {
    const { selectedUser } = this.state;
    return { optionId: `${selectedUser.firstName} ${selectedUser.lastName}`, categoryId: selectedUser.id };
  }

  handleCountChoose = pageSize => {
    this.setState({
      pageSize,
      page: 1,
    }, this.fetchUsers);
  };

  handleChangePage = page => {
    this.setState({ page }, this.fetchUsers);
  };

  handleApplyLetter = letter => {
    this.setState({ letter: letter.value ? letter : undefined, pageSize: defaultSize, page: 1, search: null }, this.fetchUsers);
  };

  handleRemoveUser = () => {
    const { selectedUser } = this.state;
    const { user, reject } = this.props;
    if (selectedUser.id === user?.id) {
      reject();
    }
    this.setState({ selectedUser: null });
  };

  handleSelect = (id) => {
    this.setState({ selectedUser: this.getUser(id) });
  };

  handleSubmit = () => {
    const { selectedUser } = this.state;
    const { switchTo, user } = this.props;
    if (selectedUser.id !== user?.id) {
      switchTo(selectedUser);
    }
  };

  handleSearch = value => {
    this.setState({ letter: undefined, pageSize: defaultSize, page: 1, search: value }, this.fetchUsers);
  };

  handleClearSearch = () => {
    this.handleSearch(null);
  };

  renderSearchTitle() {
    const { search } = this.state;
    if (!search) return null;

    return (
      <>
        <span
          className="switch-view__search-title"
          data-seleniumid="search-user-title"
        >
          Showing results for '{ search }'
        </span>
        <Svg
          className="switch-view__reset" data-seleniumid="search-user-title-reset" name="close"
          onClick={this.handleClearSearch}
        />
      </>
    );
  }

  renderPageHeader() {
    const { search } = this.state;
    return (
      <PaperHeader title="Switch View">
        <Search
          className="switch-view__search"
          placeholder={SEARCH_PLACEHOLDER}
          onSearch={this.handleSearch}
          hasCollapse={false}
          defaultValue={search}
          seleniumId="switch-view"
        />
      </PaperHeader>
    );
  }

  renderStatsPanel() {
    const { pageSize, page, selectedUser } = this.state;
    const { users, totalAmount } = this.props;
    return (
      <div className="switch-view__stats">
        <TableStatsLayout
          allPageSizes={sizes}
          currentPage={page}
          currentPageSize={pageSize}
          onCountChoose={this.handleCountChoose}
          recordsCount={users.length}
          totalRecords={totalAmount}
        />
        { selectedUser && (
          <SelectedItem
            onRemove={this.handleRemoveUser}
            data={this.getSelectedUserInfo()}
            className="switch-view__stats-selected"
          />
        )}
      </div>
    );
  }

  renderSelectUserPanel() {
    const { letter, selectedUser } = this.state;
    const { user } = this.props;
    const isSubmitEnable = selectedUser && (selectedUser?.id !== user?.id);
    return (
      <div className="switch-view__select-surname">
        <span className="select-surname__title">Browse by surname:</span>
        <Select
          options={alphabetOptions}
          value={letter}
          listPosition="bottom"
          placeholder={SELECT_LETTER_PLACEHOLDER}
          seleniumId="select-letter"
          onChange={this.handleApplyLetter}
        />
        <input
          className="user-list__apply-button"
          type="submit"
          disabled={!isSubmitEnable}
          value="Submit"
          data-seleniumid="apply-user"
          onClick={this.handleSubmit}
        />
      </div>
    );
  }

  renderResults() {
    const { selectedUser } = this.state;
    const { users, error } = this.props;

    if (users.length) {
      return (
        <UserList
          onSelect={this.handleSelect}
          users={users}
          selectedUserId={selectedUser?.id}
        />
      );
    }

    return (
      <InfoView
        seleniumId="no-results"
        title={NO_RESULTS_MESSAGE}
        text={error ? INCORRECT_REQUEST : null}
      />
    );
  }

  renderPager() {
    const { page, pageSize } = this.state;
    const { totalAmount } = this.props;
    const totalPages = Math.ceil(totalAmount / pageSize);

    return (
      <Pager
        totalPages={totalPages}
        currentPage={page}
        maxCountAroundCurrent={1}
        onPage={this.handleChangePage}
      />
    );
  }

  render() {
    const { isLoading } = this.props;

    return (
      <>
        <Helmet>
          <title>CMH | Switch View</title>
        </Helmet>
        {this.renderPageHeader()}
        <h4>Production Editors</h4>
        <SectionLoader hasLoading={isLoading}>
          <StickyContainer>
            {this.renderSearchTitle()}
            <Stickable>
              <div className="switch-view__filters" style={{ background: 'white' }}>
                {this.renderStatsPanel()}
                {this.renderSelectUserPanel()}
              </div>
            </Stickable>
            {this.renderResults()}
          </StickyContainer>
        </SectionLoader>
        {this.renderPager()}
      </>
    );
  }
}

SwitchView.layoutProps = {
  tab: {
    id: 'switch-view',
    title: 'Switch View',
    to: path.switchView,
  },
  header: {
    title: 'Switch View',
  },
};

export default SwitchView;

import ReactDOM from 'react-dom';
import './Stickable.scss';

const Stickable = ({ children, stickyId }) => {
  const container = document.getElementById(stickyId || 'stickable-container');

  if (!container) {
    return children;
  }

  return ReactDOM.createPortal(children, container);
};

export default Stickable;

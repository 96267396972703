import { put, takeLatest } from 'redux-saga/effects';
import { FETCH_JOURNALS } from 'app/redux/reducers/journals';
import { failureActionCreator, successActionCreator } from 'app/redux/asyncMiddleware';
import { NO_RESULTS_MESSAGE } from 'app/enums/commonErrors';
import { getJournals } from 'app/redux/api/common';

export function* searchWorker({ type, payload }) {
  try {
    const items = yield getJournals(payload);
    yield put(successActionCreator(type)({ payload: items }));
  }
  catch (e) {
    const { response } = e || {};
    yield put(failureActionCreator(type)({ payload: { message: response?.data?.message || NO_RESULTS_MESSAGE } }));
  }
}

export function* watchJournals() {
  yield takeLatest(FETCH_JOURNALS, searchWorker);
}

import { connect } from 'react-redux';
import {
  loadArticleJobsheets,
  selectJobsheets,
  selectJobsheetsError,
  selectJobsheetsIsLoading,
} from 'app/components/ArticleContent/Jobsheets/redux/ArticleJobsheetDucks';
import ArticleJobsheets from './ArticleJobsheets';

export const mapStateToProps = (state = {}, { id }) => ({
  files: selectJobsheets(state, id),
  isLoading: selectJobsheetsIsLoading(state),
  error: selectJobsheetsError(state),
});

export const mapDispatchToProps = (dispatch, { id, productionId, aid }) => ({
  loadArticleJobsheets() {
    dispatch(loadArticleJobsheets({ id, productionId, aid }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArticleJobsheets);

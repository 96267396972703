import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import qs from 'query-string';
import { DetailedError } from 'app/pages/DetailedViews/components';
import { selectData, selectHasLoading, selectError, loadData } from 'app/redux/detailed/DetailedDucks';
import { detailedEndpoints } from 'app/redux/api/common';
import path from 'app/router/path';
import DetailedIssuesView from './DetailedIssuesView';
import '../Detailed.scss';

class DetailedIssues extends PureComponent {
  static propTypes = {
    data: PropTypes.any,
    error: PropTypes.object,
    hasLoading: PropTypes.bool,
    issueId: PropTypes.string,
    loadData: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        issue_id: PropTypes.string,
      }),
    }),
  };

  componentDidMount() {
    const { loadData } = this.props;

    loadData();
  }

  static layoutProps = {
    header: {
      title: 'Issue',
    },
  };

  renderDetail = () => {
    const { data, hasLoading } = this.props;

    const articlesUrl = {
      pathname: path.articlesInIssue,
      search: qs.stringify({ type: 'iid', q: data.issueId, articlesInIssue: true, issueNavId: data.id }),
    };

    return <DetailedIssuesView hasLoading={hasLoading} data={data} articlesUrl={articlesUrl} />;
  };

  renderError = (error) => {
    const { match: { params = {} } } = this.props;
    const { issue_id = '' } = params;

    return (
      <DetailedError error={error} entityType="issue" entityId={issue_id} />
    );
  };

  render() {
    const { error } = this.props;

    if (error) {
      return this.renderError(error);
    }

    return (
      <Switch>
        <Route render={this.renderDetail} />
      </Switch>
    );
  }
}

export default connect(
  (state, { match: { params: { issue_id } } }) => ({
    hasLoading: selectHasLoading(state, detailedEndpoints.issueDetailed, issue_id),
    data: selectData(state, detailedEndpoints.issueDetailed, issue_id),
    error: selectError(state, detailedEndpoints.issueDetailed, issue_id),
  }),
  (dispatch, { match }) => ({
    loadData() {
      const { params } = match;
      const { issue_id: issueId } = params;

      dispatch(loadData(detailedEndpoints.issueDetailed, {
        id: issueId,
      }));
    },
  }),
)(DetailedIssues);

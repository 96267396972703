import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import EventListener from 'react-event-listener';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import SelectedItem from './SelectedItem';
import './SelectedFilters.scss';

export default class SelectedFilters extends PureComponent {
  state = {
    neededExpand: false,
    expanded: false,
  };

  static propTypes = {
    items: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  componentDidUpdate() {
    this.updateDimensions();
  }

  _lastBadge = React.createRef();

  updateDimensions = () => {
    const { current: lastBadge } = this._lastBadge;

    if (lastBadge) {
      const { parentElement: parent } = lastBadge;
      if (parent) {
        let { expanded } = this.state;
        const neededExpand = lastBadge.offsetTop !== parent.offsetTop;
        expanded = neededExpand ? expanded : false;
        this.setState({ neededExpand, expanded });
      }
    }
  };

  spliceItems = (items, item) => items.filter(val => (val.optionId !== item.optionId || val.categoryId !== item.categoryId));

  handleExpandChange = () => {
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  render() {
    const { items, onRemove } = this.props;
    const { neededExpand, expanded } = this.state;
    const isExpandVisible = neededExpand && items.length !== 0;

    return (
      <div className="filter-selected-wrap">
        <EventListener target="window" onResize={this.updateDimensions} />
        {items.length !== 0 && (
          <button
            data-seleniumid="selected-filters-clear-button"
            type="button"
            className="filter-clear-all"
            onClick={() => onRemove([])}
          >
            Clear All
          </button>
        )}
        <div className={cn('filter-selected', { expanded })}>
          {items.map((item, i) => (
            <SelectedItem
              key={`${item.categoryId}--${item.optionId}`}
              data={item}
              ref={i === items.length - 1 ? this._lastBadge : undefined}
              onRemove={badge => {
                onRemove(this.spliceItems(items, badge));
              }}
            />
          ))}
        </div>
        {isExpandVisible && (
          <div className="filter-expand">
            <div className="filter-expand__dotted">...</div>
            <button
              data-seleniumid="selected-filters-expand-button"
              type="button"
              className={cn('filter-expand__button', { expanded })}
              onClick={this.handleExpandChange}
            >
              {expanded ? 'Collapse' : 'Expand'}
              <Svg
                className={cn('icon-carrot')}
                name={`carrot-${expanded ? 'up' : 'down'}`}
              />
            </button>
          </div>
        )}
      </div>
    );
  }
}

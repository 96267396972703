import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getCompareArrayByFieldsFunction } from 'app/utils';
import TableRow from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableRow';
import PEsSearchResult from 'app/pages/Admin/ManageTitles/components/Tabs/components/PEsSearchResult';
import TableHeader from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableHeader';
import TableSearch from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableSearch';
import './Tab.scss';

const descriptor = {
  headers: ['lastName', 'firstName', 'email'],
  sort: getCompareArrayByFieldsFunction(['lastName', 'firstName', 'email']),
  searchPlaceholder: 'Assign a user by email',
  noDataPlaceholder: 'No assigned users',
  seleniumId: 'pe',
  suggestionHeader: ['firstName', 'lastName'],
  suggestionText: 'email',
};

class PEsTab extends PureComponent {
  static propTypes = {
    fetchGroups: PropTypes.func,
    onAdd: PropTypes.func,
    onChangeSearch: PropTypes.func,
    onRemove: PropTypes.func,
    onResetUsers: PropTypes.func,
    onSearch: PropTypes.func,
    search: PropTypes.object,
    updateVendor: PropTypes.func,
    users: PropTypes.array,
    usersStats: PropTypes.object,
    vendors: PropTypes.array,
  };

  componentDidMount() {
    const { fetchGroups } = this.props;
    fetchGroups();
  }

  componentWillUnmount() {
    const { onResetUsers } = this.props;
    onResetUsers();
  }

  renderHeader = () => {
    const { headers, seleniumId } = descriptor;
    const { onRemove } = this.props;
    return (
      <TableHeader
        headers={headers}
        onRemove={onRemove}
        seleniumId={seleniumId}
      />
    );
  };

  renderRows = () => {
    const { users, onRemove, usersStats } = this.props;
    const { headers, seleniumId, sort } = descriptor;

    return sort(users).map(user => (
      <TableRow
        key={user.id}
        headers={headers}
        value={user}
        stats={usersStats[user.id]}
        seleniumId={seleniumId}
        removeHandler={onRemove(user.id)}
      />
    ),
    );
  };

  renderTable = () => {
    const { users } = this.props;
    const { noDataPlaceholder } = descriptor;

    return (
      <div className="mt-table__list-scrollable">
        <div className="mt-table__list">
          {this.renderHeader()}
          {this.renderRows()}
        </div>
        {!users.length && <div className="mt-table__no-result">{noDataPlaceholder}</div>}
      </div>
    );
  };

  renderSearch() {
    const { search, usersStats, onSearch, onChangeSearch, onAdd, vendors, updateVendor } = this.props;
    const { searchPlaceholder, seleniumId, suggestionHeader, suggestionText } = descriptor;
    return (
      <TableSearch
        {...search}
        onSearch={onSearch}
        onAdd={onAdd}
        onChangeSearch={onChangeSearch}
        seleniumId={seleniumId}
        searchPlaceholder={searchPlaceholder}
        suggestionHeader={suggestionHeader}
        suggestionText={suggestionText}
        stats={usersStats}
        TableAddRender={props => <PEsSearchResult vendors={vendors} updateVendor={updateVendor} {...props} />}
      />
    );
  }

  render() {
    return (
      <div className="mt-table__list-wrap">
        {this.renderTable()}
        {this.renderSearch()}
      </div>
    );
  }
}

export default PEsTab;

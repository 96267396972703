import { connect } from 'react-redux';
import {
  loadArticleFiles,
  loadArticleFileVersions,
  toggleFileVersionExpand,
  selectFiles,
  selectFilesError,
  selectFilesIsLoading,
} from 'app/components/ArticleContent/Files/redux/ArticleFilesDucks';
import ArticleFiles from './ArticleFiles';

export const mapStateToProps = (state = {}, { id }) => ({
  files: selectFiles(state, id),
  isLoading: selectFilesIsLoading(state),
  error: selectFilesError(state),
});

export const mapDispatchToProps = (dispatch, { id, cid, aid }) => ({
  loadArticleFiles() {
    dispatch(loadArticleFiles(id, aid, cid));
  },
  loadArticleFileVersions(fileCid, fileName) {
    dispatch(loadArticleFileVersions(id, fileCid, fileName));
  },
  toggleFileVersionExpand(fileCid, toggleVal) {
    dispatch(toggleFileVersionExpand(id, fileCid, toggleVal));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArticleFiles);

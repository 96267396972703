import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RadioButton from 'app/components/RadioButton';

class UserCard extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      firstName: PropTypes.string,
      id: PropTypes.string,
      lastName: PropTypes.string,
      userAccountId: PropTypes.string,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    seleniumid: PropTypes.string.isRequired,
  };

  handleClick = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { onSelect } = this.props;
    onSelect(id);
  };

  render() {
    const { data, isActive, seleniumid } = this.props;
    const { lastName, firstName, userAccountId, id } = data;

    return (
      <div className="user-card" onClick={this.handleClick(id)} data-seleniumid={seleniumid} data-id={id}>
        <div className="user-card__active">
          <RadioButton name="user-card" checked={isActive} />
        </div>
        <div className="user-card__info">
          <div className="user-card__user-info">
            <span className="user-card__first-name" data-seleniumid={`${seleniumid}-firstName-${firstName}`}>
              {firstName}
            </span>{' '}
            <span className="user-card__last-name" data-seleniumid={`${seleniumid}-lastName-${lastName}`}>
              {lastName}
            </span>
          </div>
          { userAccountId && <div className="user-card__userAccountId">{userAccountId}</div> }
        </div>
      </div>
    );
  }
}

export default UserCard;

import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

export const initialState = fromJS({
  hasLoading: true,
  data: {
    articleUserStatistic: [],
    issueUserStatistic: [],
    productionStagesStatistic: [],
  },
  journals: {
    hasLoading: true,
    error: null,
    items: [],
  },
});

export const FETCH = 'dashboard/fetch';
export const FETCH_SUCCESS = 'dashboard/fetch/success';
export const FETCH_FAILURE = 'dashboard/fetch/failure';
export const LOAD = 'dashboard/load';
export const LOAD_INIT = 'dashboard/load_init';

export const LOAD_JOURNALS = 'dashboard/load_journals';
export const FETCH_JOURNALS = 'dashboard/fetch_journals';
export const FETCH_JOURNALS_SUCCESS = 'dashboard/fetch_journals/success';
export const FETCH_JOURNALS_FAILURE = 'dashboard/fetch_journals/failure';

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH:
      return state.setIn(['hasLoading'], true);
    case FETCH_SUCCESS:
      return state
        .setIn(['hasLoading'], false)
        .setIn(['data'], fromJS(payload));
    case FETCH_FAILURE:
      return state.setIn(['hasLoading'], false)
        .setIn(['error'], fromJS(payload))
        .setIn(['data', 'articleUserStatistic'], fromJS([]))
        .setIn(['data', 'issueUserStatistic'], fromJS([]))
        .setIn(['data', 'productionStagesStatistic'], fromJS([]));
    case FETCH_JOURNALS:
      return state.setIn(['journals', 'hasLoading'], true);
    case FETCH_JOURNALS_SUCCESS:
      return state
        .setIn(['journals', 'hasLoading'], false)
        .setIn(['journals', 'items'], fromJS(payload));
    case FETCH_JOURNALS_FAILURE:
      return state
        .setIn(['journals', 'hasLoading'], false)
        .setIn(['journals', 'error'], fromJS(payload))
        .setIn(['journals', 'items'], fromJS([]));
    default:
      return state;
  }
};
export default reducer;

// Actions
export const initLoad = (payload) => ({ type: LOAD_INIT, payload });
export const loadData = (payload) => ({ type: LOAD, payload });
export const fetchStart = payload => ({ type: FETCH, payload });
export const fetchSuccess = payload => ({ type: FETCH_SUCCESS, payload });
export const fetchFailure = payload => ({ type: FETCH_FAILURE, payload });

export const loadJournals = (payload) => ({ type: LOAD_JOURNALS, payload });
export const fetchJournalsStart = payload => ({ type: FETCH_JOURNALS, payload });
export const fetchJournalsSuccess = payload => ({ type: FETCH_JOURNALS_SUCCESS, payload });
export const fetchJournalsFailure = payload => ({ type: FETCH_JOURNALS_FAILURE, payload });

// Selectors
export const selectDashboard = state => state.dashboardView;
export const selectArticleUserStatistic = createSelector(selectDashboard, dashboard =>
  dashboard.get('data').get('articleUserStatistic').toJS(),
);
export const selectIssueUserStatistic = createSelector(selectDashboard, dashboard =>
  dashboard.get('data').get('issueUserStatistic').toJS(),
);
export const selectProductionStagesStatistic = createSelector(selectDashboard, dashboard =>
  dashboard.get('data').get('productionStagesStatistic').toJS(),
);

export const selectUserJournals = createSelector(selectDashboard, dashboard =>
  dashboard.getIn(['journals', 'items']).toJS(),
);
export const selectJournalsHasLoading = createSelector(selectDashboard, dashboard => dashboard.getIn(['journals', 'hasLoading']));
export const selectHasLoading = createSelector(selectDashboard, dashboard => dashboard.get('hasLoading'));

import { connect } from 'react-redux';
import {
  selectCountsIsLoading,
  selectEntitiesCount,
  fetchEntitiesCount,
} from '../redux/SearchDucks';
import Search from '../Search';

const mapStateToProps = state => ({
  counts: selectEntitiesCount(state),
  countsIsLoading: selectCountsIsLoading(state),
});

export const mapDispatchToProps = dispatch => ({
  fetchEntitiesCount(search, additionalFilters) {
    dispatch(fetchEntitiesCount(search, additionalFilters));
  },
});

const SearchContainer = connect(mapStateToProps, mapDispatchToProps)(Search);

export default SearchContainer;

import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { LOAD_ARTICLE_HISTORY, UPDATE_PRODUCTION_HISTORY, CLEAR_ARTICLE_HISTORY } from './constants';

export default (state = [], action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case LOAD_ARTICLE_HISTORY:
      return state;
    case LOAD_ARTICLE_HISTORY + SUCCESS: {
      const { productionHistory = [] } = payload || {};
      return [...productionHistory];
    }
    case LOAD_ARTICLE_HISTORY + FAILURE:
      return [];
    case UPDATE_PRODUCTION_HISTORY: {
      const { id, date, type, isRaw } = payload;
      const data = isRaw ? { [`raw_${type}`]: date } : { [type]: date };

      return state.map(el => {
        if (el.id === id) return { ...el, ...data };
        return el;
      });
    }
    case CLEAR_ARTICLE_HISTORY:
      return [];
    default:
      return state;
  }
};

export const updateHistory = (payload) => ({
  type: UPDATE_PRODUCTION_HISTORY,
  payload,
});

export const getHistory = state => state.articleHistory.production;

import React from 'react';
import PropTypes from 'prop-types';

// todo DOI will be validated in Production API RES-1271
export default function LinkDOIListRender({ value: { DOIs }, seleniumId }) {
  return (
    <div data-seleniumid={seleniumId}>
      {DOIs?.length
        ? DOIs
          .map(doi => <span key={doi}>{doi}</span>)
          .reduce((prev, curr) => [prev, ', ', curr])
        : ''
      }
    </div>
  );
}

LinkDOIListRender.propTypes = {
  seleniumId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    DOIs: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

import urgency, { EMPTY_URGENCY } from 'app/enums/urgency';

export const getTooltipProps = (props = {}) => {
  const {
    className = 'tooltip',
    place = 'right',
    type = 'light',
    clickable = true,
    effect = 'solid',
    delayShow = 150,
    delayHide = 150,
    dataHtml = false,
  } = props;
  return {
    place,
    type,
    className,
    clickable,
    effect,
    delayShow,
    delayHide,
    dataHtml,
  };
};

const bubbleTypes = {
  critical: { title: urgency.critical, icon: 'critical-white', class: 'critical', text: '' },
  at_risk: { title: urgency.at_risk, icon: 'critical-white', class: 'at_risk', text: '' },
  due_today: { title: urgency.due_today, icon: 'due-today-white', class: 'due_today', text: '' },
  resolved: { title: urgency.resolved, icon: 'resolved-white', class: 'resolved', text: '' },
  [EMPTY_URGENCY.type]: { title: EMPTY_URGENCY.title, icon: 'calendar-white', class: EMPTY_URGENCY.type, text: '' },
};

export function getBubbleConfig(urgency) {
  if (!urgency) {
    return bubbleTypes[EMPTY_URGENCY.type];
  }

  if (bubbleTypes[urgency] != null) {
    return bubbleTypes[urgency];
  }
  return null;
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PageHeaderPortal from '@wiley/cpp-ui-commons/lib/components/PageHeader/PageHeaderPortal';
import { connect } from 'react-redux';
import FieldsList from 'app/components/PageField/FieldsList';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import { getDefaultLabelledField } from 'app/components/PageField/FieldUtils';
import { load, selectHasLoading, selectVersion } from './redux/VersionDucks';

class Version extends PureComponent {
  static propTypes = {
    hasLoading: PropTypes.bool,
    load: PropTypes.func,
    serverVersion: PropTypes.object,
  };

  componentDidMount() {
    const { load } = this.props;
    load();
  }

  getUiProps = () => {
    const version = process.env.APP__VERSION;
    const commitHash = process.env.APP__GITHASH;
    const buildNumber = process.env.APP__BUILD_NUMBER;
    const date = process.env.APP__BUILD_DATE;

    return [
      getDefaultLabelledField('Version', `${version}-${buildNumber}`),
      getDefaultLabelledField('Git hash:', commitHash),
      getDefaultLabelledField('Build time:', date),
    ];
  };

  getServerProps = () => {
    const { serverVersion } = this.props;
    const { application, version, environment, instance, serverAddress, buildTime } = serverVersion;

    return [
      getDefaultLabelledField('Application:', application),
      getDefaultLabelledField('Version:', version),
      getDefaultLabelledField('Environment:', environment),
      getDefaultLabelledField('Instance:', instance),
      getDefaultLabelledField('Server Address:', serverAddress),
      getDefaultLabelledField('Build Time:', buildTime),
    ];
  };

  render() {
    const uiProps = this.getUiProps();
    const serverProps = this.getServerProps();

    const { hasLoading } = this.props;

    return (
      <>
        <PageHeaderPortal inside="top-header" title="Version" />
        <h1>Wiley Production Dashboard</h1>
        <div className="row mt-4">
          <div className="col-md-6">
            <h4>UI</h4>
            <FieldsList fields={uiProps} />
          </div>
          <div className="col-md-6">
            <h4>BE</h4>
            <SectionLoader hasLoading={hasLoading}>
              <FieldsList fields={serverProps} />
            </SectionLoader>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  state => ({
    hasLoading: selectHasLoading(state),
    serverVersion: selectVersion(state),
  }),
  {
    load,
  },
)(Version);

import { fromJS } from 'immutable';
import {
  PARTICIPANT_ADD,
  PARTICIPANT_REMOVE,
  PARTICIPANT_EDIT,
} from 'app/pages/Admin/api/admin';
import { alreadyAddedMSGs, alreadyAddedPluralMSGs, ENTITY_NOT_FOUND_MSG } from 'app/pages/Admin/ManageTitles/common/constants';
import { NO_ACTION_MESSAGE } from 'app/enums/commonErrors';
import { FAILURE, SUCCESS } from 'app/redux/asyncMiddleware';


export const ASSIGN_USER = 'admin/manage-titles/assign';
export const UNASSIGN_USER = 'admin/manage-titles/unassign';

export const SEARCHING_USER = 'admin/manage-titles/searching';
export const SEARCH_USER = 'admin/manage-titles/search';

export const CREATE_USER = 'admin/manage-titles/create';
export const EDIT_USER = 'admin/manage-titles/edit-user';
export const RESET_CREATING = 'admin/manage-titles/reset-creating';

export const RESET_USERS_STATS = 'admin/manage-titles/reset-users-stats';
export const MANAGE_TITLES_UPDATE_VENDOR = 'admin/manage-titles/update-vendor';

export const defaultUserState = {
  isLoading: false,
  error: null,
};

export const initUser = fromJS(defaultUserState);

const defaultSearch = {
  items: [],
  searchText: '',
  isLoading: false,
  error: null,
};

export const defaultState = {
  search: defaultSearch,
  create: {
    isLoading: false,
    error: null,
  },
};

export const initialState = fromJS(defaultState);

const getUniqueSearchedUsers = (assignedUsers, searchedUsers) => (assignedUsers ? searchedUsers.filter(({ id }) => !assignedUsers.includes(id)) : searchedUsers);

const getSearchSuccessErrorMessage = (searchedUsers, uniqueUsers, userType) => {
  if (!searchedUsers.length) return ENTITY_NOT_FOUND_MSG;
  if (uniqueUsers.length) return null;

  return searchedUsers.length > 1 ? alreadyAddedPluralMSGs[userType] : alreadyAddedMSGs[userType];
};
const getStateWithResetUsers = (state) => fromJS({
  search: state.get('search'),
  create: state.get('create'),
});
const createInnerPath = (root) => (path = '') => {
  const searchPath = [root];
  return path ? searchPath.concat(path) : searchPath;
};


export default (state = initialState, action) => {
  const { type, payload } = action;
  const searchPath = createInnerPath('search');
  const createPath = createInnerPath('create');

  switch (type) {
    case ASSIGN_USER:
    case UNASSIGN_USER:
    case MANAGE_TITLES_UPDATE_VENDOR: {
      const { id } = action;
      return getStateWithResetUsers(state)
        .updateIn([id], () => initUser.set('isLoading', true))
        .setIn(searchPath('error'), null);
    }
    case ASSIGN_USER + SUCCESS: {
      const { id } = action;
      return state
        .updateIn(searchPath('items'), searchItems => searchItems.filter(item => item.get('id') !== id))
        .setIn([id, 'isLoading'], false);
    }
    case UNASSIGN_USER + SUCCESS: {
      const { id } = action;
      return state
        .setIn([id, 'isLoading'], false);
    }
    case MANAGE_TITLES_UPDATE_VENDOR + SUCCESS: {
      const { id } = action;
      return state
        .updateIn(searchPath('items'), searchItems => searchItems.map(item => (item.get('id') === payload.id ? fromJS(payload) : item)))
        .setIn([id, 'isLoading'], false);
    }
    case ASSIGN_USER + FAILURE:
    case UNASSIGN_USER + FAILURE:
    case EDIT_USER + FAILURE:
    case MANAGE_TITLES_UPDATE_VENDOR + FAILURE: {
      const { id } = action;
      return state
        .setIn([id, 'isLoading'], false)
        .setIn([id, 'error'], payload.error);
    }
    case SEARCH_USER: {
      return getStateWithResetUsers(state)
        .setIn(searchPath('isLoading'), true)
        .setIn(searchPath('items'), fromJS([]));
    }
    case SEARCH_USER + SUCCESS: {
      const { assignedUsers, payload: searchedUsers, userType } = action;
      const uniqueSearched = getUniqueSearchedUsers(assignedUsers, searchedUsers);
      return state
        .setIn(searchPath('isLoading'), false)
        .setIn(searchPath('items'), fromJS(uniqueSearched))
        .setIn(searchPath('error'), getSearchSuccessErrorMessage(searchedUsers, uniqueSearched, userType));
    }
    case SEARCH_USER + FAILURE: {
      const { error } = payload;
      return state
        .setIn(searchPath('isLoading'), false)
        .setIn(searchPath('items'), fromJS([]))
        .setIn(searchPath('error'), error);
    }

    case SEARCHING_USER: {
      const { searchText, error } = payload;
      return state
        .setIn(searchPath('searchText'), searchText)
        .updateIn(searchPath('items'), items => ((error || !searchText) ? fromJS([]) : items))
        .setIn(searchPath('error'), error);
    }

    case CREATE_USER: {
      return getStateWithResetUsers(state)
        .setIn(createPath('isLoading'), true)
        .updateIn(searchPath(), () => fromJS(defaultSearch));
    }
    case CREATE_USER + SUCCESS: {
      return state
        .setIn(createPath('isLoading'), false)
        .setIn(searchPath('items'), fromJS(payload))
        .setIn(searchPath('searchText'), payload?.[0]?.email || '');
    }
    case CREATE_USER + FAILURE: {
      const { error } = payload;
      return state
        .setIn(createPath('isLoading'), false)
        .setIn(createPath('error'), error);
    }
    case RESET_CREATING: {
      return state.setIn(createPath('error'), null);
    }
    case RESET_USERS_STATS: {
      const { userId } = payload;
      if (!userId) {
        return getStateWithResetUsers(state);
      }
      return state.removeIn([userId]);
    }
    case EDIT_USER: {
      const { id } = action;
      return state.setIn([id, 'isLoading'], true);
    }
    case EDIT_USER + SUCCESS: {
      const { id, payload } = action;
      return state
        .setIn([id, 'isLoading'], false)
        .setIn([id, 'error'], null)
        .updateIn(searchPath('items'), items => (
          fromJS(items.toJS().map(item => (item.id === id ? Object.assign({}, item, payload) : item)))
        ));
    }
    default:
      return state;
  }
};

// Actions
const changeParticipant = ({ type, payload, asyncProps, effect }) => ({
  type,
  payload,
  asyncCall: () => effect(asyncProps),
  transformError: ({ response: { data } = {} }) => ({
    error: data?.message || NO_ACTION_MESSAGE(asyncProps.action),
  }),
});

export const unassignUser = ({ journalId, userType, id, asyncProps, effect }) => changeParticipant({
  type: UNASSIGN_USER,
  payload: { journalId, userType, id },
  asyncProps: {
    ...asyncProps,
    action: PARTICIPANT_REMOVE,
    journalId,
  },
  effect,
});

export const editUser = ({ asyncProps, id, journalId, userType, effect }) =>
  changeParticipant({
    type: EDIT_USER,
    payload: { journalId, userType, id },
    asyncProps: {
      ...asyncProps,
      action: PARTICIPANT_EDIT,
    },
    effect,
  });

export const assignUser = ({ journalId, userType, id, asyncProps, effect }) => changeParticipant({
  type: ASSIGN_USER,
  payload: { journalId, userType, id },
  asyncProps: {
    ...asyncProps,
    action: PARTICIPANT_ADD,
    journalId,
  },
  effect,
});

export const searchingUser = ({ journalId, userType, searchText, error }) => ({
  type: SEARCHING_USER,
  payload: { searchText, error },
  journalId,
  userType,
});

export const searchUser = ({ journalId, userType, params, effect, assignedUsers }) => ({
  type: SEARCH_USER,
  journalId,
  userType,
  assignedUsers,
  payload: { params, effect },

});

export const createUser = ({ journalId, userType, fields, effect }) => ({
  type: CREATE_USER,
  payload: { journalId, userType },
  asyncCall: () => effect(fields),
  transformResult: (entity) => ([entity]),
  transformError: ({ response: { data } = {} }) => ({
    error: data?.message || NO_ACTION_MESSAGE('create'),
  }),
});

export const resetCreating = ({ journalId, userType }) => ({
  type: RESET_CREATING,
  journalId,
  userType,
});

export const resetUsersStats = ({ journalId, userType }, userId) => ({
  type: RESET_USERS_STATS,
  journalId,
  userType,
  payload: { userId },
});


export const updateVendor = ({ journalId, userType, user, vendorId }, effect) => ({
  type: MANAGE_TITLES_UPDATE_VENDOR,
  payload: { id: user.id, journalId, userType },
  asyncCall: () => effect({ userId: user.id, vendorId }),
  transformResult: res => ({ ...user, ...res }),
  transformError: e => ({ error: (typeof e === 'string') ? e : e?.response?.data.message }),
});

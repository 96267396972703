import { get, isEmpty } from 'lodash';
import { detailedEndpoints } from 'app/redux/api/common';
import {
  articleMetadataOptions,
  articleMetaDataTypes,
} from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';

export const updateByPaths = (paths, result, itemState) => paths
  .reduce((acc, path) => acc.setIn(['data', ...path.split('.')], get(result, path, null)), itemState)
  .get('data')
  .toJS();

export const preProcess = (store, data = {}) => {
  const { jpcmsProperties = {} } = data;
  if (store !== detailedEndpoints.articleDetailed || isEmpty(jpcmsProperties)) return data;

  const receivedTocValue = jpcmsProperties[articleMetaDataTypes.TOC_CATEGORY];
  const parsedTocValue = get(articleMetadataOptions, [articleMetaDataTypes.TOC_CATEGORY, receivedTocValue]) || null;

  return {
    ...data,
    jpcmsProperties: {
      ...jpcmsProperties,
      [articleMetaDataTypes.TOC_CATEGORY]: parsedTocValue,
    },
  };
};

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Modal, ModalAction } from '@wiley/cpp-ui-commons/lib/components/Modal';
import useFeatures from 'app/utils/hooks/useFeatures';

import ArticleHistory from './ArticleHistory';
import WarningPanel from './components/WarningPanel';
import './index.scss';


const ArticleHistoryWrap = ({ routeHistory, url, id }) => {
  const { features: { historyDelayView }, isLoading } = useFeatures();
  const closeModal = () => {
    routeHistory.push(url.replace('/history', '/'), { avoidScrollToTop: true });
  };

  return (
    <Modal
      onLayerClick={closeModal}
      className="history-modal"
    >
      <ModalAction>
        <div className={cn('history-modal__header', { isWide: !!historyDelayView })}>
          {(historyDelayView && !isLoading) ? <WarningPanel /> : <div /> }
          <button
            className="history-modal__close-button"
            data-seleniumid="history-modal-close-button"
            type="button"
            onClick={closeModal}
          >
            &times;
          </button>
        </div>
      </ModalAction>
      <div className="history-modal__content">
        <ArticleHistory
          id={id}
        />
      </div>
    </Modal>
  );
};


ArticleHistoryWrap.propTypes = {
  id: PropTypes.string.isRequired,
  routeHistory: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
};


export default ArticleHistoryWrap;

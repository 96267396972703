import { EventEmitter } from 'fbemitter';

export const emitter = new EventEmitter();

let currentScrollData = {};
emitter.addListener('scroll', data => {
  currentScrollData = data;
});

export const getCurrentScrollData = () => currentScrollData;

export default emitter;

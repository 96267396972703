import React from 'react';
import PropTypes from 'prop-types';
import './RadioButton.scss';


const RadioButton = (props) => (
  <label className="radio-button">
    <input type="radio" {...props} readOnly />
    <span className="radio" />
  </label>
);

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
};

export default RadioButton;

import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { stringDateToDateModifierCustomFormat } from 'app/utils';
import './PaymentDetails.scss';

export default class paymentDetails extends PureComponent {
  static propTypes = {
    config: PropTypes.shape({
      paymentDate: PropTypes.string,
      paymentMethod: PropTypes.string,
    }),
  };

  render() {
    const { config } = this.props;
    if (!config) {
      return (<div data-seleniumid="payment-details">N/A</div>);
    }
    const { paymentMethod, paymentDate } = config;
    return (
      <div>
        <div className="payment-details__title">Payment Method:</div>
        <div className={cn('payment-details__value', 'payment-details__paymentMethod')} data-seleniumid="payment-details__paymentMethod">
          {paymentMethod || 'N/A'}
        </div>
        <div className="payment-details__title">Payment Date:</div>
        <div className="payment-details__value" data-seleniumid="payment-details__paymentDate">
          {paymentDate ? stringDateToDateModifierCustomFormat(paymentDate, false) : 'N/A'}
        </div>
      </div>
    );
  }
}

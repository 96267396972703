/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const TextareaInput = ({ value, onChange, className = '', placeholder = '' }) =>
  (
    <textarea
      autoFocus
      className={cn('edit-field-textarea-input', className)}
      data-seleniumid="edit-field-textarea-input"
      value={value || ''}
      onChange={e => onChange(value, e.target.value)}
      placeholder={placeholder}
    />
  );

TextareaInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default TextareaInput;

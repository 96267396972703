import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';

const DELAY = 500;
class FilterInput extends PureComponent {
  constructor(props) {
    super(props);
    const { item: { value, categoryId } } = props;
    this.state = {
      value: value || '',
    };
    this.inputRef = React.createRef();
    this.seleniumId = `filter-input-item-${categoryId}`;
  }

  static propTypes = {
    inputProps: PropTypes.shape({
      maxLength: PropTypes.number,
    }),
    item: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      optionId: PropTypes.string.isRequired,
      parentId: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    type: PropTypes.string,
    valueModifier: PropTypes.func,
  };

  static defaultProps = {
    type: 'text',
    inputProps: {},
    valueModifier: (v) => v,
  };

  componentDidUpdate(prevProps) {
    const { item: { value: prevValue } } = prevProps;
    const { item: { value } } = this.props;
    if (prevValue !== value && !value) {
      this.setState({ value: '' });
    }
  }

  saveValue = debounce(() => {
    const { item, onSelect } = this.props;
    const { value } = this.state;
    onSelect({ ...item, value });
  }, DELAY);

  onInputChange = (e) => {
    const { valueModifier } = this.props;
    const { value: currentValue } = this.state;
    const newValue = valueModifier(e.target.value);

    if (newValue !== currentValue) {
      this.setState({
        value: newValue,
      }, this.saveValue);
    }
  };

  render() {
    const { value } = this.state;
    const { type, inputProps } = this.props;

    return (
      <input
        data-seleniumid={this.seleniumId}
        placeholder="Input Value"
        ref={this.inputRef}
        className="filter-input"
        type={type}
        onChange={this.onInputChange}
        value={value}
        {...inputProps}
      />
    );
  }
}

export default FilterInput;

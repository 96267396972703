import React from 'react';
import PropTypes from 'prop-types';
import { mainUrgencyEnum } from 'app/enums/urgency';
import FilterTabs from 'app/components/FilterTabs/index';

class UrgencyFilter extends React.PureComponent {
  static propTypes = {
    onChangeFilter: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.string,
        count: PropTypes.number,
        optionId: PropTypes.string,
        parentId: PropTypes.string,
      }),
    ),
    selectedFilters: PropTypes.array.isRequired,
  };

  get tabs() {
    const { selectedFilters } = this.props;
    let { options } = this.props;

    if (!options.length) {
      options = [
        { categoryId: 'urgency', count: 0, optionId: 'resolved', parentId: null },
        { categoryId: 'urgency', count: 0, optionId: 'due_today', parentId: null },
        { categoryId: 'urgency', count: 0, optionId: 'critical', parentId: null },
        { categoryId: 'urgency', count: 0, optionId: 'at_risk', parentId: null },
      ];
    }

    const urgencyOptions = options.filter(option => option.categoryId === 'urgency');
    const urgencySelected = selectedFilters.filter(val => val.categoryId === 'urgency');
    const tabsOptions = [];

    tabsOptions.push({
      valueText: 'All',
      active: selectedFilters.length === 0,
    });

    Object.keys(mainUrgencyEnum).forEach(key => {
      const urgency = urgencyOptions.find(urgency => urgency.optionId === key);
      if (urgency) {
        const active = selectedFilters.length > 1 ? false : Boolean(urgencySelected.find(item => (item.categoryId === urgency.categoryId && item.optionId === urgency.optionId)));
        tabsOptions.push({ ...urgency, active, valueText: mainUrgencyEnum[key] });
      }
    });

    return tabsOptions;
  }

  handleClick = urgencyFilter => {
    const { valueText, active, ...filter } = urgencyFilter;
    const { onChangeFilter } = this.props;
    onChangeFilter(filter?.categoryId ? [filter] : []);
  };

  render() {
    return <FilterTabs tabList={this.tabs} onClick={this.handleClick} />;
  }
}

export default UrgencyFilter;

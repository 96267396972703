import { stringDateToDateModifier, daysDateModifier } from 'app/utils';
import {
  getDefaultLabelledField,
  getDefaultMultiLabelledField,
} from 'app/components/PageField/FieldUtils';
import {
  ListColumnRender,
} from 'app/components/PageField/Render/index';
import { formatISSN } from 'app/pages/DetailedViews/DetailedIssuesView/common/utils';

export default function transformIssueDetailedInfo(data) {
  const leftPropsCol = [
    getDefaultLabelledField('Journal ID:', data.journalAcronym),
    getDefaultLabelledField('Publication Year:', data.publicationYear ? data.publicationYear.toString() : null),
    getDefaultMultiLabelledField([
      getDefaultLabelledField('Volume:', data.volumeNumber),
      getDefaultLabelledField('Issue:', data.issue),
    ]),
    getDefaultLabelledField('ISSN:', formatISSN(data.ISSN)),
    getDefaultLabelledField('eISSN:', formatISSN(data.eISSN)),
  ];

  const rightPropsCol = [
    {
      className: 'column field--with-label-min-width',
      title: { text: 'TS Vendor(s):' },
      value: { expand: true, items: data.TSVendors },
      ValueRender: ListColumnRender,
      seleniumId: 'ts-vendors',
    },
    // temporarily removed in CMH-2134 for 1.2 release
    // {
    //   className: 'field--with-label-min-width',
    //   title: { text: 'Printer:' },
    //   value: { text: data.printer || '' },
    //   seleniumId: 'printer',
    // },
    // {
    //   className: 'field--with-label-min-width',
    //   title: { text: 'Distributor:' },
    //   value: { text: data.distributor || '' },
    //   seleniumId: 'distributor'
    // },
  ];

  const currentTaskCol = [];

  if (data?.taskCurrent) {
    currentTaskCol.push({
      title: { text: data.taskCurrent ? data.taskCurrent.name : '' },
      seleniumId: 'current-task',
    });
  }
  currentTaskCol.push({
    title: { text: 'Days to complete:' },
    value:
        data?.taskCurrent?.daysToComplete
        || data?.taskCurrent?.daysToComplete === 0
        || data?.taskCurrent?.daysToComplete === '0'
          ? { text: daysDateModifier(data.taskCurrent.daysToComplete) }
          : { text: '' },
    icon: 'calendar',
    seleniumId: 'days-to-complete',
  });

  const projectedOnlineDate = [
    {
      title: { text: 'Due date:' },
      value: data.projectedOnlineDate
        ? { text: stringDateToDateModifier(data.projectedOnlineDate.dueDate) }
        : { text: '' },
      icon: 'calendar',
      seleniumId: 'online-date-due-date',
    },
    {
      title: { text: 'Original date:' },
      value: data.projectedOnlineDate
        ? { text: stringDateToDateModifier(data.projectedOnlineDate.originalDate) }
        : { text: '' },
      icon: 'calendar',
      seleniumId: 'online-date-original-date',
    },
  ];

  const projectedPrintDate = [
    {
      title: { text: 'Due date:' },
      value: data.projectedPrintDate
        ? { text: stringDateToDateModifier(data.projectedPrintDate.dueDate) }
        : { text: '' },
      icon: 'calendar',
      seleniumId: 'print-date-due-date',
    },
    {
      title: { text: 'Original date:' },
      value: data.projectedPrintDate
        ? { text: stringDateToDateModifier(data.projectedPrintDate.originalDate) }
        : { text: '' },
      icon: 'calendar',
      seleniumId: 'print-date-original-date',
    },
  ];


  return {
    leftPropsCol,
    rightPropsCol,
    currentTaskCol,
    projectedOnlineDate,
    projectedPrintDate,
  };
}

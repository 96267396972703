import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './DiagramSummary.scss';

export default class DiagramSummary extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.shape({
      filterId: PropTypes.string,
      filterValue: PropTypes.number,
    })),
    seleniumPart: PropTypes.string,
  };

  render() {
    const { config, items, seleniumPart } = this.props;
    const total = items ? items.reduce((acc, item) => (acc + item.filterValue), 0) : 0;

    return (
      <div className="diagram-summary">
        <div className="diagram-summary__header">
          <span className="diagram-summary__header-count">{total}</span>
          <span className="diagram-summary__header-text">total</span>
        </div>
        <div className="row">
          {Object.keys(config).map((configKey, i) => {
            const conf = config[configKey];
            const item = items ? items.find(val => val.filterId === configKey) : null;

            const title = conf?.title || 'N/A';
            const color = conf?.color || '#ffffff';
            const hasValue = !(!item || item.filterValue === null || item.filterValue === undefined);

            const showLink = item?.filterValue > 0;
            const Wrapper = showLink ? Link : 'div';

            return (
              <div key={item?.filterId || i} className="col-md-4">
                <Wrapper
                  to={conf?.link || ''}
                  className={cn('diagram-summary__item', { disabled: !showLink })}
                  data-seleniumid={`${seleniumPart}-diagram-summary-${configKey}-item`}
                >
                  <div className="diagram-summary__item-left-group">
                    <div className="diagram-summary__item-color" style={{ backgroundColor: color }} />
                    <div className="diagram-summary__item-title" data-seleniumid={`${seleniumPart}-diagram-summary-${configKey}-title`}>
                      {title}
                    </div>
                  </div>
                  <div className="diagram-summary__item-amount" data-seleniumid={`${seleniumPart}-diagram-summary-${configKey}-value`}>
                    {!hasValue ? 'N/A' : item?.filterValue}
                  </div>
                </Wrapper>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

export const initialState = fromJS({
  hasLoading: false,
  data: {},
});

export const FETCH = 'version/fetch';
export const FETCH_SUCCESS = 'version/fetch/success';
export const FETCH_FAILURE = '/version/fetch/failure';
export const LOAD = '/version/load';

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH:
      return state.setIn(['hasLoading'], true);
    case FETCH_SUCCESS:
      return state.setIn(['hasLoading'], false).setIn(['data'], fromJS(payload));
    case FETCH_FAILURE:
      return state.setIn(['hasLoading'], false).setIn(['error'], fromJS(payload));
    default:
      return state;
  }
};
export default reducer;

// Actions
export const fetchStart = () => ({ type: FETCH });
export const fetchSuccess = payload => ({ type: FETCH_SUCCESS, payload });
export const fetchFailure = payload => ({ type: FETCH_FAILURE, payload });

export const load = () => ({ type: LOAD, payload: {} });

// Selectors
export const selectStore = state => state.version;

export const selectVersion = createSelector(selectStore, version => version.get('data').toJS());

export const selectHasLoading = createSelector(selectStore, version => version.get('hasLoading'));

import { takeLatest, delay } from 'redux-saga/effects';
import isWithinRange from 'date-fns/isWithinInterval';
import { toast } from 'react-toastify';
import { INIT_APP_ACTION } from 'app/redux/commonActions';

const REQUEST_TIMEOUT = 18e5;
const DEFAULT_MESSAGE = 'We are currently under maintenance!';
const LS_KEY_PREFIX = 'system-message-acknowledged';

function* systemMessageWorker() {
  while (true) {
    try {
      const systemMessageJSON = yield fetch(`${window.location.origin}/systemMessage.json`).then(response => {
        const didGetJsonResponse = response.headers
          .get('Content-Type')
          .includes('application/json');

        if (!response.ok || !didGetJsonResponse) {
          throw TypeError('Failed to fetch');
        }

        return response.json();
      });

      const { dateStart = 0, dateFinish = 0, message = DEFAULT_MESSAGE, id = 'notification' } = systemMessageJSON;
      const toastId = `${LS_KEY_PREFIX}-${id}`;

      const shouldDisplayMessage = (
        !localStorage.getItem(toastId)
        && !toast.isActive(toastId)
        && isWithinRange(
          Date.now(),
          {
            start: dateStart,
            end: dateFinish,
          },
        )
      );

      if (shouldDisplayMessage) {
        toast.info(
          ...(Array.isArray(message) ? message : [message]),
          {
            autoClose: false,
            toastId,
            onClose: () => {
              toast.dismiss(toastId);
              localStorage.setItem(toastId, 'true');
            },
            position: toast.POSITION.BOTTOM_LEFT,
          },
        );
      }
    }
    catch (e) {
      const shouldRemoveMessageFromLS = e instanceof TypeError;
      if (shouldRemoveMessageFromLS) {
        Object.keys(localStorage).forEach((key) => {
          if (key.includes(LS_KEY_PREFIX)) {
            localStorage.removeItem(key);
          }
        });
      }
    }

    yield delay(REQUEST_TIMEOUT);
  }
}

export function* watchSystemMessage() {
  yield takeLatest(INIT_APP_ACTION, systemMessageWorker);
}

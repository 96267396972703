import { useEffect, useState } from 'react';
import { getIsProductionUpdated } from 'app/pages/ArticleHistory/redux/articleHistoryDucks';
import { useSelector } from 'react-redux';

const useArticleHistoryState = () => {
  const isProductionUpdated = useSelector(getIsProductionUpdated);
  const [isUpdated, setIsUpdated] = useState(isProductionUpdated || false);

  useEffect(() => {
    if (isProductionUpdated && !isUpdated) setIsUpdated(true);
  }, [isProductionUpdated]);

  return { isUpdated };
};

export default useArticleHistoryState;

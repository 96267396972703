import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Table from 'app/containers/Table';
import { LOAD_ERROR_MSG } from 'app/constants';
import { toast } from 'react-toastify';
import { getArticleFilesDescriptor } from 'app/components/ArticleContent/common/filesDescriptor';
import Preview from 'app/components/ArticleContent/components/Preview';
import ExpandButton from 'app/components/ExpandButton';
import history from '@wiley/cpp-ui-commons/lib/router/history';
import appPath from 'app/router/path';
import DetailedSection from 'app/components/DetailedSection';
import { CACHED, FAILED } from 'app/constants/contentCacheStatuses';
import TableWrap from '../components/TableWrap';
import InfoView from '../components/InfoView';
import '../common/ArticlesFiles.scss';

export const SHOW_LIMIT = 10;
const STICKY_ID = 'files-stickable-container';

const ArticleFiles = ({
  aid,
  cid,
  loadArticleFiles,
  loadArticleFileVersions,
  toggleFileVersionExpand,
  files,
  isLoading,
  isCaching,
  error,
  cacheStatus,
  match: { url, path },
}) => {
  const [showAll, setShowAll] = useState(false);
  const [fileList, setFileList] = useState([]);
  const showExpandToggle = files?.length > SHOW_LIMIT;

  useEffect(() => {
    if (cid && cacheStatus === CACHED) loadArticleFiles();
  }, [cid, cacheStatus]);

  useEffect(() => {
    if (aid && cacheStatus === FAILED) {
      toast.error(LOAD_ERROR_MSG(`${aid} content`), { toastId: `${aid}-content`, autoClose: 5000 });
    }
  }, [aid, cacheStatus]);

  useEffect(() => {
    const resultFilesList = [];
    for (const file of files.slice(0, showAll ? files.length : SHOW_LIMIT)) {
      resultFilesList.push({
        ...file,
        fileName: { isVersion: false, name: file.fileName },
        icon: { isVersion: false, formatMnemonic: file.formatMnemonic },
        action: { isExpanded: file.isExpanded, id: file.id },
      });
      if (file.versions && file.isExpanded) {
        file.versions.forEach((version) => {
          resultFilesList.push({
            ...version,
            fileName: { isVersion: true, name: version.fileName },
            icon: { isVersion: true, formatMnemonic: version.formatMnemonic },
          });
        });
      }
      if (!file.versions?.length && file.isExpanded) {
        resultFilesList.push({
          message: 'No Versions Available',
          customDescriptor: [{ id: 'message' }],
          className: 'table-row--message',
        });
      }
    }
    setFileList(resultFilesList);
  }, [files, showAll]);

  const toggleFileVersions = (fileCid, fileName) => {
    const el = fileList.find((f) => (f.id === fileCid));
    if (el.isExpanded === undefined) {
      loadArticleFileVersions(fileCid, fileName);
    }
    else {
      toggleFileVersionExpand(fileCid, !el.isExpanded);
    }
  };

  const isError = !!(error || cacheStatus === FAILED);
  return (
    <SectionLoader
      hasLoading={isLoading || isCaching}
      className="article-content__loader"
    >
      <div data-seleniumid="article-content" className="article-content">
        { isError
          ? <InfoView message={LOAD_ERROR_MSG(`${aid} content`)} />
          : (
            <div className="article-versions__body">
              {files?.length > 0 ? (
                <DetailedSection title="Article Content">
                  <TableWrap stickyId={STICKY_ID}>
                    <Table
                      className="limit-list"
                      items={fileList}
                      descriptor={getArticleFilesDescriptor({ toggleFileVersions, history, url })}
                      headerProps={{ stickyId: STICKY_ID }}
                    />
                  </TableWrap>
                  {showExpandToggle ? (
                    <div className="d-flex justify-content-left ml-1 mt-3">
                      <ExpandButton
                        seleniumId="article-content-show-more-toggle"
                        isExpanded={showAll}
                        onClick={() => setShowAll(!showAll)}
                      />
                    </div>
                  ) : null}
                </DetailedSection>
              ) : (
                <InfoView message="Article has no content in CMS yet" />
              )}
            </div>
          )
        }
      </div>
      <Route exact path={`${path}${appPath.preview}`} render={props => <Preview {...props} />} />
    </SectionLoader>
  );
};

ArticleFiles.propTypes = {
  aid: PropTypes.string,
  cacheStatus: PropTypes.string,
  cid: PropTypes.string,
  error: PropTypes.string,
  files: PropTypes.array.isRequired,
  isCaching: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadArticleFiles: PropTypes.func.isRequired,
  loadArticleFileVersions: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      article_id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  toggleFileVersionExpand: PropTypes.func.isRequired,
};

export default ArticleFiles;

export const getPageName = () => document.querySelector('title').innerText.trim();

let previousEvent;

export const setAnalyticsUser = ({ email = '', firstName = '', lastName = '' } = {}) => {
  if (!window.digitalData) {
    window.digitalData = {};
  }
  window.digitalData.user = { email, firstName, lastName };
};
export const clearAnalyticsUser = () => {
  if (!window.digitalData) {
    window.digitalData = {};
  }
  window.digitalData.user = null;
};

export const fireEvent = (eventData = {}) => {
  const newEvent = {
    eventInfo: {
      pageName: eventData.pageName ?? getPageName(),
      eventName: eventData.eventName ?? 'pageView',
      eventData,
    },
  };
  if (!previousEvent
  || !previousEvent.eventInfo
  || previousEvent.eventInfo.pageName !== newEvent.eventInfo.pageName
  || previousEvent.eventInfo.eventName !== newEvent.eventInfo.eventName
  ) {
    window.digitalData.event.push(newEvent);
    previousEvent = newEvent;
  }
};

// TODO: Need to check and remove this function
// export const initPageMutationObserver = () => {
//   const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
//
//   if (MutationObserver) {
//     const observer = new MutationObserver(() => fireEvent());
//
//     observer.observe(document, {
//       subtree: true,
//       childList: true,
//     });
//   }
// };

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './TabSelector.scss';

export default class TabSelector extends PureComponent {
  static propTypes = {
    activeTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    header: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onTabChange: PropTypes.func,
  };

  static defaultProps = {
    className: '',
  };

  onTabClick = (id) => {
    const { onTabChange } = this.props;
    if (onTabChange) {
      onTabChange(id);
    }
  };

  render() {
    const { header, items, activeTabId, className } = this.props;

    return (
      <div className={cn('base-tabs', className)}>
        {header && <div className="base-tabs__header">{header}</div>}
        <div className="base-tabs__list">
          {items.map((item, i) => (
            <div
              key={item.id ? `tab-${item.id}` : `tab-${i}`}
              data-seleniumid={item.id ? `tab-${item.id}` : `tab-${i}`}
              className={cn('base-tabs__list-button', { active: (item.id || i) === activeTabId })}
              onClick={() => this.onTabClick(item.id || i, i)}
            >
              {item.text}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

import axiosInstance from 'app/services/axiosInstance';
import axios from 'axios';
import { forOwn } from 'lodash';
import { waitForSpinners } from './trackPageLoading';

const TIMEOUT_FOR_MULTIPLE_REQUESTS = 500;
let timeoutForClassPresence;

const requestsTokensSourceInfo = {};

const generateRequestID = config => `${config.url}|${Date.now()}`;

const saveRequestTokenSource = (id, tokenSource) => {
  requestsTokensSourceInfo[id] = tokenSource;
};

const removeRequestTokenSource = id => {
  if (requestsTokensSourceInfo[id]) delete requestsTokensSourceInfo[id];
};

// only for unit tests
export const clearRequestTokenSources = () => {
  Object.keys(requestsTokensSourceInfo).forEach(id => delete requestsTokensSourceInfo[id]);
};

const handleResponse = () => {
  timeoutForClassPresence = setTimeout(async () => {
    if (Object.keys(requestsTokensSourceInfo).length === 0) {
      try {
        await waitForSpinners();
      }
      catch (e) {
        // eslint-disable-next-line
        console.log('waiting for spinners has failed', e);
      }
      document.body.classList.remove('fetch-data-on');
    }
  }, TIMEOUT_FOR_MULTIPLE_REQUESTS);
};

axiosInstance.interceptors.request.use(config => {
  if (timeoutForClassPresence) {
    clearTimeout(timeoutForClassPresence);
  }

  document.body.classList.add('fetch-data-on');

  const requestId = generateRequestID(config);
  const tokenSource = axios.CancelToken.source();
  saveRequestTokenSource(requestId, tokenSource);

  return { ...config, requestId, cancelToken: tokenSource.token };
});

axiosInstance.interceptors.response.use(
  res => {
    handleResponse();
    removeRequestTokenSource(res?.config?.requestId);

    return Promise.resolve(res);
  },
  err => {
    handleResponse();
    removeRequestTokenSource(err?.config?.requestId || err?.message);

    return Promise.reject(err);
  },
);

export const cancelRequests = () => {
  forOwn(requestsTokensSourceInfo, ({ cancel }, key) => {
    cancel(key);
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';

import './Checkbox.scss';

const Checkbox = ({ checked = false, label, onClick, seleniumId, ...props }) => {
  const { disabled } = props;

  function handleOnChange() {
    if (onClick) {
      onClick(!checked);
    }
  }

  return (
    <label data-seleniumid={seleniumId} className={cn('checkbox', { disabled, checked })}>
      <input {...props} checked={checked} onChange={handleOnChange} type="checkbox" />
      <Svg className="checkbox-marker" data-seleniumid={`${seleniumId}-label`} name={checked ? 'checkbox-checked' : 'checkbox-not-checked'} />
      <span data-seleniumid={`${seleniumId}-label`} className="checkbox-label">
        {label}
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.any,
  onClick: PropTypes.func,
  seleniumId: PropTypes.string,
};

export default Checkbox;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table from 'app/containers/Table';
import TableStatsLayout from '@wiley/cpp-ui-commons/lib/components/Table/TableStatsLayout';
import SelectedFilters from 'app/components/SelectedFilters';
import Filter from 'app/components/Filter';
import Stickable from 'app/components/Stickable';
import StickableContainer from 'app/components/Stickable/StickyContainer';

import './FilterTableView.scss';

export default class FilterTableView extends PureComponent {
  static propTypes = {
    allPagesSizes: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    descriptor: PropTypes.array.isRequired,
    entitiesError: PropTypes.any,
    entitiesHasLoading: PropTypes.bool,
    facetsCategories: PropTypes.arrayOf(PropTypes.string),
    facetsError: PropTypes.any,
    facetsHasLoading: PropTypes.bool,
    facetsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.string,
        count: PropTypes.number,
        optionId: PropTypes.string,
        parentId: PropTypes.string,
      }),
    ),
    hideFilter: PropTypes.bool,
    items: PropTypes.array.isRequired,
    itemsCount: PropTypes.number,
    onApplySelectedFilters: PropTypes.func.isRequired,
    onChangeCount: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeTable: PropTypes.func.isRequired,
    onRemoveSelectedFilters: PropTypes.func.isRequired,
    onUpdateFilters: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    renderError: PropTypes.func,
    renderNoResults: PropTypes.func,
    selectedFilters: PropTypes.array.isRequired,
    sortDir: PropTypes.string,
    sortField: PropTypes.string,
    stats: PropTypes.shape({
      currentPage: PropTypes.number,
      pageSize: PropTypes.number,
      pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
      sortDir: PropTypes.string,
      sortField: PropTypes.string,
    }),
    tempFacets: PropTypes.object,
    totalRecords: PropTypes.number.isRequired,
  };

  static defaultProps = {
    facetsCategories: [],
    facetsOptions: [],
    facetsHasLoading: false,
  };

  componentDidUpdate() {
    const {
      onChangePage,
      items,
      itemsCount,
      pageSize,
    } = this.props;

    const hasItems = Boolean(items.length);

    if (!hasItems && itemsCount) {
      const lastPage = Math.ceil(itemsCount / pageSize) || 1;
      onChangePage(lastPage);
    }
  }

  renderError = () => {
    const {
      renderError,
      renderNoResults,
      entitiesError,
      items,
      entitiesHasLoading,
    } = this.props;

    const hasItems = Boolean(items.length);

    if (hasItems || entitiesHasLoading) {
      return null;
    }

    if (entitiesError && renderError) {
      return renderError(entitiesError);
    }

    if (renderNoResults) {
      return renderNoResults();
    }

    return null;
  };

  render() {
    const {
      allPagesSizes,
      currentPage,
      descriptor,
      items,
      onApplySelectedFilters,
      onChangeCount,
      onChangeTable,
      onRemoveSelectedFilters,
      onUpdateFilters,
      pageSize,
      selectedFilters,
      sortDir,
      sortField,
      totalRecords,
      hideFilter,
      facetsCategories,
      facetsError,
      facetsOptions,
      facetsHasLoading,
      tempFacets,
    } = this.props;

    return (
      <StickableContainer>
        <Stickable>
          <div className="table-filter" style={{ background: 'white' }}>
            <TableStatsLayout
              allPageSizes={allPagesSizes}
              currentPage={currentPage}
              currentPageSize={pageSize}
              onCountChoose={onChangeCount}
              recordsCount={items.length}
              totalRecords={totalRecords}
            />
            {hideFilter ? null : (
              <>
                <SelectedFilters items={selectedFilters} onRemove={onRemoveSelectedFilters} />
                <Filter
                  categories={facetsCategories}
                  options={facetsOptions}
                  initialSelected={selectedFilters}
                  isLoading={facetsHasLoading}
                  onApply={onApplySelectedFilters}
                  onUpdate={onUpdateFilters}
                  onClearAll={() => onRemoveSelectedFilters([])}
                  error={facetsError}
                  tempFacets={tempFacets}
                />
              </>
            )}
          </div>
        </Stickable>
        {this.renderError()}
        <Table
          descriptor={descriptor}
          items={items}
          onChange={onChangeTable}
          sortDir={sortDir}
          sortField={sortField}
        />
      </StickableContainer>
    );
  }
}

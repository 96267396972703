import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import EventListener from 'react-event-listener';

export const SidebarContext = React.createContext();

export const STATE_HIDDEN = 'hidden';
export const STATE_HAMBURGER = 'hamburger';
const WIDTH_SMALL = 992;

export const withSidebar = Component => {
  const ModifiedComponent = class extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        hasOpened: props.hasOpened ? props.hasOpened : false,
        menuModeState: props.menuModeState ? props.menuModeState : STATE_HIDDEN,
      };
    }

    static propTypes = {
      hasOpened: PropTypes.bool,
      menuModeState: PropTypes.string,
    };

    componentDidMount() {
      this.updateDimensions();
    }

    handleChange = (hasOpened) => {
      this.setState({ hasOpened });
    };

    updateDimensions = () => {
      if (window.innerWidth < WIDTH_SMALL) {
        this.setState({ menuModeState: STATE_HAMBURGER });
      }
      else {
        this.setState({ menuModeState: STATE_HIDDEN });
      }
    };

    render() {
      const { hasOpened, menuModeState } = this.state;

      const data = {
        handleChange: this.handleChange,
        hasOpened,
        menuModeState,
      };

      return (
        <SidebarContext.Provider value={data}>
          <EventListener target="window" onResize={this.updateDimensions} />
          <Component {...this.props} {...data} />
        </SidebarContext.Provider>
      );
    }
  };

  ModifiedComponent.displayName = `withSidebar(${Component.displayName || Component.name})`;
  return hoistNonReactStatics(ModifiedComponent, Component);
};

import React from 'react';
import PropTypes from 'prop-types';
import NA from './EmptyRender';
import Field from '../Field';
import './FieldListRender.scss';

export default function FieldListRender({ value: { items = [] }, seleniumId = '' }) {
  if (!items || items.length === 0) {
    return <NA seleniumId={seleniumId} />;
  }
  return (
    <div className="fields-array">
      { items.map(({ title, value, TitleRender, ValueRender, icon, seleniumId }, index) => (
        <Field
          seleniumId={seleniumId}
          key={index}
          title={title}
          value={value}
          TitleRender={TitleRender}
          ValueRender={ValueRender}
          icon={icon}
        />
      )) }
    </div>
  );
}


FieldListRender.propTypes = {
  seleniumId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
};

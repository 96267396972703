import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ExpandableList.scss';

const DEFAULT_LIMIT_TO_SHOW = 5;
const MORE_TYPE = 'more';
const LESS_TYPE = 'less';

class ExpandableList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMore: true,
      showLimit: props.limit,
    };
  }

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    className: PropTypes.string,
    limit: PropTypes.number,
    seleniumId: PropTypes.string,
    staticHeight: PropTypes.bool,
  };

  static defaultProps = {
    limit: DEFAULT_LIMIT_TO_SHOW,
    className: '',
    seleniumId: '',
    staticHeight: false,
  };

  showMore = () => {
    const { children } = this.props;

    this.setState({
      showMore: false,
      showLimit: children.length,
    });
  };

  showLess = () => {
    const { limit } = this.props;

    this.setState({
      showMore: true,
      showLimit: limit,
    });
  };

  renderButton(type, handler) {
    const {
      seleniumId,
    } = this.props;

    return (
      <span
        className={`expandable-list__show-${type}`}
        onClick={handler}
        data-seleniumid={`${seleniumId}-show-${type}-button`}
      >
        {`Show ${type}`}
      </span>
    );
  }

  render() {
    const {
      limit,
      children,
      className,
      staticHeight,
    } = this.props;

    const { showLimit, showMore } = this.state;

    if (!Array.isArray(children)) {
      return children;
    }

    const items = children.slice(0, showLimit);

    return (
      <div className={cn('expandable-list', className, staticHeight && !showMore ? 'static-height' : '')}>
        {items.map((item, index) => (
          <div className="expandable-list_item" key={index}>{item}</div>
        ))}
        {showMore && children.length > showLimit && this.renderButton(MORE_TYPE, this.showMore)}
        {!showMore && children.length > limit && this.renderButton(LESS_TYPE, this.showLess)}
      </div>
    );
  }
}

export default ExpandableList;

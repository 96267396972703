import { call, put, takeEvery } from 'redux-saga/effects';
import { apiCall } from 'app/services/api';
import { detailedEndpoints } from 'app/redux/api/common';
import { LOAD, fetchSuccess, fetchFailure, fetchStart } from './DetailedDucks';

const getDetailedById = (storeType, id) => apiCall(`${storeType}/${id}`);

const articlePostProcess = (res = {}) => {
  const { generalComments, jpcmsProperties } = res;
  if (!generalComments) res.generalComments = [];
  if (!jpcmsProperties) res.jpcmsProperties = {};
};

export function* fetchData(action) {
  const { payload } = action;
  const context = { ...payload };

  yield put(fetchStart({ context }));

  let res;
  try {
    res = yield call(getDetailedById, payload.storeType, payload.params.id);
  }
  catch (e) {
    yield put(fetchFailure({ result: e, context }));
    return;
  }

  const { storeType } = context;
  if (storeType === detailedEndpoints.articleDetailed) {
    articlePostProcess(res);
  }

  yield put(fetchSuccess({ result: res || {}, context }));
}

export function* watchDetailedNew() {
  yield takeEvery(LOAD, fetchData);
}

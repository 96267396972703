import React from 'react';
import PropTypes from 'prop-types';
import Categorize from 'app/components/Categorize';
import ManageUserCard from '../ManageUserCard/ManageUserCard';

const ManageUsersList = ({ users, onSelectRole, onSelectVendor, seleniumId, vendors, ...props }) => {
  function renderCategory(category) {
    return <div className="manage-users__category">{category}</div>;
  }

  function renderList(users) {
    const vendorsOptions = vendors.map(({ id, name }) => ({ label: name, value: id }));
    return (
      <div className="user-list row">
        {users.map(user => (
          <div key={user.id} className="col-md-3 mb-4">
            <ManageUserCard
              seleniumId={seleniumId}
              data={user}
              onSelectRole={payload =>
                onSelectRole({ user, ...payload })
              }
              onSelectVendor={vendor =>
                onSelectVendor({
                  user,
                  vendor: vendor.value,
                })
              }
              vendors={vendorsOptions}
              {...props}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <Categorize
      items={users}
      renderCategory={renderCategory}
      renderList={renderList}
      categoryCreator={user => user.lastName[0].toUpperCase()}
      sortCategories={users => users.sort()}
    />
  );
};

ManageUsersList.propTypes = {
  onSelectRole: PropTypes.func,
  onSelectVendor: PropTypes.func,
  seleniumId: PropTypes.string,
  users: PropTypes.array,
  vendors: PropTypes.array,
};

export default ManageUsersList;

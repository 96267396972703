import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_INIT,
  LOAD,
  LOAD_JOURNALS,

  loadData,

  fetchSuccess,
  fetchFailure,
  fetchStart,
  fetchJournalsStart,
  fetchJournalsSuccess,
  fetchJournalsFailure,
} from 'app/pages/Dashboard/redux/DashboardDucks';
import { getQueryParams, pushQueryParams, setQueryParams, stringify } from '@wiley/cpp-ui-commons/lib/router/history';
import ls from 'app/services/localStorage';
import path from 'app/router/path';
import { historySpy } from 'app/redux/sagas/utils/historyUtilsSagas';
import { getDashboard, getJournals } from '../api/dashboard';

const availableParams = {
  acronym: 'exactJournalAcronym',
};

const transformRequestParams = (params) => Object.keys(params).reduce((acc, paramKey) => {
  const paramValue = params[paramKey];
  const relevantParamKey = availableParams[paramKey] || paramKey;
  acc[relevantParamKey] = paramValue;
  return acc;
}, {});

const getLSName = id => `dashboard-${id}`;
const getValidParams = (validFields, source) => validFields.reduce((acc, paramName) => {
  if (source[paramName]) {
    acc[paramName] = source[paramName];
  }
  return acc;
}, {});

export function* initFetch({ payload: { userId } }) {
  const currentQuery = getValidParams(Object.keys(availableParams), getQueryParams());
  const savedQuery = ls.getJSON(getLSName(userId)) || {};

  const query = {
    ...savedQuery,
    ...currentQuery,
  };
  yield put(loadData({ userId, query }));

  const loadProps = { userId, ignoreHistory: true };

  yield call(historySpy, {
    pathname: path.dashboard,
    onBack: function* back(search) {
      yield put(loadData({ query: search, ...loadProps }));
    },
    onReset: function* reset() {
      const query = ls.getJSON(getLSName(userId)) || {};
      yield put(loadData({ query, ...loadProps }));
    },
  });
}

export function addSearchQuery(storageId, params, ignoreHistory) {
  const savedQuery = ls.getJSON(storageId) || {};

  if (stringify(params) !== stringify(savedQuery)) {
    ls.setJSON(storageId, params);
    if (!ignoreHistory) {
      pushQueryParams(params);
    }
  }
  else {
    setQueryParams(params);
  }
}

export function* fetchJournals(action) {
  const { payload: { userId } } = action;
  yield put(fetchJournalsStart());

  try {
    const items = yield call(getJournals(userId));
    yield put(fetchJournalsSuccess(items));
  }
  catch (e) {
    yield put(fetchJournalsFailure({ ...e }));
  }
}

export function* fetchData(action) {
  const { payload: { userId, query, ignoreHistory } } = action;
  const validParams = getValidParams(Object.keys(availableParams), query);
  addSearchQuery(getLSName(userId), validParams, ignoreHistory);

  yield put(fetchStart());
  try {
    const statistic = yield call(getDashboard(userId), transformRequestParams(validParams));
    yield put(fetchSuccess({ ...statistic }));
  }
  catch (e) {
    yield put(fetchFailure({ ...e }));
  }
}

export function* watchDashboard() {
  yield takeLatest(LOAD_INIT, initFetch);
  yield takeLatest(LOAD, fetchData);
  yield takeLatest(LOAD_JOURNALS, fetchJournals);
}

import path from 'app/router/path';
import { configGenerator } from 'app/pages/Dashboard/common/utils';
import { createFilter } from 'app/utils/filters';

const articlesTiles = {
  categoryName: 'Articles in Progress',
  itemName: 'Task',
  itemPluralName: 'Tasks',
  items: {
    articlesRequireEVQA: {
      icon: 'qa',
      title: 'Needs EV QA',
      moreLink: {
        pathname: path.inProgressArticles,
        filters: [createFilter('productionStages', 'evQA')],
      },
    },
    articlesDueToday: {
      icon: 'due-today',
      title: 'Article tasks due today',
      moreLink: {
        pathname: path.requiresActionArticles,
        storeName: 'requiresActionArticles',
        filters: [createFilter('urgency', 'due_today')],
      },
    },
    articlesOverdue: {
      icon: 'overdue',
      title: 'Article tasks overdue',
      moreLink: {
        pathname: path.requiresActionArticles,
        filters: [createFilter('urgency', 'overdue')],
      },
    },
    articlesLateLicenses: {
      icon: 'failures',
      title: 'Late Licenses',
      moreLink: {
        pathname: path.requiresActionArticles,
        filters: [createFilter('urgency', 'late_licenses')],
      },
    },
  },
};

const issuesTiles = {
  categoryName: 'Issues in Progress',
  itemName: 'Issue',
  itemPluralName: 'Issues',
  items: {
    issuesDueToday: {
      icon: 'due-today',
      title: 'Issue tasks due today',
      moreLink: {
        pathname: path.requiresActionIssues,
        filters: [createFilter('urgency', 'due_today')],
      },
    },
    issuesOverdue: {
      icon: 'overdue',
      title: 'Issue tasks overdue',
      moreLink: {
        pathname: path.requiresActionIssues,
        filters: [createFilter('urgency', 'overdue')],
      },
    },
    issuesDueThisMonth: {
      icon: 'due-today',
      title: 'Issue due this month',
      moreLink: {
        pathname: path.inProgressIssues,
        filters: [createFilter('schedule', 'dueThisMonth')],
      },
    },
    issuesInProgress: {
      icon: 'in-progress',
      title: 'Issues in progress',
      moreLink: {
        pathname: path.inProgressIssues,
      },
    },
  },
};

export const getArticlesConfig = additionalFilters => {
  const { items, ...props } = articlesTiles;
  return {
    ...props,
    items: configGenerator(items, 'moreLink', additionalFilters),
  };
};

export const getIssuesConfig = additionalFilters => {
  const { items, ...props } = issuesTiles;
  return {
    ...props,
    items: configGenerator(items, 'moreLink', additionalFilters),
  };
};

export const adminTiles = {
  categoryName: '',
  items: {
    manageTitles: {
      icon: 'admin-manage-titles',
      title: 'Manage Titles',
      moreText: 'Manage',
      moreLink: path.adminManageTitles,
      moreLinkAlwaysEnabled: true,
    },
    manageUsers: {
      icon: 'admin-manage-users',
      title: 'Manage Users',
      moreText: 'Manage',
      moreLink: path.adminManageUsers,
      moreLinkAlwaysEnabled: true,
    },
  },
};

export function isEqualFilter(item) {
  return val => (val.optionId === item.optionId) && (val.categoryId === item.categoryId);
}
export function checkDisableForApply({ selected, options }) {
  const allFilterCounts = options ? options.reduce((acc, val) => {
    acc[val.optionId] = val.count;
    return acc;
  }, {}) : {};
  const isCountable = ({ optionId }) => typeof allFilterCounts[optionId] !== 'undefined';
  const isCountAboveZero = ({ optionId }) => allFilterCounts[optionId] > 0;

  const selectedCountableFilters = selected.filter(isCountable);
  const selectedNotCountableFilters = selected.filter((option) => !isCountable(option));
  const allCountableFilters = options.filter(isCountable);

  const allCountableHaveEmptyResults = allCountableFilters.filter(isCountAboveZero).length === 0;
  const allSelectedCountableHaveEmptyResults = selectedCountableFilters.filter(isCountAboveZero).length === 0;
  const selectedOnlyNotCountable = selectedNotCountableFilters.length === selected.length && selected.length > 0;

  return !(
    (!selected.length && !allCountableHaveEmptyResults)
    || (selectedOnlyNotCountable && !allCountableHaveEmptyResults)
    || (!selectedOnlyNotCountable && !allSelectedCountableHaveEmptyResults)
  );
}

export const getNumericalValueForFilter = value => {
  const strValue = (typeof value === 'string' || value instanceof String) ? value : '';
  const match = strValue.match(/\d+/g);
  return match?.length ? match.join('') : '';
};

import { useState, useEffect } from 'react';

const INTERVAL = 15000;

const useIntervalRequest = ({ endpoint, interval = INTERVAL, params = {} }) => {
  const [response, setResponse] = useState(null);

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const resp = await endpoint(params);
          setResponse(resp);
        }
        catch (e) {
          setResponse(null);
        }
      };

      fetchData();
      const requestInterval = setInterval(fetchData, interval);
      return () => { clearInterval(requestInterval); };
    },
    [],
  );

  return response;
};

export default useIntervalRequest;

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

class Categorize extends PureComponent {
  static propTypes = {
    categoryCreator: PropTypes.func,
    items: PropTypes.array,
    renderCategory: PropTypes.func,
    renderList: PropTypes.func,
    sortCategories: PropTypes.func,
  };

  initGroups() {
    const { items } = this.props;

    return items.reduce((acc, item) => {
      const category = this.createCategory(item);
      (acc[category] = acc[category] || []).push(item);

      return acc;
    }, {});
  }

  createCategory = item => {
    const { categoryCreator } = this.props;

    return categoryCreator(item);
  };

  renderCategory = category => {
    const { renderCategory } = this.props;

    return renderCategory(category);
  };

  renderList = (list, category) => {
    const { renderList } = this.props;

    return renderList(list, category);
  };

  render() {
    const { sortCategories } = this.props;
    const groups = this.initGroups();
    const categories = sortCategories(Object.keys(groups));

    return (
      <>
        {
          categories.map(category => (
            <Fragment key={category}>
              {this.renderCategory(category)}
              {this.renderList(groups[category], category)}
            </Fragment>
          ))
        }
      </>
    );
  }
}

export default Categorize;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const INTERVAL_MS = 10000;

const useIntervalRequest = ({ condition = true, action, intervalMs = INTERVAL_MS, params = {} }) => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (!condition) return;

      dispatch(action(params));
      const requestInterval = setInterval(() => {
        dispatch(action(params));
      }, intervalMs);
      return () => {
        if (requestInterval) clearInterval(requestInterval);
      };
    },
    [condition],
  );
};

export default useIntervalRequest;

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import ClickAwayListener from 'app/components/ClickAwayListener';
import TextareaInput from 'app/components/PageField/Edit/TextareaInput';
import EditField from 'app/components/PageField/Edit';
import { articleMetaDataTypes } from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';
import { isSelection } from 'app/utils';
import CommentExpand, { getShortText } from './CommentExpand';

import './Comments.scss';

const PLACE_HOLDER = 'Type in here...';

const EditComment = ({ title, text, id, updateMetaData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFullText, setStatus] = useState(false);

  const shortText = getShortText(text);

  const onClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isLoading || isEditing || isSelection()) return;
    if (!isEditing) setIsEditing(true);
  }, [isLoading, isEditing]);

  const onEditing = useCallback((state) => {
    if (state && !isEditing) setIsEditing(true);
    if (!state) setIsEditing(false);
  }, [isEditing]);

  const onClickAway = useCallback(() => {
    if (isEditing) setIsEditing(false);
  }, [isEditing]);

  return (
    (
      <ClickAwayListener onClickAway={onClickAway}>
        <div
          className={cn('article-comments__comment', { edit: true, editing: isEditing || isLoading, empty: !text })}
          onClick={onClick}
          data-seleniumid="general-comment"
        >
          <div>
            <Svg name="comments" className="article-comments__icon" />
          </div>
          <div className="article-comments__comment-section">
            <div className="article-comments__comment-header">
              {title}
            </div>
            <EditField
              ignoreEmptyView
              component={TextareaInput}
              value={text}
              type={articleMetaDataTypes.GENERAL_COMMENT}
              entityId={id}
              onUpdate={updateMetaData}
              onLoading={setIsLoading}
              forceEditing={isEditing}
              ignoreClickOutside
              ignoreKeySave
              inputProps={{
                className: 'edit-comment-textarea',
              }}
              onEditing={onEditing}
            >
              <div className="article-comments__comment-content">
                <div className={cn('article-comments__comment-body', { isPlaceHolder: !text })}>
                  { shortText && !showFullText ? shortText : (text || PLACE_HOLDER) }
                </div>
                {!isEditing ? (
                  <CommentExpand
                    shortText={shortText}
                    onClick={setStatus}
                    isOpen={showFullText}
                  />
                ) : null}
              </div>
            </EditField>
          </div>
        </div>
      </ClickAwayListener>
    )
  );
};


EditComment.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  updateMetaData: PropTypes.func.isRequired,
};

export default EditComment;

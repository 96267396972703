import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import './EditableDateCellControls.scss';

const EditableDateCellControls = ({ className = '', children, onClick = () => {}, actions, seleniumId }) => (
  <div
    className={cn('edit-cell-controls-wrap', className)}
    onClick={onClick}
    data-seleniumid={`${seleniumId}-viewing`}
  >
    <div className="edit-cell-controls">
      <span className="edit-cell-controls__content">{children}</span>
      <span className="edit-cell-controls__button-wrap">
        {actions.filter(el => !!el).map(({ id, title, onClick, className = '' }) => (
          <span
            key={id}
            title={title}
            data-seleniumid={`${seleniumId}-${title.toLowerCase()}-button`}
            onClick={onClick}
            className={cn('edit-cell-button', className)}
          >
            <Svg
              name={id}
            />
          </span>
        ))}
      </span>
    </div>
  </div>
);

EditableDateCellControls.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
  })),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  seleniumId: PropTypes.string.isRequired,
};

export default EditableDateCellControls;

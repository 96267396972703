import { formatByExtention, formatByMnemonic } from 'app/components/ArticleContent/common/fileFormats';
import FileIconCell from 'app/components/ArticleContent/components/FileIconCell';
import React from 'react';

const UNKNOWN = 'unknown';

const getExtention = fileName => fileName?.length && fileName.includes('.') && fileName?.split('.')?.pop();

export const getFileFormatByMnemonicOrExtention = (formatMnemonic = UNKNOWN, fileName = {}) => formatByMnemonic[formatMnemonic]
  || formatByExtention[getExtention(fileName?.name || UNKNOWN) || UNKNOWN] || {};

export const fileIconDateModifier = ({ isVersion, formatMnemonic }, id, { fileName }) => {
  const icon = getFileFormatByMnemonicOrExtention(formatMnemonic, fileName)?.icon;

  if (icon) {
    return ({
      title: formatMnemonic,
      icon: <FileIconCell fileType={icon} isVersion={isVersion} />,
    });
  }

  return ({
    title: 'Unknown format',
    icon: <FileIconCell fileType="unknown" isVersion={isVersion} />,
  });
};

import React from 'react';
import PropTypes from 'prop-types';

const TableRowButton = ({ onClick, seleniumId, text, className = '' }) => (
  <div
    data-seleniumid={seleniumId}
    className={className}
    onClick={onClick}
  >
    {text}
  </div>
);

TableRowButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  seleniumId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TableRowButton;

import { fromJS } from 'immutable';
import { FAILURE, SUCCESS } from 'app/redux/asyncMiddleware';
import { createSelectorCreator } from 'reselect';
import memoize from 'memoize-immutable';

export const SEARCH_JOURNALS = 'admin/manage-titles/journals-search';

export const initialState = fromJS({
  isLoading: false,
  error: null,
});

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_JOURNALS:
      return initialState.set('isLoading', true);
    case SEARCH_JOURNALS + SUCCESS: {
      return state.set('isLoading', false);
    }
    case SEARCH_JOURNALS + FAILURE:
      return initialState
        .set('error', fromJS(payload.error));
    default:
      return state;
  }
};

// Actions
export const searchJournals = (acronym = '') => ({
  type: SEARCH_JOURNALS,
  payload: { acronym },
});

// Selectors
const createSelector = createSelectorCreator(memoize);
const selectState = createSelector(state => state, state => state.admin.manageTitles.state);

export const selectIsLoading = createSelector(selectState, journalsInfo => journalsInfo?.get('isLoading'));
export const selectError = createSelector(selectState, journalsInfo => journalsInfo?.get('error'));

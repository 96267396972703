import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { AUTH_TOKEN_KEY } from 'app/modules/Auth/constants';

export const getToken = () => cookie.get(AUTH_TOKEN_KEY);

export const setToken = token => cookie.set(AUTH_TOKEN_KEY, token);

export const removeToken = () => cookie.remove(AUTH_TOKEN_KEY, { path: '' });

export const getPtpId = (token) => {
  try {
    const { ptpid } = jwtDecode(token) || {};
    return ptpid || null;
  }
  catch (e) {
    return null;
  }
};

export const isAuthorized = (token = getToken()) => {
  if (!token) {
    return false;
  }

  try {
    jwtDecode(token);

    return true;
  }
  catch (e) {
    // eslint-disable-next-line
    console.error(e);

    return false;
  }
};

export default {
  getToken,
  setToken,
  isAuthorized,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withAuthProvider } from 'app/modules/Auth';
import path from 'app/router/path';

const RpmLink = () => (
  <a
    style={{
      color: 'black',
      fontWeight: 200,
    }} href="https://rpm.wiley.com/"
  >
  RPM
  </a>
);

export class Login extends PureComponent {
  static propTypes = {
    authError: PropTypes.object,
    history: PropTypes.object,
    initialLoginFields: PropTypes.object,
    isAuthorized: PropTypes.bool,
    isAuthorizing: PropTypes.bool,
    login: PropTypes.func,
  };

  static defaultProps = {
    initialLoginFields: {},
  };

  componentDidMount() {
    const { history, isAuthorized } = this.props;

    let remainingTime = 15000;
    setInterval(() => {
      if (remainingTime <= 0) {
        window.location.href = 'https://rpm.wiley.com';
      }
      else {
        document.querySelector('#timer-redirect').innerText = remainingTime / 1000;
        remainingTime -= 1000;
      }
    }, 1000);

    if (isAuthorized) {
      history.push(path.default);
    }
    else {
      toast.dismiss();
    }
  }


  render() {
    return (
      <div className="login">
        <Helmet>
          <title>CMH | Login</title>
        </Helmet>
        <div className="login-content">
          <h2 className="login-title">CMH has now been decommissioned</h2>
          <div className="login-decom">
            With the launch of Research Publication Manager (<RpmLink />), CMH is now decommissioned and is no longer accessible to users.
          </div>
          <div className="login-decom">
            New features will be added to RPM incrementally to support the full scope of business requirements.
          </div>
          <div className="login-decom">
            You will be automatically redirected in <span id="timer-redirect">15</span> seconds
          </div>
          <div className="login-decom">
            Thank you.
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withAuthProvider,
)(Login);

import { connect } from 'react-redux';
import roles from 'app/enums/roles';
import SwitchView from '../SwitchView';
import {
  fetchUsers,
  setSwitchView,
  unsetSwitchView,
  selectIsLoading,
  selectError,
  selectUsers,
  selectTotalAmount,
  selectUser,
} from '../redux/SwitchViewDucks';

const mapStateToProps = state => ({
  isLoading: selectIsLoading(state),
  error: selectError(state),
  users: selectUsers(state),
  user: selectUser(state),
  totalAmount: selectTotalAmount(state),
});

const mapDispatchToProps = dispatch => ({
  fetchUsers(params) {
    dispatch(fetchUsers({
      ...params,
      dashboardUserRoles: roles.productionEditor,
    }));
  },

  switchTo(user) {
    dispatch(setSwitchView(user));
  },

  reject() {
    dispatch(unsetSwitchView());
  },
});

const SwitchViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SwitchView);

export default SwitchViewContainer;

import roles from 'app/enums/roles';
import { ASC_SORT } from 'app/services/api/sort';
import { createAlphabet } from 'app/utils';

export const WORKS_FOR_WILEY = 'wiley';
export const CV_SUPER_MSG = 'A user cannot be both assigned to a vendor and designated as a superuser. <br>Please check and correct the vendor assignment or superuser status.';
export const ROLE_TABS = [
  {
    id: '',
    valueText: 'All',
    roles: [roles.systemAdministrator, roles.productionEditor, roles.productionManager],
  },
  {
    id: 'su',
    valueText: 'Superusers',
    roles: [roles.systemAdministrator],
  },
  {
    id: 'pe',
    valueText: 'Production Editors',
    roles: [roles.productionEditor],
  },
];
export const ALPHABET_OPTIONS = [{ value: '', label: '...' }, ...createAlphabet().map(char => ({ value: char, label: char }))];
export const SIZES = [25, 50, 100];
export const DEFAULT_SIZE = 25;

export const SEARCH_PLACEHOLDER = 'Search by email';
export const SELECT_LETTER_PLACEHOLDER = 'Select';
export const DEFAULT_USERS_SORT_FIELD = 'lastName';
export const DEFAULT_USERS_SORT_DIR = ASC_SORT;

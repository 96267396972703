import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './LicenseDetails.scss';
import { stringDateToDateModifier } from 'app/utils';
import sanitize from 'sanitize-html';

const RESET_STATUS_WARNING_MESSAGE = 'Warning: The License data displayed here may be out of sync due to a License reset. Please check WALS for current details.';

export default class LicenseDetails extends PureComponent {
  static propTypes = {
    config: PropTypes.shape({
      copyrightLine: PropTypes.string,
      licenseSigningDate: PropTypes.string,
      licenseStatement: PropTypes.string,
      licenseStatus: PropTypes.string,
      licenseType: PropTypes.string,
      walsLicenseStatus: PropTypes.string,
    }),
  };

  render() {
    const { config } = this.props;
    if (!config) {
      return (<div data-seleniumid="license-details">N/A</div>);
    }
    const { copyrightLine, licenseSigningDate, licenseStatement, licenseType, walsLicenseStatus } = config;
    return (
      <div>
        {walsLicenseStatus?.toLowerCase() === 'reset' ? (
          <div
            data-seleniumid="reset-status-warning-message"
            className="license-details__message"
          >
            {RESET_STATUS_WARNING_MESSAGE}
          </div>
        ) : ''}
        <div className="license-details__title">License Type:</div>
        <div className="license-details__value" data-seleniumid="license-details__licenseType">
          {licenseType || 'N/A'}
        </div>
        <div className="license-details__title">License Signing Date:</div>
        <div className="license-details__value" data-seleniumid="license-details__licenseSigningDate">
          {licenseSigningDate ? stringDateToDateModifier(licenseSigningDate) : 'N/A'}
        </div>
        <div className="license-details__title">License Statement:</div>
        <div className="license-details__value" data-seleniumid="license-details__licenseStatement">
          {licenseStatement ? <div dangerouslySetInnerHTML={{ __html: sanitize(licenseStatement) }} /> : 'N/A'}
        </div>
        <div className="license-details__title">Copyright Line:</div>
        <div className="license-details__value" data-seleniumid="license-details__copyrightLine">
          {copyrightLine || 'N/A'}
        </div>
      </div>
    );
  }
}

import React, { useState, useRef, useEffect } from 'react';
import { parseDatePickerDate } from 'app/utils/utils';
import PropTypes from 'prop-types';
import { isSameMinute } from 'date-fns';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import isAfter from 'date-fns/isAfter';
import Datepicker from 'app/components/Datepicker';
import './CMHFlowSelect.scss';
import Checkbox from 'app/components/Checkbox';

const CMHFlowSelect = ({ journalId, updateInCMHSinceDate, inCMHSinceDate }) => {
  const [date, setDate] = useState(inCMHSinceDate);
  const datepickerRef = useRef(null);
  const isChangedDate = date ? !isSameMinute(date, inCMHSinceDate) : false;

  useEffect(() => {
    setDate(inCMHSinceDate);
  }, [inCMHSinceDate]);

  return (
    <div className="mt-journal__included-in-flow">
      <div className="mt-journal__included-in-flow-checkbox">
        <Checkbox
          checked={isAfter(new Date(), inCMHSinceDate)}
          disabled={true}
          seleniumId="in-cmh-date-checkbox"
          label="Participate in CMH"
        />
      </div>
      <div className="select-date-wrapper">
        <div className="select-date">
          <div
            data-seleniumid="select-in-cmh-date-input-container"
            className={cn('select-date-popup', { 'not-select-dated': isChangedDate })}
          >
            <Datepicker
              ref={datepickerRef}
              className="select-in-cmh-date-input"
              calendarClassName="select-date-calendar"
              mode="dp-below"
              placeholderText="Select date"
              selected={inCMHSinceDate}
              dateFormat="MM/dd/yyyy"
              shouldCloseOnSelect={false}
              onChange={date => setDate(date)}
              title="Feature disabled. Raise a support request to enable CMH flow."
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>

  );
};

CMHFlowSelect.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  disabled: PropTypes.bool,
  inCMHSinceDate: PropTypes.instanceOf(Date),
  journalId: PropTypes.string,
  updateInCMHSinceDate: PropTypes.func.isRequired,
};

CMHFlowSelect.defaultProps = {
  disabled: false,
};

export default CMHFlowSelect;

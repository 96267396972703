import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NA from './EmptyRender';

export default function LinkRender({ value: { text, emptyText, link, blank }, seleniumId }) {
  if (!text && !link) {
    return <NA seleniumId={seleniumId} value={emptyText} />;
  }
  if (blank) {
    return <a data-seleniumid={seleniumId} target="_blank" href={link}>{text || link}</a>;
  }
  return <Link data-seleniumid={seleniumId} to={link}>{text || link}</Link>;
}

LinkRender.propTypes = {
  seleniumId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    blank: PropTypes.bool,
    emptyText: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    text: PropTypes.string,
  }).isRequired,
};

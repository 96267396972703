import React from 'react';
import PropTypes from 'prop-types';
import TextRender from './TextRender';
import NA from './EmptyRender';

export default function ListRender({ seleniumId, value: { items, ItemRender = TextRender, emptyText } }) {
  if (!items?.length) {
    return <NA seleniumId={seleniumId} value={emptyText} />;
  }

  return (
    <div>
      {items.map((item, i) => (
        <span key={`${seleniumId}-${i}`}>
          {i > 0 ? ', ' : ''}
          <ItemRender value={item} seleniumId={`${seleniumId}-${i}`} />
        </span>
      ))}
    </div>
  );
}

ListRender.propTypes = {
  seleniumId: PropTypes.string.isRequired,
  value: PropTypes.shape({
    emptyText: PropTypes.string,
    ItemRender: PropTypes.any,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

const EmptyRender = ({ seleniumId = '', value = 'N/A' }) => <div data-seleniumid={seleniumId}>{value}</div>;

EmptyRender.propTypes = {
  seleniumId: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default EmptyRender;

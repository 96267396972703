import React, { PureComponent } from 'react';
import cn from 'classnames';
import onClickOutside from 'react-onclickoutside';
import roles from 'app/enums/roles';
import { Link } from 'react-router-dom';
import { AuthContext } from 'app/modules/Auth';
import Login from 'app/pages/Login/components/LoginForm';
import Permission from 'app/modules/Auth/components/Permission';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import { profileMenuConfig } from './common/profileMenuConfig';
import { adminMenuConfig } from './common/adminMenuConfig';
import './Profile.scss';

export class Profile extends PureComponent {
  state = { isPopupOpened: false };

  profileClickHandle = () => {
    const { isPopupOpened } = this.state;
    this.setState({ isPopupOpened: !isPopupOpened });
  };

  handleClickOutside = () => {
    this.hideMenu();
  };

  hideMenu = () => {
    this.setState({ isPopupOpened: false });
  };

  renderLogged({ user, logoutByClick }) {
    const { isPopupOpened } = this.state;

    if (!isPopupOpened) {
      return null;
    }

    const username = `${user.firstName} ${user.lastName}`;

    return (
      <div data-seleniumid="profile-block" className="profile-popup">
        <div className="profile-popup__user-wrap">
          <div className="profile-popup__name" title={username}>
            {user.firstName} {user.lastName}
          </div>
          <div className="profile-popup__email" title={user.email} data-dtrum-mask>{user.email}</div>
        </div>
        <Permission requiredRoles={roles.systemAdministrator}>
          <div className="profile-popup__nav">
            <ul>{this.renderListItemsByConfig(adminMenuConfig)}</ul>
          </div>
        </Permission>
        <div className="profile-popup__nav">
          <ul>
            {this.renderListItemsByConfig(profileMenuConfig)}
            {this.renderLogoutListItem(logoutByClick)}
          </ul>
        </div>
      </div>
    );
  }

  renderListItemsByConfig = config =>
    config.map(({ id, href, title, icon, hasTargetBlank, role }) => (
      <Permission key={id} requiredRoles={role}>
        {this.renderListItem({ id, href, title, icon, hasTargetBlank })}
      </Permission>
    ));

  renderListItem = ({ id, href, title, icon, hasTargetBlank }) => (
    <li>
      { hasTargetBlank && (
        <a onClick={this.hideMenu} data-seleniumid={`profile-menu-button-${id}`} href={href} target="_blank">
          {icon && <Svg name={icon} className="profile-popup__nav-menu-icon" />}
          {title}
        </a>
      ) }
      { !hasTargetBlank && (
        <Link onClick={this.hideMenu} data-seleniumid={`profile-menu-button-${id}`} to={href}>
          {icon && <Svg name={icon} className="profile-popup__nav-menu-icon" />}
          {title}
        </Link>
      ) }
    </li>
  );

  renderLogoutListItem = logout => (
    <li>
      <a
        onClick={e => {
          e.preventDefault();
          logout();
        }}
        data-seleniumid="profile-menu-button-logout"
      >
        Logout
      </a>
    </li>
  );

  renderGuest({ login, authError, isAuthorizing }) {
    const { isPopupOpened } = this.state;

    if (!isPopupOpened) {
      return null;
    }

    return (
      <div className="profile-popup login-form">
        <SectionLoader hasLoading={isAuthorizing}>
          <Login onSubmit={login} authError={authError} focusable={true} />
        </SectionLoader>
      </div>
    );
  }

  render() {
    const { isPopupOpened } = this.state;

    return (
      <AuthContext.Consumer>
        {({ isAuthorized, isAuthorizing, authError, logoutByClick, user, login }) => (
          <div className="profile-wrap">
            <div
              role="button"
              tabIndex="0"
              data-seleniumid="profile-show-button"
              className={cn('profile-wrap__name', { opened: isPopupOpened })}
              onClick={this.profileClickHandle}
              data-dtrum-mask
            >
              {isAuthorized ? `${user.firstName} ${user.lastName}` : 'Login'}
              <Svg name={isPopupOpened ? 'carrot-up' : 'carrot-down'} className="profile-wrap__name-icon" />
            </div>
            {isAuthorized ? this.renderLogged({ user, logoutByClick }) : this.renderGuest({ login, authError, isAuthorizing })}
          </div>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default onClickOutside(Profile);

import { compact } from 'lodash';
import {
  daysDateModifier,
  generateDoiURL,
  stringDateToDateModifier,
  stringDateToDateModifierCustomFormat,
} from 'app/utils/utils';
import { LinkRender } from 'app/components/PageField/Render';
import { getDefaultLabelledField } from 'app/components/PageField/FieldUtils';
import ListRender from 'app/components/PageField/Render/ListRender';
import LinkDOIListRender from 'app/components/PageField/Render/LinkDOIListRender';
import EmailListColumnRender from 'app/components/PageField/Render/EmailListColumnRender';
import ListColumnRender from 'app/components/PageField/Render/ListColumnRender';
import SelectInput from 'app/components/PageField/Edit/SelectInput';
import TextareaInput from 'app/components/PageField/Edit/TextareaInput';
import path from 'app/router/path';
import {
  ERRATUM_TYPE,
  ORIGIN_TYPE,
  ORIGIN_TITLE,
  ERRATUM_SINGLE_TITLE,
  ERRATUM_PLURAL_TITLE,
  ARTICLE_NOT_PUBLISHED_MSG,
  ARTICLE_NOT_IN_WALS,
  LINKED_DOIS_PH,

  articleMetaDataTypes,
  getOptions,
  prevTaskStatusTypes,
} from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';
import {
  parseComment,
} from 'app/pages/DetailedViews/DetailedArticlesView/common/utils';

export { parseComment };

export const getFunderNoteByStatus = (status) => {
  switch (status?.toLowerCase()) {
    case 'missing':
    case 'late':
      return 'No funder was provided when this article was submitted. The author has not yet confirmed that they did not receive funding.';
    case 'unconfirmed':
      return 'Funder was declared when this article was submitted but it has not been confirmed by the author in Author Services. It is recommended that this confirmation is made before publication.';
    case 'confirmed':
      return 'Funder information has been confirmed by the author.';
    case 'none':
      return 'Author has confirmed that they did not receive funding for their research.';
    default:
      return null;
  }
};

const getLink = (progressStatus, id, q, type = 'aid') => (
  progressStatus === 'published'
    ? path.getSearchPublishedArticlesDetail(id, { type, q })
    : path.getSearchDashboardArticlesDetail(id, { type, q })
);

const getOriginArticleField = articleLinks => {
  const origin = articleLinks?.find(el => el.articleLinkType === ORIGIN_TYPE);
  if (!origin) return null;

  return {
    title: { text: ORIGIN_TITLE },
    value: { text: origin.aid, link: getLink(origin.progressStatus, origin.id, origin.aid), emptyText: '' },
    ValueRender: LinkRender,
    seleniumId: ORIGIN_TYPE,
  };
};

const getErratumField = articleLinks => {
  const erratum = articleLinks?.filter(el => el.articleLinkType === ERRATUM_TYPE);
  if (!erratum?.length) return null;

  return {
    title: { text: erratum.length > 1 ? ERRATUM_PLURAL_TITLE : ERRATUM_SINGLE_TITLE },
    value: {
      items: erratum.map(({ id, aid, progressStatus }) => ({ text: aid, link: getLink(progressStatus, id, aid) })),
      ItemRender: LinkRender,
      emptyText: '',
    },
    ValueRender: ListRender,
    seleniumId: ERRATUM_TYPE,
  };
};

export const getTitle = data => {
  const { titles } = data || {};
  return ((titles || []).find(({ type }) => type === 'main') || {}).content;
};

export const getYNValue = rawValue => {
  if (typeof rawValue === 'boolean') {
    return rawValue ? 'Yes' : 'No';
  }

  const value = (rawValue || '').substring(0, 1).toLowerCase();
  if (value === 'y') return 'Yes';
  if (value === 'n') return 'No';
  return '';
};

export const getWithEmptyValue = (value, ignoreValues = ['>----']) => {
  if (!value || ignoreValues.includes(value)) return '';
  return value;
};

export const getLeftInfoColumnConfig = (data, entityId, onUpdate, isEditable) => {
  const linkedDOIs = data?.jpcmsProperties?.linkedDoi || '';
  return compact([
    {
      title: { text: 'Article ID:' },
      value: { text: data.aid || '', emptyText: '' },
      icon: 'article',
      seleniumId: 'article-id',
    },
    getDefaultLabelledField('Editorial Reference Code:', data.edRefCode, ''),
    getDefaultLabelledField('Journal ID:', data.journalAcronym, ''),
    getDefaultLabelledField('Production ID:', data.productionId, ''),
    data.acceptedArticlePublished ? getDefaultLabelledField('Accepted Article Published:', stringDateToDateModifierCustomFormat(data.acceptedArticlePublished, true, 'd MMM yyyy HH:mm'), '') : null,
    data.earlyViewPublished ? getDefaultLabelledField('Early View Published:', stringDateToDateModifierCustomFormat(data.earlyViewPublished, true, 'd MMM yyyy HH:mm'), '') : null,
    data.issuePublished ? getDefaultLabelledField('Issue Published:', stringDateToDateModifierCustomFormat(data.issuePublished, true, 'd MMM yyyy HH:mm'), '') : null,
    getErratumField(data.articleLinks),
    getOriginArticleField(data.articleLinks),
    {
      title: { text: 'DOI:' },
      value: { link: data.doi ? generateDoiURL(data.doi) : '', blank: true, emptyText: '' },
      ValueRender: LinkRender,
      seleniumId: 'doi',
    },
    (isEditable || linkedDOIs.length)
      ? {
        title: { text: 'Related Article(s):' },
        value: { DOIs: linkedDOIs.split(';').map(v => v.trim()),
        },
        ValueRender: LinkDOIListRender,
        seleniumId: 'doi',
        edit: isEditable && {
          type: articleMetaDataTypes.LINKED_DOI,
          value: linkedDOIs.replace(/;/g, ', '),
          component: TextareaInput,
          entityId,
          ignoreKeySave: true,
          onUpdate,
          inputProps: {
            placeholder: LINKED_DOIS_PH,
            className: 'linked-dois-field',
          },
        },
      }
      : null,
  ]);
};

export const getAffiliations = (affiliationRef, affiliations) => {
  if (!affiliationRef || !affiliations?.length) {
    return '';
  }

  const affiliation = affiliations.find((aff) => affiliationRef.replace('#', '') === aff['xml:id']);
  return affiliation
    ? `${[
      affiliation.orgDiv,
      affiliation.orgName,
      affiliation.address?.street,
      affiliation.address?.city,
      affiliation.address?.countryPart,
      affiliation.address?.country,
      affiliation.address?.postCode,
    ].filter(a => a).join(', ')}`
    : '';
};

export const getRightInfoColumnConfig = (data, issueLink) => (
  compact([
    {
      title: { text: 'Issue ID:' },
      value: { text: `${data.issueId || ''}`, link: issueLink, emptyText: '' },
      ValueRender: issueLink ? LinkRender : undefined,
      seleniumId: 'issue-id',
    },
    {
      title: { text: 'Issue DOI:' },
      value: { link: data?.issueDoi ? generateDoiURL(data.issueDoi) : '', blank: true, emptyText: '' },
      ValueRender: LinkRender,
      seleniumId: 'doi',
    },
    {
      title: { text: 'PE(s):' },
      value: { tooltip: true, expand: true, items: data.PEs, emptyText: '' },
      ValueRender: EmailListColumnRender,
      seleniumId: 'pes',
    },
    {
      title: { text: 'TS Vendor(s):' },
      value: { expand: true, items: data.TSVendors, emptyText: '' },
      ValueRender: ListColumnRender,
      seleniumId: 'ts-vendors',
    },
  ])
);

export const getPreviousTaskColumnConfig = (taskPrevious) => {
  const { name, completedDate } = taskPrevious || {};
  const result = [];
  if (taskPrevious) {
    result.push({
      title: { text: name || '' },
      seleniumId: 'previous-task',
    });
  }
  if (completedDate) {
    result.push({
      title: prevTaskStatusTypes.completed,
      value: { text: stringDateToDateModifier(completedDate) },
      icon: 'completed',
      seleniumId: 'previous-task-status',
    });
  }
  return result;
};

export const getCurrentTaskColumnConfig = ({ taskCurrent, delayReason, entityId }, onUpdate, isEditable) => {
  const { name, taskActor, daysToComplete } = taskCurrent || {};

  return [
    {
      title: { text: name || '' },
      seleniumId: 'current-task',
    },

    {
      title: { text: 'Task with:' },
      value: { text: taskActor || '' },
      icon: 'task-with',
      seleniumId: 'task-with',
    },
    {
      title: { text: 'Days to complete:' },
      value:
                daysToComplete
                || daysToComplete === 0
                || daysToComplete === '0'
                  ? { text: daysDateModifier(daysToComplete) }
                  : { text: '' },
      icon: 'calendar',
      seleniumId: 'days-to-complete',
    },
    {
      title: { text: 'Delay Reason:' },
      value: { text: delayReason, emptyText: '' },
      seleniumId: 'delay-reason',
      edit: isEditable && {
        type: articleMetaDataTypes.DELAY_REASON,
        component: SelectInput,
        entityId,
        onUpdate,
        inputProps: { options: getOptions(articleMetaDataTypes.DELAY_REASON), searchable: true },
      },
    },
  ];
};

export const getNextTaskColumnConfig = (taskNext) => {
  const { name, revisesDue } = taskNext || {};
  return [
    {
      title: { text: name || '' },
      seleniumId: 'next-task',
    },

    {
      title: { text: 'Due:' },
      value: revisesDue ? { text: stringDateToDateModifier(revisesDue) } : { text: '' },
      icon: 'calendar',
      seleniumId: 'revises-due',
    },
  ];
};

export const getFunderDetailConfig = (data) => (
  data?.funderDetails ? {
    message: getFunderNoteByStatus(data.funderDetails.status),
    funders: data.funderDetails.funders,
  } : null
);

export const getLicenseDetailConfig = (data) => (
  data?.licenseDetails ? {
    copyrightLine: data.licenseDetails.copyrightLine,
    licenseSigningDate: data.licenseDetails.licenseSigningDate,
    licenseStatement: data.licenseDetails.licenseStatement,
    walsLicenseStatus: data.licenseDetails.walsLicenseStatus,
    licenseType: data.licenseDetails.licenseType,
    licenseStatus: data.licenseDetails.status,
  } : null
);

export const getPaymentDetailConfig = (data) => (
  {
    paymentMethod: data?.jpcmsProperties?.paymentMethod,
    paymentDate: data?.paymentDate,
  }
);

export const getLicensePreStatus = (data) => {
  const licenseStatus = data?.licenseDetails?.status || '';
  const participateInWals = data?.participateInWals || false;

  if (!participateInWals) {
    return ARTICLE_NOT_IN_WALS;
  }

  if (['missing', 'late'].includes(licenseStatus.toLowerCase())) {
    return ARTICLE_NOT_PUBLISHED_MSG;
  }
  return '';
};

export const validateEmail = email => {
  if (!email?.length) {
    return 'Email cannot be empty';
  }

  const parsed = email.split(/@/g);

  if (parsed.length > 2) {
    return 'Enter a valid email';
  }

  const [login, domain] = parsed;

  if (!login || !domain) {
    return 'Enter a valid email';
  }

  return false;
};

export const validatePassword = pass => {
  const size = pass.length;

  if (size === 0) {
    return 'Empty password';
  }

  return false;
};

export const validateNotEmpty = (fieldName, value) => {
  if (!value?.trim().length) {
    return `${fieldName} cannot be empty`;
  }

  return false;
};

import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { getGroups } from 'app/redux/api/common';

const initialState = fromJS({
  items: [],
  error: null,
  isLoading: false,
});

const GROUPS_FETCH = 'admin/groups/fetch';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GROUPS_FETCH:
      return state
        .set('error', null)
        .set('isLoading', true);
    case GROUPS_FETCH + SUCCESS: {
      return state
        .set('isLoading', false)
        .set('items', fromJS(payload));
    }
    case GROUPS_FETCH + FAILURE:
      return initialState.set('error', fromJS(payload));
    default:
      return state;
  }
};

// Actions
export const fetchGroups = (payload) => ({
  type: GROUPS_FETCH,
  payload,
  asyncCall: () => getGroups(),
});

// Selectors
const selectGroupsStore = state => state.groups;

export const selectGroups = createSelector(
  selectGroupsStore,
  groups => groups.get('items')?.toJS(),
);
export const selectGroupsIsLoading = createSelector(
  selectGroupsStore,
  groups => groups.get('isLoading'),
);
export const selectGroupsError = createSelector(
  selectGroupsStore,
  groups => groups.get('error')?.toJS?.());

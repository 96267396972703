import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { getUsers } from 'app/pages/Admin/ManageUsers/api/manageUsers';

export const defaultState = {
  isLoading: false,
  byId: {},
  itemsIds: [],
  error: null,
  totalAmount: 0,
};

export const initialState = fromJS(defaultState);

export const itemStatusDefault = {
  isLoading: false,
  error: null,
};

export const MANAGE_USERS_FETCH = 'admin/manage-users/fetch';
export const MANAGE_USERS_SEARCH = 'admin/manage-users/search';
export const UPDATE_ROLES = 'admin/manage-users/roles';
export const MANAGE_USERS_UPDATE_VENDOR = 'admin/manage-users/update-vendor';
export const RESET_USER_ERROR = 'admin/manage-users/reset-user-error';

const userPath = (id, path) => ['byId', id, path].filter(Boolean);

export default (state = initialState, action) => {
  const { type, payload, userId } = action;

  switch (type) {
    case MANAGE_USERS_SEARCH:
    case MANAGE_USERS_FETCH:
      return state
        .set('error', null)
        .set('isLoading', true);

    case MANAGE_USERS_SEARCH + SUCCESS:
    case MANAGE_USERS_FETCH + SUCCESS: {
      const { items, totalAmount } = payload;
      const itemsIds = items.map(item => item.id);
      const byId = items.reduce((acc, item) => {
        acc[item.id] = { ...item, ...itemStatusDefault };
        return acc;
      }, {});

      return state
        .set('isLoading', false)
        .set('byId', fromJS(byId))
        .set('itemsIds', fromJS(itemsIds))
        .set('totalAmount', totalAmount);
    }
    case MANAGE_USERS_SEARCH + FAILURE:
    case MANAGE_USERS_FETCH + FAILURE:
      return initialState.set('error', fromJS(payload));
    case UPDATE_ROLES:
    case MANAGE_USERS_UPDATE_VENDOR:
      return state
        .setIn(userPath(userId, 'error'), null)
        .setIn(userPath(userId, 'isLoading'), true);
    case MANAGE_USERS_UPDATE_VENDOR + SUCCESS: {
      const { user } = payload;
      return state.mergeIn(userPath(userId), {
        ...user,
        isLoading: false,
        error: null,
      });
    }
    case UPDATE_ROLES + SUCCESS: {
      const { roles } = payload;
      return state
        .setIn(userPath(userId, 'isLoading'), false)
        .setIn(userPath(userId, 'roles'), fromJS(roles));
    }
    case UPDATE_ROLES + FAILURE:
    case MANAGE_USERS_UPDATE_VENDOR + FAILURE:
      return state
        .setIn(userPath(userId, 'error'), fromJS(payload))
        .setIn(userPath(userId, 'isLoading'), false);
    case RESET_USER_ERROR:
      return state
        .setIn(userPath(userId, 'error'), null);
    default:
      return state;
  }
};

// Actions
export const fetchUsers = (payload) => ({
  type: MANAGE_USERS_FETCH,
  payload,
  asyncCall: () => getUsers({
    ...payload,
    calculateAssignedJournals: true,
  }),
});

export const updateVendor = ({ user, vendorId }, effect) => ({
  type: MANAGE_USERS_UPDATE_VENDOR,
  payload: { userId: user.id },
  asyncCall: () => effect({ userId: user.id, vendorId }),
  transformResult: res => ({ user: { ...user, ...res } }),
});

export const updateRole = (payload, params, effect) => ({
  type: UPDATE_ROLES,
  payload,
  asyncCall: () => effect(params),
});


export const resetUserError = userId => ({ type: RESET_USER_ERROR, userId });

export const searchUser = payload => ({ type: MANAGE_USERS_SEARCH, payload });

// Selectors
const selectManageUsers = state => state.admin.manageUsers;

export const selectUsers = createSelector(
  selectManageUsers,
  users => users.get('itemsIds').toJS().map(id => users.getIn(['byId', id]).toJS()),
);
export const selectIsLoading = createSelector(
  selectManageUsers,
  users => users.get('isLoading'),
);
export const selectTotalAmount = createSelector(
  selectManageUsers,
  users => users.get('totalAmount'));
export const selectError = createSelector(
  selectManageUsers,
  users => users.get('error')?.toJS?.());

import urgencyEnums from './urgency';

export const filtersWithValues = {
  journalAcronym: {
    getValueParam: v => v,
    categoryParam: 'exactJournalAcronyms',
  },
  volumeYears: {
    getValueParam: v => Number(v),
  },
  volumeNumbers: {
    getValueParam: v => Number(v),
  },
  issueNumbers: {
    getValueParam: v => v,
  },
};

export default {
  journalAcronym: 'JID',
  urgency: urgencyEnums,
  accesses: {
    subscription: 'Subs',
    openaccess: 'OA',
    onlineopen: 'OO',
  },
  productionStages: {
    received: 'Received',
    bookingIn: 'Booking In',
    proofPrep: 'Proof Prep',
    proofing: 'Proofing',
    articleRevises: 'Article Revision',
    revisesCheck: 'Revises Check',
    evQA: 'EV QA',
    evPending: 'EV Pending',
  },
  paymentStatuses: {
    unpaid: 'Unpaid',
    paid: 'Paid',
  },
  licenseStatuses: {
    na: 'N/A',
    missing: 'Missing',
    late: 'Late',
    signed: 'Signed',
  },
  schedule: {
    dueThisMonth: 'Due This Month',
  },
  pubDate: {
    less1years: '< 1 year',
    more1less3years: '1-3 years',
    more3years: '> 3 years',
  },
};

import { IconRender } from 'app/containers/Table/containers/CellRender';
import { LinkRender } from '@wiley/cpp-ui-commons/lib/components/Table/containers/CellRender';
import path from 'app/router/path';
import { prepareDataForIconRender, stringDateToDateModifier } from 'app/utils';

export const descriptor = (search) => [
  {
    id: 'volumeYear',
    caption: 'Volume Year',
    colWidth: 15,
    sortable: true,
  },
  {
    id: 'volumeNumber',
    caption: 'Volume Number',
    colWidth: 16,
    sortable: true,
  },
  {
    id: 'issueNumber',
    caption: 'Issue Number',
    colWidth: 14,
    sortable: true,
  },
  {
    id: 'aid',
    caption: 'ID',
    sortable: true,
    colWidth: 14,
    Render: LinkRender,
    dataModifier: (val, id) => ({
      title: val,
      href: path.getSearchPublishedArticlesDetail(id, search),
      hrefId: id,
    }),
  },
  {
    id: 'doi',
    caption: 'DOI',
    colWidth: 14,
    sortable: true,
    Render: LinkRender,
    dataModifier: (val, id) => ({
      title: val,
      href: `https://doi.org/${val}`,
      hrefId: id,
      openInNewWindow: true,
    }),
  },
  {
    id: 'access',
    caption: 'Access',
    sortable: true,
    Render: IconRender,
    colWidth: 13,
    dataModifier: val => prepareDataForIconRender(val),
  },
  {
    id: 'onlineDate',
    caption: 'Online Date',
    colWidth: 14,
    sortable: true,
    defaultSortField: true,
    dataModifier: stringDateToDateModifier,
  },
];

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import TableRowButton from 'app/pages/Admin/ManageTitles/components/Tabs/components/buttons/TableRowButton';
import TableError from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableError';
import './TableRow.scss';

class TableRow extends PureComponent {
  static propTypes = {
    headers: PropTypes.array,
    removeHandler: PropTypes.func,
    RemoveRender: PropTypes.func,
    seleniumId: PropTypes.string.isRequired,
    stats: PropTypes.object,
    value: PropTypes.object.isRequired,
  };

  renderCols() {
    const { headers, seleniumId, value } = this.props;
    return (
      headers.map(colId => (
        <div
          key={colId} data-seleniumid={`assigned-${seleniumId}-${colId}-${value.id}`}
          className="mt-row__item"
        >
          {value[colId]}
        </div>
      ))
    );
  }

  renderRemoveAction() {
    const { RemoveRender = TableRowButton, removeHandler, seleniumId, value: { id } } = this.props;
    return (
      <RemoveRender
        onClick={removeHandler}
        seleniumId={`assigned-${seleniumId}-remove-button-${id}`}
        text="Remove"
        className="mt-row__action"
      />
    );
  }


  renderViewMode() {
    const { removeHandler, stats, value, seleniumId } = this.props;
    return (
      <>
        <div className="mt-row__value-wrap">
          {this.renderCols()}
          <div className="mt-row__actions-wrap">
            {removeHandler && this.renderRemoveAction()}
          </div>
        </div>
        { stats?.error && (
          <TableError
            className="mt-row__error"
            error={stats?.error}
            seleniumId={`remove-${value.id}-${seleniumId}`}
          />
        )}
      </>
    );
  }

  render() {
    const {
      seleniumId,
      value: { id },
      stats,
    } = this.props;

    return (
      <SectionLoader hasLoading={Boolean(stats?.isLoading)} className="mt-row__loader">
        <div
          key={id} data-seleniumid={`assigned-${seleniumId}-row-${id}`}
          className="mt-row__wrap"
        >
          {this.renderViewMode()}
        </div>
      </SectionLoader>
    );
  }
}

export default TableRow;

import React from 'react';
import { Helmet } from 'react-helmet';

const Settings = () => (
  <>
    <Helmet>
      <title>CMH | Settings</title>
    </Helmet>
    <p>
      <b>Settings title</b>
    </p>
    Settings content
  </>
);

Settings.layoutProps = {
  header: {
    title: 'Settings',
  },
};

export default Settings;

/* eslint-disable jsx-a11y/no-autofocus */
import PropTypes from 'prop-types';
import React from 'react';

const TextInput = ({ value, onChange }) =>
  (
    <input
      autoFocus
      className="edit-field-text-input"
      data-seleniumid="edit-field-text-input"
      type="text"
      value={value || ''}
      onChange={e => onChange(value, e.target.value)}
    />
  );

TextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default TextInput;

import { validateEmail, validateNotEmpty } from 'app/services/validators';
import {
  alreadyAddedMSGs,
} from 'app/pages/Admin/ManageTitles/common/constants';

export const mergeProps = (stateProps, dispatch, ownProps) => {
  const { users, search } = stateProps;
  const { onRemove, onSearch, onAdd, onEdit } = dispatch;
  return {
    ...stateProps,
    ...dispatch,
    ...ownProps,
    onRemove: (id) => () => onRemove(id),
    onAdd: (id) => () => onAdd(id),
    onEdit: (id) => (user) => onEdit(id, user),
    onSearch: () => onSearch(users, search?.searchText),
  };
};


export const isNotValidEmail = (email, users, userType) => {
  const isNotValid = validateEmail(email);
  const isExist = users.find(user => user.email === email) && alreadyAddedMSGs[userType];
  return isNotValid || isExist;
};

export const isNotValidName = (name, users, userType) => {
  const isNotValid = validateNotEmpty('Name', name);
  const isExist = users.find(user => user.name === name) && alreadyAddedMSGs[userType];
  return isNotValid || isExist;
};

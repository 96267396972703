/* eslint react/prop-types: 0 */
import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import TableError from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableError';
import withForm, { Field } from '@wiley/cpp-ui-commons/lib/hoc/withForm';
import { isString, reduce, startCase } from 'lodash';
import './TableAdd.scss';

export class EditableTableAddComponent extends PureComponent {
  static propTypes = {
    changeEditModeHandler: PropTypes.func,
    editMode: PropTypes.bool,
    entity: PropTypes.object.isRequired,
    error: PropTypes.string,
    headers: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    onAdd: PropTypes.func,
    onSubmit: PropTypes.func,
    seleniumId: PropTypes.string.isRequired,
    suggestionHeader: PropTypes.array,
    suggestionText: PropTypes.string,
  };

  renderViewMode = () => {
    const { entity, onAdd, seleniumId, suggestionHeader, suggestionText, changeEditModeHandler } = this.props;
    const isHeaderExist = suggestionHeader?.some((el) => entity[el]);

    return (
      <>
        <div>
          { isHeaderExist && (
            <div className="mt-add__header">
              {suggestionHeader.map(val => (
                <span
                  key={val}
                  data-seleniumid={`assigned-${seleniumId}-search-result-${val}-${entity.id}`}
                >{entity[val]}
                </span>
              ))}
            </div>
          )}
          {suggestionText && (
            <div
              data-seleniumid={`assigned-${seleniumId}-search-result-${suggestionText}-${entity.id}`}
              className="mt-add__item"
            >
              {entity[suggestionText]}
            </div>
          )}
        </div>
        <div className="mt-add__buttons-wrap">
          <div
            data-seleniumid={`assigned-${seleniumId}-search-edit-button-${entity.id}`}
            className="mt-add__edit-button"
            onClick={() => changeEditModeHandler(true)}
          >
            Edit
          </div>

          <div
            data-seleniumid={`assigned-${seleniumId}-search-add-button-${entity.id}`}
            className="mt-add__button"
            onClick={onAdd(entity.id)}
          >
            Add
          </div>
        </div>
      </>
    );
  };

  renderEditMode = () => {
    const { headers, entity, seleniumId, changeEditModeHandler, onSubmit } = this.props;
    return (
      <>
        <div className="mt-add__edit-fields-wrap">
          {
            headers.map(col => (
              <div className="mt-add__field-item" key={col.id}>
                <Field
                  key={col.id}
                  name={col.id}
                  placeholder={startCase(col.id)}
                  disabled={col.readOnly}
                  data-seleniumid={`${seleniumId}-update-${col.id}-input`}
                />
              </div>
            ))
          }
        </div>
        <div className="mt-add__buttons-wrap">
          <div
            data-seleniumid={`assigned-${seleniumId}-search-edit-button-${entity.id}`}
            className="mt-add__edit-button"
            onClick={() => changeEditModeHandler(false)}
          >
            Cancel
          </div>

          <div
            data-seleniumid={`assigned-${seleniumId}-search-add-button-${entity.id}`}
            className="mt-add__button"
            onClick={onSubmit}
          >
            Save
          </div>
        </div>
      </>
    );
  };

  renderSearchError = () => {
    const { entity, seleniumId, error } = this.props;
    return (
      <TableError
        className="mt-add__error"
        error={error}
        seleniumId={`add-${entity.id}-${seleniumId}`}
      />
    );
  };

  render() {
    const { isLoading, error, editMode } = this.props;
    return (
      <SectionLoader hasLoading={isLoading}>
        <div className="mt-add__wrap">
          <div className="mt-add__value-wrap">
            {editMode && this.renderEditMode()}
            {!editMode && this.renderViewMode()}
          </div>
          {error && this.renderSearchError()}
        </div>
      </SectionLoader>
    );
  }
}

const EditableTableAdd = ({ editHandler, headers }) => (props) => {
  const [editMode, changeEditModeHandler] = useState(false);

  const validators = headers.reduce((acc, col) => ({
    ...acc,
    [col.id]: col.readOnly ? undefined : col.validator,
  }), {});

  const C = withForm({
    validators,
    onSubmit: (fields, props) => {
      changeEditModeHandler(false);
      return editHandler(props.entity.id)(reduce(fields, (acc, v, k) => {
        acc[k] = isString(v) ? v.trim() : v;
        return acc;
      }, {}));
    },
  })(EditableTableAddComponent);

  return (
    <C
      {...props} editMode={editMode} headers={headers}
      changeEditModeHandler={changeEditModeHandler}
    />
  );
};

export default EditableTableAdd;

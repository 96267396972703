import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'app/modules/Auth/hoc/withAuthProvider/index';

export class Permission extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    fallback: PropTypes.func,
    requiredRoles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  static defaultProps = {
    requiredRoles: '',
    fallback: () => null,
  };

  static contextType = AuthContext;

  hasPermission() {
    const { roles } = this.context;
    const { requiredRoles } = this.props;

    if (!requiredRoles) return true;
    if (!roles || !roles.length) return false;

    return Array.isArray(requiredRoles)
      ? requiredRoles.some(role => roles.includes(role))
      : roles.includes(requiredRoles);
  }

  render() {
    const { children, fallback: Fallback } = this.props;

    return this.hasPermission() ? children : <Fallback />;
  }
}

export default Permission;

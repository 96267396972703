import { fromJS } from 'immutable';
import { createSelectorCreator } from 'reselect';
import memoize from 'memoize-immutable';
import { SUCCESS, FAILURE } from 'app/redux/asyncMiddleware';
import { getUsers } from '../api/switchView';

export const SWITCH_VIEW_LS = 'switch-view-user';

export const initialState = fromJS({
  isLoading: false,
  totalAmount: 0,
  user: null,
  error: null,
  items: [],
});

export const FETCH_USERS = 'switch-view/users-fetch';
export const SET_SWITCH_VIEW = 'switch-view/switch-view-set';
export const UNSET_SWITCH_VIEW = 'switch-view/switch-view-uncset';
export const UNSET_SWITCH_VIEW_SUCCESS = `${UNSET_SWITCH_VIEW}${SUCCESS}`;

export default function switchViewReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USERS:
      return state.set('isLoading', true).set('error', null);

    case FETCH_USERS + SUCCESS:
      return state
        .set('isLoading', false)
        .set('items', fromJS(payload.items))
        .set('totalAmount', payload.totalAmount);

    case FETCH_USERS + FAILURE:
      return state
        .set('isLoading', false)
        .set('error', fromJS({ message: payload?.message ? payload.message : payload }))
        .set('totalAmount', 0)
        .set('items', fromJS([]));

    case SET_SWITCH_VIEW:
      return state;

    case SET_SWITCH_VIEW + SUCCESS:
      return state.set('user', fromJS(payload.user));

    case SET_SWITCH_VIEW + FAILURE:
      return state.set('error', fromJS(payload));

    case UNSET_SWITCH_VIEW:
      return state;

    case UNSET_SWITCH_VIEW + SUCCESS:
      return state.set('user', null);

    default:
      return state;
  }
}

const createSelector = createSelectorCreator(memoize);

export const fetchUsers = payload => ({
  type: FETCH_USERS,
  payload,
  asyncCall: () => getUsers(payload),
});

export const setSwitchView = user => ({
  type: SET_SWITCH_VIEW,
  payload: user,
});

export const unsetSwitchView = () => ({
  type: UNSET_SWITCH_VIEW,
});

const selectState = state => state.switchView;

export const selectUsers = createSelector(selectState, state => state?.get('items')?.toJS?.());

export const selectError = createSelector(selectState, state => {
  const error = state?.get('error');

  return error ? error.toJS() : null;
});

export const selectIsLoading = createSelector(selectState, state => state?.get?.('isLoading'));

export const selectTotalAmount = createSelector(selectState, state => state?.get?.('totalAmount'));

export const selectUser = createSelector(selectState, state => {
  const user = state?.get('user');

  return user ? user.toJS() : user;
});

import { JournalsFilter } from 'app/components/Filter/FilterCategory/Items/FilterAutoComplete/index';
import FilterInput from 'app/components/Filter/FilterCategory/Items/FilterInput';
import { getNumericalValueForFilter } from '../utils';

const VOLUME_YEAR_MAX_LENGTH = 4;
const VOLUME_NUMBER_MAX_LENGTH = 4;

export default {
  journalAcronym: { Render: JournalsFilter },
  volumeYears: { Render: FilterInput, valueModifier: getNumericalValueForFilter, inputProps: { maxLength: VOLUME_YEAR_MAX_LENGTH } },
  volumeNumbers: { Render: FilterInput, valueModifier: getNumericalValueForFilter, inputProps: { maxLength: VOLUME_NUMBER_MAX_LENGTH } },
  issueNumbers: { Render: FilterInput },
};

import { select, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_ENTITIES_INIT,
} from 'app/modules/Table/redux/TableDucks';
import { addImitateToParams } from 'app/pages/SwitchView/utils';
import { INIT_APP_ACTION } from 'app/redux/commonActions';
import { LOGIN_SUCCESS, selectIsAuth } from 'app/modules/Auth/redux/AuthDucks';
import { DASHBOARD_ARTICLES_STORE_KEY, DASHBOARD_ISSUES_STORE_KEY, PUBLISHED_ARTICLES_STORE_KEY } from '../constants';
import { fetchEntitiesCount, fetchSearchHistory, setEntitiesCount } from './SearchDucks';

export function* loadSearchHistory() {
  const isAuthorized = yield select(selectIsAuth);

  if (isAuthorized) {
    yield put(fetchSearchHistory());
  }
}

export function* searchWorker({ name, initialState = {} }) {
  if (![DASHBOARD_ARTICLES_STORE_KEY, DASHBOARD_ISSUES_STORE_KEY, PUBLISHED_ARTICLES_STORE_KEY].find(val => name.indexOf(val) > -1)) {
    return;
  }
  const { searchQuery, extraFilterParamsArticles, extraFilterParamsIssues, extraFilterParamsPublishedArticles } = initialState;
  if (!searchQuery) {
    yield put(setEntitiesCount(0, 0, 0));
    return;
  }
  yield put(fetchEntitiesCount(addImitateToParams(searchQuery), { extraFilterParamsArticles, extraFilterParamsIssues, extraFilterParamsPublishedArticles }));
}

export function* watchSearch() {
  yield takeLatest(FETCH_ENTITIES_INIT, searchWorker);
  yield takeLatest([
    LOGIN_SUCCESS,
    INIT_APP_ACTION,
  ], loadSearchHistory);
}

import React, { useRef, useCallback, useReducer, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { isEqual } from 'date-fns';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import PropTypes from 'prop-types';
import Loader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import Datepicker from 'app/components/Datepicker';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'app/components/Tooltip';
import {
  getValidateError,
  parseISOStringDate,
  parseStringDate,
  parseTimeToString,
  getErrorTooltip,
} from 'app/pages/ArticleHistory/common/utils';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import { UPDATE_ERROR_MSG, DELETE_ERROR_MSG } from 'app/constants';
import { parseDatePickerDate } from 'app/utils/utils';
import { getTooltipProps } from 'app/pages/DetailedViews/common/utils';
import EditableDateCellControls from './EditableDateCellControls';
import './EditableDateCell.scss';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
const initialState = initDate => ({
  date: initDate ? parseStringDate(initDate) : new Date(),
  isEditing: false,
  validationError: null,
  isLoading: false,
});

function reducer(state, action) {
  const { payload = {} } = action || {};
  switch (action.type) {
    case 'handleChange': return { ...state, ...payload, validationError: null };
    case 'handleValidationError': return { ...state, validationError: payload };
    case 'handleEdit': return { ...state, isEditing: payload, validationError: null };
    case 'loading': {
      const { flag, date } = payload;
      return { ...state, isEditing: false, isLoading: flag, date: parseISOStringDate(date) };
    }
    case 'reset': return initialState(payload.date);
    default: return state;
  }
}

const CalendarContainer = ({ children }) => (children ? (
  ReactDOM.createPortal(React.cloneElement(children, {
    className: 'react-datepicker-popper',
  }), document.body)
) : null);

const getWarnings = (date, func) => {
  const message = func(date);
  if (!message) return null;
  return Array.isArray(message) ? message : [message];
};

const EditableDateCell = ({
  date: initDate = '',
  className,
  rowId,
  rowTitle,
  additionalProps,
  ...domProps
}) => {
  const datepickerRef = useRef(null);
  const [{ date, isEditing, isLoading, validationError }, dispatch] = useReducer(reducer, initialState(initDate));
  const {
    entityId,
    // eslint-disable-next-line no-unused-vars
    onUpdate = (_) => {}, getWarningMessage = (_) => null, getViewTooltipMessage = (_) => null,
    updateAsyncCall,
    removeAsyncCall,
    getApiPayload,
    ignoreEditLoading,
    ...rest
  } = additionalProps;

  const isRemovable = !!removeAsyncCall;
  const toastId = (rowId || '').replace(':', '');

  useEffect(() => () => {
    toast.dismiss(toastId);
  }, []);

  useEffect(() => {
    if (!initDate || isEditing) return;

    dispatch({ type: 'reset', payload: { date: initDate } });
  }, [initDate]);

  const saveSchedule = async (dateTime = null) => {
    const rawPayload = { id: rowId, date: dateTime, ...rest };
    // set raw value
    onUpdate({ ...rawPayload, isRaw: true, isUpdating: true });

    try {
      dispatch({ type: 'loading', payload: { flag: true, date: dateTime } });
      dateTime ? await updateAsyncCall(entityId, getApiPayload(rawPayload)) : await removeAsyncCall(entityId, rawPayload);
      // save new value
      onUpdate({ ...rawPayload, isUpdated: true });
      dispatch({ type: 'reset', payload: { date: parseTimeToString(dateTime) } });
    }
    catch (e) {
      onUpdate({ ...rawPayload, isFailed: true });
      dispatch({ type: 'reset', payload: { date: initDate } });
      toast.error(dateTime ? UPDATE_ERROR_MSG(rowTitle) : DELETE_ERROR_MSG(rowTitle), { toastId });
    }
    finally {
      // clear raw value
      onUpdate({ ...rawPayload, date: null, isRaw: true });
    }
  };

  const handleChangeRaw = useCallback((e) => {
    const { value } = e?.target || {};
    if (!value) return;
    dispatch({ type: 'handleChange' });
  }, []);

  const handleChange = useCallback((newDate) => {
    dispatch({ type: 'handleChange', payload: { date: newDate } });
  }, []);

  const handleClickOutside = useCallback((e) => {
    const { className: targetClass } = e?.target || {};
    if (typeof targetClass === 'string' && targetClass.includes('close-icon')) {
      const { current: dp } = datepickerRef;
      dp.setOpen(true);
      dp.setFocus(true);
      dispatch({ type: 'handleChange', payload: { date: null } });
      return;
    }

    dispatch({ type: 'handleEdit', payload: false });
  }, [datepickerRef]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      dispatch({ type: 'handleEdit', payload: false });
      return;
    }
    if (e.key !== 'Enter') return;
    const { current: dp } = datepickerRef;
    setTimeout(() => {
      if (!dp.isCalendarOpen()) {
        dp.setOpen(true);
        dp.setFocus(true);
      }
    });
  }, [datepickerRef]);

  const onRemove = useCallback((e) => {
    if (e) e.stopPropagation();
    saveSchedule();
  });

  const onEdit = useCallback(() => {
    dispatch({ type: 'handleEdit', payload: true });
  }, []);

  const onSave = useCallback(() => {
    const { current: dp } = datepickerRef;

    if (isRemovable && initDate && !date) {
      onRemove();
    }

    const error = getValidateError((dp.input.props.value || '').toString().trim());
    if (error) {
      dispatch({ type: 'handleValidationError', payload: error });
      return;
    }

    const selectedDate = parseDatePickerDate(date);
    saveSchedule(selectedDate.toISOString());
  }, [datepickerRef, date]);

  const errorTooltipProps = useMemo(() => getErrorTooltip(validationError ? () => validationError : null), [validationError]);
  const viewTooltipMessage = getViewTooltipMessage(rowId);
  const viewTooltipId = viewTooltipMessage ? `view-${rowId}` : null;

  const isRemoveFlow = isRemovable && ignoreEditLoading && !date;
  if (isLoading && (!ignoreEditLoading || isRemoveFlow)) {
    return (
      <div
        {...domProps}
        className={`${className} edit-date-cell_progress`}
      >
        <Loader hasLoading={true} />
      </div>
    );
  }

  if (!isEditing) {
    const viewDate = (isLoading && ignoreEditLoading && date) ? parseTimeToString(parseDatePickerDate(date).toISOString()) : initDate;
    return (
      <div
        {...domProps}
        className={`${className} edit-date-cell_idle`}
        {...(viewTooltipId ? { 'data-for': viewTooltipId, 'data-tip': viewTooltipMessage, 'data-html': true } : {})}
      >
        <EditableDateCellControls
          seleniumId={toastId}
          onClick={onEdit}
          actions={[{
            id: 'edit_field',
            title: 'Edit',
          }, isRemovable ? {
            id: 'close',
            title: 'Remove',
            onClick: onRemove,
          } : null]}
        >
          {viewDate || ''}
        </EditableDateCellControls>
        {viewTooltipId ? (
          <ReactTooltip
            id={viewTooltipId}
            {...getTooltipProps({ place: 'left', dataHtml: true })}
          />
        ) : null}
      </div>
    );
  }

  const isSaveDisabled = initDate && date && isEqual(parseStringDate(initDate), date);
  const warnings = date ? getWarnings(date, getWarningMessage) : null;

  return (
    <div {...domProps} className={className}>
      <Datepicker
        autoFocus
        isClearable
        disabledKeyboardNavigation
        ref={datepickerRef}
        className="date-cell-datepicker"
        calendarClassName="select-date-calendar"
        mode="dp-below"
        popperContainer={CalendarContainer}
        placeholderText="mm/dd/yyyy"
        selected={date}
        dateFormat="MM/dd/yyyy"
        shouldCloseOnSelect={false}
        onChange={handleChange}
        onChangeRaw={handleChangeRaw}
        onClickOutside={handleClickOutside}
        onKeyDown={handleKeyDown}
        customInput={(
          <MaskedInput
            pipe={autoCorrectedDatePipe}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            keepCharPositions
            guide
          />
        )}
      >
        { (warnings?.length && !validationError) ? warnings.map(msg => (
          <div
            key={msg.replace(' ', '')}
            className="edit-date-cell__warning"
            data-seleniumid="warning-date-massage"
          >
            <Svg name="warning" className="edit-date-cell__warning-icon" />
            <span className="edit-date-cell__warning-title">{msg}</span>
          </div>
        )) : null}
        <button
          data-seleniumid="select-date-approve-button"
          type="button"
          disabled={isSaveDisabled}
          className="select-date-button"
          onClick={onSave}
        >
          Save
        </button>
      </Datepicker>
      <Tooltip {...errorTooltipProps} />
    </div>
  );
};

EditableDateCell.propTypes = {
  additionalProps: PropTypes.shape({
    entityId: PropTypes.string.isRequired,
    getApiPayload: PropTypes.func.isRequired,
    getViewTooltipMessage: PropTypes.func,
    getWarningMessage: PropTypes.func,
    ignoreEditLoading: PropTypes.bool, // the date cell is not processed separately during editing (table level progress)
    onUpdate: PropTypes.func,
    removeAsyncCall: PropTypes.func,
    type: PropTypes.string,
    updateAsyncCall: PropTypes.func.isRequired,
  }),
  className: PropTypes.string,
  date: PropTypes.string,
  rowId: PropTypes.string.isRequired,
  rowTitle: PropTypes.string.isRequired,
};

export default EditableDateCell;

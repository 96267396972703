import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { FAILURE, SUCCESS } from 'app/redux/asyncMiddleware';

import { createAction } from './actionUtils';

const stats = {
  pageSize: 25,
  currentPage: 1,
  sortField: '',
  sortDir: '',
  pageSizeOptions: [25, 50, 100],
};

export const initialState = fromJS({
  stats,
  hasLoading: false,
  error: null,
  items: [],
  totalAmount: 0,
  selectedFilters: [],
});

export const SET_INITIAL_STATE = 'table-filter-view/set-initial-state';
export const FETCH_ENTITIES_INIT = 'table-filter-view/fetchEntities/init';
export const FETCH_ENTITIES = 'table-filter-view/fetchEntities';
export const APPLY_SELECTED_FILTERS = 'table-filter-view/selected-filters/apply';
export const UPDATE_SELECTED_FILTERS = 'table-filter-view/selected-filters/update';
export const REMOVE_SELECTED_FILTERS = 'table-filter-view/selected-filters/remove';
export const UPDATE_STATS = 'table-filter-view/stats/update';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INITIAL_STATE: {
      const { initialState } = payload;

      return state.mergeDeep(fromJS(initialState));
    }

    case FETCH_ENTITIES: {
      const { initialState } = action;
      const { selectedFilters, stats = payload?.stats } = initialState ?? {};
      return state
        .merge({
          items: fromJS([]),
          hasLoading: true,
          totalAmount: 0,
          error: null,
        })
        .updateIn(['stats'], statsState => (stats ? statsState.merge(stats) : statsState))
        .updateIn(['selectedFilters'], filters => (selectedFilters ? fromJS(selectedFilters) : filters));
    }
    case FETCH_ENTITIES + SUCCESS: {
      const { items, totalAmount } = payload;

      return state.merge({
        hasLoading: false,
        items: fromJS(items),
        totalAmount,
        error: null,
      });
    }
    case FETCH_ENTITIES + FAILURE:
      return state.merge({
        hasLoading: false,
        error: fromJS(payload),
      });
    case UPDATE_SELECTED_FILTERS:
    case REMOVE_SELECTED_FILTERS:
    case APPLY_SELECTED_FILTERS: {
      return state.updateIn(['stats'], stats => stats.set('currentPage', 1));
    }

    case UPDATE_SELECTED_FILTERS + SUCCESS:
    case REMOVE_SELECTED_FILTERS + SUCCESS:
    case APPLY_SELECTED_FILTERS + SUCCESS: {
      const { selectedFilters } = payload;
      return state.updateIn(['selectedFilters'], filters => (selectedFilters ? fromJS(selectedFilters) : filters));
    }

    default:
      return state;
  }
};

// Actions

export const setInitialState = createAction(({ initialState }) => ({
  type: SET_INITIAL_STATE,
  payload: {
    initialState,
  },
}));

export const fetchEntities = createAction(({ initialState, payload }) => ({
  type: FETCH_ENTITIES_INIT,
  payload,
  initialState,
}));

export const applySelectedFilters = createAction(({ payload }) => ({
  type: APPLY_SELECTED_FILTERS,
  payload,
}));

export const removeSelectedFilters = createAction(({ payload }) => ({
  type: REMOVE_SELECTED_FILTERS,
  payload,
}));

export const updateSelectedFilters = createAction(({ payload }) => ({
  type: UPDATE_SELECTED_FILTERS,
  payload,
}));

export const updateStats = createAction(({ payload }) => ({
  type: UPDATE_STATS,
  payload,
}));

// Selectors

export const selectTable = (state, name) => state?.tableFilterView?.get?.(name)?.get?.('entities') || initialState;

export const selectItems = createSelector(selectTable, table => table?.get('items')?.toJS?.());
export const selectSelectedFilters = createSelector(selectTable, table => table?.get('selectedFilters').toJS?.());
export const selectEntitiesHasLoading = createSelector(selectTable, table => table?.get('hasLoading'));
export const selectEntitiesError = createSelector(selectTable, table => table?.get('error'));
export const selectStats = createSelector(selectTable, table => table?.get?.('stats')?.toJS?.());
export const selectItemsCount = createSelector(selectTable, table => table?.get('totalAmount'));
export const selectPageCount = createSelector(selectItemsCount, selectStats, (totalAmount, stats) =>
  Math.max(1, Math.ceil(totalAmount / stats.pageSize)),
);

import axiosInstance from 'app/services/axiosInstance';
import { toast } from 'react-toastify';

axiosInstance.interceptors.response.use(
  res => Promise.resolve(res),
  error => {
    const serverMessage = error?.response?.data?.message || error?.response?.data?.error;
    const status = error?.response?.status;

    const { errorMessage5XX, url, data = '' } = error?.config || {};
    const isDisplay = status >= 500 && errorMessage5XX;
    // eslint-disable-next-line
    console.log('error', { error, status, request: error?.request, response: error?.response, msg: serverMessage || errorMessage5XX });
    if (isDisplay) {
      toast.error(errorMessage5XX, { toastId: `${url}${data}`, autoClose: 5000 });
    }
    return Promise.reject(error);
  },
);

import React from 'react';
import PropTypes from 'prop-types';

import '../Stickable.scss';

const StickyContainer = ({ children, stickyId }) => (
  <div className="sticky-container">
    <div className="stickable" id={stickyId || 'stickable-container'} />
    {children}
  </div>
);

StickyContainer.propTypes = {
  children: PropTypes.any,
  stickyId: PropTypes.string,
};

export default StickyContainer;

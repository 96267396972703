import React from 'react';
import PropTypes from 'prop-types';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';

// TODO: move to shared modules after migrate common
import './AppHeaderLogo.scss';

const AppHeaderLogo = ({ logoIcon, projectName }) => (
  <div className="header-menu-logo">
    <Svg name={logoIcon} className="header-menu-logo__icon" />
    <div className="header-menu-logo__text">{projectName}</div>
  </div>
);

AppHeaderLogo.propTypes = {
  logoIcon: PropTypes.string,
  projectName: PropTypes.string,
};

export default AppHeaderLogo;

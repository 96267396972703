import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from 'app/components/StatusIcon';

export default function IconRender({ id, value, colWidth, seleniumId }) {
  const newColWidth = `${colWidth}%`;
  const newSeleniumId = seleniumId || id;

  return (
    <div
      data-seleniumid={newSeleniumId}
      className="table-cell"
      style={{
        width: newColWidth,
        minWidth: newColWidth,
      }}
    >
      <StatusIcon value={value.type} count={value.count} seleniumId={newSeleniumId} />
    </div>
  );
}

IconRender.propTypes = {
  colWidth: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seleniumId: PropTypes.string,
  value: PropTypes.shape({
    count: PropTypes.number,
    type: PropTypes.string,
  }),
};

/* eslint react/prop-types: 0 */
import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import TableRowButton from 'app/pages/Admin/ManageTitles/components/Tabs/components/buttons/TableRowButton';
import TableError from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableError';
import withForm, { Field } from '@wiley/cpp-ui-commons/lib/hoc/withForm';
import { isString, reduce, startCase } from 'lodash';
import './TableRow.scss';

export class EditableTableRow extends PureComponent {
  static propTypes = {
    changeStateHandler: PropTypes.func,
    editHandler: PropTypes.func,
    editMode: PropTypes.bool,
    EditRender: PropTypes.func,
    headers: PropTypes.array,
    onSubmit: PropTypes.func,
    removeHandler: PropTypes.func,
    RemoveRender: PropTypes.func,
    seleniumId: PropTypes.string.isRequired,
    startEditHandler: PropTypes.func,
    stats: PropTypes.object,
    value: PropTypes.object.isRequired,
  };

  onStartEdit = () => {
    const { changeStateHandler, startEditHandler, value: { id } } = this.props;
    changeStateHandler(true);
    if (startEditHandler) {
      startEditHandler(id);
    }
  };

  onCancelEdit = () => {
    const { changeStateHandler } = this.props;
    changeStateHandler(false);
  };

  renderInputs() {
    const { headers, seleniumId } = this.props;

    return headers.map(col => (
      <div className="mt-row__item" key={col.id}>
        <Field
          key={col.id}
          name={col.id}
          placeholder={startCase(col.id)}
          disabled={col.readOnly}
          data-seleniumid={`${seleniumId}-update-${col.id}-input`}
        />
      </div>
    ));
  }

  renderCols() {
    const { headers, seleniumId, value } = this.props;
    return (
      headers.map(col => (
        <div
          key={col.id} data-seleniumid={`assigned-${seleniumId}-${col.id}-${value.id}`}
          className="mt-row__item"
        >
          {value[col.id]}
        </div>
      ))
    );
  }

  renderEditAction() {
    const { EditRender = TableRowButton, seleniumId, value: { id } } = this.props;
    return (
      <EditRender
        onClick={this.onStartEdit}
        seleniumId={`assigned-${seleniumId}-edit-button-${id}`}
        text="Edit"
        className="mt-row__action"
      />
    );
  }

  renderRemoveAction() {
    const { RemoveRender = TableRowButton, removeHandler, seleniumId, value: { id } } = this.props;
    return (
      <RemoveRender
        onClick={removeHandler}
        seleniumId={`assigned-${seleniumId}-remove-button-${id}`}
        text="Remove"
        className="mt-row__action"
      />
    );
  }

  renderViewMode() {
    const { removeHandler, editHandler, stats, value, seleniumId } = this.props;
    return (
      <>
        <div className="mt-row__value-wrap">
          {this.renderCols()}
          <div className="mt-row__actions-wrap">
            {editHandler && this.renderEditAction()}
            {removeHandler && this.renderRemoveAction()}
          </div>
        </div>
        { stats?.error && (
          <TableError
            className="mt-row__error"
            error={stats?.error}
            seleniumId={`remove-${value.id}-${seleniumId}`}
          />
        )}
      </>
    );
  }

  renderEditMode() {
    const { stats, value, seleniumId, value: { id }, onSubmit } = this.props;
    return (
      <>
        <div className="mt-row__value-wrap">
          {this.renderInputs()}
          <div className="mt-row__actions-wrap">
            <TableRowButton
              onClick={onSubmit}
              seleniumId={`assigned-${seleniumId}-save-button-${id}`}
              text="Save"
              className="mt-row__action"
            />
            <TableRowButton
              onClick={this.onCancelEdit}
              seleniumId={`assigned-${seleniumId}-cancel-button-${id}`}
              text="Cancel"
              className="mt-row__action"
            />
          </div>
        </div>
        { stats?.error && (
          <TableError
            className="mt-row__error"
            error={stats?.error}
            seleniumId={`remove-${value.id}-${seleniumId}`}
          />
        )}
      </>
    );
  }

  render() {
    const {
      seleniumId,
      value: { id },
      stats,
      editMode,
    } = this.props;

    return (
      <SectionLoader hasLoading={Boolean(stats?.isLoading)} className="mt-row__loader">
        <div
          key={id} data-seleniumid={`assigned-${seleniumId}-row-${id}`}
          className="mt-row__wrap"
        >
          {!editMode && this.renderViewMode()}
          {editMode && this.renderEditMode()}
        </div>
      </SectionLoader>
    );
  }
}

export default (props) => {
  const [editMode, changeStateHandler] = useState(false);
  const { headers } = props;

  const validators = headers.reduce((acc, col) => ({
    ...acc,
    [col.id]: col.readOnly ? undefined : col.validator,
  }), {});

  const C = withForm({
    validators,
    onSubmit: (fields, props) => {
      changeStateHandler(false);
      return props.editHandler(reduce(fields, (acc, v, k) => {
        acc[k] = isString(v) ? v.trim() : v;
        return acc;
      }, {}));
    },
  })(EditableTableRow);

  return <C {...props} editMode={editMode} changeStateHandler={changeStateHandler} />;
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { RequiresActionContainer } from 'app/pages/RequiresAction/containers';
import { ASC_SORT } from 'app/services/api/sort';
import { defaultTableNoResult } from 'app/utils/defaultTableNoResult';
import { getArticles, getArticlesFacets } from 'app/redux/api/common';
import { getValidParamsForPage, sortSecondaryFields } from 'app/utils';
import { ARTICLES_REDUCER_NAME, RA_URGENCY, RA_ARTICLE_PROGRESS_STATUSES } from './constants';
import { descriptor } from './common/articlesRequiresDescriptor';

const defaultSortFieldId = descriptor.find(col => col.defaultSortField).id;

class RequiresActionArticles extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
      key: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  };

  getInitialStats() {
    const { location = {} } = this.props;
    const { sortField: sortFieldFromURL } = getValidParamsForPage(location, ['sortField']);
    return {
      stats: {
        sortField: defaultSortFieldId,
        sortDir: ASC_SORT,
        secondarySortFields: [...sortSecondaryFields(descriptor).map(col => col.id)
          .filter(id => id !== (sortFieldFromURL || defaultSortFieldId))],
      },
    };
  }

  render() {
    return (
      <RequiresActionContainer
        descriptor={descriptor}
        title="Articles"
        name={ARTICLES_REDUCER_NAME}
        entitiesEffect={getArticles({ ...RA_URGENCY, ...RA_ARTICLE_PROGRESS_STATUSES })}
        facetsEffect={getArticlesFacets({ ...RA_URGENCY, ...RA_ARTICLE_PROGRESS_STATUSES })}
        initialState={this.getInitialStats()}
        renderNoResults={defaultTableNoResult}
      />
    );
  }
}

export default RequiresActionArticles;

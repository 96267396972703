import React, { memo, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { ModalContainer, Modal, ModalAction } from '@wiley/cpp-ui-commons/lib/components/Modal';

import Content from 'app/components/ArticleContent/components/FileContent';
import { endpoints } from '../api';
import { isTextFormatContent } from '../common/fileFormats';

export function reducer(state, action) {
  switch (action.type) {
    case 'success':
      return { ...state,
        isLoading: false,
        textContent: action.payload,
        error: '',
      };
    case 'error':
      return { ...state,
        isLoading: false,
        textContent: '',
        error: 'Error loading content',
      };
    default:
      return state;
  }
}

export const initialState = {
  isLoading: true,
  textContent: '',
  error: '',
};

const Preview = ({
  contentURLPredefined = '',
  history,
  match: {
    url,
    params: {
      previewType,
      fileName,
      previewContentId,
      isVersion,
    },
  },
}) => {
  let contentURL = isVersion === 'true'
    ? `/api/${endpoints.getContentVersion(previewContentId)}`
    : `/api/${endpoints.getContent(previewContentId)}`;

  if (contentURLPredefined) {
    contentURL = contentURLPredefined;
  }

  const [{ isLoading, textContent, error }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isTextFormatContent(previewType)) {
      fetch(contentURL)
        .then((response) => {
          response.text().then((text) => {
            dispatch({ type: 'success', payload: text });
          });
        })
        .catch(() => {
          dispatch({ type: 'error' });
        });
    }
    else {
      dispatch({ type: 'success', payload: '' });
    }
  }, []);

  const closeModal = () => {
    history.push(url.replace(new RegExp('/preview.+'), '/'), { avoidScrollToTop: true });
  };

  return (
    <div>
      <ModalContainer
        customId="preview-modal"
      >
        <Modal
          onLayerClick={closeModal}
          className="preview-modal"
        >
          <ModalAction>
            <div className="preview-modal__header">
              <div className="preview-modal__file-name">
                {fileName}
              </div>

              <button
                className="preview-modal__close-button"
                data-seleniumid="preview-modal-close-button"
                type="button" onClick={closeModal}
              >
                &times;
              </button>
            </div>
          </ModalAction>
          <div className="preview-modal__content-container">
            <div className="preview-modal__content">
              <Content
                previewType={previewType}
                isLoading={isLoading}
                contentURL={contentURL}
                error={error}
                fileName={fileName}
                textContent={textContent}
              />
            </div>
          </div>
        </Modal>
      </ModalContainer>
    </div>
  );
};

Preview.propTypes = {
  contentURLPredefined: PropTypes.string,
  history: PropTypes.shape(),
  match: PropTypes.shape({
    params: PropTypes.shape({
      fileName: PropTypes.string,
      isVersion: PropTypes.string,
      previewContentId: PropTypes.string,
      previewType: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
};

export default memo(Preview, () => true);

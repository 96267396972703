import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Swipeable from 'react-swipeable';
import emitter from './emitter';

class ScrollableDispatcher extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
  };

  handleWheel = e => {
    emitter.emit('wheel', e.nativeEvent);
  };

  handleSwiping = (e, deltaX, deltaY) => {
    emitter.emit('wheel', { deltaY });
  };

  render() {
    const { children } = this.props;

    return (
      <Swipeable
        onSwiping={this.handleSwiping}
        onWheel={this.handleWheel}
      >
        {children}
      </Swipeable>
    );
  }
}

export default ScrollableDispatcher;

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import naturalCompare from 'string-natural-compare';
import qs from 'query-string';
import _ from 'lodash';
import { SHORT_MONTH_DATE } from 'app/constants';

const rEdge = /Edge\/(\d+)./i;
const rIE = /Trident.*rv:11\./;
const vendorsCSS = ['', '-o-', '-webkit-', '-moz-', '-ms-'];
const notBuggyEdge = 17;
const ua = navigator.userAgent;

export function isBuggyEdge() {
  const parsedEdgeVersion = ua.match(rEdge);
  if (!parsedEdgeVersion) {
    return false;
  }

  const [, version] = parsedEdgeVersion;

  return version ? parseInt(version, 10) < notBuggyEdge : true;
}

export function isIE() {
  return ua.indexOf('MSIE ') > 0 || Boolean(ua.match(rIE));
}

const cachedIsSupportSticky = (() => {
  const test = document.head.style;
  const isSupport = vendorsCSS.some(prefix => {
    test.position = `${prefix}sticky`;

    return Boolean(test.position) && test.position.includes('sticky');
  });

  test.position = '';

  return isSupport && !isBuggyEdge();
})();

export function isSupportSticky() {
  return cachedIsSupportSticky;
}

export const getCompareArrayByFieldsFunction = fields => arr =>
  arr.slice().sort((a, b) => {
    for (let i = 0; i < fields.length; i++) {
      if (a[fields[i]] > b[fields[i]]) {
        return 1;
      }
      if (a[fields[i]] < b[fields[i]]) {
        return -1;
      }
    }
    return 0;
  });

export const getSortNaturalCompareByFieldFunction = field => (a, b) => naturalCompare(a[field], b[field]);

function resetISODateTZOffset(date) {
  const reg = /^(\d+-\d+-\d+T\d+:\d+:\d+\.*\d*)([-+][\d:]+)$/;
  let replaced = date;
  try {
    replaced = date.replace(reg, (p1, p2) => `${p2}+0000`);
  }
  catch (e) {
    // eslint-disable-next-line
    console.log('removeTZOffset', e);
  }
  return replaced;
}

export function stringDateToDateModifierCustomFormat(val, ignoreTzOffset = true, dateFormat = SHORT_MONTH_DATE) {
  if (!val) {
    return null;
  }
  // note: we use 'parse' instead of 'new Date', because IE can't parse current format
  let date = parseISO(val);

  // we should use date with same TZ as was sent by the BE (no conversion to browser TZ)
  if (ignoreTzOffset) {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = parseISO(resetISODateTZOffset(val));
    date = new Date(date.getTime() + userTimezoneOffset);
  }

  try {
    return format(date, dateFormat);
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}

export const parsedDateFromString = (dateString, formatString, baseDate = new Date()) => parse(
  dateString,
  formatString,
  baseDate,
);

/**
 * transform to date when time = timezone
 * Mon May 10 2021 00:00:00 GMT+0300 -> Mon May 10 2021 03:00:00 GMT+0300
 * Wed Jun 30 2021 17:00:49 GMT+0300 -> Wed Jun 30 2021 03:00:00 GMT+0300
 *
 * it allows transform it to string date without timezone with toISOString, e.g. 2021-06-30T00:00:00.000Z
 */
export const parseDatePickerDate = (rawDate) => {
  const userTimezoneOffset = rawDate.getTimezoneOffset() * 60000;
  const selectedDate = new Date(rawDate.getTime() - userTimezoneOffset);
  selectedDate.setUTCHours(0, 0, 0, 0);
  return selectedDate;
};

export function stringDateToDateModifier(val) {
  return stringDateToDateModifierCustomFormat(val);
}

export function daysDateModifier(val) {
  const absVal = Math.abs(val);
  if (absVal > 1 || absVal === 0) {
    return `${val} days`;
  }
  return `${val} day`;
}

export function prepareDataForIconRender(val, count) {
  return { type: val, count: count || 0 };
}

export const createAlphabet = () => {
  const aCharCode = 'A'.charCodeAt(0);
  const zCharCode = 'Z'.charCodeAt(0);

  return new Array(zCharCode - aCharCode + 1).fill(0).map((_, i) => String.fromCharCode(aCharCode + i));
};

export const generateDoiURL = doi => `https://doi.org/${doi}`;

export const getValidParamsForPage = (location, validParams, transformParams = param => param) => {
  const params = qs.parse(location.search);
  return validParams.reduce((acc, paramName) => {
    if (params[paramName]) {
      acc[paramName] = transformParams(params[paramName], paramName);
    }
    return acc;
  }, {});
};

export const cutLongValue = (value, maxLength) =>
  (!value || !maxLength || value.length <= maxLength ? value : `${value.substr(0, maxLength)}...`);

export const sortSecondaryFields = (fields) => _.orderBy(fields, 'secondarySortOrder', 'asc');

export const between = (rawValue, min, max) => {
  const value = Number.parseInt(rawValue, 10);
  return value >= min && value <= max;
};

export const capitalizeFirstLetter = (string = '') => string.charAt(0).toUpperCase() + string.slice(1);


export const isSelection = () => !!(window.getSelection() || '').toString();

import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { getTooltipProps } from 'app/pages/DetailedViews/common/utils';
import NA from './EmptyRender';

export default function EmailRender({ value: { name, email }, seleniumId, tooltip }) {
  const lowercaseEmail = email ? email.toLowerCase() : '';

  if (!name && !lowercaseEmail) {
    return <NA seleniumId={seleniumId} />;
  }
  return (
    <div data-seleniumid={seleniumId}>
      {
        tooltip
          ? (
            <>
              <div data-tip={lowercaseEmail} data-for={lowercaseEmail}>{name}</div>
              <ReactTooltip
                id={lowercaseEmail}
                {...getTooltipProps({})}
              />
            </>
          )
          : (
            <>
              {`${name}`}
              { lowercaseEmail && (<> (<a href={`mailto:${lowercaseEmail}`}>{lowercaseEmail}</a>)</>)}
            </>
          )
      }

    </div>
  );
}

EmailRender.propTypes = {
  seleniumId: PropTypes.string.isRequired,
  tooltip: PropTypes.bool,
  value: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { getCorrectSeleniumId } from '@wiley/cpp-ui-commons/lib/utils/seleniumIdUtil';
import cn from 'classnames';

import './FilterTabs.scss';

export default function FilterTabButton({ text, active = false, onClick, seleniumId = '' }) {
  return (
    <div
      data-seleniumid={seleniumId || `filter-tabs__button-${getCorrectSeleniumId(text)}`}
      className={cn('filter-tabs__button', { active })}
      onClick={onClick}
    >
      {text}
    </div>
  );
}

FilterTabButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  seleniumId: PropTypes.string,
  text: PropTypes.string.isRequired,
};

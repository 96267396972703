import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import DotLoader from '@wiley/cpp-ui-commons/lib/components/DotLoader';
import Select from '@wiley/cpp-ui-commons/lib/components/Select';
import debounce from 'debounce';

const DELAY = 500;
const MIN_NOTSEARCHED_LETTERS_VALUE = 1;

class FilterAutoComplete extends PureComponent {
  constructor(props) {
    super(props);
    const { item } = props;
    const { categoryId, value } = item;

    this.seleniumId = `filter-select-item-${categoryId}`;
    this.state = {
      selectedValue: value,
      dropdownValue: value,
      searching: false,
      options: value ? [value] : value,
    };
  }

  static propTypes = {
    fetchEntities: PropTypes.func.isRequired,
    item: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      optionId: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.array,
    optionsIsLoading: PropTypes.bool,
  };

  componentDidUpdate(prevProps) {
    const { optionsIsLoading, options, item: { value } } = this.props;
    const { item: { value: prevValue } } = prevProps;

    const searchCompleted = !optionsIsLoading && prevProps.optionsIsLoading;
    const optionsUpdated = options.length !== prevProps.options.length;

    if (searchCompleted) {
      this.setState({ searching: false });
    }
    if (optionsUpdated && !optionsIsLoading) {
      this.setState({ options });
    }
    if (prevValue !== value && !value) {
      this.setState({
        selectedValue: null,
        dropdownValue: null,
        searching: false,
        options: null,
      });
    }
  }

  findAcronyms = debounce(() => {
    const { dropdownValue } = this.state;
    if (dropdownValue && dropdownValue.length > MIN_NOTSEARCHED_LETTERS_VALUE) {
      const { fetchEntities } = this.props;
      fetchEntities(dropdownValue);
    }
  }, DELAY);

  onChange = (selected) => {
    const { onSelect, item } = this.props;
    const { value } = selected;

    if (!value) return;

    onSelect({ ...item, value });
    this.setState({
      selectedValue: value,
      dropdownValue: value,
    });
  };

  onSearch = (searchString) => {
    const { selectedValue, dropdownValue } = this.state;
    const newValue = searchString.trim();
    if (!newValue.length && selectedValue === dropdownValue) return;

    this.setState(({ selectedValue }) => ({
      dropdownValue: !newValue.length ? selectedValue : newValue,
      options: !newValue.length ? [selectedValue] : [],
      searching: newValue.length > MIN_NOTSEARCHED_LETTERS_VALUE,
    }), this.findAcronyms);
  };

  noItemsFound() {
    const { searching, dropdownValue } = this.state;
    if (!dropdownValue || dropdownValue.length <= MIN_NOTSEARCHED_LETTERS_VALUE) return false;
    if (searching) return <div data-seleniumid={`${this.seleniumId}-searching`}>Searching<DotLoader /></div>;

    return <div data-seleniumid={`${this.seleniumId}-no-results`}>No results</div>;
  }

  render() {
    const { dropdownValue, options } = this.state;
    return (
      <Select
        options={compact(options)}
        onSearch={this.onSearch}
        className="filter-autocomplete"
        searchable={true}
        noItemsFound={this.noItemsFound()}
        searchClearOnClose={true}
        placeholder="Input and select"
        seleniumId={this.seleniumId}
        onChange={this.onChange}
        maxLengthSelectedValue={Infinity}
        value={dropdownValue}
      />
    );
  }
}

export default FilterAutoComplete;

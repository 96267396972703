import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';

import './TileWidget.scss';

const TileWidget = ({ amount, name, pluralName, config = {}, seleniumPart = '' }) => {
  const showAmount = amount !== undefined;
  const label = showAmount && (amount > 1 || amount.toString() === '0') ? pluralName : name;
  const { moreLink = null, moreText = null, icon = null, moreLinkAlwaysEnabled = false } = config;
  const showLink = moreLinkAlwaysEnabled || (showAmount && amount) > 0;

  const Wrapper = showLink ? Link : 'div';

  return (
    <Wrapper to={showLink ? moreLink || '' : undefined} className="journal-item" data-seleniumid={`journal-item-${seleniumPart}`}>
      <div className="journal-item__body">
        <div className="journal-item__content">
          {icon && <Svg name={icon} className="journal-item__icon" />}
          <div className="journal-item__title">{config && config.title}</div>
          {showAmount && (
            <div className="journal-item__description">
              <div className="journal-item__count">{amount}</div>
              <div className="journal-item__label">{label}</div>
            </div>
          )}
        </div>
        <div className="journal-item__footer">
          <div className="journal-item__more" data-seleniumid={`${seleniumPart}-open-journal-button`}>
            {moreText || 'View More'}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

TileWidget.propTypes = {
  amount: PropTypes.number,
  config: PropTypes.shape({
    icon: PropTypes.string,
    moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    moreLinkAlwaysEnabled: PropTypes.bool,
    moreText: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  pluralName: PropTypes.string,
  seleniumPart: PropTypes.string,
};

export default TileWidget;

import qs from 'query-string';
import { connect } from 'react-redux';
import Dashboard from 'app/pages/Dashboard/components/DashboardComponent';
import {
  initLoad,
  loadData,
  loadJournals,

  selectHasLoading,
  selectArticleUserStatistic,
  selectIssueUserStatistic,
  selectProductionStagesStatistic,

  selectUserJournals,
  selectJournalsHasLoading,
} from 'app/pages/Dashboard/redux/DashboardDucks';
import withUIState, { AUTH, SWITCH_VIEW } from 'app/hoc';

const mapStateToProps = (state, { location }) => ({
  hasLoading: selectHasLoading(state),
  articleUserStatistic: selectArticleUserStatistic(state),
  issueUserStatistic: selectIssueUserStatistic(state),
  productionStagesStatistic: selectProductionStagesStatistic(state),
  journals: selectUserJournals(state),
  journalsHasLoading: selectJournalsHasLoading(state),
  acronym: qs.parse(location?.search)?.acronym,
});

export const mapDispatchToProps = (dispatch, { auth, switchView }) => {
  const { userId } = auth;
  const { user: switchViewUser, hasChangedView } = switchView;
  const currentUserId = hasChangedView ? switchViewUser.id : userId;
  const userProp = { userId: currentUserId };

  return {
    initLoad() {
      dispatch(initLoad(userProp));
    },
    loadData(params) {
      dispatch(loadData({ ...userProp, query: params || {} }));
    },
    loadJournals() {
      dispatch(loadJournals(userProp));
    },
  };
};

export default withUIState(AUTH, SWITCH_VIEW)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard));

/* eslint-disable no-unused-vars,no-console */
import {
  articleMetaDataTypes,
  articleMetadataOptions,
  COLOR_UNPAGINATED_HINT,
  ONLINE_PAGES_EDIT_HINT,
  iconsStatus,
  iconsCover,
  eoSourceTitles,
  MANUAL_SOURCE,
} from 'app/pages/DetailedViews/DetailedArticlesView/common/constant';

const JOIN_AUTHOR = ' ';
const JOIN_AFFILIATION = ', ';

const joinValues = (list, symbol) => list.filter(({ value }) => !!value).map(({ value }) => value).join(symbol);
export const getAuthorName = personName => {
  if (!Array.isArray(personName)) {
    // eslint-disable-next-line no-console
    console.error('Incorrect person name format');
    return null;
  }
  return personName?.length ? joinValues(personName, JOIN_AUTHOR) : null;
};
export const parseOrcid = orcid => (orcid || '').split('://').reverse()[0];
export const isCorresponding = corresponding => corresponding === 'yes';
export const getAffiliations = (affiliationRef, affiliations) => ((Array.isArray(affiliationRef) && affiliationRef?.length && affiliations?.length) ? affiliationRef
  .map(rawId => rawId.replace('#', ''))
  .map(id => affiliations.find(({ 'xml:id': xmlId }) => xmlId === id) || {})
  .map(({ 'xml:id': xmlId, affiliationDetails }) => {
    if (!affiliationDetails?.length) return null;
    return {
      id: xmlId,
      text: joinValues(affiliationDetails, JOIN_AFFILIATION),
    };
  })
  .filter(el => !!el)
  : []);

const cutColorTail = v => v.replace(/([.|,]$)/g, '');

export const preProcessPrintPages = (value) => {
  if (!value || !/\.\d{3,}$/.test(value)) return value;
  const [h, t] = value.split('.');
  return [h, t.substring(0, 2)].join('.');
};

export const printPagesProcessing = (oldV, rawV = '') => {
  const newV = rawV.replace(/^0+(?!\.|$)/, '');
  return ((!newV || /^\d+(\.?\d{0,2})$/.test(newV)) ? newV.substr(0, 8) : oldV);
};
export const onlinePagesProcessing = (oldV, rawV = '') => {
  const newV = rawV.replace(/^0+(?!\.|,|$)/, '');
  return ((!newV || /^\d+([.|,]?\d*)$/.test(newV)) ? newV.substr(0, 8) : oldV);
};
export const manuscriptPagesProcessing = (oldV, rawV = '') => {
  const newV = rawV.replace(/^0+(?!\.|$)/, '');
  return (!newV || (/^\d+$/.test(newV)) ? newV.substr(0, 8) : oldV);
};
export const getOnlinePagesHint = (rawValue = '') => {
  if (!/^\d+([.|,]{1}\d+)$/.test(rawValue)) return null;
  const value = Math.ceil(rawValue.replace(',', '.'));

  if (Number.isNaN(value)) return null;
  return ONLINE_PAGES_EDIT_HINT(value);
};

export const getColorUnpaginatedHint = (rawValue = '') => {
  if (!rawValue) return null;
  return COLOR_UNPAGINATED_HINT;
};

export function getIconsGroups(data) {
  const res = {};
  Object.keys(iconsCover).forEach(actionName => {
    const status = data[actionName]?.status;
    res[actionName] = {
      cover: iconsCover[actionName],
      status: status ? iconsStatus[status.toLowerCase()] : null,
    };
  });

  return res;
}

export const parseEoSource = val => {
  if (!val) return MANUAL_SOURCE;
  return eoSourceTitles[val.toLowerCase()] || val;
};

/**
 * Due to a problem in processing line breaks on the JIP side, only such a \r gets in the comments instead of a \r\n.
 * Replace all \r (without \n) to \r\n for correct display.
 * @param comment
 * @returns {string}
 */
export const parseComment = comment => (comment || '').replace(/\r(?!\n)/g, '\r\n');

/**
 * Due to a problem in processing line breaks on the JIP side, if UI sends only \n these characters are removed.
 * Replace all \n (without \r) to \r\n before send to API.
 * @param comment
 * @returns {string}
 */
export const postProcessComment = comment => (comment || '').replace(/\r/g, '').replace(/\n/g, '\r\n');


export const postProcessMetadata = ({ type, value }) => {
  let processedValue = value;

  switch (type) {
    case articleMetaDataTypes.LINKED_DOI:
      processedValue = value.split(',').map(el => (el || '').trim()).join(';');
      break;
    case articleMetaDataTypes.PRINT_PAGES:
    case articleMetaDataTypes.NO_OF_MS_PAGES:
    case articleMetaDataTypes.COLOR_PAGE_NO_UNPAGINATED:
      processedValue = cutColorTail(value);
      break;
    case articleMetaDataTypes.ONLINE_PAGES:
      processedValue = value ? Math.ceil(cutColorTail(value).replace(',', '.')) : '';
      break;
    case articleMetaDataTypes.GENERAL_COMMENT:
      processedValue = value ? postProcessComment(value) : '';
      break;
    case articleMetaDataTypes.TOC_CATEGORY: {
      if (!value) return '';

      const [key] = Object.entries(articleMetadataOptions[articleMetaDataTypes.TOC_CATEGORY]).find(([_, label]) => label === value) || [null];
      if (!key) console.error('Unknown tocCategory value ', value);
      processedValue = key || '';
      break;
    }
    default:
      break;
  }

  return processedValue.toString().trim();
};

import React from 'react';
import PropTypes from 'prop-types';
import ExpandButton from 'app/components/ExpandButton';

export const getShortText = text => (text && text.length > 325 ? `${text.substr(0, 325)} ...` : null);

const CommentExpand = ({ shortText, isOpen, onClick }) => {
  if (!shortText) return null;

  return (
    <div className="my-2">
      <ExpandButton
        seleniumId={`${isOpen ? 'collapse' : 'expand'}-comment`}
        isExpanded={isOpen}
        less="See short"
        more="See full"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(!isOpen);
        }}
      />
    </div>
  );
};

CommentExpand.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  shortText: PropTypes.string,
};

export default CommentExpand;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FilterTabsButton from './FilterTabsButton';
import './FilterTabs.scss';

export default function FilterTabs({ tabList = [], onClick, className }) {
  const hasItems = tabList.length > 0;

  return (
    <div className={cn('filter-tabs', className)}>
      {hasItems
        && tabList.map(({ seleniumId, active = false, valueText }, i) => (
          <FilterTabsButton
            key={valueText}
            seleniumid={seleniumId}
            onClick={e => onClick(tabList[i], e)}
            active={active}
            text={valueText}
          />
        ))}
    </div>
  );
}

FilterTabs.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  tabList: PropTypes.array.isRequired,
};

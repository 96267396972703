import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import HorizontalDiagram from './HorizontalDiagram';
import DiagramSummary from './DiagramSummary';

import './HorizontalDiagramWidget.scss';

export default class HorizontalDiagramWidget extends PureComponent {
  static propTypes = {
    config: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.shape({
      filterId: PropTypes.string,
      filterValue: PropTypes.number,
    })),
    seleniumPart: PropTypes.string,
  };

  render() {
    const { config, items, seleniumPart } = this.props;
    return (
      <div className="diagram-widget__card" data-seleniumid={`${seleniumPart}-card`}>
        <div data-seleniumid={`${seleniumPart}-title`} className="diagram-widget__card-title">Articles By Stage</div>
        <div className="diagram-widget__diagram-wrap">
          <HorizontalDiagram seleniumPart={seleniumPart} config={config} items={items} />
        </div>
        <div className="diagram-widget__summary-wrap">
          <DiagramSummary seleniumPart={seleniumPart} config={config} items={items} />
        </div>
      </div>
    );
  }
}

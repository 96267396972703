import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import useAuth from 'app/modules/Auth/hoc/withAuthProvider/useAuth';
import { useFeatures as useFeaturesCommon } from '@wiley/cpp-ui-commons/lib/components/FeatureToggle';

const parseFeatures = ({ source, email }) => Object.entries(source).reduce((acc, [key, value]) => {
  if (Array.isArray(value)) {
    acc[key] = value.includes(email);
  }
  else {
    acc[key] = value;
  }
  return acc;
}, {});


const useFeatures = () => {
  const { features: source = {}, isLoading, error } = useFeaturesCommon();
  const { userEmail: email } = useAuth();
  const [state, setState] = useState({ isLoading, error, features: {} });


  useEffect(
    () => {
      if (isLoading || !email || !source || isEmpty(source)) return;
      setState({
        isLoading: false,
        features: parseFeatures({ source, email }),
      });
    },
    [email, source, isLoading],
  );

  return state;
};

export default useFeatures;

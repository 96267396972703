import React from 'react';
import roles from 'app/enums/roles';
import { NO_RESULTS_MESSAGE, NO_JOURNALS } from 'app/enums/commonErrors';
import Permission from 'app/modules/Auth/components/Permission';
import InfoView from '@wiley/cpp-ui-commons/lib/components/InfoView';

export const defaultTableNoResult = () => (
  <Permission
    requiredRoles={[roles.productionEditor, roles.productionManager]}
    fallback={() => <InfoView seleniumId="no-results" title={NO_RESULTS_MESSAGE} text={NO_JOURNALS} />}
  >
    <InfoView seleniumId="no-results" title={NO_RESULTS_MESSAGE} text="" />
  </Permission>
);

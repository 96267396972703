import { connect } from 'react-redux';
import { uniq } from 'lodash';
import {
  fetchJournals,
  selectJournals,
  selectJournalsIsLoading,
} from 'app/redux/reducers/journals';
import FilterAutoComplete from '../FilterAutoComplete';

export const mapStateToProps = state => {
  const journals = selectJournals(state);
  const isLoading = selectJournalsIsLoading(state);
  const options = uniq(journals.map(({ acronym }) => acronym));

  return {
    options,
    optionsIsLoading: isLoading,
  };
};

export const mapDispatchToProps = dispatch => ({
  fetchEntities(acronym) {
    dispatch(fetchJournals(acronym));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterAutoComplete);

import React from 'react';
import PropTypes from 'prop-types';
import SectionLoader from '@wiley/cpp-ui-commons/lib/components/SectionLoader';
import './TableAdd.scss';
import TableError from 'app/pages/Admin/ManageTitles/components/Tabs/components/TableError';

const TableAdd = ({ entity, onAdd, isLoading, seleniumId, suggestionHeader, suggestionText, error }) => (
  <SectionLoader hasLoading={isLoading}>
    <div className="mt-add__wrap">
      <div className="mt-add__value-wrap">
        <div>
          { suggestionHeader && (
            <div className="mt-add__header">
              {suggestionHeader.map(val => (<span key={val} data-seleniumid={`assigned-${seleniumId}-search-result-${val}-${entity.id}`}>{entity[val]}</span>))}
            </div>
          )}
          {suggestionText && (
            <div
              data-seleniumid={`assigned-${seleniumId}-search-result-${suggestionText}-${entity.id}`}
              className="mt-add__item"
            >
              {entity[suggestionText]}
            </div>
          )}
        </div>

        <div
          data-seleniumid={`assigned-${seleniumId}-search-add-button-${entity.id}`}
          className="mt-add__button"
          onClick={onAdd(entity.id)}
        >
          Add
        </div>
      </div>
      { error && (
        <TableError
          className="mt-add__error"
          error={error}
          seleniumId={`add-${entity.id}-${seleniumId}`}
        />
      )}
    </div>
  </SectionLoader>
);

TableAdd.propTypes = {
  entity: PropTypes.object.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onAdd: PropTypes.func,
  seleniumId: PropTypes.string.isRequired,
  suggestionHeader: PropTypes.array,
  suggestionText: PropTypes.string,
};

export default TableAdd;

import { fromJS } from 'immutable';
import { createSelectorCreator } from 'reselect';
import memoize from 'memoize-immutable';
import { isObject } from 'lodash';

import ls from 'app/services/localStorage';
import { getToken, isAuthorized } from 'app/services/auth';
import { AUTH_USER_KEY, AUTH_ROLES_KEY } from 'app/modules/Auth/constants';

export const initialState = fromJS({
  isAuthorizing: false,
  token: getToken(),
  user: ls.getJSON(AUTH_USER_KEY),
  error: null,
  roles: ls.getJSON(AUTH_ROLES_KEY),
  rolesIsLoading: false,
});

export const LOGIN_REQUEST = 'auth/login/request';
export const LOGIN_SUCCESS = 'auth/login/success';
export const LOGIN_FAILURE = 'auth/login/failure';

export const LOGOUT_REQUEST = 'auth/logout/request';
export const LOGOUT_SUCCESS = 'auth/logout/success';
export const LOGOUT_FAILURE = 'auth/logout/failure';

export const ROLES_REQUEST = 'auth/roles/request';
export const ROLES_SUCCESS = 'auth/roles/success';
export const ROLES_FAILURE = 'auth/roles/failure';

export default function loginReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_REQUEST:
      return state.set('token', '').set('isAuthorizing', true);

    case LOGIN_SUCCESS:
      return state
        .set('user', fromJS(payload.user))
        .set('token', payload.token)
        .set('error', null)
        .set('isAuthorizing', false);

    case ROLES_REQUEST:
      return state.set('rolesIsLoading', true);

    case ROLES_SUCCESS:
      return state.set('roles', fromJS(payload)).set('rolesIsLoading', false);

    case ROLES_FAILURE:
      return state.set('roles', null).set('rolesIsLoading', false);

    case LOGIN_FAILURE: {
      const error = isObject(payload) ? payload : { errorCode: 'AUTHSVC_UNAUTHORIZED' };
      return state
        .set('error', fromJS(error))
        .set('isAuthorizing', false)
        .set('roles', null);
    }

    case LOGOUT_SUCCESS:
      return state
        .set('user', null)
        .set('token', '')
        .set('roles', null);

    case LOGOUT_FAILURE:
      return state.set('user', null).set('token', '');

    default:
      return state;
  }
}

export const login = creds => ({ type: LOGIN_REQUEST, payload: creds });
export const loginSuccess = payload => ({ type: LOGIN_SUCCESS, payload });
export const loginFailure = err => ({ type: LOGIN_FAILURE, payload: err });

export const logout = () => ({ type: LOGOUT_REQUEST });
export const logoutByClick = () => ({ type: LOGOUT_REQUEST, payload: { byClick: true } });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutFailure = () => ({ type: LOGOUT_FAILURE });

export const fetchRoles = () => ({ type: ROLES_REQUEST });
export const fetchRolesSuccess = roles => ({ type: ROLES_SUCCESS, payload: roles });
export const fetchRolesFailure = err => ({ type: ROLES_FAILURE, payload: err });

const createSelector = createSelectorCreator(memoize);

const selectAuth = state => state.auth;

export const selectUser = createSelector(selectAuth, auth => {
  const user = auth.get('user');
  return user ? user.toJS() : null;
});

export const selectError = createSelector(selectAuth, auth => {
  const error = auth.get('error');

  return error ? error.toJS() : null;
});

export const selectIsAuthorizing = createSelector(selectAuth, auth => auth.get('isAuthorizing'));

export const selectName = createSelector(selectUser, user => user.name);

export const selectPtpId = createSelector(selectUser, user => user.ptpid);

export const selectLastName = createSelector(selectUser, user => user.lastName);

export const selectEmail = createSelector(selectUser, user => user.email);

export const selectRoles = createSelector(selectAuth, auth => auth.get('roles')?.toJS());

export const selectRolesIsLoading = createSelector(selectAuth, auth => auth.get('rolesIsLoading'));

export const selectRolesIsLoaded = createSelector(selectAuth, auth => auth.get('rolesIsLoaded'));

export const selectIsAuth = createSelector(selectAuth, selectUser, (auth, user) =>
  isAuthorized(auth.get('token')) && Boolean(user?.ptpid));

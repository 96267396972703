import {
  PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ARTICLES,
  PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ISSUES, PROGRESS_STATUSES_FOR_SEARCH_PUBLISHED_ARTICLES,
} from 'app/pages/Search/constants';

const types = {
  jid: 'wildcardJournalAcronym',
};

export const getRelevantSearchType = (type) => types[type] || type;
export const getExtraFilterParams = articlesInIssueQueryParam => ({
  extraFilterParamsArticles: { progressStatuses: articlesInIssueQueryParam === 'true' ? [] : PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ARTICLES },
  extraFilterParamsIssues: { progressStatuses: PROGRESS_STATUSES_FOR_SEARCH_DASHBOARD_ISSUES },
  extraFilterParamsPublishedArticles: { progressStatuses: PROGRESS_STATUSES_FOR_SEARCH_PUBLISHED_ARTICLES },
});

import createSagaMiddleware from 'redux-saga';
import asyncMiddleware from 'app/redux/asyncMiddleware';
import extraRequestsProtector from 'app/redux/extraRequestsProtector';

export const sagaMiddleware = createSagaMiddleware();

const middleware = [extraRequestsProtector, asyncMiddleware({
  defaultTransformError: e => (typeof e === 'string' ? { message: e } : e?.response?.data || e),
}), sagaMiddleware];

export default middleware;

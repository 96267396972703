import React from 'react';
import PropTypes from 'prop-types';
import ExpandableList from 'app/components/ExpandableList';
import EmailRender from './EmailRender';
import NA from './EmptyRender';

class EmailListColumnRender extends React.PureComponent {
  static propTypes = {
    seleniumId: PropTypes.string.isRequired,
    value: PropTypes.shape({
      emptyText: PropTypes.string,
      expand: PropTypes.bool,
      items: PropTypes.array,
      tooltip: PropTypes.bool,
    }),
  };

  renderValues(values) {
    const {
      value: { expand = false },
      seleniumId,
    } = this.props;

    return (expand ? <ExpandableList seleniumId={seleniumId}>{values}</ExpandableList> : values);
  }

  render() {
    const {
      value: { items, tooltip, emptyText },
      seleniumId = '',
    } = this.props;

    const valuesRender = items?.length && items.map((item, key) => (
      <EmailRender key={key} seleniumId={`${seleniumId}-${key}`} value={item} tooltip={tooltip} />
    ));

    return (
      <div data-seleniumid={`${seleniumId}-email-list-render`}>
        { valuesRender ? this.renderValues(valuesRender) : <NA seleniumId={seleniumId} value={emptyText} /> }
      </div>
    );
  }
}

export default EmailListColumnRender;

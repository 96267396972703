import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from '@wiley/cpp-ui-commons/lib/components/Svg';
import ClickAwayListener from 'app/components/ClickAwayListener';
import './EditControls.scss';

const EditControls = ({ className = '', children, actions, onClose, ignoreClickOutside, seleniumId }) =>
  (
    <ClickAwayListener
      onClickAway={() => {
        if (!ignoreClickOutside) onClose();
      }}
    >
      <div
        className={cn('edit-controls', className)}
        data-seleniumid={`${seleniumId}-editing`}
      >
        {children}
        <div className="edit-controls__button-wrap">
          {actions.map(({ id, title, onClick }) => (
            <span
              key={id}
              title={title}
              data-seleniumid={`${seleniumId}-${title.toLowerCase()}-button`}
              className="edit-controls__button"
              onClick={onClick}
            >
              <Svg
                name={id}
                className={cn('edit-field-button', { edit: true })}
              />
            </span>
          ))}
        </div>
      </div>
    </ClickAwayListener>
  );
EditControls.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
  })),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  ignoreClickOutside: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  seleniumId: PropTypes.string.isRequired,
};

export default EditControls;

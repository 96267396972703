export const EMPTY_URGENCY = {
  title: 'On Schedule',
  type: 'on_schedule',
};

export const mainUrgencyEnum = {
  onschedule: 'On Schedule',
  critical: 'Critical',
  at_risk: 'At Risk',
  due_today: 'Due Today',
  resolved: 'Resolved',
};

export const ignoreListForRAPage = [
  'onschedule',
];

const urgencyWithSubCategoriesEnum = {
  ...mainUrgencyEnum,
  late_licenses: 'Late Licenses',
  overdue: 'Overdue',
};

export const getUrgencyOrDefault = urgency => (urgency !== null ? urgency : EMPTY_URGENCY.type);

export default urgencyWithSubCategoriesEnum;

import React from 'react';
import PropTypes from 'prop-types';
import authErrors from 'app/enums/authErrors';

class LoginErrors extends React.PureComponent {
  render() {
    const {
      authError,
    } = this.props;
    if (!authError) {
      return null;
    }

    const { errorCode, refId } = authError;

    return (
      <div className="login-error pb-3">
        <div data-seleniumid="sign-on-error-message">{authErrors[errorCode] || errorCode}</div>
        {refId ? <div className="login-error__refId">refId: {refId}</div> : null}
      </div>
    );
  }
}

LoginErrors.propTypes = {
  authError: PropTypes.shape({
    errorCode: PropTypes.string,
    refId: PropTypes.string,
  }),
};

export default LoginErrors;

/**
 * Configuration
 * @module config
 */

const config = {
  rootUrl: process.env.CPP_APP_CONTEXT_PATH ? `/${process.env.CPP_APP_CONTEXT_PATH}/api/` : '/api',
};

export default config;
